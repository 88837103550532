import React, { useEffect } from "react";
import {Layout} from "antd";
import EventsSider from "./EventsSider";
import EventsForm from "./EventsForm";
import { useParams } from "react-router-dom";
import axiosData from "../../../helpers/axiosData";
import { useDispatch, useSelector } from "react-redux";
import { FullConvertDate } from "../../../config/CoreMethods";

const { Content } = Layout;

const EventUpdate = () => {

    const date = new Date();

    let currentDay= String(date.getDate()).padStart(2, '0');
    let nextDay= String(date.getDate() + 1).padStart(2, '0');
    let currentMonth = String(date.getMonth()+1).padStart(2,"0");
    let currentYear = date.getFullYear();
    let currentDate;
    let nextDate = `${currentYear}-${currentMonth}-${nextDay}`;
        currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

    let formData = useSelector ((state) => state.event.formData)

    const { event_id } = useParams();
    let dispatch = useDispatch();

    const getEvent = (id) => {
        
        axiosData.get(`/api/_web3/event/my-event/${id}`).then((res) => {

            if (res.data.success) {

                let data = res.data.data;
                if (data.start_date != null) {

                    let start_date = FullConvertDate(data.start_date, 1).split(' ');
                    data.start_date = start_date[0];
                    let start_time = start_date[1].split(':');
                    data.start_time = start_time[0]+":"+start_time[1];
                } else {
                    data.start_date = currentDate
                    data.start_time = formData.start_time
                }

                if (data.end_date != null) {
                    let end_date = FullConvertDate(data.end_date, 1).split(' ');
                    let end_time = end_date[1].split(':');
                    data.end_time = end_time[0]+":"+end_time[1];
                    data.end_date = end_date[0]; 
                } else {

                    data.end_date = nextDate
                    data.end_time = formData.end_time
                }

                dispatch({type: 'setFormData', payload:  data })
            }
        })
    }

    useEffect(()=>{

        getEvent(event_id);

    },[event_id])


    return <div className="container mx-auto pt-162 min-h-calc-head h-full">
        <Layout className="h-full settings-layout">
            <EventsSider />
            <Content className="pt-8 pl-10 pr-10 settings-content">
                <EventsForm />
            </Content>
        </Layout>
    </div>
}

export default EventUpdate;