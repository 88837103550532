import React from "react";
import {PATH} from "../../config";
import {Col, Row} from "antd";

const GetLandContent = ({L}) => {
  return (
    <>
      <div 
          className="flex items-center justify-center gland-intro" 
          style={{backgroundImage: `url(${PATH + 'get-land-intro.png'})`}}
      >
          <h1 className="mb-0 font-bold text-white gland-intro_ttl">
              {L.getNFTLandPlots}
          </h1>
      </div>
      <div className="gland-info">
          <Row gutter={44} className="gap-y-10">
            <Col xs={24} sm={24} md={12} lg={12}>
                <h2 className="font-mont mb-5 text-base gland-info_ttl">
                    {L.getLandPlotsLeftColTtl}
                </h2>
                <p className="font-light font-mont mb-7 gland-info_text">
                    {L.getLandPlotsLeftColTxt}
                </p>
                <div className="flex flex-col gap-y-4 gland-details">
                    <div className="flex items-center justify-between gap-x-2">
                        <span className="gland-details_ttl">{L.network}</span>
                        <div className="flex items-center gap-x-1">
                            <img src={PATH + 'icon_binance.svg'} alt="" className="gland-details_icon"/>
                            <span className="font-normal font-sans gland-details_text">
                                {L.binanceSmartChain}
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-x-2">
                        <span className="gland-details_ttl">{L.size}</span>
                        <div className="flex items-center gap-x-1">
                            <img src={PATH + 'profile/marker.svg'} alt="" className="gland-details_icon" />
                            <span className="font-normal font-sans gland-details_text">100x100 (1 ha)</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <h2 className="font-mont mb-5 text-base gland-info_ttl">
                  {L.getLandPlotsRightColTtl}
              </h2>
              <ul className="font-mont font-light gland-info_list">
                  <li>{L.getLandPlotsRightColList1}</li>
                  <li>{L.getLandPlotsRightColList2}</li>
                  <li>{L.getLandPlotsRightColList3}</li>
                  <li>{L.getLandPlotsRightColList4}</li>
                  <li>{L.getLandPlotsRightColList5}</li>
                  <li>{L.getLandPlotsRightColList6}</li>
                  <li>{L.getLandPlotsRightColList7}</li>
                  <li>{L.getLandPlotsRightColList8}</li>
                  <li>{L.getLandPlotsRightColList9}</li>
                  <li>
                    {document.location.pathname == '/get-direct-land' ? 
                    L.getLandPlotsRightColList10 
                    : L.getLandPlotsRightColList11}
                  </li>
              </ul>
            </Col>
          </Row>
      </div>
    </>
  )
}

export default GetLandContent;