import React, {useState, useEffect} from 'react';
import NumberFormat from 'react-number-format';
import '../assets/css/style.css';
import contractMetafluenceBuild from '../contracts/Metafluence.json';
import { ethers } from 'ethers';
import SetStakeModal from "./SetStakeModal";
import FindStakerTable from "./FindStakerTable";
import {PATH} from "../../../../config";
import { log_ } from '../../../../config/CoreMethods';
import { useSelector } from 'react-redux';

const ListStacking = (props) => {
    let L = useSelector (state => state.settings.L);

    const {
        metaToken,
        stakingToken,
        packageItem_,
        setLoadingText,
        setLoadingStaking,
        setLoadingClaim,
        setLoadingUnstaking,
        setLoadingData,
        SuccessStake,
        setSuccessStakeToast,
        claimUnstakeNotify,
        setSuccessUnstakeToast,
        // totalStaked,
        userBalance,
        MyLevel
    } = props

    const StakingJson = require('../contracts/'+packageItem_.fileJson);
    const [stakerInfo, setStakerInfo] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [stakeVal, setStakeVal] = useState("");
    const [stakePrice, setStakePrice] = useState("");
    const [unstakeConfirm, setUnstakeConfirm] = useState(false);
    const [packageTotalBalance, setPackageTotalBalance] = useState(0);
    const [packageTotalRewards, setPackageTotalRewards] = useState(0);
    const [tableInlineLoading, setTableInlineLoading] = useState(false);
    const [packageItem, setPackageItem] = useState({});

    let FrontLogin = useSelector ((state) => state.metahut.FrontLogin);
    let FrontNetwork = useSelector ((state) => state.metahut.FrontNetwork);
    let Account = useSelector ((state) => state.metahut.Account);

    let connectedContract;
    let totalBalance = 0;
    let totalRewards = 0;

    let provider = false;
    let signer = false;

    if(window.ethereum) {    
         provider = new ethers.providers.Web3Provider(window.ethereum);
         signer = provider.getSigner();
    } 

    const showStakeModal = () => {
        setIsModalVisible(true);
    };

    const hideStakeModal = () => {
        setIsModalVisible(false);
    };


    const findStaker = async (currentUser='') => {
        
        let UserWallet = currentUser && currentUser !== '' ? currentUser : Account;
        let location = window.location.search.split('wallet=')
        let wallet  = location[1] && location[1].length ? location[1] : '';
        console.log('userwallet : ', UserWallet, 'wallet:', wallet)
        UserWallet = wallet != '' ? wallet : UserWallet;
        UserWallet = Array.isArray(UserWallet) ? UserWallet[0] : UserWallet;
        connectedContract = new ethers.Contract(stakingToken, StakingJson.abi, signer);

        if (window.ethereum && UserWallet !== '') {
            
            setTableInlineLoading(true);
             
            try { 
                let result = packageItem_.level ? await connectedContract.getMyStakes() : await connectedContract.myStakes(UserWallet);
                let FilterResult = []; 
                // console.log('result:', result, packageItem.name, packageItem.stakingToken)
                if (result.length) {
                    for (let i = 0; i < result.length; i++) {
                        if (parseInt(result[i].amount)>0 && (packageItem.level==0 ||  (packageItem.level>0 && result[i].level == packageItem.level) ) ) {
                            FilterResult.push(result[i]);
                        }
                    }
                }
                
                if (FilterResult.length) {
                    log_({type:'findStaker then', wallet:UserWallet, FilterResult:FilterResult})
                }

                setLoadingData(true);
                setTableInlineLoading(false);
                calculateTotals(FilterResult)
                // totalStaked();
                // setStakerInfo(FilterResult);
                
            } catch(err) {
                log_({type:'findStaker error', wallet:UserWallet, result: err});
                console.log('findStaker error', err)
                setTableInlineLoading(false)
            }
        }
    }

    const stake = async () => {

        const { ethereum } = window;

        if (ethereum) {

            let StakeValue = parseInt(stakePrice);
            
            setStakePrice(0);

            if (StakeValue !== '' && StakeValue > 0 && StakeValue >= parseInt(packageItem.minPrice) ) {
                hideStakeModal();
                setLoadingText(true);
                StakeValue = parseInt(StakeValue)  * 1000000  + '000000000000';
                
                approve(StakeValue).then(async (res) => {

                    setTimeout(()=>{
                        StakeMeto(StakeValue)
                    }, 10000)
                    
                }).catch(err => {
                    setLoadingText(false);
                    console.log('Stake error', err.message)
                });
            }

        }
    }

    const StakeMeto = async (StakeValue) => {
        setLoadingText(false);
        setLoadingStaking(true);

        try {
            connectedContract = new ethers.Contract(stakingToken, StakingJson.abi, signer);
            await connectedContract.stake(StakeValue).then(result => {
                    setLoadingStaking(false);
                    setSuccessStakeToast(true);
                    SuccessStake(L.successfullyStaked);;
                    setTimeout(() => {
                        window.location.reload()
                    }, 5000)
                })
                .catch(err => {
                    setLoadingStaking(false);
                    console.log('Stake catch error', err)
                })

            } catch (error) {
                console.log('stake error', error)
            }
    }

    const approve = async (amount) => {
        
        const { ethereum } = window;
        
        if (ethereum) { 

            connectedContract = new ethers.Contract(metaToken, contractMetafluenceBuild.abi, signer);

            return await connectedContract.approve(stakingToken, amount)

        }
    }


    const convertTime = (timeStamp) => {
        const month = [ L.january, L.february, L.march, L.april, L.may, L.june, L.july, L.august, L.september, L.october, L.november, L.december];
        const date = new Date(timeStamp*1000);
        const dateMinState = (date.getMinutes() < 10 ? '0' : '');
        const dateSecState = (date.getSeconds() < 10 ? '0' : '');
        let date_ = date.getDate()+ "-"+ month[date.getMonth()] + "-" + date.getFullYear() + " " + date.getHours() + ":" + dateMinState + date.getMinutes() + ":" + dateSecState + date.getSeconds();
        return date_;
    }

    const getRewardFromContract = async (stakedAt) => {
         
        stakedAt = parseInt(stakedAt);
        console.log('stakedAt', stakedAt)
        try {
            connectedContract = new ethers.Contract(stakingToken, StakingJson.abi, signer);
            let result = await connectedContract.getTransferAmount(stakedAt) 
                
                // console.log('getTransferAmount-stakedAt', stakedAt, ', result ', result, parseInt(result[0])/10**18, parseInt(result[1])/10**18 )
                return parseInt(result[0])/10**18  

            } catch (error) {
                console.log('stake error', error)
            }
    }

    const calculateTotals = async (result) => {
        
        let newResult = [];

        if (result && result.length) {
            
            for (let i=0; i<result.length; i++) { 
                let item = result[i];
                let amount = parseFloat(item.amount); 

                let rewaard_;
                if( packageItem.lockUp !== false) {

                    if (typeof item.reward == 'undefined' ) {

                        rewaard_ = Math.round(  0.01*parseInt(item.percent) * (parseInt(item.amount)/10**16) ) / 100

                    } else {
                        rewaard_ = (parseInt(item.reward)/10**18)-parseInt(item.amount)/10**18
                    }                 

                    totalBalance += amount/(10**18);
                    totalRewards += rewaard_ ;

                } else {

                    totalBalance += amount/(10**18);

                    rewaard_ = parseInt(stakeReward(amount, packageItem.APY, packageItem.lockUp, item.claimedAt, packageItem.dateSecond))
                    totalRewards += rewaard_;
                } 
                
                newResult.push( Object.assign({CalculatedReward: rewaard_}, item ) ) 

            }

            // console.log('newResult', newResult)

            setStakerInfo(newResult);
            totalBalance = Math.round(parseFloat(totalBalance)*100,2)/100;

            setPackageTotalBalance(totalBalance)
            setPackageTotalRewards(totalRewards)

        } else {
            setPackageTotalBalance(0)
            setPackageTotalRewards(0)
        }
    }

    
    const stakeReward = (deposit, APY, lockUp, claimedAt = null, dateSecond = null) => {

        if(lockUp !== false) {
            deposit /= 10 ** 18;
            const percentPerMonth = APY / 12;
            const packagePercent = percentPerMonth * lockUp / 100;
            let reward = deposit * packagePercent;
            return reward.toFixed(2);
        } else {
            let _now = Math.floor(Date.now() / 1000);
            let hour = Math.floor((_now - claimedAt) / dateSecond);
            deposit /= 10 ** 18;

            return ((deposit * APY * hour)/100).toFixed(1);
        }
    }


    const stakePenalty = (amount, stakeTime, dateSecond, penaltyPercent, divideValue) => {
        amount /= 10 ** 18;
        stakeTime = parseInt(stakeTime);
        dateSecond = parseInt(dateSecond);

        const currentTime = Math.round(new Date(window.Time).getTime() / 1000);
        let stakeYetSecond =  (stakeTime + dateSecond) - currentTime;


        const currentTime1 = Math.round(new Date(window.Time).getTime() / 1000);
        let stakeYetSecond1 =  (stakeTime + dateSecond) - currentTime1;

        console.log('currentTime', currentTime, 'stakeYetSecond', stakeYetSecond, 'stakeYetSecond1', stakeYetSecond1, 'currentTime1', currentTime1,  'dateSecond', dateSecond)

        if (stakeYetSecond <= 0) {
            return 0;
        }

        let periodTime = dateSecond / divideValue;
        let periodPercent = penaltyPercent / divideValue;
        let remainingPeriodTime = stakeYetSecond/periodTime;
        remainingPeriodTime = parseInt(remainingPeriodTime) == remainingPeriodTime ? remainingPeriodTime : parseInt(remainingPeriodTime)+1;
        let remainingTotal = (remainingPeriodTime * periodPercent * amount) / 100 ;

        return  parseInt(remainingTotal) ;
    }

    const stakeStablePenalty = (amount, penaltyPercent) => {
        let remainingTotal;

        amount /= 10 ** 18;

        remainingTotal = amount * penaltyPercent / 100;

        return parseInt(remainingTotal);
    }

    const remainingDays = (stakeTime, dateSecond) => {
        const currentTime = Math.round(new Date(window.Time).getTime() / 1000);
        stakeTime = parseInt(stakeTime)
        dateSecond = parseInt(dateSecond)
        let diffday = (stakeTime+dateSecond) - currentTime
        return (diffday / (24 * 3600)).toFixed();
    }

    const claim = async (timeStamp, type_) => {

        const { ethereum } = window;

        if (ethereum) {

            const currentTime = Math.round(new Date(window.Time).getTime()/1000);
            const StakeInterval = currentTime - timeStamp - packageItem.dateSecond;

            if(StakeInterval > 0) {
                setLoadingClaim(true);
            } else {
                setLoadingUnstaking(true);
            }
            setUnstakeConfirm(false);

            connectedContract = new ethers.Contract(stakingToken, StakingJson.abi, signer);
            if (type_ == 1 || typeof connectedContract.claimAll == 'undefined') {
                await connectedContract.claim(timeStamp)
                    .then((result) => {
                        setLoadingClaim(false);
                        setLoadingUnstaking(false);
                        setSuccessUnstakeToast(type_)
                        claimUnstakeNotify(L.unstakeNotification)
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                        findStaker();
                        userBalance();
                    })
                    .catch(err => {
                        setLoadingClaim(false);
                        setLoadingUnstaking(false);
                        console.log(err.message)
                    })
            } else {
                
                await connectedContract.claimAll(timeStamp)
                    .then((result) => {
                        setLoadingClaim(false);
                        setLoadingUnstaking(false);
                        setSuccessUnstakeToast(type_)
                        claimUnstakeNotify()
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                        findStaker();
                        userBalance();
                    })
                    .catch(err => {
                        setLoadingClaim(false);
                        setLoadingUnstaking(false);
                        console.log(err.message)
                    })

            } 
        }
    }

    const StakeUnstake = (interval, timestamp) => {

        timestamp = parseInt(timestamp);
        interval = parseInt(interval);

        const currentTime = Math.round(new Date(window.Time).getTime()/1000);
        const StakeInterval = (timestamp + interval) - currentTime;
        let typeR = 1;
        if (StakeInterval > 0) {
            typeR = 0;
        }

        return typeR; 
    }

    const showHideButtons = (type, interval, timestamp) => {

        if( StakeUnstake(interval, timestamp) === 1 ) {
            if (type === 1){ // time not end yet - unstake
                //     enable
                return 'hidden';
            } else {
                //    disable
                return '';
            }
        } else {
            if (type === 1){
                //     enable
                return '';
            } else {
                //    disable
                return 'hidden';
            }
        }
    }

    const GetLevelData = async (Account, callback=()=>{}) => {
        
        if (packageItem_.level > 0 &&  packageItem_.level != MyLevel) {

            let newPackageItem = packageItem_;

            newPackageItem['active'] = -1
            setPackageItem(newPackageItem);

        }

        return callback();

        connectedContract = new ethers.Contract(stakingToken, StakingJson.abi, signer);

        if (window.ethereum && Account !== '') {
                         
            try {
                if (packageItem_.level == 1) { 

                    let result =  await connectedContract.levelDetails(MyLevel) 

                    if (result.length) {
                        let newPackageItem = packageItem_;

                        newPackageItem['APY'] = parseInt(result.levelReward)
                        newPackageItem['maxPrice'] = parseInt(result.levelMaxStakingAmount)/(10**18)
                        // console.log('old packageItem_', packageItem_, 'packageItem', packageItem, 'newPackageItem', newPackageItem)
                        setPackageItem(newPackageItem);
                    }

                    // console.log('GetLevelData then', result);
                }

                callback()
                
            } catch(err) {
                console.log({type:'GetLevelData error', wallet:Account, result: err})
            }
        }
    }


    useEffect(() => {  

        const isoString = new Date(window.Time).toISOString();

        const time = document.createElement("time");
        time.setAttribute("datetime", isoString);
        document.body.appendChild(time);

        setPackageItem(packageItem_)
        
        if (FrontLogin && FrontNetwork) { 
            GetLevelData(Account, ()=>{
                findStaker(Account);
            })
        }


    }, [Account, packageItem_, FrontLogin, FrontNetwork])


    return (
            <>             
                <div className="mb-6 rounded-md staking-pool" id="stake">
                    <div className={`flex items-center gap-x-6 pool-header justify-between px-10 py-6 rounded-md ${packageItem.lockUp !== false ? 'bg-indigo-900' : 'animate-bg'}`} style={packageItem.lockUp !== false ? {} : {backgroundImage: `url(${PATH + 'fire-effect-bg.gif'})`}}>
                        <div className="flex items-center gap-x-6 flex-1 z-10">
                            <div className="flex flex-col gap-y-1 items-center w-1/5">
                                <h2 className={`text-white text-lg font-medium uppercase ${packageItem.level > 0 ? 'color-'+packageItem.level : ''}`}>
                                    {packageItem.name}
                                </h2>
                                {packageItem.active > 0 ? 
                                <span className="text-cyan-500">
                                    {L.available}
                                </span> 
                                : <span className="text-rose-500">{packageItem.active==0 ? 
                                    L.filled : L.notAvaible}
                                </span>}
                            </div>
                            <div className="flex flex-col gap-y-5 items-center w-1/6">
                                <h2 className="text-white text-xl font-medium">{L.period}</h2>
                                <span className="text-white text-base font-light">{packageItem.date}</span>
                            </div>
                            <div className="flex flex-col gap-y-5 items-center w-1/6">
                                <h2 className="text-white text-xl font-medium">{packageItem.level > 0 ? L.reward : L.apy}</h2>
                                <span className="text-white text-base font-light">{packageItem.lockUp !== false ? packageItem.APY+'%' : '87.6%'}</span>
                            </div>
                            <div className="flex flex-col gap-y-5 items-center w-1/6">
                                <h2 className="text-white text-xl font-medium">
                                    {packageItem.lockUp !== false ? 
                                    L.monthlyRate : L.hourlyRate}
                                </h2>
                                <span className="text-white text-base font-light">{packageItem.lockUp !== false ? (packageItem.APY / 12).toFixed(2)+'%' : '0.01%'} {}</span>
                            </div>
                            <div className="flex flex-col gap-y-5 items-center w-1/6">
                                <h2 className="text-white text-xl font-medium">{L.balance}</h2>
                                {packageTotalBalance > 0 && FrontLogin && FrontNetwork ?
                                    <div className="flex items-center gap-x-1">
                                        <img src={PATH + 'token.svg'} alt="meto" className="w-5" />
                                        <NumberFormat
                                            value={packageTotalBalance}
                                            className="text-white text-base font-light"
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={(value, props) =><span {...props}>{value}</span>}
                                        />
                                    </div> : '-'
                                }
                            </div>
                            <div className="flex flex-col gap-y-5 items-center w-1/7">
                                <h2 className="text-white text-xl font-medium">{L.stakeRewards}</h2>
                                {((packageItem.lockUp === false && packageTotalRewards > 0) || packageTotalRewards > 0) && FrontLogin && FrontNetwork ?
                                    <div className="flex items-center gap-x-1">
                                        <img src={PATH + 'token.svg'} alt="meto" className="w-5" />
                                        <NumberFormat
                                            value={packageTotalRewards}
                                            className="text-white text-base font-light"
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={(value, props) =><span {...props}>{value}</span>}
                                        />
                                    </div> : '-'
                                }
                            </div>
                        </div>

                        <button
                            disabled={packageItem.active>0 & FrontLogin && FrontNetwork ? '' : 'disabled' }
                            className={`border-0 text-base px-7 py-2 z-10 rounded-md ${ packageItem.active>0 && FrontLogin && FrontNetwork ? 'bg-cyan-400 text-indigo-900 hover:bg-cyan-500 cursor-pointer' : 'bg-indigo-600 text-white opacity-50 cursor-not-allowed'}`}
                            onClick={() => showStakeModal()}
                            type="button">{L.stake}</button>
                    </div>
                    {FrontNetwork ? 
                    
                        <FindStakerTable
                            showHideButtons={showHideButtons}
                            stakerInfo={stakerInfo}
                            packageItem={packageItem}
                            convertTime={convertTime}
                            stakeReward={stakeReward}
                            stakePenalty={stakePenalty}
                            stakeStablePenalty={stakeStablePenalty}
                            claim={claim}
                            setUnstakeConfirm={setUnstakeConfirm}
                            unstakeConfirm={unstakeConfirm}
                            remainingDays={remainingDays}
                            tableInlineLoading={tableInlineLoading}
                        />
                    :""}

                </div>
                {isModalVisible ?
                    <SetStakeModal
                        hideStakeModal={hideStakeModal}
                        stake={stake}
                        minPrice={packageItem.minPrice}
                        maxPrice={packageItem.maxPrice}
                        id={packageItem.id}
                        setStakeVal={setStakeVal}
                        stakeVal={stakeVal}
                        setStakPrice={setStakePrice}
                        stakeReward={stakeReward}
                        packageItem={packageItem}
                    /> : ''
                }
            </>
    );
}

export default ListStacking;
