import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer, { fetchUser } from "./features/userSlice";
// import ResponsesReducer from "./reducers/ResponsesReducer";
import thunk from "redux-thunk";

import {metahutReducer, landReducer, menuReducer, settingsReducer, eventReducer} from './reducers';

const store = configureStore({
  reducer: {
    menu: menuReducer,
    metahut : metahutReducer,
    land : landReducer,
    user: userReducer,
    settings: settingsReducer,
    event: eventReducer,
    // ResponsesReducer: ResponsesReducer,

  },
  middleware: [thunk],
});

// const initialThunks = async (dispatch, getState) => {
//   await dispatch(fetchUser());
//   console.log(getState().user.user);
// };

// store.dispatch(initialThunks);

export default store;
