/* eslint-disable no-unused-expressions */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createSelectorHook } from "react-redux";
import { toast } from "react-toastify";
import { ROUTES } from "../../config/constants";

import { ethers } from 'ethers';


import {getCookie, redirectTo} from '../../config/CoreMethods'

export const fetchUser = createAsyncThunk(
  "/user/getUser",
  async (payload, { dispatch, rejectWithValue }) => {
    return await userSlice
      .getInitialState()
      .instance.get("/api/user")
      .then(async (res) => { 
        return await res.data
      })
      .catch((error) => { 
        if(error.request.status === 401) {
            const path = document.location.pathname;
            
            if ( (path.indexOf('/profile') > -1 && path.length < 11) || path.indexOf('/settings') > -1 || path.indexOf('/admin') > -1 || path.indexOf('/referrals/') > -1) {
              redirectTo('/login')
            }
        }
      })
      
  }
);

export const destroyUser = createAsyncThunk(
  "/user/destroyUser",
  async (payload, { dispatch }) => {
    redirectTo(ROUTES.loginPage.path)
    return await userSlice.getInitialState().instance.delete("/api/_web3/users/logout")
      .then(async (res) => { 

        return await res.data;
      })
      .catch((error) => {
      console.log('log out error', error)
    });
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    ReloadAllPages : true,
    user: null,
    fetched : 0,
    provider: null,
    modalLoading: true,
    reloadAfterFetching: false,
    alertUserIfMetamaskIsNotInstalled: true,
    cacheProvider: false,  
    disableInjectedProvider: false,
    instance: axios.create({
      withCredentials: true,
      baseURL: "https://app.metafluence.com/api/v1/public",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
    }),
  },
  reducers: {
    login:  async (state, param1, param2) => {
      try {

        const web3Modal = userSlice.caseReducers.prepareWeb3Modal(state);
        const provider = await web3Modal.connect();
        
        state.ReloadAllPages = false

        await userSlice.caseReducers.fetchAccount(state, null, provider);
        state.modalLoading = false;
                
        if (state.reloadAfterFetching) window.location.reload();

      } catch (e) { 

        if (state.reloadAfterFetching) window.location.reload();
        state.modalLoading = false;
      }
    },

    fetchAccount: async (state, action, web3Provider) => {
      
      const referrer = getCookie('referrer')
      const provider = new ethers.providers.Web3Provider(web3Provider);
      const message = await ( await userSlice.getInitialState().instance.get("/api/_web3/users/signature") ).data.message;
      const url = state.user===null ? '' : '/connect';

      await userSlice.getInitialState().instance.post("/api/_web3/users"+url, {

          signature: await provider.getSigner().signMessage(message),
          address: await provider.getSigner().getAddress(),
          referrer: referrer, 
        
        }).then((res) => {

          if (typeof res.data.success !='undefined' && res.data.success) {
            state.ReloadAllPages = true;
            
            localStorage.setItem("user", res.data.account);
            state.user = res.data;
            window.location.reload()

          } else {
              toast.error(res.data.error)
          }
        }).catch((error)=>{
            toast.error('Error occured, '+error)
        });

      state.provider = provider;
    },

    logout: async (state) => {
      state.provider = null;

      const web3Modal = userSlice.caseReducers.prepareWeb3Modal();
      await web3Modal.clearCachedProvider();
      await userSlice.getInitialState().instance.delete("/api/_web3/users/logout")
        .then((res) => {
          localStorage.removeItem("user");
          console.log('then logout', res)
          state.user = null;
        })
        .catch((err)=>{
          console.log('errr logout', err)
        });
    },

    getProvider: async (state) => {
      if (!state.provider) {
        const web3Modal = userSlice.caseReducers.prepareWeb3Modal();
        const web3Provider = await web3Modal.connect();
        this.provider = new ethers.providers.Web3Provider(web3Provider);
      }
      return state.provider;
    },

    prepareWeb3Modal: (state) => {
      let Web3Modal, WalletConnectProvider;
      if ( !(window.web3 || window.ethereum) && state.alertUserIfMetamaskIsNotInstalled ) {
        alert(`Please install Metamask first`);
        return;
      }

      if (window.Web3Modal.default) {
        Web3Modal = window.Web3Modal.default;
      }

      if (window.WalletConnectProvider) {
        WalletConnectProvider = window.WalletConnectProvider.default;
      }
      
      var options = {
        cacheProvider: userSlice.getInitialState().cacheProvider,
        disableInjectedProvider: userSlice.getInitialState().disableInjectedProvider,
      };

      const web3Modal = new Web3Modal(options);
      return web3Modal;
    },
  },
  
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchUser.fulfilled, (state, action) => {
 
      if (action.payload) {
        localStorage.setItem("user", action.payload.account);
        state.user = action.payload;       

      } else {
        
        localStorage.removeItem("user");
        state.user = null;
      }
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      localStorage.removeItem("user");
      state.user = null;
    });
    builder.addCase(destroyUser.fulfilled, (state, action) => {

      localStorage.removeItem("user");
      state.user = null;
      state.provider = null;
      // if (state.reloadAfterFetching) 
      window.location.reload();
    });
    builder.addCase(destroyUser.pending, (state, action) => {
      state.provider = null;
    });
  },

});

export const { login, logout, prepareWeb3Modal, getProvider } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export const selectInstance = (state) => state.user.instance;

export const selectCompletedTodoDescriptions = createSelectorHook(
  [],
  (user) => (state) => state.user.user
);

export default userSlice.reducer;
