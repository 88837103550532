import React from "react";
import ReactPlayer from 'react-player'

const VideoContainer = () => {
    return (
        <>
            <ReactPlayer url='https://www.youtube.com/watch?v=h5iP9ds0wIw' controls={true} width="100%" />
            <div className="mt-5">
                <p className="text-lg">Metahut is a Metaverse residence, consisting of various virtual spaces, each having its own monetization and revenue models:</p>
                <div className="text-lg mt-4">
                    <p className="mt-1 mb-0">1. Connect your MetaMask wallet & ensure it has enough funds</p>
                    <p className="mt-1 mb-0">2. Choose how many Metahuts you want to buy</p>
                    <p className="mt-1 mb-0">3. Select the payment method & purchase</p>
                </div>
            </div>
        </>
    )
}

export default VideoContainer;