
import { ethers } from 'ethers';
import {API, MetoAddress, BusdAddress, NFTAddress, MetahutAddress, ChainId, MetaABI, NFTABI, MetahutABI, ChainName, ChainRPCUrls, ChainBlockExplorerUrls} from '../../config';
import {get, getCookie, log_, saveBuyedNFT, setCookie} from '../../config/CoreMethods'


/*-------------------------------------*/
const InitWalletConnect  =  (callback = ()=>{}) => async (dispatch, state) => {
    const { ethereum } = window;

    if (!ethereum) {
        return;
    } 

    await ethereum.request({ method: 'eth_accounts' }).then((MyAccounts) => {
        let MyAccount = '';

        if (MyAccounts && MyAccounts[0]) {
            MyAccount = MyAccounts[0].toLowerCase() 

            dispatch({ type: 'SetFrontLogin', payload: true })
            setCookie('account', MyAccount);
            dispatch({type: 'SetAccount', payload: MyAccount})
            callback(state())
        }  else {
            dispatch({type: 'SetAccount', payload: ''})
        }

        return MyAccount
    }).catch(error => {
        console.log('InitWalletConnect error', error)
    }).then((MyAccount)=>{
        callback(state());
    });
}


/*-------------------------------------*/
const getUserBalance =  (callback=()=>{}) => async (dispatch, state) => { 

    const {ethereum} = window;
    let Account = typeof state != 'undefined' ? state().metahut.Account : '';
    if (ethereum && Account && Account !== '') {

        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner()

        const connectedMetoContract = new ethers.Contract(MetoAddress, MetaABI, signer)
        const connectedBusdContract = new ethers.Contract(BusdAddress, MetaABI, signer)

        await connectedMetoContract.balanceOf(Account).then( async (data)=>{
            dispatch({type : 'SetBalanceMeto', payload : (parseInt(data)/(10**18)).toFixed()})
            return (parseInt(data)/(10**18)).toFixed();
        }).catch((err1) => {
            dispatch({type : 'SetBalanceMeto', payload : ''})
        }).then( async (data1) => {

            await connectedBusdContract.balanceOf(Account).then((data)=>{
                dispatch({type : 'SetBalanceBusd', payload : (parseInt(data)/(10**18)).toFixed()})
                return (parseInt(data)/(10**18)).toFixed();    
            }).catch((err1) => {
                dispatch({type : 'SetBalanceBusd', payload : ''})
            }).then((data2)=>{    
                callback(state())
            })

        })
    }
}

/*-------------------------------------*/
const CheckNetwork = (callback = ()=>{}) => async (dispatch, state) => {

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    await provider.getNetwork().then(network => {
        
        if(network && network.chainId) {
            
            let chainId = network.chainId;

            if (chainId === ChainId) {
                dispatch({type : 'SetFrontNetwork', payload : true}) 
            } else {
                dispatch({type : 'SetFrontNetwork', payload : false}) 
            }

            return chainId === ChainId
        }
        return 0;

    }).catch(error => {
        console.log('CheckNetwork error --> ', error)
    })
    .then (chainId => {
        callback(typeof chainId != 'undefined' ? chainId : 0)
    })

}


/*-------------------------------------*/
const connectWallet = (callback = ()=>{}) => async (dispatch, state) => {
    
    const { ethereum } = window;

    if (ethereum) {
        
        await ethereum.request({ method: "eth_requestAccounts" }).then((MyAccounts)=> {

            let MyAccount = MyAccounts[0]

            setCookie ("account", MyAccount)
            dispatch({type: 'SetAccount', payload: MyAccount})

            return MyAccount
        }).catch(error => {
            console.log('connectWallet error', error)
        })
        .then((MyAccount) => {
            callback(state());
        });
    }
     
}


const checkBrowser = () => (dispatch, state) => {
    
    const handleEthereum = () => {
        const { ethereum } = window;
        if (ethereum && ethereum.isMetaMask) {
            dispatch ({ type: "SetIsMetamask", payload: true })
        } else {
            dispatch ({ type: "SetIsMetamask", payload: false })
        }
    }

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        dispatch ({ type: "SetIsMobile", payload: true })
    }

    if (window.ethereum) {
        handleEthereum();
    } else {
        window.addEventListener('ethereum#initialized', handleEthereum, { once: true });
        setTimeout(handleEthereum, 3000); // 3 seconds
    }
}

const getMapSettings = (callback = ()=>{}) => async (dispatch, state) => {
    
    const { ethereum } = window;
        
    if (ethereum) {
    
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner()
        let connectedContract = new ethers.Contract(NFTAddress, NFTABI, signer)

        await connectedContract.BUSD_METO_PAIR().then((datam) => {

            let price = parseInt(datam)/10**18
            let settingsData = {meto_price: (1/price) } 
            dispatch({type : 'SetMapSettings', payload : settingsData})
            localStorage.setItem('meto_settings', JSON.stringify(settingsData))

        })
        .catch((error)=>{
            get (API+'get_settings.php', function(result) {

                let data_;
    
                try {
                    data_ = JSON.parse(result);
                } catch(err) {
                    // console.log('err parse', err);
                }
                
                if ( data_ != null && data_ !== 'null' && data_ !== '' && (typeof data_ == 'object' || typeof data_.length != 'undefined') ) {
                    
                    dispatch({type : 'SetMapSettings', payload : data_})
                    localStorage.setItem('meto_settings', JSON.stringify(data_))
                }
            });

        })
        .then(() => {   
            callback(state());
        });

    }
}



/*-------------------------------------*/
const OnAccountChanged  =  (callback = ()=>{}) => async (dispatch, state) => {
    const { ethereum } = window;

    if (!ethereum) {
        callback(state(), '')
        return;
    } 
    
    ethereum.on("accountsChanged", (MyAccounts) => { 
        
        let MyAccount = '';
        
        if (MyAccounts && MyAccounts.length && MyAccounts[0]) {

            
            MyAccount = MyAccounts[0].toLowerCase()  

            setCookie('account', MyAccount); 

            if (typeof state().user.then != 'undefined') {
                
                state().user.then(datam=>{ return datam; })
                .catch(error => { console.log('error promise', error) })
                .then (data => {
                    
                    if (typeof data != 'undefined' && typeof data.ReloadAllPages != 'undefined' && data.ReloadAllPages) {
                        window.location.reload()
                    }
                })
            } else if (typeof state().user.ReloadAllPages != 'undefined' && state().user.ReloadAllPages) {
                window.location.reload()
            }

        }  else {

            dispatch({ type: 'ResetLand' })
            dispatch({ type: 'ResetMetahut' })
    
            setCookie('account', '');
        }
        
        callback(state(), MyAccount);
    })
} 
     

const OnNetworkChanged = (callback=()=>{}) => async (dispatch, state) => {
    const { ethereum } = window;

    if (ethereum) {
        ethereum.on("chainChanged", (networkId) => {
            
            networkId = parseInt(networkId);

            console.log('network changed-', networkId)            
            window.location.reload()
            return false;
        })
    }
}

    

const switchNetwork = (callback = ()=>{}) => async (dispatch, state) => {

    const { ethereum } = window;

    try {

        if (ethereum) {
            await ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{chainId: `0x${Number(ChainId).toString(16)}`}]
            }).catch( async error => {
                
                console.log('catch err', error)

                if (error.code === 4902) { 

                    await window.ethereum.request({
                        id: 1,
                        jsonrpc: "2.0",
                        method: 'wallet_addEthereumChain',
                        params: [
                            { 
                                chainId: '0x'+Number(ChainId).toString(16),
                                rpcUrls: ChainRPCUrls, 
                                chainName: ChainName,
                                blockExplorerUrls: ChainBlockExplorerUrls,
                                nativeCurrency: {
                                    name: 'BNB', //string;
                                    symbol: "BNB",
                                    decimals: 18,
                                },
                            },
                        ]
                    }) 
                    .then((dat) => {
                        console.log('add Chain to Wallet', dat)
                    }).catch((error)=>{
                        console.log('AddethereumChain error', error)
                    }).then( () => {
                        callback(state())
                    })       
                } else {
                    callback(state())
                }

            }).then(() => {
                console.log('then network switched')
                callback(state())
            }); 
        } else {
            callback(state())
        }
    } catch (error) {
        console.log('error', error)
    }
}



/*-------------------------------*/
const BuyMetahut = (is_meto, count_) => async (dispatch, state) => { 
    try {
        const { ethereum } = window;
        let metahuts
        

        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner()
            const connectedContract = new ethers.Contract(MetahutAddress, MetahutABI, signer)

            // --? setShowPayConfirmLoading(1);
            dispatch({type: 'SetMetahutBuyLoading', payload: 2});

            console.log('MetahutAddress', MetahutAddress, ', MetahutABI ', MetahutABI)

            if( is_meto ) { 
                // console.log('buyMetahuts is meto', is_meto, 'count_', count_)
                metahuts = await connectedContract.mintWithMeto(count_)
            } else { 
                metahuts = await connectedContract.mintWithBusd(count_)
            } 


            await metahuts.wait().then((result)=>{

                log_({'action':"buyMetahut", 'result': result, 'txtn': metahuts, 'is_meto': is_meto, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto':state().metahut.BalanceMeto});

                dispatch({type: 'SetMetahutBuyLoading', payload: 3});

                saveBuyedNFT(state().metahut.Account)
            })

        } else {
            // console.log("Ethereum object doesn't exist")
        }
    } catch (error) {

        // setBuyMetahut(false);

        log_({'action':"buyMetahut-error", 'is_meto': is_meto, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto, 'error': error});

        dispatch({type: 'SetMetahutBuyLoading', payload: 4});

    }
}



    /*-----------------------------------*/
const approveMetahut = (is_meto, count_, totalPrice, callback=()=>{} ) => async (dispatch, state) => {

    let metahutTXN;
    totalPrice = Math.round(totalPrice);

    try {
        // alert(MetoAddress + '-' + MetaABI + '-' + BusdAddress)
        const { ethereum } = window;

        if (ethereum) {

            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner()
            let connectedContract;
            dispatch({type: 'SetMetahutBuyLoading', payload: 1});

            if(is_meto) {
                connectedContract = new ethers.Contract(MetoAddress, MetaABI, signer)
                metahutTXN = await connectedContract.approve(MetahutAddress, totalPrice + '000000000000000000' )
            } else {
                connectedContract = new ethers.Contract(BusdAddress, MetaABI, signer)
                metahutTXN = await connectedContract.approve(MetahutAddress, totalPrice + '000000000000000000')
            }

            await metahutTXN.wait().then((data)=>{
                log_({'action':"approveMetahut", 'txtn': metahutTXN, 'is_meto': is_meto, 'totalPrice' : totalPrice, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto});
                console.log('then data', data)
                // dispatch({type: 'SetMetahutBuyLoading', payload: 0});
                setTimeout(()=>{
                    dispatch(BuyMetahut(is_meto, count_)); 
                }, 7000)
                
            }).catch(errorm => {
                console.log('errorm', errorm)
            })

        } else {
            // console.log("Ethereum object doesn't exist")
        }
    } catch (error) {

        log_({'action':"approveMetahut-error", 'txtn': metahutTXN, 'is_meto': is_meto, 'totalPrice' : totalPrice, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto, 'error': error});
        dispatch({type: 'SetMetahutBuyLoading', payload: 4, message: "Error occured"});

    }

}





/*-----------will-remove-after-publish-admin---------------------*/
const getMyMetahutCollection = () => async (dispatch, state) => {

    let myCollections = [];

    try {

        let Account = typeof state != 'undefined' ? state().metahut.Account : getCookie('account');
        let nft_name = 'by ' + String(Account).substring(0, 6) + ' ... ' + String(Account).substring(38);

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        // const connectedContract = new ethers.Contract(NFTRoomAddress, NFTRoomABI, signer)
        const connectedContract = new ethers.Contract(MetahutAddress, MetahutABI, signer);

        await connectedContract.myCollection()
        .then((myCollection) => {
            console.log('myCollection then', myCollection)
            for (let i = 0 ; i < myCollection.length; i++) {
                myCollections.push( {
                    shopping_room : parseInt(myCollection[i]['event_room']),
                    nft_name: nft_name
                } )

            }
            dispatch({type: 'SetMetahutCollections', payload: myCollections});

        }).catch((error) => {
            console.log('----------', error)
        })


    } catch (error) {
        console.log(error)
    }

}

const connectWalletOld = () => async (dispatch, state) => {
     /*-------------------------------------*/
    try {
        const { ethereum } = window;

        if (ethereum) {
            await ethereum.request({ method: "eth_requestAccounts" })
            .then((MyAccounts)=> {

                let Account = MyAccounts[0]
                setCookie ("account", Account)

            });

        }

    } catch (error) {
        // console.log('connectWallet: ', error)
    }

}


export {InitWalletConnect, CheckNetwork, getUserBalance, connectWallet, getMapSettings, OnAccountChanged,
     OnNetworkChanged, switchNetwork, checkBrowser, approveMetahut, getMyMetahutCollection, connectWalletOld}