import React from "react";
import {Button, Col, Row, Tooltip} from "antd";
import {PATH} from "../../../config";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../config/constants";
import { ConvertDate } from "../../../config/CoreMethods";
import { useSelector } from "react-redux";

const SliderItem = ({slide, IWantToGo, LoggedIn}) => {

    let L = useSelector (state => state.settings.L);

    return (
        <div className="font-mont eslider_item">
            <Row>
                <Col md={12} lg={12}>
                    <img src={slide.cover_image} alt="" className="eslider_img" />
                </Col>
                <Col md={12} lg={12}>
                    <div className="eslider_details">
                        <div className="eslider_header">
                            <span className="eslider_date">{ConvertDate(slide.start_date_, 1)} PM</span>
                            <div className="eslider-price">
                                {slide.ticket_value ?
                                    <>
                                        <img src={PATH + slide.ticket_type + '.svg'} alt=""/>
                                        <span>{slide.ticket_value}</span>
                                    </> :
                                    <span>{L.free}</span>
                                }
                            </div>
                        </div>
                        <h2 className="eslider-title">{slide.name}</h2>
                        <div className="eslider_credit">
                            <div className="eslider_user">
                                <div className="relative avatar_box user-box" 
                                    style={{backgroundColor: slide.user_bgcolor}}
                                >
                                    <img src={slide.avatar} onError={e => e.target.src=PATH+'avatar.png'} width="100%" height="100%" className="user-avatar"/>
                                </div>
                                <span className="eslider_username">{slide.user_name}</span>
                            </div>
                            <div className="eslider_metahut">
                                <img src={PATH + 'metahut-mini.svg'} alt=""/>
                                <span>{slide.metahut_name}</span>
                            </div>
                            <div className="eslider_plot">
                                {slide.categories.map(el => {
                                    return (
                                        <Tooltip placement="bottom" title={el.name}>
                                            <div className="eslider-catg" key={el.name}>
                                                <i className={el.icon}></i>
                                            </div>
                                            <span>{el.name}</span>
                                        </Tooltip>
                                    )
                                })}
                            </div>
                        </div>
                        <p className="eslider_desc">{slide.description}</p>
                        <div className="eslider_action">
                            <div className="eslider-visitor">
                                {slide.want_users && slide.want_users.length ?
                                    <>
                                        {slide.want_users.map((i, index) => {
                                            return ( 
                                                <div className="relative avatar_box user-box" 
                                                    style={{backgroundColor: i.bgcolor}} key={index}
                                                >
                                                    <img src={i.avatar} 
                                                        onError={e => e.target.src=PATH+'avatar.png'} width="100%" 
                                                        height="100%" 
                                                        className="user-avatar" 
                                                        alt="avatar"
                                                    />
                                                </div>
                                            )
                                        })}
                                        <div className="eslider-visitor_count">{slide.total_want_go}</div>
                                    </>
                                :''}
                            </div>
                            {LoggedIn ?
                                IWantToGo ? 
                                    slide.going ?
                                    <Button type="primary" disabled="disable" className="eslider-btn btn-disable">{L.goingBtn}</Button>
                                    :
                                    <Button type="primary" onClick={()=> IWantToGo(slide.id)} className="eslider-btn">{L.wantToGoBtn}</Button>
                                :''
                            : IWantToGo ? 
                                <Link to={ROUTES.loginPage.path}>
                                    <Button type="primary" className="eslider-btn">
                                        {L.wantToGoBtn}
                                    </Button>
                                </Link>
                            :''}

                            
                        </div>
                        <div className="eslider-bottom">
                            <div className="eslider-pool">
                                {slide.reward_value ?
                                    <>
                                        <div className="eslider-reward">
                                            <span>{L.rewardPool}:</span>
                                        </div>
                                        <div className="eslider-amount">
                                            <img src={PATH + slide.reward_type + '.svg'} alt=""/>
                                            <span>{slide.reward_value} {slide.reward_type.toUpperCase()}</span>
                                        </div>
                                    </> 
                                :''}
                            </div>
                            <div className="eslider-share">
                                <i className="icon-share"></i>
                                <span>{L.share}</span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SliderItem;
