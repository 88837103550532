import React from 'react';
import tokenIcon from '../assets/img/loading.svg';

const Loading = () => {
    return (
        <div className="w-full h-full fixed block top-0 left-0 bg-gray-900 z-50">
            <div className="top-1/2 my-0 mx-auto block relative w-0 h-0">
                <div className="animate-spin inline-block w-10 h-10" role="status">
                    <img src={tokenIcon} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default Loading;