import React from "react";
import { useSelector } from "react-redux";

const TermsConditions = () => {
    let L = useSelector   (state => state.settings.L);

    return (
        <div className="container mx-auto px-12 relative pt-162 min-h-calc-head py-8">
            <div className="wrapper mt-20">
                <h1 className="text-white text-3xl font-bold mb-4">{L.termsTitle}</h1>
                <p className="font-light mb-0 mb-12 text-base">{L.termsSubTitle}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.termsAcceptance}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.termsAcceptanceText}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.termsUseMetafluence}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.termsUseMetafluenceText}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.termsOwnership}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.termsOwnershipText}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.termsProhibited}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.termsProhibitedTxt1}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.termsProhibitedTxt2}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.termsProhibitedTxt3}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.termsProhibitedTxt4}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.termsProhibitedTxt5}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.termsUserContent}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.termsUserContentText}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.termsDisclaimers}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.termsDisclaimersText}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.termsIndemnifica}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.termsIndemnificaText}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.termsChanges}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.termsChangesText}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.termsGoverning}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.termsGoverningText}</p>
            </div>
        </div>
    )
}

export default TermsConditions;