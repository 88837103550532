import React, {useState} from "react";
import {Button, Layout} from "antd";
import {Link} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {PATH} from "../../config";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";
import axiosData from "../../helpers/axiosData";
import AuthNote from "./AuthNote";
import {useSelector} from "react-redux";

const { Content } = Layout

const ForgotPass = () => {

    let L = useSelector(state => state.settings.L);
    const [email, setEmail] = useState()

    const sendNewPassword = () => {

        axiosData.post("/api/_web3/user/forgot-password/", {email:email}).then((res) => {
            if (res.data.success) {
                toast.success(`${L.checkEmailSuccess}`)
            } else {
                toast.error(`${L.checkEmailError}`)
            }
        })
    }

    return (
        <div className="min-h-calc-head h-full flex items-center justify-center" 
        style={{paddingTop: "92px", background: `url(${PATH + 'bg-auth.png'}) center center / cover`}}>
            <div className="container mx-auto">
                <Layout className="bg-transparent h-full">
                    <Content className="pt-4 pb-4 pl-14 pr-14 xs-p-2">
                        <div className="flex flex-col items-center gap-y-5">
                            <AuthNote />
                            <ToastContainer />
                            <div className="flex justify-center">
                                <div className="login-form form-box">
                                    <h1 className="text-center text-white font-normal text-xl mb-6">
                                        {L.forgotPass}
                                    </h1>
                                    <label htmlFor="acc_username" className="text-white text-base font-light mb-2 block">{L.emailLabel}</label>
                                    <div className="flex items-center gap-x-6 mb-4 xs-w-100">
                                        <input
                                            className="bg-indigo-700 border-0 rounded-md text-white login-input w-100"
                                            type="text"
                                            name="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex justify-center mt-7">
                                        <Button type="button" onClick={sendNewPassword} className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium text-base login-btn flex items-center justify-center gap-x-3">
                                            {L.resetPassword}
                                        </Button>
                                    </div>
                                    <div className="mt-5 flex items-center justify-center gap-x-1 gap-y-2 flex-wrap">
                                        <span>{L.noAccount}</span>
                                        <div>
                                            • <Link to={ROUTES_CONFIG.register.path} className="text-sm text-cyan-500">New Account</Link> • <Link to={ROUTES_CONFIG.loginPage.path} className="text-sm text-cyan-500">{L.login}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>
        </div>
    )
}

export default ForgotPass;