import React from "react";
import {NavLink} from "react-router-dom";
import {Layout} from "antd";
import {ROUTES as ROUTES_CONFIG} from "../../../config/constants";
import { useSelector } from "react-redux";

const { Sider } = Layout

const ReferralsSidebar = () => {

    let L = useSelector (state => state.settings.L);
    return (
        <Sider className="pt-8 pb-8 pl-10 pr-4 bg-indigo-900 sidebar-bg h-full z-1 visible-md" width="260px">
            <h3 className="text-gray-300 font-light text-base uppercase">{L.navReferrals}</h3>
            <ul className="pl-4 text-lg flex-col flex mt-10 vert_menu">
                <li>
                    <NavLink to={ROUTES_CONFIG.referralLink.path} className="text-white font-light">
                        {L.navReferral}
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES_CONFIG.influenceNetwork.path} className="text-white font-light">
                        {L.navNetwork}
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES_CONFIG.monthlyPerformance.path} className="text-white font-light">
                        {L.navPerformance}
                    </NavLink>
                </li>
            </ul>
        </Sider>
    )
}

export default ReferralsSidebar;