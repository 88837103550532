import React, { useEffect, useState } from "react";
import {Link, NavLink, useParams} from "react-router-dom";
import {Col, Layout, Row, Skeleton} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setOpenPlotOnMap} from "../../../redux/actions/landActions";
import {PATH} from "../../../config";
import {addPlotToLocalstorage} from "../../../config/CoreMethods";
import Intro from "./Intro";
import Performance from "./Performance";
import {ROUTES, ROUTES as ROUTES_CONFIG} from "../../../config/constants";
import axiosData from "../../../helpers/axiosData";

const { Content } = Layout

const PublicLands = () => {

    let L = useSelector(state => state.settings.L);
    const dispatch = useDispatch();
    let { username } = useParams();
    const [listData, setListData] = useState([])

    const getAllData = (username) => {
        axiosData.get("/api/_web3/land/get-my-lands/"+username).then((res) => {

            if (res.data.data.length) {
                setListData( res.data.data ); 
                addPlotToLocalstorage(res.data.data)

            } else {
                setListData( [] );     
            }
        })
    }


    const[UserData, setUserData] = useState({});
        
    /* ------ getAllQuizes ---------*/
    const getUserData = (username) => {

        axiosData.get("/api/_web3/user/public-profile/"+username).then((res) => {
            if (res.data && res.data.success) {
                 setUserData( res.data.data ); 
            } else {
            }
        })
    }
    
    useEffect(() => { 

        getUserData(username);  

        getAllData(username);

    }, [username]);
   


    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10">
                    <Intro GetUser={UserData}/>
                    <div className="flex items-start dashboard">
                        <div className="content-left">
                            <Performance GetUser={UserData}/>
                        </div>
                        <div className="content-right">
                            <div className="block font-bold mb-10 meta-card-header">
                                <NavLink to={`${ROUTES_CONFIG.publicProfile.path.replace(':username', username)}`} className="meta-nav">My Assets</NavLink> / <span className="text-white ">Lands ({listData.length})</span>
                            </div>
                            <Row gutter={44} className="assets-row">
                                {listData && listData.length ?
                                    listData.map((item) => {
                                        return (
                                            <Col xs={24} sm={24} md={12} lg={8} key={item.id} className="assets-col">
                                                <div className="w-100 thumb">
                                                    <div className="relative thumb-asset" style={{background: `url(${PATH + 'crop/'+ item.id + '.png'}) no-repeat center center / cover`}}>
                                                        <Link to={ROUTES.home.path} onClick={()=>{dispatch(setOpenPlotOnMap(item.id, 2))}}  className="absolute top-0 rounded-t-xl left-0 w-full h-full flex flex-col items-center justify-center gap-y-2 bg-plot text-center">
                                                            <i className="icon-map-marker text-white text-xl"></i>
                                                            <span className="text-base font-light text-white">{L.viewLandPlot}</span>
                                                        </Link>
                                                    </div>
                                                    <div className="flex flex-col thumb-info thumb-info_land">
                                                        <p className="text-white mb-0 font-normal flex items-start thumb-title">
                                                            <i className="text-pink-400 icon-pin"></i>
                                                            <span className="font-light">{item.real_coord}</span>
                                                        </p>
                                                        <div className="flex items-center flex-wrap justify-between stats">
                                                            <span className="uppercase">{L.land}</span>
                                                            <span className="land-id">#{item.id}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }) :
                                    <Col xs={24} sm={24} md={12} lg={8} className="assets-col">
                                        <div className="w-100 thumb">
                                            <Skeleton.Image active className="land-asset_skeleton"/>
                                            <div className="flex flex-col thumb-info thumb-info_land">
                                                <p className="text-white mb-0 font-normal flex items-start thumb-title">
                                                    <i className="text-pink-400 icon-pin"></i>
                                                    <Skeleton active paragraph={false} title={{
                                                        width: "34px",
                                                        style: {margin: "0", height: "15px"}
                                                    }}></Skeleton>
                                                </p>
                                                <div className="flex items-center flex-wrap justify-between stats">
                                                    <span className="uppercase">{L.land}</span>
                                                    <span className="land-id">
                                                <Skeleton active paragraph={false}
                                                          title={{width: "41px", style: {margin: "0"}}}></Skeleton>
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default PublicLands;