export const Packages = (is_test=0) => { 
    return [
        {
            id: 15,
            name: "Metapreneur 12",
            date: "12 months",
            lockUp: 12,
            APY: 150,
            dateSecond: 12*30*24*3600, 
            penaltyPercentage: 45,
            divideValue: 360,
            minPrice: 2000,  
            maxPrice: 500000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ?  "0x80d906d1dA34C7e00040CB3718aE4A46A68e6058" : "0x8bD4610401764893e8a5f458541937DEEF4FE465",  
            fileJson: 'LevelsStaking.json', 
            active: 1,
            level: 3
        },

        {
            id: 14,
            name: "Metapreneur 9",
            date: "9 months",
            lockUp: 9,
            APY: 75,
            dateSecond: 9*30*24*3600,
            penaltyPercentage: 45,
            divideValue: 270,
            minPrice: 2000,
            maxPrice: 500000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0xaAC5762374B810145B759c35D619A389b38a5C1a" : "0x7904EdA9d7dE68059CA1813d2B1F923ADDC5f318",  
            fileJson: 'LevelsStaking.json', 
            active: 1,
            level: 3
        },
        {
            id: 13,
            name: "Metapreneur 6",
            date: "6 months",
            lockUp: 6,
            APY: 36,
            dateSecond: 6*30*24*3600,
            penaltyPercentage: 45,
            divideValue: 180,
            minPrice: 2000,
            maxPrice: 500000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ?  "0xCcdAcA94C4dbd9462Bf954EB51783165b2E5e05f" /* "0x057facb3f0cCde74a68b56e4A997C2316C125C32" */ : "0x7754165E54709EA194d701430CD62b9024bceCf7",  
            fileJson: 'LevelsStaking.json', 
            active: 1,
            level: 3
        }, 

        {
            id: 12,
            name: "Resident 12",
            date: "12 months",
            lockUp: 12,
            APY: 75,
            dateSecond: 12*30*24*3600, 
            penaltyPercentage: 45,
            divideValue: 360,
            minPrice: 2000,  
            maxPrice: 250000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0x80d906d1dA34C7e00040CB3718aE4A46A68e6058" : "0x8bD4610401764893e8a5f458541937DEEF4FE465",  
            fileJson: 'LevelsStaking.json', 
            active: 1,
            level: 2
        },

        {
            id: 11,
            name: "Resident 9",
            date: "9 months",
            lockUp: 9,
            APY: 36,
            dateSecond: 9*30*24*3600,
            penaltyPercentage: 45,
            divideValue: 270,
            minPrice: 2000,
            maxPrice: 250000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0xaAC5762374B810145B759c35D619A389b38a5C1a" : "0x7904EdA9d7dE68059CA1813d2B1F923ADDC5f318",  
            fileJson: 'LevelsStaking.json', 
            active: 1,
            level: 2
        },
        {
            id: 10,
            name: "Resident 6",
            date: "6 months",
            lockUp: 6,
            APY: 18,
            dateSecond: 6*30*24*3600,
            penaltyPercentage: 45,
            divideValue: 180,
            minPrice: 2000,
            maxPrice: 250000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0xCcdAcA94C4dbd9462Bf954EB51783165b2E5e05f" /* "0x057facb3f0cCde74a68b56e4A997C2316C125C32" */ : "0x7754165E54709EA194d701430CD62b9024bceCf7",  
            fileJson: 'LevelsStaking.json', 
            active: 1,
            level: 2
        }, 


        {
            id: 9,
            name: "Traveler 12",
            date: "12 months",
            lockUp: 12,
            APY: 24,
            dateSecond: 12*30*24*3600, 
            penaltyPercentage: 45,
            divideValue: 360,
            minPrice: 2000, 
            maxPrice: 100000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0x80d906d1dA34C7e00040CB3718aE4A46A68e6058" : "0x8bD4610401764893e8a5f458541937DEEF4FE465",  
            fileJson: 'LevelsStaking.json', 
            active: 1, 
            level: 1 
        },

        {
            id: 8,
            name: "Traveler 9",
            date: "9 months",
            lockUp: 9,
            APY: 18,
            dateSecond: 9*30*24*3600,
            penaltyPercentage: 45,
            divideValue: 270,
            minPrice: 2000,
            maxPrice: 100000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0xaAC5762374B810145B759c35D619A389b38a5C1a" : "0x7904EdA9d7dE68059CA1813d2B1F923ADDC5f318",  
            fileJson: 'LevelsStaking.json', 
            active: 1,
            level: 1
        },
        {
            id: 7,
            name: "Traveler 6",
            date: "6 months",
            lockUp: 6,
            APY: 12,
            dateSecond: 6*30*24*3600,
            penaltyPercentage: 45,
            divideValue: 180,
            minPrice: 2000,
            maxPrice: 100000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0x057facb3f0cCde74a68b56e4A997C2316C125C32" : "0x7754165E54709EA194d701430CD62b9024bceCf7",  
            fileJson: 'LevelsStaking.json', 
            active: 1,
            level: 1
        }, 

        {
            id: 1,
            name: "ANNIVERSARY POOL",
            date: "12 months",
            lockUp: 12,
            APY: 23,
            dateSecond: 12*30*24*3600,
            penaltyPercentage: 45,
            divideValue: 360,
            minPrice: 2000,
            maxPrice: 500001,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0x7728192a0e775978324f3f1e63156bDF94CBc23A" : "0x6f0f6ec3128e726dfa38cfd425aa7ba07f876e8c",  
            fileJson: 'Staking-23.json', 
            active: 0,
            level: 0

        },

        {
            id: 2,
            name: "Super Pool",
            date: "Unlimited",
            lockUp: false,
            APY: 0.01,
            dateSecond: 3600,
            penaltyPercentage: 1.7,
            divideValue: 1,
            minPrice: 2000,
            maxPrice: 1000000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0x1e78144c60a466CF943E48b292EDC8Ac24989458" : "0x54b41a74aFbd38A847E3E95e87168ad169d7f943",

            fileJson: 'ProdSuperPool.json',
            active: 0,
            level: 0

        },
        {
            id: 3,
            name: "Nano",
            date: "3 months",
            lockUp: 3,
            APY: 12,
            dateSecond: 3*30*24*3600,
            penaltyPercentage: 30,
            divideValue: 90,
            minPrice: 2000,
            maxPrice: 250000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",  
            stakingToken: is_test ? "0x4be60983F6b0d7B426B0E40c49EC28b3B2DeC3D7" : "0xFB914F6E0765188d26C19E4454787C99CbDf4D88", 

            fileJson: 'Staking_3.json',
            active: 0,
            level: 0

        },
        {
            id: 4,
            name: "Micro",
            date: "6 months",
            lockUp: 6,
            APY: 20,
            dateSecond: 6*30*24*3600,
            penaltyPercentage: 35,
            divideValue: 180,
            minPrice: 2000,
            maxPrice: 500000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",          
            stakingToken: is_test ? "0x9353c696C4DEdAd2F7ebeD1ED286C8B06CFc0e56" : "0x03D8F5D4276De72f4b9cCCF570F8eBE977AD4359",
            fileJson: 'Staking_6.json',
            active: 0,
            level: 0

        },
        {
            id: 5,
            name: "Macro",
            date: "9 months",
            lockUp: 9,
            APY: 26.66,
            dateSecond: 9*30*24*3600,
            penaltyPercentage: 40,
            divideValue: 270,
            minPrice: 2000,
            maxPrice: 750000,        
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",          
            stakingToken: is_test ? "0xfE5E9dab0d51Ec3B9B707109d291BFcc46F8B271" : "0x168fF4326fbb435f43AEb2eDeBeAd8D13b827D9f", 
            fileJson: 'Staking_9.json',
            active: 0,
            level: 0

        },
        {
            id: 6,
            name: "Mega",
            date: "12 months",
            lockUp: 12,
            APY: 36,
            dateSecond: 12*30*24*3600,
            penaltyPercentage: 45,
            divideValue: 360,
            minPrice: 2000,
            maxPrice: 1000000,
            metaToken : is_test ? "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57" : "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba",
            stakingToken: is_test ? "0x57f836c5747411688387a2D0CB0C34Ee3cffdEeE" : "0xfE0f130512e5F24E3a1EFa1c6b1FB73be104Cd68",
            fileJson: 'Staking_12.json',
            active: 0,
            level: 0

        },

    ];
}



 