import React from "react";
import {Carousel} from "antd";
import {PATH} from "../../config";

const GetMetahutCarousel = () => {
    return (
        <Carousel autoplay className="mb-20 metahut-carousel">
            <img src={PATH + 'metahut-slider1.png'} alt=""/>
            <img src={PATH + 'metahut-slider2.png'} alt=""/>
            <img src={PATH + 'metahut-slider3.png'} alt=""/>
        </Carousel>
    )
}

export default GetMetahutCarousel;