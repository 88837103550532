import React, {useState} from "react";
import {PATH} from "../../../../../config";
import {Button} from "antd";
import {selectUser} from "../../../../../redux/features/userSlice";
import {useSelector} from "react-redux";

const Preview = ({L}) => {
    const selector = useSelector

    const [AvatarFile, setAvatarFile] = useState(PATH+'avatar.png')
    let GetUser = selector(selectUser);
    let Form = useSelector ((state) => state.event.formData)

    return (
        <>
            <span className="text-white text-base mb-2 text-center block">{L.preview}</span>
            <div className="events-card">
                <div className="events-cover" style={{background: `url(${Form.cover_image_src}) center center / cover`}}>
                </div>
                <div className="events_details">
                    <div className="events_header">
                        <span className="events_date">{Form.start_date + ' ' + Form.start_time } PM</span>
                        <div className="events-price events-price_soto">
                            <img src={PATH + 'soto_icon.svg'} alt=""/>
                            <span>{Form.ticket_value}</span>
                        </div>
                    </div>
                    <h2 className="events-title">{Form.name}</h2>
                    <span className="events-author">{L.byUsername.replace("_USERNAME_", '@'+GetUser.username)}</span>
                    <div className="events_action">
                        <Button type="primary" className="events-btn"> {L.wantToGoBtn} </Button>
                    </div>
                    <div className="events-bottom">
                        <div className="events-pool">
                            <div className="events-reward">
                                <span>{L.reward}:</span>
                            </div>
                            <div className="events-amount">
                                <img src={PATH + 'token.svg'} alt=""/>
                                <span>{Form.reward_value} METO</span>
                            </div>
                        </div>
                        <div className="events-share">
                            <i className="icon-share"></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Preview;