import React from "react";
import RoomsItem from "./RoomsItem";
import {Row} from "antd";
import Title from "../shared/Title";
import './rooms.css';

const RoomsContainer = () => {

    return (
        <>
            <Title title="A QUICK PEEK INTO THE METAHUT" className="px-8 mb-5 mt-20 text-base text-2xl sm-mt-0 text-center text-white uppercase font-normal" />
            <Row gutter={{xs: 86, sm: 86, md: 46, lg: 44}} className="px-8 pr-0 w-full xs-col-full sm-px-0 mt-5 sm-mt-0">
               <RoomsItem />
            </Row>
        </>
    )
}

export default RoomsContainer;