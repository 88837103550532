import React, {useState, useEffect} from "react";
import panzoom from './panzom';
import './map.css'
import {Button} from "antd";
import { useSelector, useDispatch } from 'react-redux';
import {PATH, $ROOT} from '../../config';
import {getRectSelecteds, getItems, getCookie, get} from "../../config/CoreMethods";
import {getInitialCoords, setInitialCoords} from "../../config/landMethods";
import {setOpenPlotOnMap} from "../../redux/actions/landActions";


const MapSvg = ({Events, L}) => {
 
    const dispatch = useDispatch()
    const [showMapDetails, setShowMapDetails] = useState(true);
    const [AllRects, setAllRects] = useState([]);

    let dWidth;
    let dHeight;
        
    let dWidth_ =  7000; // 7509; 
    let dHeight_ = 6548; // 7025; 

    let PS = {

        x1 : 3805,
        y1 : 3865,

        x2 : 6872,
        y2 : 6886,

        scale_1 : 0.145,
        scale_2 : 0.31, 
        scale_3 : 0.6,      // max 1.3

        dl1 : 3.101714285714286, 
        dl2 : 1.9214285714285715, 

    }

    useEffect(() => {

        let ClaimMode = parseInt( localStorage.getItem('claimmode') );
        let type = ClaimMode == 1 ? 2 : 1;

        dispatch({type: "SetMapSelectedBarVisible", payload : [getRectSelecteds(1).length, type]})

        panz()

    }, [])


    const createMap = (transform) => {

        try {

        let dom_main = document.getElementById('zoomable-main');
        let dom_svg = document.getElementById('zoomable-svg');  

        let srcWidth = dom_main.offsetWidth
        let srcHeight = dom_main.offsetHeight

        let scaleX = transform.x - (-PS.x2*transform.scale) 
        let scaleY = transform.y - (-PS.y2*transform.scale) 
        let scale = transform.scale;

        let diffPercent = 2.2870211549456836 / scale

        let visibleXmin = Math.round(Math.abs(scaleX) * diffPercent)// - 50; 
        let visibleYmin = Math.round(Math.abs(scaleY) * diffPercent) // - 50;

        let visibleXmax = visibleXmin + (srcWidth * diffPercent)  -40 // + 50;

        let visibleYmax = visibleYmin + (srcHeight * diffPercent) -40 // + 50;

        let $url = $ROOT + 'x_min=' + visibleXmin + '&x_max=' + visibleXmax;
        $url += '&y_min=' + visibleYmin + '&y_max=' + visibleYmax;
        let myAccount = getCookie('account')
        $url += '&account='+myAccount
        get($url, function(result) {
           
            let data = JSON.parse(result)

            if (result != '' && data != null  &&  typeof data !='undefined' && data.length) {

                let elementum =  document.getElementById('panzom-panned');

                if(elementum.className == 'w-3') {
                    setAllRects(data)
                } else {                    
                    setAllRects([])
                    document.getElementById('tooltip-area').innerHTML = ''
                }
            }
 
        })

        } catch (error) {
            console.log('rect error', error)
        }
    }



    const makeRect = (rect, opnPlot) => {

        let items = getRectSelecteds(1);

        let fill = '';
        let class_ = items.indexOf(rect['id']) != -1 ? 'selected-plot' : 'gen-plot';

        if(rect['is_buy'] == '0' )  {

            fill = '';
        } else {
            
            if(rect['is_my']==1)  {
                class_ = 'my-plot';
            } else {
                fill = rect.buyer_acoount;
                class_ = "buyed-plot";
            }
        }

        return (<rect id={rect['id']} key={rect['id']} 
                onMouseUp={Events.mouseUp} 
                onMouseDown={Events.mouseDown}  
                onMouseMove={Events.mouseMove} 
                onTouchEnd={Events.touchEnd} 
                onTouchMove={Events.touchMove} 
                x={rect['x']} y={rect['y']}  real_coord={rect['real_coord']} transform={rect['transform']}  width="37.3" height="37.3"  className={class_} acc={fill} />);                            
 
    }

    const createMapFromSelecteds = () => {
        
        let items = getRectSelecteds();
        let Rects = ''; 

        if (Object.keys(items).length > 0) {

            for (let i in items) {
 
                Rects += '<rect id="'+i+'" x="' + items[i]['x'] + '" y="' + items[i]['y'] + '" transform="' + items[i]['transform'] + '" width="37.3" height="37.3" class="selected-plot" />';

            } 
        }
        
        if (document.getElementById('selected-rects') != null) {
            document.getElementById('selected-rects').innerHTML = Rects;
        } 
    }

    let instance;
    let openPlot = useSelector(state => state.land.OpenLandOnMap);

    const panz = () => {

        let Timeout;
        let first = 1;
        let element = document.querySelector('#panzom-panned')

        let parameters = { 
                maxZoom: 1.3,  
                minZoom: 0.001 
        } 

        dispatch(setOpenPlotOnMap(0,0))
        
        let GivenScale = 1.1;

        let StartParams = {scale : GivenScale };

        if(openPlot.id > 0) {

            let keylocal;

            if( openPlot.type == 1) {
                keylocal = 'selectedrects';
            } else if( openPlot.type == 2 ) {
                keylocal = 'myCollection'; 
            }

            let items = getItems(keylocal, 0);    
            let selected = items [ openPlot.id ]; 

            if (typeof selected != 'undefined') {
                    
                let diffPercent = 2.2870211549456836 / GivenScale

                let visibleX = selected.x / diffPercent // - 50; 
                let visibleY = selected.y / diffPercent // - 50; 

                let maxX = GivenScale * PS.x2; 
                let maxY = GivenScale * PS.y2; 


                let dom_main = document.getElementById('zoomable-main');

                let srcWidth = dom_main.offsetWidth / 2  
                let srcHeight = dom_main.offsetHeight / 2


                StartParams.x = -1 * Math.round(visibleX + maxX - srcWidth); 
                StartParams.y = -1 * Math.round(visibleY + maxY - srcHeight); 

                first = 2;
                
                dispatch(setOpenPlotOnMap(0,0)) 
                
            }

        } 

        instance = panzoom(element, parameters);
        

        const TransformMethod =  (e) => {

            instance.applyTransform(function(transform) {
                
                let dom_main = document.getElementById('zoomable-main'); 
                let dom_svg = document.getElementById('panzom-panned');  

                if(dom_main == null) { return; }
                
                let srcWidth = dom_main.offsetWidth
                let srcHeight = dom_main.offsetHeight


                let FminScaleX = srcWidth/(dWidth_* PS.dl1); 
                let FminScaleY = srcHeight/(dHeight_ * PS.dl1); 
                let FScaleRes = FminScaleX>FminScaleY ? FminScaleX : FminScaleY;

                let minX, minY, maxX, maxY, transform_, sfirst=0;

                let svgWidth = Math.round(dWidth_ * PS.scale_3);
                let svgHeight = Math.round(dHeight_ * PS.scale_3);

                
                
                if (first == 1) {
                    transform_ = getInitialCoords()

                    if (typeof transform_.x == 'undefined') {
                        transform_ = {x:0, y:0, scale: FScaleRes}
                        sfirst = 1;
                    } 

                    first = 0;                    

                } else if(first == 2) {

                    transform_ = StartParams; 

                    first = 0;
                }
                else {
                    transform_ = transform; 
                }


                if (transform_.scale < PS.scale_1) {

                    maxX = 0;
                    maxY = 0;
                    dWidth = dWidth_* PS.dl1;
                    dHeight = dHeight_ * PS.dl1;

                } else if (transform_.scale < PS.scale_2) {

                    maxX = -transform_.scale * PS.x1;
                    maxY = -transform_.scale * PS.y1;

                    dWidth = dWidth_* PS.dl2;
                    dHeight = dHeight_ * PS.dl2;

                } else {

                    maxX = -transform_.scale * PS.x2;
                    maxY = -transform_.scale * PS.y2;
                    dWidth = dWidth_;
                    dHeight = dHeight_;
                }

                let FirstScale = transform_.scale;
 
                let minScaleX = srcWidth/dWidth; 
                let minScaleY = srcHeight/dHeight; 
                let ScaleRes = minScaleX>minScaleY ? minScaleX : minScaleY;

                transform.scale = ScaleRes < transform_.scale ? transform_.scale : ScaleRes;


                svgWidth = Math.round(dWidth * transform.scale);
                svgHeight = Math.round(dHeight * transform.scale);

                minX = -(svgWidth - srcWidth) + maxX;     
                minY = -(svgHeight - srcHeight) + maxY;   


                transform.x = transform_.x <= minX  ? minX : (transform_.x >= maxX ? maxX : transform_.x);
                transform.y = transform_.y <= minY  ? minY : (transform_.y >= maxY ? maxY : transform_.y);


                if ( (sfirst == 1 || FirstScale < ScaleRes) && transform.scale < PS.scale_1) {
                    transform.x = -(maxX - minX)/2
                    transform.y = -(maxY - minY)/2
                }

                if (transform.scale >= PS.scale_2) {

                    dom_svg.className = "w-3"

                } else {

                    if (transform.scale >= PS.scale_1) {
                        dom_svg.className = "w-2"
                    } else {
                        dom_svg.className = "w-1"
                    }
                } 

                let index_ = transform.scale <= PS.scale_1 ? 1 : (transform.scale > PS.scale_1 && transform.scale <= PS.scale_2 ? 2 : (transform.scale > PS.scale_2 && transform.scale <= PS.scale_3)? 3 : 4) ; 

                let listNode = document.querySelectorAll('.zoom-level-pointers');

                for (var i = listNode.length - 1; i >= 0; i--) {
                    listNode[i].classList.remove('active');
                }

                document.querySelector('.level_'+index_).classList.add('active');
                
                
                clearTimeout(Timeout)

                if (transform.scale > PS.scale_3) {

                    Timeout = setTimeout(function() {
                        createMap(transform)
                    }, 100);

                    document.getElementById('selected-rects').innerHTML = '';

                } else {
                    setAllRects([])
                    createMapFromSelecteds()
                }
                
                document.getElementById('tooltip-area').innerHTML = ''

                setInitialCoords(transform)

                return transform;
                 
            }, 1)

        }; 

        instance.on('transform', TransformMethod); 

        addEventListeners('#zoomInBTN', ['click', 'touchend'], function(e) { 
            e.preventDefault(); e.stopPropagation();
            instance.makeZoom(2.2, 0)
        });

        addEventListeners('#zoomOutBTN', ['click', 'touchend'], function(e){ 
            e.preventDefault(); e.stopPropagation();
            instance.makeZoom(0.4, 0)
        });


        addEventListeners('#maxZoomOut', ['click', 'touchend'], function(e) {
            e.preventDefault(); e.stopPropagation();
            instance.makeZoom(0.05, 0);
        });


        addEventListeners('.zoom-level-pointers.level_1', ['click', 'touchend'], function(e){
            e.preventDefault();  e.stopPropagation();
            instance.makeZoom(0.05, 1);
        });

        addEventListeners('.zoom-level-pointers.level_2', ['click', 'touchend'], function(e){
            e.preventDefault();  e.stopPropagation();
            instance.makeZoom(PS.scale_2, 1);
        });

        addEventListeners('.zoom-level-pointers.level_3', ['click', 'touchend'], function(e){
            e.preventDefault();  e.stopPropagation();
            instance.makeZoom(PS.scale_3, 1);
        });

        addEventListeners('.zoom-level-pointers.level_4', ['click', 'touchend'], function(e){
            e.preventDefault();  e.stopPropagation();
            instance.makeZoom(2, 1);
        });

    }   


    const addEventListeners = (selector, events, callback) => {
        for (var i = events.length - 1; i >= 0; i--) {
            document.querySelector(selector).addEventListener(events[i], function(e){callback(e)})
        }
    }
 




    return (
        <>   
            <div id="zoomable-main" className='zoomable'>
                <div className={`map-details  ${showMapDetails ? 'map-details-close' : ''}`}>
                    <Button className="map-details-caret border-0" onTouchEnd={() => setShowMapDetails(!showMapDetails)} onClick={() => setShowMapDetails(!showMapDetails)} >{showMapDetails ? <i className="icon-angle-left text-white"></i> : <i className="icon-angle-right text-white"></i>}</Button>
                    <div className="flex items-center gap-x-6">
                        <div className="map-plots flex flex-col gap-y-4">
                            <div className="flex items-center gap-x-2">
                                <span className="plot-square plot-red"></span>
                                <span className="text-sm">{L.mapLandSold}</span>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <span className="plot-square plot-black"></span>
                                <span className="text-sm">{L.mapLandAvaliable}</span>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <span className="plot-square plot-blue"></span>
                                <span className="text-sm">{L.mapLandSelected}</span>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <span className="plot-square plot-gray"></span>
                                <span className="text-sm">{L.mapLandUnavaliable}</span>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <span className="plot-square plot-yellow"></span>
                                <span className="text-sm">{L.mapLandReserved}</span>
                            </div>
                        </div>

                        <div className="map-zoom">
                            <div className="flex flex-col items-center gap-y-2">
                                <button id="zoomInBTN"><i className="icon-zoom-in text-xl"></i></button>
                                <div className="flex flex-col gap-y-8 map-zoom-dots relative">
                                    <button className="zoom-level-pointers level_4"></button>
                                    <button className="zoom-level-pointers level_3"></button>
                                    <button className="zoom-level-pointers level_2"></button>
                                    <button className="zoom-level-pointers level_1"></button>
                                </div>
                                <button id="zoomOutBTN"><i className="icon-zoom-out text-xl"></i></button>
                            </div>
                            <button className="mt-2" id="maxZoomOut"><i className="icon-globe text-xl"></i></button>
                        </div>
                    </div>

                </div>
                <div id="panzom-panned" className="w-1"> 
                    <img src={PATH+'map-sector-1.png'} id="map-sector-1" className="map-sector-bgs"/>
                    <img src={PATH+'r-view-1.min.png'} id="r-view-1" className="map-bgs"/>
                    
                    <img src={PATH+'r-view-3-new.png'} id="r-view-3" className="map-bgs"/>

                    <svg version="1.1" id="zoomable-svg" className="z-index-down" viewBox="0 0 16008.6 14974.8">
                        <filter id="dropShadow" name="Shadow 2">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="2"></feGaussianBlur>
                            <feOffset dx="1" dy="2"></feOffset>
                            <feMerge>
                                <feMergeNode></feMergeNode>
                                <feMergeNode in="SourceGraphic"></feMergeNode>
                            </feMerge>
                        </filter>

                
                        <g id="dynamic-g">{
                            AllRects.length ?
                             AllRects.map((rect) => {
                                return makeRect(rect, openPlot)
                            })
                            : ''}</g>

                        <g id="selected-rects"></g>
                        <g id="tooltip-area"></g>
                    </svg>

                </div>
            </div> 

             
        </>
    )
}

export default MapSvg;