import React from "react";
import {Button, Layout, Menu} from "antd";
import {useDispatch, useSelector} from "react-redux";

const { Sider } = Layout

const EventsSider = () => {
    const selector = useSelector;
    const dispatch = useDispatch();

    let activeStep = selector ((state) => state.event.activeStep);
    let L = selector (state => state.settings.L);

    const handleActiveStep = (key) => {
        dispatch({ type: 'setActiveStep', payload: key })
    }
    return (
        <Sider className="pt-8 pb-8 pl-10 pr-4 bg-indigo-900 sidebar-bg h-full z-1 events-sider" width="350px">
            <h3 className="font-mont text-gray-300 font-bold text-base uppercase">{L.submitEvent}</h3>
            <Menu className="font-mont text-lg flex-col flex mt-5 vert_menu" selectedKeys={activeStep} onClick={(e) => handleActiveStep(e.key)} theme="dark" mode="inline" items={[
                {
                    label: <Button>{L.eventSiderOverview}</Button>,
                    key: 'overview',
                },
                {
                    label: <Button>{L.eventSiderEventDetails}</Button>,
                    key: 'details',
                },
                {
                    label: <Button>{L.eventSiderVenue}</Button>,
                    key: 'venue',
                },
                {
                    label: <Button>{L.eventSiderRewards}</Button>,
                    key: 'rewards',
                },
                {
                    label: <Button>{L.eventSiderHosts}</Button>,
                    key: 'hosts',
                },
            ]} />
        </Sider>
    )
}

export default EventsSider;