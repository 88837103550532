import React from "react";
import {PATH} from "../../../config";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";
import NumberFormat from "react-number-format";
import {Skeleton} from "antd";

const Balance = () => {
    const selector = useSelector

    let GetUser = selector(selectUser);

    let L = selector (state => state.settings.L);


    return (
        <div className="mb-19 meta-card">
            <div className="font-bold mb-6 meta-card-header">{L.balance}</div>
            <div className="mb-7 w-100 line-x"></div>
            <div className="meta-card-body">
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <div className="flex items-center token">
                        <img src={PATH+'soto_icon.svg'} alt="" className="icon"/>
                        <span className="uppercase text-white font-normal label">SOTO</span>
                    </div>
                    {GetUser ?
                        <NumberFormat
                            value={GetUser.soto_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><span className="amount">{value}</span>}
                        /> :
                        <Skeleton active paragraph={false} title={{width: "58px", style: {margin: "0 0 0 auto", height: "19px"}}}></Skeleton>
                    }
                </div>
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <div className="flex items-center token">
                        <img src={PATH+'meto_icon.svg'} alt="" className="icon"/>
                        <span className="uppercase text-white font-normal label">METO</span>
                    </div>
                    {GetUser ?
                        <NumberFormat
                            value={GetUser.meto_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><span className="amount">{value}</span>}
                        /> :
                        <Skeleton active paragraph={false} title={{width: "58px", style: {margin: "0 0 0 auto", height: "19px"}}}></Skeleton>
                    }
                </div>
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <div className="flex items-center token">
                        <img src={PATH+'bnb.svg'} alt="" className="icon"/>
                        <span className="uppercase text-white font-normal label">BUSD</span>
                    </div>
                    {GetUser ?
                        <NumberFormat
                            value={GetUser.busd_balance}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><span className="amount">{value}</span>}
                        /> :
                        <Skeleton active paragraph={false} title={{width: "58px", style: {margin: "0 0 0 auto", height: "19px"}}}></Skeleton>
                    }
                </div>
            </div>
        </div>
    )
}

export default Balance;