import React, { useEffect, useState } from "react";
import {Col, Layout, Row} from "antd";
import Intro from "./Intro";
import Performance from "./Performance";
import {NavLink, useParams} from "react-router-dom";
import {PATH} from "../../../config";
import {ROUTES as ROUTES_CONFIG} from "../../../config/constants";
import axiosData from "../../../helpers/axiosData";
import { useSelector } from "react-redux";

const { Content } = Layout

const PublicMetahuts = () => {

    let L = useSelector(state => state.settings.L);
    let { username } = useParams();
    const [listData, setListData] = useState([])
    const DefaultCoverImage = PATH+'/metahuts-empty.png';

    const getAllData = (username) => {
        axiosData.get("/api/_web3/metahuts/my-list/"+username).then((res) => {

            if (res.data.data.length) {
                setListData( res.data.data ); 
            } else {
                setListData( [] );     
            }
        })
    }

    const[UserData, setUserData] = useState({});
        
    /* ------ getAllQuizes ---------*/
    const getUserData = (username) => {

        axiosData.get("/api/_web3/user/public-profile/"+username).then((res) => {
            if (res.data && res.data.success) {
                 setUserData( res.data.data ); 
            } else {
            }
        })
    }
    
    useEffect(() => { 
        getUserData(username);  
        getAllData(username)
    }, [username]);

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10">
                    <Intro GetUser={UserData}/>
                    <div className="flex items-start dashboard">
                        <div className="content-left">
                            <Performance GetUser={UserData}/>
                        </div>
                        <div className="content-right">
                            <div className="block font-bold mb-10 meta-card-header">
                                <NavLink to={`${ROUTES_CONFIG.publicProfile.path.replace(':username', username)}`} className="meta-nav">{L.myAssets}</NavLink> / 
                                <span className="text-white ">{L.metahuts} ({listData && listData.length?listData.length:0})</span>
                            </div>

                            <Row gutter={34}>
                                {listData && listData.length ?
                                    listData.map(li => {
                                        return <Col xs={24} sm={24} md={12} lg={8} className="flex flex-col items-center assets-col">
                                                <div className="w-100 thumb">
                                                    <div className="relative text-right thumb-asset list-metahut" style={{background: `url(${li.cover_image !='' ? li.cover_image : DefaultCoverImage})  no-repeat center center / contain`}}>
                                                        <span className="font-medium inline-block thumb-ctg thumb-ctg_publish">{L.publish}</span>
                                                    </div>
                                                    <div className="flex flex-col thumb-info">
                                                        <p className="text-white mb-0 font-normal thumb-title">{li.land_name}</p>
                                                        <div className="flex items-center flex-wrap stats">
                                                            <div className="flex items-center stats-item">
                                                                <i className="icon-people"></i>
                                                                <span>{li.followers}</span>
                                                            </div>
                                                            <div className="flex items-center stats-item">
                                                                <i className="icon-eye"></i>
                                                                <span>{li.visitors}</span>
                                                            </div>
                                                            <div className="flex items-center stats-item">
                                                                <i className="icon-star"></i>
                                                                <span>{li.rate}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            })
                                    : ''}
                            </Row>
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default PublicMetahuts;