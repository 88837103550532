import React, {useEffect} from "react";
import {Button, Col, Layout, Row} from "antd";
import SettingsSidebar from "./SettingsSidebar";
import {PATH} from "../../../config"; 
import axiosData from "../../../helpers/axiosData";
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import {fetchUser} from "../../../redux/features/userSlice";
import { setOpenPlotOnMap } from "../../../redux/actions/landActions";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../config/constants";


const {Content} = Layout;

const Claims = ({props}) => {

    const dispatch = useDispatch();
    let L = useSelector (state => state.settings.L);
    let launchpadPlotLimit = useSelector ((state) => state.land.LaunchpadPlotLimit); 

    const fetchUserData = async (dispatch, getState) => {
        try {
            await dispatch(fetchUser())
        } catch (error) {
            console.log('fetchUser error', error)
        } 
    };


    let GetUser = useSelector((state) => state.user.user);

    const claimCryptoAsset = (id) => {

        axiosData
            .post('/api/_web3/user/claim-referral', {claim_id:id})
            .then((res) => {
                if (res && res.data.success) {
                    toast.success(L.claimRefSuccess);            
                    dispatch(fetchUserData)

                } else {
                    toast.error(L.errorOccured); 
                }
            })
            .catch((error) => {
                toast.error(L.errorOccured); 
                console.log('status error: ', error)
            }) 
    }

    const GetClaimableLands = (length_) => {
        let list = [];

        for (let i = 0; i < length_; i++) {
            
            list.push(
                <Col xs={24} sm={24} md={12} lg={6} className="mb-9">
                    <div className="w-100 thumb">
                        <div className="thumb-asset thumb-claim_asset" style={{background: `url(${PATH + 'land-claim.png'}) no-repeat center center / cover`}}>
                        </div>
                        <div className="flex flex-col items-center gap-y-3 thumb-claim thumb-claim_page">
                            <Link to={ROUTES.home.path}  onClick={()=>{dispatch(setOpenPlotOnMap(0, 3))}}>
                                <Button className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-normal gland-claim_btn">
                                    {L.claim}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Col>
            )
        }

        return list;
    } 

    return <div className="container mx-auto pt-162 min-h-calc-head h-full">
                <Layout className="h-full settings-layout">
                <ToastContainer />
            <SettingsSidebar /> 
            <Content className="pt-8 pl-10 pr-4 settings-content">
                <h3 className="text-xl text-white font-bold mb-5 settings-title">{L.userDropClaims}</h3>
                <p className="font-light mb-15">{L.infNetworkDesc}</p>
                <Row gutter={36}>
                    
                    {launchpadPlotLimit!='-' && launchpadPlotLimit > 0 ? 
                        GetClaimableLands(launchpadPlotLimit)                        
                    :''}

                    {GetUser && GetUser.referral_claimable && GetUser.referral_claimable.length ?
                        GetUser.referral_claimable.map(li => {
                            return (                     
                            <Col xs={24} sm={24} md={12} lg={6} key={li.id} className="mb-9">
                                <div className="flex flex-col items-center claims-thumb">
                                    <img src={PATH + li.short.toLowerCase() + '_icon.svg'} className="mb-4 claims-thumb_icon" alt=""/>
                                    <span className="uppercase font-light claims-thumb_amount">{li.crypto_value}</span>
                                    <span className="uppercase font-bold claims-thumb_ctg" style={{color: "#45EAFF"}}>{li.short}</span>
                                    <Button onClick={()=>{claimCryptoAsset(li.id)}} className="border-0 mt-2 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-normal gland-claim_btn">{L.claim}</Button>
                                </div>
                            </Col>
                            )
                        })
                    :''}
                </Row>
            </Content>
        </Layout>
    </div>
}

export default Claims;