import React, {useState} from "react";
import { useSelector } from "react-redux";
import {PATH} from "../../config";
import { Col, Row } from "antd";

const PfpNftAvatar = () => {
  let L = useSelector (state => state.settings.L);

    return (
      <>
        <div className="video-layout">
            <div className="video-gradient"></div>
            <video loop autoPlay muted className="video-bg" src={PATH + 'video/pfp-intro.mp4'} />
            <div className="container mx-auto px-12 relative pt-162 py-8 video-3d">
                <div className="flex flex-col video-content">
                    <h2 className="font-mont font-bold mb-0 text-white video-3d-title">
                      Transform your PFP NFT into an playable 3D avatar
                    </h2>
                    <p className="font-mont font-normal mb-0 video-content-desc">
                    Don't settle for static images - unleash the power of immersive digital expression today.
                    </p>
                </div>
            </div>
        </div>
        <div className="container mx-auto px-12 relative">
            <div className="avatars-3d mt-20">  
              <h2 className="text-white video-3d-title text-center font-bold mb-16">3D Avatars</h2>
              <p className="font-mont font-normal text-center mb-0 text-xl px-14">Elevate your digital identity with our groundbreaking service. Convert your exclusive PFP NFT into a dynamic 3D avatar, bringing your online presence to life like never before. Play, interact, and engage with your personalized avatar across Metafluence platform, adding a whole new dimension to your virtual experience.</p>
            </div>
        </div>
        <video loop autoPlay muted width={'100%'} src={PATH + 'video/characters.mp4'} className="mt-20" />
        <div className="pfp-avatars-bg">
          <div className="container mx-auto px-12 relative">
              
              <Row gutter={{lg: 70}} className="pfp-avatars-row items-center">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <video loop autoPlay muted width={'100%'} src={PATH + 'video/avatar.mp4'} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <h2 className="font-mont font-bold mb-12 text-white video-3d-title">PFP NFT Avatars</h2>
                  <p className="font-mont font-normal mb-0 text-lg">For those who prefer to leverage the power of NFTs, our exclusive "PFP NFT to Avatar Converter" offers a groundbreaking opportunity to bring your 2D or 3D NFT profile picture to life. Watch in awe as your beloved NFT becomes a dynamic, interactive avatar that embodies your unique identity and is only accessible to you. Experience the thrill of bridging the gap between the static and dynamic, as your NFT avatar becomes a fully functional 3D representation of your creativity and individuality.</p>
                </Col>
              </Row>
            </div>
          </div>
          <div className="container mx-auto px-12 relative">
              <div className="convert-pfp">  
                <h2 className="text-white text-3xl text-center font-bold mb-12">Convert your PFP NFT, become the first-mover.</h2>
                <p className="font-mont font-normal mb-0 text-2xl text-center">Limited to the first 250 users exclusively</p>
              
                <Row gutter={{lg: 70}} className="join-steps mt-20">
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <p className="font-mont font-normal mb-8 text-lg">Benefits of converting your PFP NFTs:</p>
                    <ul className="text-lg">
                      <li>Create immediate utility and functionality</li>
                      <li>Immerse yourself in the vast 3D world of Metafluence Platform</li>
                      <li>Unlock new revenue streams such as selling event tickets, and referral model. </li>
                      <li>Play games and earn rewards</li>
                      <li>Connect with like-minded people and expand your network </li>
                      <li>Interact in different virtual environments belonging to communities, influencers, and projects</li>
                      <li>Attend events like concerts, conferences, meetups, and more</li>
                      <li>Increase the value of your NFT with new use cases</li>
                    </ul>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <h3 className="font-mont text-center mb-12 text-white text-lg">For opening the ticket and payments, visit our discord channel</h3>
                    <div className="tcard-pfp bg-indigo-700 text-center">
                      <span className="tcard-price font-mont text-white font-bold inline-block mb-4">$90</span>
                      <p className="tcard-label font-mont text-white mb-17">Limited-time offer</p>
                      <a href="https://discord.com/invite/metafluence-official" target="_blank" className="tcard-button font-mont text-center font-bold text-indigo-900 inline-block">Discord</a>
                    </div>
                  </Col>
                </Row>
              </div>
          </div>
      </>
    )
}

export default PfpNftAvatar;