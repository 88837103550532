import React from "react";
import {NavLink} from "react-router-dom";

const NavMenu = ({L}) => {

    const menuList = [
        {
            icon: "metahut",
            label: L.myMetahut,
            path: "/metahuts/mycollection"
        }
    ]

    return (
        <div className="flex items-center gap-x-2 nav-menu bg-transparent border-0 text-white uppercase">
            {menuList.map( (menuItem, index) => {
                return (
                    <NavLink to={menuItem.path} key={index} className="flex items-center gap-x-2 text-sm uppercase px-5 py-2 rounded-3xl">
                        <i className={`icon-${menuItem.icon} text-lg`}></i>
                        <span>{menuItem.label}</span>
                    </NavLink>
                )
            })}
        </div>
    )
}

export default NavMenu;