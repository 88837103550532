import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosData from "../../helpers/axiosData";
import { selectUser } from "../../redux/features/userSlice";
import EventsFilter from "./events/EventsFilter";
import EventsInfo from "./events/EventsInfo";

const EventsSingle = () => {

    const { event_id } = useParams();
    const[Data, setData] = useState({});

    let GetUser = useSelector(selectUser);
    let L = useSelector(state => state.settings.L);


    const getEvent = (id, is_internal) => {
        axiosData.get(`/api/_web3/event/${is_internal}${id}`).then((res) => {
            setData(res.data.data);
        })
    }
    
    const IWantToGo = (id) => {
        axiosData.post("/api/_web3/event/want-to-go/", {id : id}).then((res) => {
            
            if (res.data.success) { 
                
                getEvent(id, (GetUser && GetUser.id ? 'internal/' : ''))

            } else {
                toast.error(`${L.wantToGoEventErr}`)
            }
        }).catch(er => {
            toast.error(`${L.wantToGoErr}`)
        })
    }
    
    useEffect(() => {

        getEvent(event_id, (GetUser && GetUser.id ? 'internal/' : '') )

    }, [event_id, GetUser]);

    return (
        <div className="container mx-auto px-12 pr-0 relative pt-162 min-h-calc-head py-8">
            <div className="wrapper mt-16">
                <div className="esingle">
                    <EventsFilter/>
                    <EventsInfo data={Data} IWantToGo={IWantToGo} LoggedIn={GetUser && GetUser.id?1:0}/>
                </div>
            </div>
        </div>
    )
}

export default EventsSingle; 