import React, { useState, useEffect } from "react";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link, useParams} from "react-router-dom";
import axiosData from "../../../../helpers/axiosData";
import {Button, Col, Layout, Modal, Row} from "antd";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/features/userSlice";
import SelectSearch from "../../../custom/SelectSearch";
import Sidebar from "../Sidebar";
const selector = useSelector
const { Content } = Layout

export default function Edit() {

    const [loading, setLoading] = useState(true);
    const [rooms, setRooms] = useState([]);
    const [roomTypes, setRoomTypes] = useState([]);
    const [errors, setErrors] = useState({});
    const [pivotRooms, setPivotRooms] = useState();
    const [metahutFile, setMetahutFile] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [trigger, setTrigger] = useState(1);
    const [userAccount, setUserAccount] = useState(0);
    const [metahutId, setMetahutId] = useState(0);
    const[EditLink, setEditLink] = useState({});
    const [lands, setLands] = useState([]);
    const [ApiData, setApiData] = useState({});
    
    const {id} = useParams()

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    }
    

    const  getAllRooms = (metahutId_, userAccount_, metahutRooms) => {

        let metahut = metahutId_ ? metahutId_ : metahutId;
        let account = userAccount_ ? userAccount_ : userAccount;
        
        axiosData.get("/api/_web3/rooms/types").then((res) => {
            setRoomTypes(res.data)

            if (metahut) {

                axiosData.get("/api/_web3/rooms?metahut="+metahut+'&user_id='+account).then((res) => {
                    let data = res.data;
                    let list = {}

                    setRooms(data);

                    for (let k in metahutRooms) {
                        let filterList = data.filter((room) => room.id === metahutRooms[k].id )
                        list[metahutRooms[k].room.type.key] = metahutRooms[k].room_id
                    }

                    // setEditLink(list)
                    // setTrigger(trigger+1)
                });
            }
        })

    }

    const CheckImage = (data) => {
        // console.log('data', data)
        if (typeof data != 'undefined' && data && data['type']) {
            if(data['size'] <= 5242880 && (  data['type'].indexOf('png') != -1 || data['type'].indexOf('jpg') != -1 || data['type'].indexOf('jpeg') != -1 || data['type'].indexOf('gif') != -1) ) { 
                return {data:data}
            }  
        }  

        return {error : 'Select valid File'}
    };


    const setFormData = (data, key, type='text') => {

        console.log('setFormData - data', data, ', key', key, ', type ', type, ', ----- ApiData', ApiData)
    
        let newApiData = ApiData; 

        if ( key.indexOf('_room') > -1 ) {
            EditLink[key] = parseInt(data);
            setEditLink(EditLink)
        }
    
        if (type === 'text' || type === 'check') { 
    
            if(type === 'check') {
    
                newApiData[key] =  data ? 1 : 0;
            } else {
                newApiData[key] = data;
            }
    
            setApiData(newApiData);
            // setFormTrigger(FormTrigger+1);
    
        } else if (type === 'image') {
    
            let check_img = CheckImage(data)
    
            if(typeof check_img.data !='undefined' && check_img.data) {
    
                newApiData[key] = data;
                setApiData(newApiData);
                // setFormTrigger(FormTrigger+1);
    
            } else {
                // error
            }
        }   
        
        setTrigger(trigger+1)
    
    }
    
    useEffect(() => {


        setMetahutId(id) 


        // sd();

        axiosData.get(`/api/_web3/metahuts/${id}`).then((res) => {
            
            setMetahutFile(res.data.metahut_files)
            setPivotRooms(res.data.metahut_rooms)
            setUserAccount(res.data.user_id)
            
            // console.log('res.data', res.data)
            
            let Ldata = res.data;
            let RoomData = {};

            res.data.metahut_rooms.forEach(item => {
                Ldata[item.room.type.key+'_id'] = item.room.id
                RoomData[item.room.type.key+'_id'] = item.room.id;
            });
            
            setApiData(Ldata)
            
            getAllRooms(id, res.data.user_id, res.data.metahut_rooms)
            setEditLink(RoomData)
        });


        getLands()

        setLoading(false);
   
    }, [id])



    const getLands = (user_id=0) => {

        axiosData
          .get("/api/_web3/metahut/lands?user_id="+user_id)
          .then((res) => {
            if (res.data && res.data.success) {
                setLands(res.data.lands)
            }
          })
    }

    
    function editMetahut(e) 
    { 
        if (Object.keys(errors).length && errors.banner_1!=null && errors.banner_2!=null && errors.banner_3 != null) {
            return false;
        }

        var formData = new FormData();

        if (Object.keys(ApiData).length > 0) {
            for (let x in ApiData) {
                formData.append(x, ApiData[x]); 
            }   
        }

        formData.append('user_id', userAccount);

        axiosData.post(`/api/_web3/metahuts/${id}?_method=PUT`, formData).then((res) => {
            // toast.success('Updated Successfully!')
            setIsModalVisible(true);
        })
        .catch((error)=>
        {
            if(error.response.data.errors) {
                setErrors(error.response.data.errors)
                
                Object.values(error.response.data.errors).map((errorText, i) => {
                    return toast.error(errorText.toString())
                })
              }
              else{
                toast.error('OOPS! Something went wrong.')
            }

        })
    }


    const changeAccount = (val) => {
        
        setUserAccount(val)
        getAllRooms(metahutId, val)

        setPivotRooms([])
        
        // console.log('changeAccount', val, metahutId);

    }


return (

    <div className="container mx-auto pt-162 min-h-calc-head h-full">
        <div className="container mx-auto min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Sidebar />
                <Content className="pt-8 pb-8 pl-14 pr-0 settings-content"><ToastContainer />
                    <div className="pr-4 py-8">
                        {userAccount ?  
                        <SelectSearch  changeAccount={changeAccount} selectedItem={userAccount}/>
                        : ''}
                        
                        <Modal footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} className="modal">
                            <div className="text-center">
                                <i className="icon-check text-cyan-500 text-5xl mt-4 mb-4 inline-block"></i>
                                <p className="text-white text-lg">
                                    Your changes successfully saved.
                                </p>
                            </div>
                            <div className="flex items-center justify-center gap-x-4 mt-10">
                                <Link to="/admin/metahut">
                                    <Button onClick={() => handleOk()} className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">Ok</Button>
                                </Link>
                            </div>
                        </Modal>

                        <Link to="/admin/metahut" className="flex items-center gap-x-4 mb-8 w-fit">
                            <i className="icon-back text-3xl text-white"></i>
                            <span className="text-2xl font-normal text-white">Metahuts</span>
                        </Link>
                        <div className="md-mt-0 sm-px-0 flex items-center justify-between">
                            <h1 className="text-white text-5xl font-extralight mb-0">Edit Metahut</h1>
                            <Button type="button"
                                    className="float-right border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md"
                                    onClick={() => editMetahut()}
                            >
                                Save
                            </Button>
                        </div>
                        {selector(selectUser) ? 
                            loading ?
                                <div className="flex items-center gap-x-4 mt-5">
                                    <h1 className="text-white text-2xl mb-0">Loading</h1>
                                    <div className="loading-spinner"></div>
                                </div> :
                                <>
                                    <ToastContainer />

                                    <Row gutter={26} className="formContainer d-flex mt-5">
                                        <Col className="col" xs={24} sm={12} md={12} lg={12}>
                                            <div className="py-5">
                                                <label htmlFor="land_name" className="text-white text-base mb-4 block">Land Name</label>
                                                <input
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("land_name") ? "validation-border" : null}`}
                                                    type="text"
                                                    value={ApiData.land_name}
                                                    onChange={(e) => setFormData(e.target.value, 'land_name')}
                                                    name="land_name"
                                                    placeholder="Enter Land Name"
                                                    id="land_name"
                                                    maxLength={25}
                                                />
                                            </div>

                                            {roomTypes.map((type, i) => {
                                                return (
                                                    <div className="py-5" key={i}>
                                                        <div className="flex items-center gap-x-6 mb-4">
                                                            <label className="text-white text-base block">{type["name"]}</label>
                                                            {EditLink[type["key"]+'_id'] ? <Link to={'/admin/rooms/'+EditLink[type["key"]+'_id']} className="underline text-sm">Edit room-{EditLink[type["key"]+'_id']}</Link> : ''}
                                                        </div>
                                                        <select
                                                            // onChange={(e) => setMetahutRooms(e.target.value, type["key"])}
                                                            onChange={(e) => setFormData(e.target.value, type["key"]+'_id')}
                                                            className={`bg-indigo-700 input select-style rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty(type["key"]+"_id") ? "validation-border" : null}`}
                                                            defaultValue=""
                                                        >
                                                            <option value={""}>Select</option>

                                                            {rooms
                                                                .filter((room) => room.type_id === type["id"])
                                                                .map((item, l) => {
                                                                    return (
                                                                        <option key={l} value={item["id"]}
                                                                                selected={pivotRooms.filter((room) => room.room_id === item['id']).length !== 0}>
                                                                            {item["name"] + " - " +item["id"]}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </select>
                                                    </div>
                                                );
                                            })}

                                            <div className="pr-14">
                                                <div className="py-1 pr-10 flex items-center justify-between mb-4 gap-x-14">
                                                    <label htmlFor="main_color" className="text-white text-base block">Outside Color</label>
                                                    <input
                                                        type="color"
                                                        className={`bg-indigo-700 border-0`}
                                                        value={ApiData.main_color}
                                                        onChange={(e) => setFormData(e.target.value, 'main_color')}
                                                        placeholder="outside color..."
                                                        id="main_color"
                                                        name="main_color"
                                                    />
                                                </div>

                                                <div className="py-1 pr-10 flex items-center justify-between mb-4 gap-x-14">
                                                    <label htmlFor="stairs_color" className="text-white text-base block">Stairs Color</label>
                                                    <input
                                                        type="color"
                                                        className={`bg-indigo-700 border-0`}
                                                        value={ApiData.stairs_color}
                                                        onChange={(e) => setFormData(e.target.value, 'stairs_color')}
                                                        placeholder="stairs color"
                                                        id="stairs_color"
                                                    />
                                                </div>

                                                <div className="py-1 pr-10 flex items-center justify-between mb-4 gap-x-14">
                                                    <label htmlFor="color_1" className="text-white text-base block">Metahut Color 1</label>
                                                    <input
                                                        type="color"
                                                        className={`bg-indigo-700 border-0`}
                                                        value={ApiData.color_1}
                                                        onChange={(e) => setFormData(e.target.value, 'color_1')}
                                                        placeholder="outside color..."
                                                        id="color_1"
                                                        name="color_1"
                                                    />
                                                </div>

                                                <div className="py-1 pr-10 flex items-center justify-between mb-4 gap-x-14">
                                                    <label htmlFor="color_2" className="text-white text-base block">Metahut Color 2</label>
                                                    <input
                                                        type="color"
                                                        className={`bg-indigo-700 border-0`}
                                                        value={ApiData.color_2}
                                                        onChange={(e) => setFormData(e.target.value, 'color_2')}
                                                        placeholder="color 2"
                                                        id="color_2"
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex items-start gap-x-14">
                                            </div>
                                            
                                            <div className="fileChoose py-5">
                                                <div className="currentHeader">
                                                    <label className="text-white text-base mb-4 block">Description</label>
                                                </div>
                                                <textarea
                                                    className="bg-indigo-700 border-0 rounded-md text-white account-textarea input-style"
                                                    type="text" 
                                                    rows="5"
                                                    cols="55"
                                                    defaultValue={ApiData.description}
                                                    name="description"
                                                    maxLength="100"
                                                    onChange={(e) => setFormData(e.target.value, 'description')}
                                                />
                                            </div>
                                            

                                            <div className="py-5">
                                                <label htmlFor="ad_banner_link_1" className="text-white text-base mb-4 block">Advertisement Banner 1 Link</label>
                                                <input
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("ad_banner_link_1") ? "validation-border" : null}`}
                                                    type="text"
                                                    value={ApiData.ad_banner_link_1}
                                                    onChange={(e) => setFormData(e.target.value, 'ad_banner_link_1')}
                                                    name="ad_banner_link_1"
                                                    placeholder="Enter Link..."
                                                    id="ad_banner_link_1"
                                                    // maxLength={20}
                                                />
                                            </div>

                                            <div className="fileChoose py-5">
                                                <div className="currentHeader">
                                                    <label className="text-white text-base mb-4 block">Advertisement Banner 1 Photo</label>
                                                    {metahutFile && metahutFile['ad_banner_photo_1'] && <a href={metahutFile['banner_url'] + metahutFile['ad_banner_photo_1']} target="_blank" rel="noreferrer">See Current</a> }
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("ad_banner_photo_1") && errors.ad_banner_photo_1 != null ? errors.ad_banner_photo_1+ " validation-border" : ''}`}
                                                    placeholder="Ad Banner 1"
                                                    onChange={(e) => setFormData(e.target.files[0], 'ad_banner_photo_1', 'image')}
                                                />
                                            </div>

                                            <div className="py-5">
                                                <label htmlFor="user_limit" className="text-white text-base mb-4 block">User Limit</label>
                                                <input
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("user_limit") ? "validation-border" : null}`}
                                                    type="number"
                                                    value={ApiData.user_limit}
                                                    onChange={(e) => setFormData(e.target.value, 'user_limit')}
                                                    name="user_limit"
                                                    // placeholder="Enter Link..."
                                                    id="user_limit"
                                                    // maxLength={20}
                                                />
                                            </div>

                                        </Col>

                                        <Col className="col" xs={24} sm={12} md={12} lg={12}>

                                            <div className="py-5">
                                                <div className="currentHeader">
                                                    <label className="text-white text-base mb-4 block">Publish</label>
                                                </div>
                                                
                                                <input
                                                    type="checkbox"
                                                    className={`bg-indigo-700 input rounded-md input-style ${errors.hasOwnProperty("ad_banner_photo_1") && errors.ad_banner_photo_1 != null ? errors.ad_banner_photo_1+ " validation-border" : ''}`}
                                                    placeholder="publish"
                                                    onChange={(e) => setFormData(e.target.checked?1:0, 'published')}
                                                    defaultChecked={ApiData.published?true:false}
                                                    style={{width:"20px"}}
                                                    />
                                            </div>

                                            <div className="py-5">
                                                <label className="text-white text-base mb-4 block">Land plot</label>
                                                <select
                                                    onChange={(e) => setFormData(e.target.value, 'land_id')}
                                                    className={`bg-indigo-700 input select-style rounded-md text-white px-2 py-2 input-style`}
                                                >
                                                    <option value={""}>Select</option>

                                                    {lands.map((item, l) => {
                                                        return (
                                                            <option key={l} value={item["id"]}
                                                                    selected={item['id']==ApiData.land_id ? true : false}>
                                                                {item["sector"] + item["zone"] + " - " +item["plot_id"]}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>                       


                                            <div className="fileChoose py-5">
                                                <div className="currentHeader">
                                                    <label className="text-white text-base mb-4 block">Banner 1 (1920x500)</label>
                                                    {metahutFile && metahutFile['banner_1'] && <a href={metahutFile['banner_url'] + metahutFile['banner_1']} target="_blank" rel="noreferrer">See Current</a> }
                                                </div>
                                                <input
                                                    type="file"
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("banner_1") && errors.banner_1 != null ? errors.banner_1+ " validation-border" : ''}`}
                                                    placeholder="Banner 1"
                                                    onChange={(e) => setFormData(e.target.files[0], 'banner_1', 'image')}
                                                />
                                            </div>

                                            <div className="fileChoose py-5">
                                                <div className="currentHeader">
                                                    <label className="text-white text-base mb-4 block">Banner 2 (1920x500)</label>
                                                    {metahutFile && metahutFile['banner_2'] && <a href={metahutFile['banner_url'] + metahutFile['banner_2']} target="_blank" rel="noreferrer">See Current</a> }
                                                </div>
                                                <input
                                                    type="file"
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("banner_2") && errors.banner_2 != null ? errors.banner_2+ " validation-border" : ''}`}
                                                    placeholder="Banner 2"
                                                    onChange={(e) => setFormData(e.target.files[0], 'banner_2', 'image')}
                                                />
                                            </div>

                                            <div className="fileChoose py-5">
                                                <div className="currentHeader">
                                                    <label className="text-white text-base mb-4 block">Banner 3 (1920x500)</label>
                                                    {metahutFile && metahutFile['banner_3'] && <a href={metahutFile['banner_url'] + metahutFile['banner_3']} target="_blank" rel="noreferrer">See Current</a> }
                                                </div>
                                                <input
                                                    type="file" placeholder="Banner 3"
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("banner_3") && errors.banner_3 != null ? errors.banner_3+ " validation-border" : ''}`}
                                                    onChange={(e) => setFormData(e.target.files[0], 'banner_3', 'image')}
                                                />
                                            </div>

                                            <div className="fileChoose py-5">
                                                <div className="currentHeader">
                                                    <label className="text-white text-base mb-4 block">Cover image (ratio 1:1)</label>
                                                    {metahutFile && metahutFile['cover_image'] && <a href={metahutFile['banner_url'] + metahutFile['cover_image']} target="_blank" rel="noreferrer">See Current</a> }
                                                </div>
                                                <input
                                                    type="file"
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("cover_image") ? "validation-border" : null}`}
                                                    placeholder="Cover image ..."
                                                    onChange={(e) => setFormData(e.target.files[0], 'cover_image', 'image')}
                                                />
                                            </div>

                                            <div className="fileChoose py-5">
                                                <div className="currentHeader">
                                                    <label className="text-white text-base mb-4 block">Welcome message</label>
                                                </div>
                                                <textarea
                                                    className="bg-indigo-700 border-0 rounded-md text-white account-textarea input-style"
                                                    type="text"
                                                    rows="5"
                                                    cols="55"
                                                    defaultValue={ApiData.welcome_message}
                                                    name="welcome_message"
                                                    maxLength="100"
                                                    onChange={(e) => setFormData(e.target.value, 'welcome_message')}
                                                />
                                            </div>

                                            <div className="py-5">
                                                <label htmlFor="ad_banner_link_2" className="text-white text-base mb-4 block">Advertisement Banner 2 Link</label>
                                                <input
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("ad_banner_link_2") ? "validation-border" : null}`}
                                                    type="text"
                                                    value={ApiData.ad_banner_link_2}
                                                    onChange={(e) => setFormData(e.target.value, 'ad_banner_link_2')}
                                                    name="ad_banner_link_2"
                                                    placeholder="Enter Link..."
                                                    id="ad_banner_link_2"
                                                    // maxLength={20}
                                                />
                                            </div>

                                            <div className="fileChoose py-5">
                                                <div className="currentHeader">
                                                    <label className="text-white text-base mb-4 block">Advertisement Banner 2 Photo</label>
                                                    {metahutFile && metahutFile['ad_banner_photo_2'] && <a href={metahutFile['banner_url'] + metahutFile['ad_banner_photo_2']} target="_blank" rel="noreferrer">See Current</a> }
                                                </div>
                                                <input
                                                    type="file" 
                                                    accept="image/*"
                                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("ad_banner_photo_2") && errors.ad_banner_photo_2 != null ? errors.ad_banner_photo_2+ " validation-border" : ''}`}
                                                    placeholder="Ad Banner 1"
                                                    onChange={(e) => setFormData(e.target.files[0], 'ad_banner_photo_2', 'image')}
                                                />
                                            </div>

                                        </Col>
                                    </Row>
                                </>
                                : ''
                        }
                    </div>
                </Content>
            </Layout>
        </div>
    </div>
)
}

