import React from "react";
import {PATH} from "../../config";
import {Button} from "antd";

const MetahutPurchase = ({paymentType, Token, updateMetahutCount, DoBuying, L}) => {
    return <>
            <h4 className="gland-pay_ttl">{L.amount}</h4>
            <div className="flex items-start justify-between gap-y-4 flex-wrap gap-x-8 gland-amount_row mb-6">
                <div className="flex flex-col gap-y-1">
                    <p className="mb-0 font-normal gland-amount_token">{Token.price} {Token.name}</p>
                    {paymentType != 3 ? <span className="font-normal gland-amount_usd">≈ {Token.priceUsd} USD</span> : ''}
                </div>
                <span>x</span>
                <div className="flex gap-x-4 gland-amount_land">
                    <input
                        className="bg-white text-indigo-700 font-bold border-0 rounded-md text-center font-mont gland-amount_input"
                        type="text"
                        defaultValue="1"
                        name="landLimit"
                        onChange={(e) => updateMetahutCount(e.target.value)}
                    />
                    <div className="flex flex-col gap-y-1">
                        <p className="mb-0 font-normal gland-amount_token">Metahut</p>
                        <span className="font-normal font-mont gland-amount_limit">
                            3 {L.getMetahutRooms}
                        </span>
                    </div>
                </div>
            </div>
            <div className="gland-amount-total">
                <h5 className="gland-amount_ttl">{L.completeAmount}</h5>
                <div className="flex flex-col gland-amount_price">
                    <div className="flex items-center gap-x-1 gland-amount_token">
                        {Token.nftCount ? Token.totalPrice : '-' }
                        <img src={PATH + Token.icon} style={{height: "16px", marginLeft: "3px"}} alt=""/>
                        {Token.name}
                    </div>
                    {paymentType != 3 ? <span className="gland-amount_usd">≈ {Token.totalPriceUsd + ' USD'}</span> : ''}
                </div>
            </div>
            <div className="line-x"></div>
            <p className="font-light font-sans gland-amount_note">{L.getLandAmountNote}</p>
            <div className="text-center">
                <Button
                    disabled={Token.nftCount ? '' : 'disable'}
                    className={`border-0 mb-5 rounded-3xl font-normal gland-amount_btn ${Token.nftCount ? 'bg-cyan-500 text-indigo-900 hover:bg-cyan-400' : 'bg-indigo-600 text-white opacity-50 cursor-not-allowed'}`}
                    onClick={DoBuying}
                >{L.getLandAmountButton}</Button>
            </div>
        </>
}

export default MetahutPurchase;