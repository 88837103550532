import React from "react";
import {NavLink} from "react-router-dom";
import { ConvertDate } from "../../../config/CoreMethods";
import { useSelector } from "react-redux";

const TicketItem = ({element}) => {
    let L = useSelector (state => state.settings.L);

    return (
        <NavLink to="" className="ticket font-mont">
            <div className="ticket-article">
                <span className="ticket-subject">{L.myTicketNavEvent}</span>
                <h4 className="ticket-info">{element.event_name}</h4>
            </div>
            <div className="ticket-article">
                <span className="ticket-subject">{L.myTicketNavDate}</span>
                <h4 className="ticket-info">{ConvertDate(element.start_date, 1)} PM</h4>
            </div>
            <div className="ticket-article">
                <span className="ticket-subject">{L.myTicketNavVenue}</span>
                <h4 className="ticket-info">{element.metahut_name}</h4>
            </div>
            <div className="ticket-article">
                <span className="ticket-subject">{L.myTicketNavTicket}</span>
                <h4 className="ticket-info color-burn">#{element.ticket_id}</h4>
            </div>
        </NavLink>
    )
}

export default TicketItem;