import React from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";

const UnstakeConfirmModal = ({
    claim,
    hideUnstakeModal,
    penaltyPrice,
    claimItem,
    IsSuperPool
}) => {
    let L = useSelector (state => state.settings.L);

    return (
        <div className="fixed inset-0 z-20 overflow-y-auto unstake" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div className="fixed inset-0 bg-indigo-900 bg-opacity-80 transition-opacity" aria-hidden="true"> </div>
                <div className="inline-block border-indigo-600 border-2 border-solid rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-xs sm:w-full">
                    <div className="bg-indigo-800">
                        <div className="modal-body px-6 py-8 gap-y-5 flex flex-col items-center">
                            <NumberFormat
                                value={penaltyPrice}
                                className="text-base text-md"
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={(value, props) =><span {...props}>
                                    {IsSuperPool ? 
                                        L.unstakeConfirmModalNoteFee ? L.unstakeConfirmModalNoteFee.replace('_VALUE_', value) :''
                                    :
                                    L.unstakeConfirmModalNote ? L.unstakeConfirmModalNote.replace('_VALUE_', value) :''
                                    }
                                </span>}
                            />
                            <div className="flex items-center justify-center gap-x-2">
                                <button type="button" onClick={() => hideUnstakeModal()} className="bg-indigo-900 text-gray-300 hover:bg-indigo-600 cursor-pointer py-2 px-4 rounded-md border-0 focus:outline-none focus:shadow-outline">
                                    {L.unstakeConfirmModalCancel}
                                </button>
                                <button type="button" onClick={() => claim(claimItem, 2)} className="bg-cyan-500 text-indigo-900 hover:bg-cyan-400 cursor-pointer py-2 px-4 rounded-md border-0 focus:outline-none focus:shadow-outline">
                                    {L.unstakeConfirmModalOk}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnstakeConfirmModal;