import React, { Component } from 'react'
import './styles.css'
import { Link } from "react-router-dom";
import {PATH} from "../../../../config";

export default class index extends Component {
  render() {
    return (
      <div className="container">
        <div
          className="card"
          style={{ "--tooltip-color": this.props.metahut.main_color ?? "#fff" }}
        >
          <div className="imgBx">
            <img
              src={PATH+'/admin/metahut-plot.png'}
              alt="Metahut"
            />
          </div>
          <div className="contentBx">
            <h2 className="text-base">{this.props.metahut.land_name}</h2>
            <div className="size"></div>
            <div className="color">
              <h3 className="text-gray-300">Main Color :</h3>
              <span
                style={{ background: this.props.metahut.main_color ?? null }}
              ></span>
            </div>
            <div className="color">
              <h3 className="text-gray-300">Stairs Color:</h3>
              <span
                style={{ background: this.props.metahut.stairs_color ?? null }}
              ></span>
            </div>
            <div className="mt-4">
              <a href="#" className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">Delete</a>
              <Link to={`/admin/metahut/${this.props.metahut.id}`} className="border-0 bg-indigo-600 text-gray-300 hover:bg-indigo-800 rounded-md">Edit</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
