import React, {useEffect} from "react";
import {Button, Col, Row} from "antd";
import {PATH} from "../../config";
import BuyContainer from "./buy/BuyContainer";
import Title from "./shared/Title";
import Fag from "./fag/Fag";
import VideoContainer from "./video/VideoContainer";
import RoomsContainer from "./rooms/RoomsContainer";
import BenefitsContainer from "./benefits/BenefitsContainer";
import {useLocation} from "react-router-dom";

const NewMetahutContainer = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [location]);

    return (
         <>
             <div className="container mx-auto relative pt-162 min-h-calc-head py-8">
                <Title title="MEET METAHUT" className="px-8 mb-9 mt-20 sm-mt-0 text-2xl text-center text-white uppercase font-normal" />
                <Row gutter={{xs: 46, sm: 46, md: 46, lg: 44}} className="px-8 pr-0 w-full xs-col-full sm-px-0 mt-10 sm-mt-0">
                    <Col xs={15} sm={15} md={14} lg={15} className="gutter-row mb-4">
                        <VideoContainer />
                    </Col>
                    <Col xs={9} sm={9} md={10} lg={9} className="gutter-row mb-4">
                        <BuyContainer />
                    </Col>
                </Row>
                <RoomsContainer />
                <BenefitsContainer />
                 <Fag />
             </div>
             <Title title="iNVESTED BY" className="px-8 pt-4 mb-6 mt-10 sm-mt-0 text-center text-2xl text-white uppercase font-normal" />
             <div className="pb-8">
                 <div className="mb-20" style={{marginTop: "3em", background: `url(${PATH+'dark_bg-scaled.jpg'}) no-repeat center center`}}>
                     <div className="container mx-auto py-8">
                         <img src={PATH+'investors.png'} alt="" className="w-100" />
                     </div>
                 </div>
                 <div className="pb-8">
                     <div className="bg-indigo-900 py-8 px-8 text-center">
                         <h4 className="text-2xl text-white mb-12">100+ influencers with 50M+ audiences are already Metahut Owners</h4>
                         <Button type="primary" size="large" className={`border-0 text-sm font-bold py-2 px-4 h-auto rounded-md bg-cyan-500 text-indigo-900 hover:bg-cyan-400`}>ACQUIRE YOUR METAHUT</Button>
                     </div>
                 </div>
             </div>
         </>
    )
}

export default NewMetahutContainer;