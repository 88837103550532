import React, {useState} from 'react';
import JsxParser from 'react-jsx-parser';
import '../../../assets/css/accordion.css';
import {PATH} from "../../../config";
import { useSelector } from 'react-redux';

const FagMore = () => {
    let L = useSelector (state => state.settings.L);

    const [selected, setSelected] = useState(null);
    const toggle = (i) => {
        if(selected === i) {
            return setSelected(null)
        }
        setSelected(i);
    }


    const data = [
        {
            question: L.faqTitle1,
            answer: L.fagContent1
        },
        {
            question: L.faqTitle2,
            answer: L.fagContent2 
        },
        {
            question: L.faqTitle13,
            answer: L.fagContent3 
        },
        {
            question: L.faqTitle3,
            answer: L.fagContent4
        },
        {
            question: L.faqTitle4,
            answer: L.fagContent5 
        },
        {
            question: L.faqTitle5,
            answer: L.fagContent6
        },
        {
            question: L.faqTitle6,
            answer: L.fagContent7 
        },
        {
            question: L.faqTitle7,
            answer: L.fagContent8 
        },
        {
            question: L.faqTitle8,
            answer: L.fagContent9
        },
        {
            question: L.faqTitle9,
            answer: L.fagContent10
        },
        {
            question: L.faqTitle10,
            answer: L.fagContent11
        },
        {
            question: L.faqTitle11,
            answer: L.fagContent12
        },
        {
            question: L.faqTitle12,
            answer: L.fagContent13 
        },
    ];

    return (
        <div className="container mx-auto px-12 relative pt-162 min-h-calc-head py-8">
        <div className="wrapper mt-20">
            <h1 className="text-white text-5xl font-extralight mb-12 uppercase">{L.faq}</h1>
            <div className="accordion">
                {data.map((item, i) => (
                    <div className="item mb-4" key={i}>
                        <div className="fag-head title bg-indigo-900 hover:bg-indigo-400 rounded-md py-5 px-8 flex items-center justify-between gap-x-4" onClick={() => toggle(i)}>
                            <h2 className="font-medium text-lg text-white mb-0">{item.question}</h2>
                            <img src={selected === i ? PATH + 'arrow-up.svg' : PATH + 'arrow-down.svg'} alt=""/>
                        </div>
                        <div className={`text-base text-gray-300 ${selected === i ? 'content show' : 'content'}`}>
                            <div className="py-5">
                                <JsxParser
                                    jsx={item.answer}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </div>
    )
}


export default FagMore;