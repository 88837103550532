import React, {useState} from 'react';
import JsxParser from 'react-jsx-parser';
import '../../../assets/css/accordion.css';
import {Link} from "react-router-dom";
import {Button} from "antd";
import {PATH} from "../../../config";
import Title from "../shared/Title";
import { useSelector } from 'react-redux';

const Fag = () => {
    let L = useSelector (state => state.settings.L);

    const [selected, setSelected] = useState(null);
    const toggle = (i) => {
        if(selected === i) {
            return setSelected(null)
        }
        setSelected(i);
    }
    return (
        <>
            <Title title={L.faq} className="px-8 mb-9 mt-20 sm-mt-0 text-center text-2xl text-white uppercase font-normal" />
            <div className="wrapper">
                <div className="accordion">
                    {data.map((item, i) => (
                        <div className="item mb-4" key={i}>
                            <div className="title bg-indigo-900 hover:bg-indigo-400 rounded-md py-5 px-8 flex items-center justify-between" onClick={() => toggle(i)}>
                                <h2 className="font-medium text-lg text-white">{item.question}</h2>
                                <img src={selected === i ? PATH + 'arrow-up.svg' : PATH + 'arrow-down.svg'} alt=""/>
                            </div>
                            <div className={`text-base text-gray-300 ${selected === i ? 'content show' : 'content'}`}>
                                <div className="py-5">
                                    <JsxParser
                                        jsx={item.answer}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="text-center mt-20">
                <Link to="/faq">
                    <Button type="primary" size="large" className={`border-0 text-sm font-bold py-2 px-4 h-auto rounded-md bg-cyan-500 text-indigo-900 hover:bg-cyan-400`}>{L.seeMore}</Button>
                </Link>
            </div>
        </>
    )
}


const data = [
    {
        question: 'How to buy a Metahut?',
        answer: `Here are some resources that you can use to learn how to buy Metahuts step-by-step (they explain the Land Plot purchase process but it’s nearly the same with the Metahut purchase process):
            <ul className="list-circle pl-9">
                <li className="mt-2"><a href="https://medium.com/@metafluence/how-to-buy-land-plots-in-metafluence-city-75b8671fb14c" target="_blank" rel="noreferrer" className="underline text-cyan-500">Blog post</a> (ENG)</li>
                <li className="mt-2"><a href="https://www.youtube.com/watch?v=XIyqCPeV_eQ&feature=youtu.be" target="_blank" rel="noreferrer" className="underline text-cyan-500">Video guide</a> (ENG)</li>
                <li className="mt-2"><a href="https://www.youtube.com/watch?v=bw01pSiiJeQ&feature=youtu.be" target="_blank" rel="noreferrer" className="underline text-cyan-500">Video guide</a> (RU)</li>
                <li className="mt-2"><a href="https://www.youtube.com/watch?v=c_JJCwEhg3U&feature=youtu.be" target="_blank" rel="noreferrer" className="underline text-cyan-500">Video guide</a> (TR)</li>
            </ul>`
    },
    {
        question: 'What is the price for Metahuts?',
        answer: `Metahut prices will vary depending on the means of payment:
            <br/><br/><p>Whitelist Sale prices:</p>
            <ul className="list-circle pl-9">
                <li className="mt-2">If bought with $METO: $3500</li>
                <li className="mt-2">If bought with $BUSD: $3700</li>
            </ul>
            <p>Public Sale prices:</p>
            <ul className="list-circle pl-9">
                <li className="mt-2">If bought with $METO: $3900</li>
                <li className="mt-2">If bought with $BUSD: $4150</li>
            </ul>
            <p><i>Aside from the required $METO/$BUSD</i> amount, a small amount of $BNB is also required to pay the transaction fees.</p>`,
    },
    {
        question: 'Can I add additional rooms to my Metahut in the future?',
        answer: `Yes, you can! While Event, NFT, and Shopping Rooms are the fundamental constituents within every Metahut, you’ll also be able to add other customizable rooms to your virtual home. We will share relevant details about this feature in the future, so stick with us not to miss any updates.`
    },
    {
        question: 'Is it possible to purchase Metahuts on mobile?',
        answer: 'Yes, you can acquire Land Plots or Metahuts through Metamask mobile application with just a few clicks. The process is similar to how it’s on desktop, but we also created a detailed <a href="https://medium.com/@metafluence/how-to-buy-land-plots-in-metafluence-city-75b8671fb14c#5fcf" className="underline text-cyan-500">step-by-step guide</a>.',
    },
    {
        question: 'What are the main benefits of Metahuts?',
        answer: `Metahut is a unique multi-purpose Metaverse residence with numerous advantages. It consists of different customizable rooms, such as Event, NFT, and Shopping Rooms, where you can host public and private events, exhibit NFTs, or even sell your products in a personalized marketplace. All these great features will enable Metahut owners to engage with their fans and brands in a seamless manner while providing them with an unparalleled experience.`,
    },
    {
        question: 'What kind of events can I host in my Event Room?',
        answer: `Long story short, you can organize meetings, parties, presentations, etc., in your Event Room. As the Metafluence ecosystem is commercially oriented, the main focus of the events is to allow individuals to monetize their social media reach and influence, whether it’s audience participation or brand sponsorships. The events can be held as Public events (accessible by everyone) or Private events (accessible for the event ticket holders).`
    },
    {
        question: 'Are Metahuts only intended for influencers?',
        answer: `The good news is, they are absolutely not! Metafluence is an influencer-driven metaverse aimed at bringing influencers, brands, and their audiences together under the same roof. That way, by attracting lots of people from all over the world, Metafluence City will be an irreplaceable platform for everyone to shine and become a metapreneur. So everybody can acquire Metahuts and become a Metafluence citizen.`,
    },
    {
        question: 'Will there be any limit on Metahut Purchases?',
        answer: 'No. You can purchase as many Metahuts as you want with a single wallet.'
    },
    {
        question: 'Can I choose the location of my Metahut?',
        answer: `Yes, you can! Metahuts are acquired and placed on the existing Land Plots. The sooner you decide to acquire the properties, the better locations you’ll be able to find.`,
    },
    {
        question: 'How can I claim my Metahut',
        answer: `You can claim your virtual properties with your MetaMask wallet on Metafluence City Map: https://app.metafluence.com/city/.
        <br/><br/><p>If the wallet that you’ve used during the Launchpad Sale or a certain campaign is not a MetaMask wallet, then you’ll need to transfer it to MetaMask. We’ve prepared a detailed guide on how to do that.</p>`
    },
];

export default Fag;