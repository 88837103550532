import React, { useEffect, useState } from "react";
import {Button, Col, Row} from "antd";
import {PATH} from "../../config";
import {NavLink} from "react-router-dom";
import axiosData from "../../helpers/axiosData";
import {useSelector} from "react-redux";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";


const DiscoveryItems = ({title, image, type, limit}) => {

    const DefaultAvatar = PATH+'avatar.png';
    const AvatarBG = PATH+'avatarBG.png';
    const DefaultCoverImage = PATH+'/metahuts-empty.png';

    const [listData, setListData] = useState([])
    
    let DiscoverySearchText = useSelector((state) => state.metahut.DiscoverySearchText);
    let BackLogin = useSelector((state) => state.metahut.BackLogin);
    let L = useSelector(state => state.settings.L);
    
    let apiUrl = '-in';
    let ReloadError = 0;

    const getAllData = (type, limit, search) => {

        let search_text = search != '' ? "?search="+search : "";

        axiosData.get("/api/_web3/metahuts/filter" + apiUrl + '/' + type + "/0/" + limit + "/" + search_text).then((res) => {

            if (res.data.data.length) {
                setListData( res.data.data );
            } else {
                setListData( [] );     
            }
        }).catch(error => {
            if ( !ReloadError ) {
                apiUrl = '';
                ReloadError = 1;
                getAllData (type, limit, search)
                console.log('error get data', error)
            }
        })
            
    }

    const sendVisitorData = (id, FollowType) => {
        
        let Post = FollowType === 0 ? { metahut_id: id, unfollow: 1 } : { metahut_id: id, follow: 1 };

        axiosData.post(`/api/_web3/metahuts/add-visitor/`, Post)
            .then(() => {
                getAllData(type, limit, DiscoverySearchText)
            })
            .catch((error) => {
                console.log('error send data', error);
            })
    }
    
    useEffect(() => {
         
        getAllData(type, limit, DiscoverySearchText)

    }, [type, limit, DiscoverySearchText])

    return (
        <div> 
            <div className="flex items-center mb-10 discovery-subject">
                <img src={PATH + image} alt="" className="dicovery-subject_icon" />
                <h3 className="font-bold font-mont mb-0 discovery-title"> {title} </h3>
            </div>
            <Row gutter={30} className="mb-20 discovery-row">
        
                {listData && listData.length ?  
                  listData.map(li => {
                    return <Col xs={24} sm={24} md={6} lg={6} key={li.id}>
                        <div className="w-100 thumb">
                            <div className="relative flex items-end thumb-asset" style={{background: `url(${li.cover_image !='' ? li.cover_image : DefaultCoverImage})  no-repeat center center`, backgroundSize: "cover", minHeight: "164px"}}>
                                <div className="flex items-center flex-1 justify-between gap-x-2 thumb-asset_metahut">
                                    <div className="flex items-center gap-x-1 thumb-metahut_owner">
                                        <div className="relative avatar_box thumb-metahut_avatar"
                                             style={{backgroundColor: li.bgcolor, width: "28px", height: "28px"}}
                                        >
                                            <img lo={li.avatar} src={li.avatar} onError={(elem) => { elem.target.src=DefaultAvatar }}  width="100%" height="100%" className="avatar_img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col thumb-info">
                                <div className="flex items-center justify-between gap-x-6">
                                    <p className="text-white mb-0 font-normal thumb-title">
                                        {li.land_name}
                                    </p>
                                    {BackLogin ?
                                        li.is_followed ?
                                            <Button onClick={() => sendVisitorData(li.id, 0)} className={`border-0 mt-2 text-white rounded-3xl font-medium discovery-follow font-mont flex items-center justify-center gap-x-1 bg-indigo-700 hover:bg-indigo-800 focus:bg-indigo-700`}>
                                                <img src={PATH + 'user-add.svg'} alt=""/>
                                            </Button>
                                            :
                                            <Button onClick={() => sendVisitorData(li.id, 1)} className={`border-0 mt-2 text-white rounded-3xl font-medium discovery-follow font-mont flex items-center justify-center gap-x-1 bg-pink-400 hover:bg-pink-300 focus:bg-pink-400`}>
                                                <img src={PATH + 'user-plus.svg'} alt=""/>
                                                <span>{L.follow}</span>
                                            </Button>
                                         :
                                        <NavLink to={ROUTES_CONFIG.loginPage.path}>
                                            <Button className="border-0 mt-2 bg-pink-400 text-white hover:bg-pink-300 rounded-3xl font-medium discovery-follow font-mont flex items-center justify-center gap-x-1">
                                                <img src={PATH + 'user-plus.svg'} alt=""/>
                                                <span>{L.follow}</span>
                                            </Button>
                                        </NavLink>
                                    }
                                </div>
                                <div className="flex items-center flex-wrap stats">
                                    <div className="flex items-center stats-item">
                                        <i className="icon-people"></i>
                                        <span>{ li.followers }</span>
                                    </div>
                                    <div className="flex items-center stats-item">
                                        <i className="icon-eye"></i>
                                        <span>{li.visitors}</span>
                                    </div>
                                    <div className="flex items-center stats-item">
                                        <i className="icon-star"></i>
                                        <span>{li.rate}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                })
                : ''}
            </Row>
        </div>    
    )
}

export default DiscoveryItems;