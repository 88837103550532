import React from "react";
import {NavLink} from "react-router-dom";
import {Layout} from "antd";
import {ROUTES as ROUTES_CONFIG} from "../../../config/constants";
import { useSelector } from 'react-redux';

const { Sider } = Layout

const SettingsSidebar = () => {

    let L = useSelector (state => state.settings.L);
    let GetUser = useSelector((state) => state.user.user);
    let launchpadPlotLimit = useSelector ((state) => state.land.LaunchpadPlotLimit); 

    return (
        <Sider className="pt-8 pb-8 pl-10 pr-4 bg-indigo-900 sidebar-bg h-full z-1 visible-md" width="260px">
            <h3 className="text-gray-300 font-light text-base uppercase">{L.sidebarSettings}</h3>
            <ul className="pl-4 text-lg flex-col flex mt-10 vert_menu">
                <li>
                    <NavLink to={ROUTES_CONFIG.account.path} className="text-white font-light">
                        {L.sidebarAccount}
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES_CONFIG.status.path} className="text-white font-light">
                        {L.sidebarStatus}
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES_CONFIG.wallet.path} className="text-white font-light">
                        {L.sidebarWallet}
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES_CONFIG.claims.path} className="flex items-center gap-x-4 text-white font-light">Claims {(GetUser && GetUser.referral_claimable && GetUser.referral_claimable.length) || launchpadPlotLimit!='-' ? <span className="font-bold text-center counter">{ (GetUser && GetUser.referral_claimable && GetUser.referral_claimable.length ? GetUser.referral_claimable.length : 0) +(launchpadPlotLimit!='-'?launchpadPlotLimit:0)}</span>:''}</NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES_CONFIG.securityPrivacy.path} className="text-white font-light">
                        {L.userDropSecurity}
                    </NavLink>
                </li>
            </ul>
        </Sider>
    )
}

export default SettingsSidebar;