import React, {useRef, useState} from "react";
import {Button, message} from "antd";
import { useDispatch, useSelector } from "react-redux";

const FileUpload = ({L}) => {
    const inputRef = useRef(null);

    const [file, setFile] = useState(null);
    const [src, setSrc] = useState(null);
    const maxFileSize = 2000000; // 2Mb

    let formData = useSelector ((state) => state.event.formData);
    let dispatch = useDispatch();

    const handleUploadClick = () => {
        inputRef.current?.click();
    };

    const handleChange = (e) => {
        const allowedTypes = ["image/jpeg", "image/png"];
        console.log(e.target.files);

        if (allowedTypes.includes(e.target.files[0]?.type)) {
            console.log('type true')
            if (e.target.files[0].size < maxFileSize) {
                if(e.target.files.length > 0){
                    setFile(e.target.files[0]);
                    setSrc(URL.createObjectURL(e.target.files[0]));
                    dispatch({type: 'setFormData', payload: {...formData, cover_image : e.target.files[0], cover_image_src : URL.createObjectURL(e.target.files[0]) }})
                }
            } else {
                message.error(`${L.uploadSizeError}`);
            }
        } else {
            console.log('err')
            message.error(`${L.uploadTypeError}`);
        }
        if (!e.target.files) {
            return;
        }
    }

    const ClearFile = () => {
        setFile(null); 
        setSrc(null);
        dispatch({type: 'setFormData', payload: {...formData, cover_image : '', cover_image_src: ''}})
    }

    return (
        <div className="eform-item">
            <label htmlFor="eventDesc" className="elabel">{L.eventCover}</label>
            {!file ?
                <Button onClick={handleUploadClick} className="eform-upload">
                    <i className="icon-upload"></i>
                    <span>{L.upload}</span>
                </Button> :
                <div className="eform-cover">
                    <img 
                        src={src} 
                        alt="" 
                        style={file ? {width: "100%", height: "150px", objectFit: "cover"} : {}} 
                    />
                    <Button onClick={() => {ClearFile()}} className="eform-reset"><i className="icon-remove"></i></Button>
                </div>
            }
            <input type="file" onChange={handleChange} name="eventDesc" ref={inputRef} accept=".png, .jpg" className="eform-file" />
            <div className="eform-file_val">
                <div className="eform-file_res">
                    {L.resolution}: <span>1000 x 500 (2:1)</span>
                </div>
                <div className="line-y"></div>
                <div className="eform-file_size">
                    {L.sizeLimit}: <span>{maxFileSize / 1000000} MB</span>
                </div>
                <div className="line-y"></div>
                <div className="eform-file_format">
                    {L.format}: <span>.png, .jpg</span>
                </div>
            </div>
        </div>
    )
}

export default FileUpload;