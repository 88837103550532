import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {Link} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './NFTRooms.css'
import { ethers } from 'ethers';
import nft_abi from "../contract/NftRoom.json";
import event_abi from "../contract/EventRoom.json";
import shopping_abi from "../contract/ShoppingRoom.json";
import {Button, Col, Layout, Row} from "antd";
import SelectSearch from "../../custom/SelectSearch";
import Sidebar from "./Sidebar";
import {PATH} from "../../../config";

const { Content } = Layout


export default class NFTRooms extends Component {
  constructor() {
    super();
    this.state = {
      needTo: axios.create({
        withCredentials: true, 
        baseURL: "https://app.metafluence.com/api/v1/public",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      }),

      rooms: [],
      loading: true,
      keys: [],
      nftRooms: [],
      shoppingRooms: [],
      eventRooms: [],
      addressList : []
    };
  }

  async syncRooms(category) {
    let myCollection = [];
  
    var api = null;
    var contract_adress = null;
    if(category === 'nft'){
      contract_adress = "0x443c76A1B1Fc0b6c62A4Ee62098D5c4F5eB67379";
      api = nft_abi.abi
    }else if(category === 'shopping'){
      contract_adress = "0x6c56bD4b327c60c85aBCeFCCA5Ff65EFbf4823dA";
      api = shopping_abi.abi
    }else if(category === 'event'){
      contract_adress = "0x3Dd73fc3118Da3F609583bAe5173005E41295A89";
      api = event_abi.abi
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const user_address = signer.getAddress();
    const contract = new ethers.Contract(contract_adress, api, signer);
  
    myCollection = await contract.myCollection(user_address);

    if(category === 'nft'){
      this.setState({
        nftRooms: myCollection
      })
    }else if(category === 'shopping'){
      this.setState({
        shoppingRooms: myCollection
      })
    }else if(category === 'event'){
      this.setState({
        eventRooms: myCollection
      })
    }
  }



  changeAccount = (val) => {

    this.state.needTo
      .get("/api/_web3/rooms?user_id="+val)
      .then((res) => {
        this.setState({
          rooms: res.data,
        });
      })
      .finally(() => this.setState({ loading: false }));
    // console.log('changeAccount', val)
    return false;
  }

  initRooms(e) {
    // this.setState({ loading: true });
    return this.state.needTo
      .post(`/api/_web3/rooms`, {})
      .then((res) => {
        // toast.success("Synced Successfully");
      })
      .catch((error) => {
        console.log(error); 
        // toast.error("Oops! Something went wrong");
      })
  }

  async componentDidMount() {
    // await this.getKeys();
    await this.initRooms();

    this.changeAccount('')

    console.log('this.state.rooms.length', this.state.rooms.length, this.state.rooms)
  }

  async componentDidUpdate() {
    if (this.state.rooms.length == 0) {
      /*await this.setState({
        loading: true
      })*/
    }
  }



  deleteNFTs(id, e) {
    this.state.needTo.post(`/api/_web3/nfts/${id}/delete`).then((res) => {
      e.target.closest(".nft_item").remove();
    });
  }

  render() {
    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
          <Layout className="bg-transparent h-full">
            <Sidebar />
            <Content className="pt-8 pb-8 pl-14 pr-0 settings-content">
              <div className="pr-4 py-8">
                <SelectSearch changeAccount={this.changeAccount}/> 
                <div className="md-mt-0 sm-px-0">
                  <h1 className="text-white text-5xl font-extralight">Rooms</h1>
                </div>
                {
                  this.state.loading ?
                      <div className="flex items-center gap-x-4 mt-5">
                        <h1 className="text-white text-2xl mb-0">Loading</h1>
                        <div className="loading-spinner"></div>
                      </div> :
                      <>
                        <ToastContainer />
                        <Row gutter={26} className="grid-container mt-4">
                          {this.state.rooms.length > 0 && !console.log('this.state.rooms', this.state.rooms) ?
                              this.state.rooms.map((item, i) => {
                                return (
                                    <Col xs={24} sm={12} md={12} lg={8} key={i} className="gutter-row py-5 grid-item">
                                      <div className="nft-room h-full">
                                        <div className="nft-room-head w-full">
                                          <img src={PATH+'/admin/guestroom2.jpeg'} alt="nft room" />
                                        </div>
                                        <div className="nft-room-bottom px-6 py-5 h-full">
                                          <div className="flex items-center gap-x-4">
                                            <span className="text-gray-300 text-sm">Room name: </span>
                                            <span className="text-sm">{item["name"]}</span>
                                          </div>
                                          <div className="flex items-center gap-x-4">
                                            <span className="text-gray-300 text-sm">Type: </span>
                                            <span className="text-sm">{item["type"] && item["type"]["name"]}</span>
                                          </div>
                                          <div className="nft-room-bottom-buttons mt-5 d-flex">
                                            <Link to={`/admin/rooms/${item["id"]}`}>
                                              <Button type="btn" className="w-full border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">
                                                Edit
                                              </Button>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                );
                              }) :
                              <div className="mt-5 bg-indigo-900 px-8 py-12 w-full flex items-center justify-center rounded-xl">
                                <p className="text-xl text-white font-medium mb-0">You don't have any rooms</p>
                              </div>
                          }
                        </Row>
                      </>
                }
              </div>
            </Content>
          </Layout>
        </div>
    )
  }
}
