import React from "react";
import { useSelector } from "react-redux";

const Marketplace = () => {
    let L = useSelector(state => state.settings.L);
    return (
        <div className="container mx-auto px-12 relative pt-162 min-h-calc-head py-8">
            <div className="wrapper mt-20">
                <h1 className="text-white text-5xl font-extralight mb-12 uppercase">
                    {L.marketplaceTitle}
                </h1>
            </div>
        </div>
    )
}

export default Marketplace;