import React, {useEffect, useState} from "react";
import {Button, Col, Skeleton} from "antd";
import {NavLink} from "react-router-dom";
import {PATH} from "../../../../config";
import {selectUser} from "../../../../redux/features/userSlice";
import {useSelector} from "react-redux";
import {ROUTES as ROUTES_CONFIG} from "../../../../config/constants";

const Avatars = ({L}) => {

    const selector = useSelector;

    let GetUser = selector(selectUser);
    let CurrentAccount = selector ((state) => state.metahut.Account);

    const [AvatarFile, setAvatarFile] = useState(PATH+'avatar.png')

    const lastAvatar = GetUser && GetUser.avatars[GetUser.avatars.length - 1]
    const AvatarBG = PATH+'avatarBG.png';


    useEffect(() => {
        if (GetUser && GetUser.avatar) {
            setAvatarFile(GetUser.avatar)
        }

    }, [GetUser, CurrentAccount])

    return (
        GetUser && GetUser.avatars && GetUser.avatars.length == 0 ?
        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col items-center assets-col">
            <div className="w-100 thumb">
                <div className="relative flex items-end avatar_box" 
                    style={{backgroundImage: `url(${AvatarBG})`, width: '100%', minHeight: '213px'}}
                >
                    <img src={PATH + 'avatar.png'} width="100%" height="213px" />
                    <div className="flex flex-col items-center justify-center z-1 thumb-buy_content">
                        <p className="text-center mb-0 font-light thumb-buy_note">
                            {L.createCustomAvatar}
                        </p>
                        <NavLink to="#">
                            <Button type="primary" className="min-w-24 border-0 text-indigo-900 hover:bg-cyan-400 thumb-buy_btn">{L.createAvatarBtn}</Button>
                        </NavLink>
                    </div>
                </div>
            </div>
            <span className="font-normal flex items-center gap-x-1 asset-link">{L.avatars} (0)</span>
        </Col> :
        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col items-center assets-col">
            <NavLink to={ROUTES_CONFIG.avatar.path} className="w-100 thumb">
                <div className="relative flex items-end avatar_box" 
                    style={{backgroundImage: `url(${AvatarBG})`, width: '100%', height: '217px'}}
                >
                    {lastAvatar && GetUser.readyplayermeavatar == lastAvatar.url ? 
                    <i className="icon-check text-2xl absolute top-0 right-0" 
                        style={{color: "#00A76A", margin: "10px 14px"}}></i> : ''}
                    <img src={AvatarFile} width="100%" height="213px" style={{objectFit: "cover"}} />
                </div>
            </NavLink>
            <NavLink to={ROUTES_CONFIG.avatar.path} 
                className="font-normal flex items-center gap-x-1 asset-link"
            >
                {L.avatars} {GetUser ? `(${GetUser.avatars.length})`
                : <Skeleton active paragraph={false} title={{width: "22px", style: {margin: "0", height: "22px"}}} className="inline"></Skeleton>}
            </NavLink>
        </Col>
    )
}

export default Avatars;