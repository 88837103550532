import axios from "axios";

export default axios.create({
  withCredentials: true,
  baseURL: "https://app.metafluence.com/api/v1/public",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
  },
});
