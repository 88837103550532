import React from "react";
import {Layout} from "antd";
import EventsSider from "./EventsSider";
import EventsForm from "./EventsForm";

const { Content } = Layout;

const EventsStep = () => {
    return <div className="container mx-auto pt-162 min-h-calc-head h-full">
        <Layout className="h-full settings-layout">
            <EventsSider />
            <Content className="pt-8 pl-10 pr-10 settings-content">
                <EventsForm />
            </Content>
        </Layout>
    </div>
}

export default EventsStep;