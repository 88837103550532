import React from "react";
import {Col, Row} from "antd";
import { useDispatch, useSelector } from "react-redux";

const ContactDetails = () => {


    const selector = useSelector;
    const dispatch = useDispatch();

    let formData = selector ((state) => state.event.formData);
    let L = selector (state => state.settings.L);

    const setFormData = (key, value) => {
        dispatch({type: 'setFormData', payload: {...formData, [key] : value}})
    }

    return (
        <div className="contact-details">
            <Row gutter={50}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="eform-item">
                        <label htmlFor="wpNum" className="text-base text-white mb-2 inline-block">
                            {L.whatsappNumber}
                        </label>
                        <div className="relative">
                            <input
                                className="input eform-input input-prefix"
                                type="text"
                                defaultValue={formData.whatsapp}
                                name="wpNum"
                                onChange={ e => setFormData('whatsapp', e.target.value) }
                            />
                            <i className="icon-whatsapp"></i>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="eform-item">
                        <label htmlFor="wpNum" className="text-base text-white mb-2 inline-block">
                            {L.discordUsername}
                        </label>
                        <div className="relative">
                            <input
                                className="input eform-input input-prefix"
                                type="text"
                                defaultValue={formData.discord}
                                onChange={ e => setFormData('discord', e.target.value) }
                                name="wpNum"
                            />
                            <i className="icon-discord"></i>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="eform-item">
                        <label htmlFor="wpNum" className="text-base text-white mb-2 inline-block">
                            {L.telegramUsername}
                        </label>
                        <div className="relative">
                            <input
                                className="input eform-input input-prefix"
                                type="text"
                                defaultValue={formData.telegram}
                                onChange={ e => setFormData('telegram', e.target.value) }
                                name="wpNum"
                            />
                            <i className="icon-telegram"></i>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="eform-item">
                        <label htmlFor="wpNum" className="text-base text-white mb-2 inline-block">
                            {L.linkedinProfileURL}
                        </label>
                        <div className="relative">
                            <input
                                className="input eform-input input-prefix"
                                type="text"
                                defaultValue={formData.linkedin}
                                onChange={ e => setFormData('linkedin', e.target.value) }
                                name="wpNum"
                            />
                            <i className="icon-linkedin"></i>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ContactDetails;