import React from "react";
import {Col} from 'antd';
import {PATH} from "../../../config";
import { useSelector } from "react-redux";

const RoomsItem = () => {
    let L = useSelector (state => state.settings.L);

    const roomDetails = [
        {
            roomId: 0,
            roomBg: 'event-room.jpg',
            roomName: L.roomName1,
            roomDesc: L.roomInfo1,
        },
        {
            roomId: 1,
            roomBg: 'nft-room.jpg',
            roomName: L.roomName2,
            roomDesc: L.roomInfo2,
        },
        {
            roomId: 2,
            roomBg: 'shopping-room.jpg',
            roomName: L.roomName3,
            roomDesc: L.roomInfo3,
        },
    ]

    return (
        <>
            {roomDetails.map((item) => {
                return (
                    <Col xs={24} sm={12} md={8} lg={8} className="gutter-row py-8" key={item.roomId}>
                        <div className="relative">
                            <div className="overflow-hidden relative flex items-end" style={{background: `url(${PATH + item.roomBg}) no-repeat center center / cover`, height: "195px"}}>
                                <div style={{backgroundColor: "rgba(28,26,66, .9)"}} className="pl-4 pr-14 py-3 text-base d-block mb-2">{item.roomName}</div>
                            </div>
                            <div className="text-base py-8">
                                <p style={{lineHeight: "22px"}}>{item.roomDesc}</p>
                            </div>
                        </div>
                    </Col>
                )
            })}
        </>
    )
}

export default RoomsItem;