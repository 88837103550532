import React, {useState} from "react";
import {PATH} from "../../../../config";
import {useSelector} from "react-redux";
import {Button} from "antd";
import {Link} from "react-router-dom";
import { ROUTES } from "../../../../config/constants";
import { ConvertDate } from "../../../../config/CoreMethods";

const About = ({data, IWantToGo, LoggedIn}) => {

    const [AvatarFile, setAvatarFile] = useState(PATH+'avatar.png')

    const AvatarOnError = () => {
        setAvatarFile(PATH+'avatar.png')
    }

    let L = useSelector(state => state.settings.L);

    return (
        <div className="font-mont einfo">
            <h2 className="einfo-ttl">{data.name}</h2>
            <div className="einfo-meta">
                <div className="einfo-meta_item">
                    Organized by
                    <div className="einfo_user">
                        <div className="relative avatar_box user-box" 
                            style={{backgroundColor: data.user_bgcolor}} 
                        >
                            <img src={data.avatar} onError={AvatarOnError} width="100%" height="100%" className="user-avatar" alt="" />
                        </div>
                        <Link to="" className="einfo_username">{ data.username }</Link>
                    </div> in
                    <div className="einfo_metahut">
                        <img src={PATH + 'metahut-icon.svg'} alt=""/>
                        <Link to="">{data.metahut_name}</Link>
                    </div>
                </div>
                <div className="einfo-meta_item">
                    {L.category}
                    <div className="einfo_network">
                        {data.categories && data.categories.length ? 
                            data.categories.map(el => {
                                return (
                                    <span> &nbsp;
                                        <i class={el.icon}></i>
                                        <Link to="">  {el.name} </Link>
                                    </span>
                                )
                            })
                        :''}
                    </div>
                </div>
            </div>
            <div className="einfo-cover">
                <img src={data.cover_image} onError={el => el.target.src = PATH + 'metahut-owner.jpg'} alt=""/>
            </div>
            <div className="einfo-datetime">
                <div className="einfo-date">
                    <span className="einfo-date_label">Start</span>
                    <div className="einfo-date_set">{ConvertDate(data.start_date_, 1) } PM</div>
                </div>
                <div className="einfo-date">
                    <span className="einfo-date_label">End</span>
                    <div className="einfo-date_set"> {ConvertDate(data.end_date_, 1)} PM</div>
                </div>
            </div>
            <p className="einfo-desc"> {data.description} </p>
            <div className="einfo_action">
                <div className="einfo-visitor">
                {data && data.want_users && data.want_users.length ?
                data.want_users.map((i, index) => {
                    return (
                        <div className="relative avatar_box user-box" style={{backgroundColor: i.bgcolor}} key={index}>
                            <img src={i.avatar} onError={AvatarOnError} width="100%" height="100%" className="user-avatar" alt="" />
                        </div>
                        )
                    })
                :''}  
                <div className="einfo-visitor_count">{data.total_want_go}</div>
                </div>
                 
                {LoggedIn ?
                        IWantToGo ? 
                            data.going ?
                            <Button type="primary" disabled="disable" className="einfo-btn btn-disable">
                                {L.going}
                            </Button>
                            :
                            <Button type="primary" onClick={()=> IWantToGo(data.id)}    className="einfo-btn">
                                {L.wantToGoBtn}
                            </Button>
                        :''
                    : IWantToGo ? 
                        <Link to={ROUTES.loginPage.path}>
                            <Button type="primary" className="einfo-btn">{L.wantToGoBtn}</Button>
                        </Link>
                :''}
            </div>
        </div>
    )
}

export default About;