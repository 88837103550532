import React, {useRef} from "react";
import {Button, Carousel} from "antd";
import SliderItem from "./SliderItem";

const Slider = ({Items, IWantToGo, LoggedIn}) => {
    const slider = useRef(null);

    return (
        <>
            <div className="eslider-control">
                <Button onClick={() => slider.current.prev()} className="eslider-left"><i className="icon-back"></i></Button>
                <Button onClick={() => slider.current.next()} className="eslider-right"><i className="icon-back"></i></Button>
            </div>
            <Carousel autoplay  className="eslider" ref={slider}>
                {Items.map((slide,index) => {
                    return (
                        index < 4 ?
                        <SliderItem slide={slide} IWantToGo={IWantToGo} key={index} LoggedIn={LoggedIn} />
                        : false
                    )
                })}
            </Carousel>
        </>
    )
}

export default Slider;