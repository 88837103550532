import React, {useState} from "react";
import {Input} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import {ROUTES as ROUTES_CONFIG} from "../../../config/constants";
import {selectUser} from "../../../redux/features/userSlice";
import {useSelector} from "react-redux";
import { redirectTo } from "../../../config/CoreMethods";

const selector = useSelector;

const EventsFilter = () => {

    let L = useSelector(state => state.settings.L);
    let GetUser = selector(selectUser);

    const filterNav = [
        {id: 1, name: L.filterPastEvents, path: 'past-events'},
        {id: 2, name: L.filterMyEvents, path: 'my-events'},
        {id: 3, name: L.filterMyTickets, path: 'my-tickets'},
    ]

    const [eventSearch, setEventSearch] = useState('');

    const location = useLocation();

    const setSearchText = (value) => {
        SettingSearchText(value)
    }

    let Timeout;

    const SettingSearchText = (value) => {

        clearTimeout(Timeout)

        Timeout = setTimeout(function() {
            setEventSearch(value)
        }, 500);
    }

    return (
        <div className="font-mont events-filter">
            <div className="events-action">
                <Input size="large" placeholder="Search for an event" className="input input-search"
                       prefix={<i className="icon-search text-gray-300"></i>}
                       onChange={e => setSearchText(e.target.value)}
                       defaultValue={eventSearch}
                />
                <div className="events-nav">
                    {filterNav.map((item) => { 
                        return <NavLink
                            to={`/${item.path}`} key={item.id}
                            className={`${location.pathname == item.path ? 'active' : ''}`}
                            >
                            <span>{item.name}</span>
                        </NavLink>
                    })}
                </div>
            </div>
            <span onClick={ () => redirectTo(GetUser && GetUser.id ? ROUTES_CONFIG.eventsStep.path : ROUTES_CONFIG.loginPage.path) } className="events-submit cursor-pointer">
                <i className="icon-zoom-in"></i>
                <span>{L.submitEvent}</span>
            </span>
        </div>
    )
}

export default EventsFilter;