import React, {useState, useEffect} from "react";
import {Button, Checkbox, Col, Layout, Row} from "antd";
import { ToastContainer, toast } from "react-toastify";
import {getCookie, redirectTo} from '../../config/CoreMethods'
import axiosData from "../../helpers/axiosData";
import {Link, NavLink} from "react-router-dom";
import { useSelector } from "react-redux";
import {PATH} from "../../config";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";
import AuthNote from "./AuthNote";
// import { parse } from "@babel/core";

import JsxParser from 'react-jsx-parser';

const { Content } = Layout

const Register = () => {
    let L = useSelector(state => state.settings.L);

    const [UserData, setUserData] = useState({});
    const [FormTrigger, setFormTrigger] = useState(1);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [Referrer, setReferrer] = useState('');
    const [Agrement, setAgrement] = useState(false);
    const [Error, setError] = useState(false);

    const ChangeAgrement = (value) => { 
        setAgrement(value)
    } 

    const setFormUsername = (value) => {

        if ( /^([A-Za-z0-9_.]{4,30})$/.test(value) ) {
            setFormData(value, 'username');
            setError(false)
        } else {
            setError(true)
        }
    }

    let GetUser = useSelector(state => state.user.user)
    if (GetUser && GetUser.id) {
        redirectTo('/profile')
    }

 
    // add form data to state
    const setFormData = (data, key, type='text') => {

        let newUserData = UserData; 

        if (type === 'text' || type === 'check') { 

            if(type === 'check') {

                newUserData[key] =  data ? 1 : 0;
            } else {
                newUserData[key] = data;
            }

            setUserData(newUserData);
            setFormTrigger(FormTrigger+1);
        }        
    }

    // register
    const registerForm = () => {

        if (Error) {
            toast.error(`${L.registerFormErr}`);
            return false;

        }

        let form_data = new FormData(); 
        let keys = [];

        if (Object.keys(UserData).length > 0) {
            for (let x in UserData) {
                if (UserData[x] != '') {
                    form_data.append(x, UserData[x]);
                    keys.push(x);
                }
            }
        }

        if (keys.length !== 4) {
            toast.error(`${L.registerFormFill}`); 
            return;
        }
        
        setLoadingBtn(true);

        axiosData
         .post(`/api/_web3/register/`, form_data)
         .then((res) => {

            if (res.data.success && res.data.success === true) {
                toast.success(`${L.registerFormSuccess}`)
                
                setTimeout(()=>{
                    redirectTo('/login')
                }, 3000)
            } else {
                toast.error(res.data.error);
            }
            setLoadingBtn(false);

         })
        .catch((error) => {
            toast.error(error.response.data.message);
        })
        .finally(() => setLoadingBtn(false));
    };

    useEffect(() => {  
        const referrer = getCookie('referrer')

        if (typeof referrer == 'string'  && /^([0-9a-zA-Z]{4,30})$/.test(referrer)) {
            setReferrer(referrer); 
            setFormData(referrer, 'referrer')
        }
        
    }, [])


    return (
        <div className="min-h-calc-head h-full flex items-center justify-center pb-8" 
        style={{paddingTop: "92px", background: `url(${PATH + 'bg-auth.png'}) center center / cover`}}>
            <div className="container mx-auto">
                <Layout className="bg-transparent h-full">
                    <Content className="pt-4 pb-4 pl-14 pr-14 xs-p-2">
                        <div className="flex flex-col items-center gap-y-5">
                            <AuthNote />
                            <ToastContainer />
                            <div className="flex justify-center">
                                <div className="register-form form-box">
                                    <h1 className="text-center text-white font-normal text-xl mb-2">
                                        {L.newAccount}
                                    </h1>
                                    <Row gutter={74} className="register-form-row">
                                        <Col xs={24} sm={24} md={12} lg={12} className="mt-2 mb-2 register-form-left">
                                            <div>
                                                <label htmlFor="acc_username" className="text-white text-base mb-2 block">{L.usernameLabel} <span className="text-red">*</span></label>
                                                <div className="flex items-center gap-x-6 mb-4">
                                                    <input
                                                        onChange={(e) => {setFormUsername(e.target.value)}}
                                                        className={`bg-indigo-700 border-0 login-input w-100 rounded-md text-white ${Error?'input-error' : ''}` }
                                                        type="text"
                                                        defaultValue=""
                                                        name="username"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="acc_email" className="text-white text-base mb-2 block">{L.emailLabel} <span className="text-red">*</span></label>
                                                <div className="flex items-center gap-x-6 mb-4">
                                                    <input
                                                        onChange={(e) => setFormData(e.target.value, 'email')}
                                                        className="bg-indigo-700 border-0 login-input w-100 rounded-md text-white"
                                                        type="text"
                                                        defaultValue=""
                                                        name="email"
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="acc_desc" className="text-white text-base mb-2 block">{L.passwordLabel} <span className="text-red">*</span></label>
                                                <input
                                                    onChange={(e) => setFormData(e.target.value, 'password')}
                                                    className="bg-indigo-700 border-0 login-input w-100 rounded-md text-white xs-w-100"
                                                    type="password"
                                                    defaultValue=""
                                                    name="name"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} className="mt-2 register-form-right">
                                            <div>
                                                <label htmlFor="acc_username" className="text-white text-base mb-2 block">{L.referralCode} <span className="text-red">*</span></label>
                                                <div className="flex items-center gap-x-6 mb-4">
                                                    <input
                                                        className="bg-indigo-700 border-0 login-input w-100 rounded-md text-white"
                                                        type="text"
                                                        defaultValue={Referrer != '' ? Referrer : UserData.referrer}
                                                        name="referer"
                                                        required={true}
                                                        disabled={Referrer!=''?true:false}
                                                        onChange={(e) => setFormData(e.target.value, 'referrer')}
                                                    />
                                                </div>
                                            </div>
                                            <p className="text-xs font-light">{L.referralCodeNote}</p>
                                            <a href="https://t.me/metafluence" target="_blank" className="leading-0 mt-5 text-white hover:text-cyan-500 flex items-center gap-x-2 w-fit">
                                                <i className="icon-telegram text-xl"></i>
                                                <span className="font-light text-sm">{L.telegramGroup}</span>
                                            </a>
                                            <a href="https://discord.com/invite/metafluence-official" target="_blank" className="leading-0 mt-3 text-white hover:text-cyan-500 flex items-center gap-x-2 w-fit">
                                                <i className="icon-discord text-xl"></i>
                                                <span className="font-light text-sm">{L.discordServer}</span>
                                            </a>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} className="mt-2">
                                            <Checkbox className="text-white text-sm font-light ml-0 mb-2" onChange={(e) => { ChangeAgrement(e.target.checked)}}  value="second">
                                                {L.registerPageAgreementCheckbox && <JsxParser jsx={L.registerPageAgreementCheckbox &&  L.registerPageAgreementCheckbox
                                                    .replace('_TERM_LINK_START_', '<NavLink to="'+ROUTES_CONFIG.terms.path+'" className="text-sm text-cyan-400 hover:text-cyan-500">')
                                                    .replace('_SAFT_LINK_START_', '<NavLink to="'+ROUTES_CONFIG.saft.path+'" className="text-sm text-cyan-400 hover:text-cyan-500">')
                                                    .replace('_PRIVACY_LINK_START_', '<NavLink to="'+ROUTES_CONFIG.privacy.path+'" className="text-sm text-cyan-400 hover:text-cyan-500">')
                                                    .replace('_LINK_END_', '</NavLink>')
                                                    .replace('_LINK_END_', '</NavLink>')
                                                    .replace('_LINK_END_', '</NavLink>')
                                                    
                                                 } />}
                                            </Checkbox>

                                            <div className="mt-2 flex flex-col gap-y-4 items-center">
                                                <Button type="submit" onClick={registerForm} disabled={Agrement ? '' : 'disable'} className={`border-0 rounded-3xl font-medium text-base login-btn flex items-center justify-center gap-x-3 ${Agrement ? 'bg-cyan-500 text-indigo-900 hover:bg-cyan-400' : 'bg-indigo-600 text-white opacity-50 cursor-not-allowed'}`}>
                                                    {loadingBtn ? <span className="loading-btn"></span> : ""}
                                                    {L.registerBtn}
                                                </Button>
                                                <div>
                                                    <span>{L.haveAccount} • <Link to="/login" className="text-sm text-cyan-500">{L.signIn}</Link></span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>
        </div>
    )
}

export default Register;