import React from "react";
import { Row } from "antd";
import Lands from "./assets/Lands";
import Metahuts from "./assets/Metahuts";
import Avatars from "./assets/Avatars";
import { useSelector } from "react-redux";

const Assets = ({GetUser}) => {

    let L = useSelector(state => state.settings.L);

    return (
        <>
            {GetUser ?  
                <div className="assets">
                    <span className="block font-bold mb-10 meta-card-header">My Assets</span>
                    <Row gutter={44} className="assets-row">
                        <Metahuts  GetUser={GetUser} L={L} />
                        <Lands GetUser={GetUser} L={L} />
                        <Avatars GetUser={GetUser} L={L} />
                    </Row>
                </div>
            :''}
        </>
    )

}

export default Assets;