const INITIAL_STATE = {
    ActiveMenu: false,
    ShowDrawer: false,
    IsAdmin: false,
    ShowFooter: false,
}


const menuReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "setActiveMenu" : return {...state, ActiveMenu: action.payload};
        case "setShowDrawer" : return {...state, ShowDrawer: action.payload};
        case "setIsAdmin" : return {...state, IsAdmin: action.payload};
        case "setShowFooter" : return {...state, ShowFooter: action.payload};
        default : return state;
    }
}


export default menuReducer;