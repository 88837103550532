import React, {useEffect, useState} from "react";
import {Button, Col, Layout, Row, Radio, Modal, notification} from "antd";
import {PATH} from "../../config";
import {Link, NavLink} from "react-router-dom";
import {approveNFT, getLandPriceMeto, RemoveItemFromList, setOpenPlotOnMap} from "../../redux/actions/landActions";
import {getRectSelecteds, updateUserAccount} from "../../config/CoreMethods";
import {useDispatch, useSelector} from "react-redux";
import {getMapSettings, switchNetwork} from "../../redux/actions/metahutActions";
import {login} from "../../redux/features/userSlice";
import GetLandLoading from "../loaders/GetLandLoading";
import {ROUTES, ROUTES as ROUTES_CONFIG} from "../../config/constants";
import GetLandContent from "./GetLandContent";

const { Content } = Layout

const GetMapLand = () => {

    const dispatch = useDispatch();
    
    let L = useSelector(state => state.settings.L);
    let SelectedRetcs = getRectSelecteds();
    let SelectedLandCount = useSelector ((state) => state.land.SelectedLandCount);
    let State = useSelector(state => state)
    let MetoConvertRate = useSelector ((state) => state.metahut.MapSettings.meto_price);
    let BalanceBusd = useSelector ((state) => state.metahut.BalanceBusd);
    let BalanceMeto = useSelector ((state) => state.metahut.BalanceMeto);
    let FrontNetwork = useSelector ((state) => state.metahut.FrontNetwork);
    let FrontLogin = useSelector ((state) => state.metahut.FrontLogin);
    let BackLogin = useSelector ((state) => state.metahut.BackLogin);
    let LandBuyingLoading = useSelector ((state) => state.land.LandBuyingLoading);

    // let LandPriceMeto_ = useSelector ((state) => state.land.LandPriceMeto);


    let paymentTitle;
    // let LandPriceMeto = 105; // IsWhiteList ? 85 : 95;
    let LandPriceBusd = 105; // IsWhiteList ? 95 : 105;
    let LandPriceMetoDiscount = 10;
    const [LandPriceMeto, setLandPriceMeto] = useState(95)

    const [checkVal, SetCheckVal] = useState('')
    const [metoSpend, setMetoSpend] = useState(0)
    const [TotalMetoWillSpend, setTotalMetoWillSpend] = useState(0)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [payErrorMessage, isPayErrorMessage] = useState(false);
    const tokenType = [
        {name: 'METO', icon: 'meto_icon.svg'},
        {name: 'BUSD', icon: 'bnb.svg'},
        {name: 'USD', icon: 'binance_usd.svg'}
    ]

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onChange = (e) => {
        SetCheckVal(e.target.value);
    };

    const DoBuyingLand = (is_meto, State) => {

        if (State.user.user.account == '' || State.user.user.account == null) {
            updateUserAccount(State.metahut.Account)
        }


        let totalPrice=-1, balanceSwiched=-1;
        let MetoConvertRate = parseFloat(State.metahut.MapSettings.meto_price);
        let LandSaleIsOpen = State.land.LandSaleIsOpen;

        if (LandSaleIsOpen) {
            if (is_meto) {
                totalPrice = getRectSelecteds(1).length * (LandPriceMeto/parseFloat(MetoConvertRate));
                balanceSwiched = parseInt(BalanceMeto)
                paymentTitle = 'METO'
                notification.destroy()

            } else {
                totalPrice = getRectSelecteds(1).length * LandPriceBusd;
                balanceSwiched = parseInt(BalanceBusd);
                paymentTitle = 'BUSD'
                notification.destroy()
            }

            if ( (balanceSwiched > 0 &&  balanceSwiched >= totalPrice)) {
                dispatch(approveNFT(is_meto, totalPrice))
            } else {
                openNotificationWithIcon('error', L.youDontHaveEnoughToken.replace('_TOKEN_', paymentTitle) )
                window.scrollTo(0, 0);
            }

            return true;

        } else {
            openNotificationWithIcon('error', L.saleLandIsOpenErr)

            return false;
        }
    }

    const StartLandBuying = async (is_meto) => {

        if (FrontLogin && BackLogin) {

            if ( FrontNetwork ) {

                DoBuyingLand(is_meto, State);

            } else {

                dispatch(switchNetwork(state => {
                    // console.log('state', state);
                }))
            }

        } else if (window.ethereum) {

            dispatch(login())

        } else {
            openNotificationWithIcon('error', L.installMetamask)
            isPayErrorMessage(true);
        }

    }

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Error',
            description: message,
            maxCount: 1,
        });
    };

    useEffect(() => {
        dispatch({type : 'SetSelectedLandCount', payload: getRectSelecteds(1).length})

        dispatch( getMapSettings() );

        if ( MetoConvertRate  &&  SelectedLandCount) {
            dispatch( getLandPriceMeto(price => {
                console.log('land-price', price, price/MetoConvertRate)

                setTotalMetoWillSpend(Math.round(price/parseFloat(MetoConvertRate)) * SelectedLandCount);
                setMetoSpend(Math.round(LandPriceMeto/parseFloat(MetoConvertRate)));
                setLandPriceMeto(  price )
            }) )
        }


    }, [TotalMetoWillSpend, BalanceBusd, BalanceMeto, MetoConvertRate, SelectedLandCount])

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10 font-mont">
                    <Row gutter={84} className="mt-10">
                        <Col xs={24} sm={24} md={16} lg={16} className="mb-8">
                            <GetLandContent L={L} />
                            <div className="gland-list">
                                <div className="flex items-center gap-x-2 gland-list_header">
                                    <img src={PATH + 'map-icon.svg'} alt=""/>
                                    <h3 className="font-bold mb-0 gland-list_ttl">{L.selectedLandCountOnMap && L.selectedLandCountOnMap.replace('_COUNT_', SelectedLandCount)}</h3>
                                </div>
                                <Row gutter={44}>
                                    { getRectSelecteds(1) && SelectedLandCount ?
                                        getRectSelecteds(1).map((item) => {
                                                return (
                                                    <Col xs={24} sm={24} md={8} lg={8}>
                                                        <div className="w-100 thumb">
                                                            <div className="relative thumb-asset" style={{background: `url(${PATH + 'crop/'+ item + '.png'}) no-repeat center center / cover`}}>
                                                                <Link to={ROUTES.home.path} onClick={()=>{dispatch(setOpenPlotOnMap(item, 1))}}  className="rounded-t-xl w-full h-full flex flex-col items-center justify-center gap-y-2 bg-plot text-center">
                                                                    <i className="icon-map-marker text-white text-xl"></i>
                                                                    <span className="text-base font-light text-white">{L.viewLandPlot}</span>
                                                                </Link>
                                                                <Button type="link" onClick={() => showModal()} className="thumb-remove">
                                                                    <i className="icon-close text-white text-sm"></i>
                                                                </Button>
                                                                <Modal footer={false} visible={isModalVisible} closable={false} className="modal modal-meta">
                                                                    <p className="text-center text-white text-lg">
                                                                        {L.removeLandPlotModalTxt}
                                                                    </p>
                                                                    <div className="flex items-center justify-center gap-x-4 mt-10">
                                                                        <Button
                                                                            onClick={() => setIsModalVisible(false)}
                                                                            className="min-w-24 border-0 bg-indigo-600 text-gray-300 hover:bg-indigo-800 rounded-3xl">
                                                                            {L.removeLandPlotModalCancel}
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => {setIsModalVisible(false); dispatch(RemoveItemFromList(item))}}
                                                                            className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl">
                                                                            {L.removeLandPlotModalOk}
                                                                        </Button>
                                                                    </div>
                                                                </Modal>
                                                            </div>
                                                            <div className="flex flex-col thumb-info thumb-info_land">
                                                                <p className="text-white mb-0 font-normal flex items-start thumb-title">
                                                                    <i className="text-pink-400 icon-pin"></i>
                                                                    <span className="font-light">{SelectedRetcs[item].real_coord}</span>
                                                                </p>
                                                                <div className="flex items-center flex-wrap justify-between stats">
                                                                    <span className="uppercase">{L.land}</span>
                                                                    <span className="land-id">ID: {item}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                        ) :
                                        <Col xs={24} sm={12} md={8} lg={8} className="assets-col">
                                            <div className="w-100 relative thumb thumb-buy">
                                                <div className="thumb-buy_bg"
                                                     style={{background: `url(${PATH + 'land-empty.png'}) no-repeat center center / cover`}}></div>
                                                <div className="flex flex-col items-center justify-center z-1 thumb-buy_content">
                                                    <p className="text-center mb-0 font-light thumb-buy_note">{L.getMapLandNotSelectedNote}</p>
                                                    <NavLink to={ROUTES_CONFIG.home.path}>
                                                        <Button 
                                                            type="primary"
                                                            className="min-w-24 border-0 text-indigo-900 hover:bg-cyan-400 thumb-buy_btn">
                                                                {L.getMapLandNotSelectedBtn}
                                                        </Button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col gap-y-1 mb-8">
                            <div className="gland-pay">
                                <h4 className="gland-pay_ttl">{L.payment}</h4>
                                <Radio.Group onChange={onChange} value={checkVal} className="flex flex-col gland-pay_check">
                                    {tokenType && tokenType.map((item, i) => {
                                        return (
                                            <Radio value={i+1} key={i+1} className={`gland-pay-token ${i == 2 ? 'opacity-50' : ''}`} disabled={i == 2 ? true : false}>
                                                <div className="flex items-center gap-x-1 font-normal text-white gland-pay_label">
                                                    {L.payWith} <img src={PATH + item.icon} alt="" style={{height: "16px", marginLeft: "4px"}} /> {item.name}
                                                </div>
                                                <span className="font-bold uppercase gland-pay-type">
                                                    {i != 2 ? L.crypto : L.fiat}
                                                </span>
                                                {i == 0 ? <span className="font-bold gland-pay-discount">{LandPriceMetoDiscount}% {L.discountOff}</span> : ''}
                                            </Radio>
                                        )
                                    })}
                                </Radio.Group>
                            </div>
                            {checkVal &&
                                <div className="relative gland-amount">
                                    {LandBuyingLoading ? <GetLandLoading /> : ""}
                                    <h4 className="gland-pay_ttl">{L.amount}</h4>
                                    <div className="flex items-start gap-x-8 gland-amount_row">
                                        <div className="flex flex-col gland-amount_price">
                                            <p className="mb-0 font-normal gland-amount_token">{checkVal == 1 ? metoSpend + ` ${tokenType[checkVal - 1].name}` : LandPriceBusd + ` ${tokenType[checkVal - 1].name}`}</p>
                                            {checkVal != 3 ? <span className="font-normal gland-amount_usd">≈ {checkVal == 1 ? LandPriceMeto : LandPriceBusd} USD</span> : ''}
                                        </div>
                                        <span>x</span>
                                        <p className="gland-amount_land">{SelectedLandCount} {L.lands}</p>
                                    </div>
                                    <div className="gland-amount-total">
                                        <h5 className="gland-amount_ttl">{L.completeAmount}</h5>
                                        <div className="flex flex-col gland-amount_price">
                                            <div className="flex items-center gap-x-1 gland-amount_token">
                                                {SelectedLandCount ? checkVal == 1 ? (!isNaN(TotalMetoWillSpend) ? TotalMetoWillSpend : '-') : LandPriceBusd * SelectedLandCount : '-' }
                                                <img src={PATH + tokenType[checkVal - 1].icon} style={{height: "16px", marginLeft: "3px"}} alt=""/>
                                                {tokenType[checkVal - 1].name}
                                            </div>
                                            {checkVal != 3 ? <span className="gland-amount_usd">≈ {checkVal == 1 ? (LandPriceMeto*SelectedLandCount) + ' USD' : (LandPriceBusd*SelectedLandCount) + ' USD'}</span> : ''}
                                        </div>
                                        {checkVal == 1 ? <p className="font-light gland-amount_discount"> {L.LandBuyReducingInfo && L.LandBuyReducingInfo.replace('_AMOUNT_', ((LandPriceBusd*SelectedLandCount*LandPriceMetoDiscount)/100) )} </p> : ''}
                                    </div>
                                    <div className="line-x"></div>
                                    <p className="font-light font-sans gland-amount_note">
                                        {L.getLandAmountNote}
                                    </p>
                                    <div className="text-center">
                                        <Button
                                            disabled={SelectedLandCount ? '' : 'disable'}
                                            onClick={() => StartLandBuying(1)}
                                            className={`border-0 mb-5 rounded-3xl font-normal gland-amount_btn ${SelectedLandCount ? 'bg-cyan-500 text-indigo-900 hover:bg-cyan-400' : 'bg-indigo-600 text-white opacity-50 cursor-not-allowed'}`}>
                                                {L.getLandAmountButton}
                                        </Button>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    )
}

export default GetMapLand;