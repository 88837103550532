import React from "react";
import About from "./info/About";
import Details from "./info/Details";
import {Col, Row} from "antd";

const EventsInfo = ({data, IWantToGo, LoggedIn}) => {
    return (
        <>
            <Row gutter={60}>
                <Col xs={24} sm={24} md={12} lg={16}>
                    <About data={data} IWantToGo={IWantToGo} LoggedIn={LoggedIn}/>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                    <Details data={data}/>
                </Col>
            </Row>
            <div className="line-x"></div>
        </>
    )
}

export default EventsInfo;