import React from "react";
import {Layout} from "antd";
import DiscoveryFilter from "./DiscoveryFilter";
import DiscoveryItems from "./DiscoveryItems";
import { useSelector } from "react-redux";

const {Content} = Layout;

const DiscoveryTopRated = () => {
    let L = useSelector(state => state.settings.L);

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10 sm:pr-10">
                    <DiscoveryFilter />
                    <DiscoveryItems title={L.discoveryRate} type="rate" limit="48" image="rated.svg"/>
                </Content>
            </Layout>
        </div>
    )
}

export default DiscoveryTopRated;