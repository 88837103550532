import React, {useState} from "react";
import {PATH} from "../../../../config";
import {Button, Modal, message} from "antd";
import CountdownTimer from "../timer/CountDowntimer";
import axiosData from "../../../../helpers/axiosData";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/features/userSlice";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../config/constants";
import JsxParser from 'react-jsx-parser';
 

const Details = ({data}) => {
    let L = useSelector(state => state.settings.L);
    
    const [messageApi, contextHolder] = message.useMessage();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleOk = () => { setIsModalVisible(false);}
    const handleCancel = () => { setIsModalVisible(false); };
    
    const buyTicket = (event_id) => {
        axiosData.post("/api/_web3/event/buy-ticket/" + event_id).then((res) => {

            if (res.data.success) {
                window.location.reload();
                messageApi.open({
                    type: 'success',
                    content: L.buyTicketSuccess,
                });

            } else {
                messageApi.open({
                    type: 'error',
                    content: res.data.message,
                });
            }
            setIsModalVisible(false);
        }).catch(er => {
            toast.error(`${L.buyTicketError}`)
            setIsModalVisible(false);
        })
    }

    let GetUser = useSelector(selectUser);


    return (
        <div className="font-mont edets">
            {contextHolder}

            <Modal footer={false} closable={false} visible={isModalVisible} onOk={handleOk} 
                onCancel={handleCancel} className="modal modal-logo" 
                style={{background: `#1c1a42 url(${PATH+'logo-icon-gray.svg'}) no-repeat center center / 140%`}}
            >
                <div className="text-center">
                    <h3 className="font-normal text-lg text-white mb-9">{L.youWantToBuyTicket && <JsxParser jsx={L.youWantToBuyTicket.replace("_TICKET_NAME_", '<strong>“'+data.name+'”</strong>') } /> }</h3>
                    <div className="flex items-center justify-center gap-x-3 mb-3">
                        <img src={PATH + data.ticket_type + '.svg'} alt="" width="32px"/>
                        <span className="font-bold text-xl text-white uppercase">{data.ticket_value} {data.ticket_type}</span>
                    </div>
                    
                {GetUser && data.ticket_type && GetUser[data.ticket_type+'_balance'] && parseInt(GetUser[data.ticket_type+'_balance']) > parseInt(data.ticket_value) ?
                    <p className="font-normal text-sm text-green-500">{L.ticketModalBalanceMore}</p>
                : 
                    <p className="font-normal text-sm text-red">{L.ticketModalBalanceLess}</p>
                }

                </div>

                {GetUser && data.ticket_type && GetUser[data.ticket_type+'_balance'] && parseInt(GetUser[data.ticket_type+'_balance']) > parseInt(data.ticket_value) ?
                    <div className="flex items-center justify-center gap-x-4 mt-12">
                        <Button onClick={() => handleCancel()} className="min-w-24 border-0 bg-gray-200 text-indigo-900 hover:bg-gray-300 rounded-2xl font-large">{L.ticketModalBalanceMoreCancel}</Button>
                        <Button onClick={() => buyTicket(data.id)} className="min-w-24 border-0 bg-pink-300 text-white hover:bg-pink-400 rounded-2xl font-large">{L.ticketModalBalanceMoreOk}</Button>
                    </div>
                :
                    <div className="flex items-center justify-center gap-x-4 mt-12">
                        <Button onClick={() => handleCancel()} className="min-w-24 border-0 bg-gray-200 text-indigo-900 hover:bg-gray-300 rounded-2xl font-large">{L.ticketModalBalanceLessCancel}</Button>
                        <Link to={ROUTES.wallet.path} > 
                            <Button onClick={() => buyTicket(data.id)} className="min-w-24 border-0 bg-pink-300 text-white hover:bg-pink-400 rounded-2xl font-large">{L.ticketModalBalanceLessOk}</Button>
                        </Link>
                    </div>
                }

            </Modal>

            <div className="edets-card">
                <h4 className="edets_ttl">{L.ticket}</h4>
                <div className="edets-card_body">
                    <div className="edets-token">
                        {GetUser && data.ticket_type!='' ? 
                            <>
                                <img src={PATH + data.ticket_type + '.svg'} alt=""/>
                                <span className="edets-token_amount uppercase">{data.ticket_value} {data.ticket_type}</span>
                            </>
                            : <span className="edets-token_free uppercase">{L.free}</span>
                        }

                    </div>
                    {GetUser && data.ticket_type!='' ? 
                        <p className="edets-desc">{L.premiumTicketNote}</p> :
                        <p className="edets-desc">{L.freeTicketNote}</p> 
                    }
                    {data.is_buyed ?
                        <Button type="primary" disabled="disable" className="einfo-btn btn-disable">{L.freeTicketButton}</Button>
                    :
                    <Button className={`submit-btn ${GetUser && data.ticket_type!='' ? '' : 'border-0 bg-indigo-600 text-white opacity-50 cursor-not-allowed'}`} disabled={GetUser && data.ticket_type!='' ? "" : 'disable' } onClick={() => setIsModalVisible(true)}>{L.freeTicketButton}</Button>
                }
                {/* <Button onClick={() => buyTicket(data.id)} className="submit-btn">Get Ticket</Button> */}
                </div>
            </div>
            <div className="edets-card">
                {data.reward_value?
                    <h4 className="edets_ttl">{L.rewardPool}</h4>
                :''}
                <div className="edets-card_body">
                    {data.reward_value?
                    <div className="edets-token">
                        <img src={PATH + data.reward_type + '.svg'} alt=""/>
                        <span className="edets-token_amount uppercase">{data.reward_value} {data.reward_type}</span>
                    </div>
                    :''}

                    <p className="edets-desc">{L.rewardPoolText}</p>
                    <div className="edets-note"><span className="evisit">{data.participants}/{data.participant_count}</span> {L.numberParticipants}</div>
                    <div className="eremain">
                        {data.status === 'end' ?

                        <p className="eremain-status eremain-finished">{L.eventStatusFinished}</p>
                        : data.status === 'live' ?
                            <p className="eremain-status eremain-live">{L.eventStatusLive}</p>
                        :
                        <>
                        <div className="countdown">
                            <CountdownTimer targetDate={data.deadline} />
                        </div>
                        <p className="eremain-txt">{L.eventTimerNote}</p>
                        </>
                        } 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details;