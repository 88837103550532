import React, {useEffect, useState} from "react";
import {Button, Layout} from "antd";
import {useParams} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {basePath, PATH} from "../../config";
import { redirectTo } from "../../config/CoreMethods";
import {useSelector} from "react-redux";
import axiosData from "../../helpers/axiosData";
import AuthNote from "./AuthNote";

const { Content } = Layout

const NewPass = () => {

    let L = useSelector(state => state.settings.L)
    let { hash } = useParams();
    const [UserData, setUserData] = useState({})

    
    const sendNewPassword = () => {

        if (UserData.password.length > 0  &&  UserData.password==UserData.repeat_password &&  typeof UserData.hash == 'string' && /^([a-z0-9]{44,46})$/.test(UserData.hash) ) {
            
            axiosData.post("/api/_web3/user/new-password/", {hash: UserData.hash, password: UserData.password}).then((res) => {
                if (res.data.success) {
                    toast.success(`${L.newPassSuccess}`)
                } else {
                    toast.error(`${L.newPassError}`)
                }
            })
        } else {
            toast.error(`${L.validData}`)
        }

    }

    const setFormData = (data, key, type='text') => {

        let newUserData = UserData;
        if (type == 'text' || type == 'check') {

            if(type == 'check') {

                newUserData[key] =  data ? 1 : 0;
            } else {
                newUserData[key] = data;
            }

            setUserData(newUserData);
        }
        console.log('FormData ', UserData)
    }

    let BackLogin = useSelector(state => state.metahut.BackLogin)

    if (BackLogin) {
        redirectTo(basePath)
    }

    useEffect(()=>{
        setFormData(hash, 'hash')
    },[hash])

    return (
        <div className="min-h-calc-head h-full flex items-center justify-center" style={{paddingTop: "92px", background: `url(${PATH + 'bg-auth.png'}) center center / cover`}}>
            <div className="container mx-auto">
                <Layout className="bg-transparent h-full">
                    <Content className="pt-4 pb-4 pl-14 pr-14 xs-p-2">
                        <div className="flex flex-col items-center gap-y-5">
                            <AuthNote />
                            <ToastContainer />
                            <div className="flex justify-center">
                                <div className="login-form form-box">
                                    <h1 className="text-center text-white font-normal text-xl mb-6">
                                        {L.resetPassword}
                                    </h1>
                                    <label htmlFor="acc_username" className="text-white text-base font-light mb-2 block">{L.newPassword}</label>
                                    <div className="flex items-center gap-x-6 mb-4 xs-w-100">
                                        <input
                                            className="bg-indigo-700 border-0 rounded-md text-white login-input w-100"
                                            type="password"
                                            name="newPass"
                                            onChange={(e) => setFormData(e.target.value, 'password')}
                                        />
                                    </div>
                                    <label htmlFor="acc_username" className="text-white text-base font-light mb-2 block">{L.repeatNewPassword}</label>
                                    <input
                                        className="bg-indigo-700 border-0 rounded-md text-white login-input w-100"
                                        type="password"
                                        name="rePass"
                                        onChange={(e) => setFormData(e.target.value, 'repeat_password')}
                                    />
                                    <div className="flex justify-center mt-7">
                                        <Button type="submit" onClick={sendNewPassword} className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium text-base login-btn flex items-center justify-center gap-x-3">
                                            {L.setNewPassword}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>
        </div>
    )
}

export default NewPass;