import React, { useEffect, useState } from "react";
import {Select} from "antd";
import { useDispatch, useSelector } from "react-redux";
import axiosData from "../../../../../helpers/axiosData";
import { toast } from "react-toastify";

const Category = ({L}) => {
    const { Option } = Select;
    const [List, setList] = useState([]);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    let formData = useSelector ((state) => state.event.formData)

    
    const setFormData = (type, value) => {
        dispatch({type: 'setFormData', payload: {...formData, [type] : value}})
    }

    const getCategories = () => {
        axiosData.get("/api/_web3/event/categories/").then((res) => {

            if (res.data.success) {
                setList(res.data.data.list) 
            } else {
                toast.error(`${L.getCategoriesErr}`)
            }
        })
    }

    useEffect(()=>{
        getCategories();
    },[])
 
    return (
        <div className="eform-item">
            <label htmlFor="endDate" className="text-white text-base mb-2 block">{L.eventCategory}</label>
            {List && List.length ? 
                <Select 
                    open={formData.categories.length >= 3 ? false : open} 
                    defaultValue={formData.categories}
                    onDropdownVisibleChange={(visible) => setOpen(visible)} 
                    mode="multiple" 
                    onChange={(value) => setFormData('categories', value) } 
                    className="eform-select" 
                    getPopupContainer={triggerNode => triggerNode.parentElement}
                >   
                    {List.map(li => {
                        return <Option value={li.id} key={li.id}><i className={li.icon}></i>{li.name}</Option>
                    })} 
                </Select>
            :''}  
        </div>
    )
}

export default Category;