import React from "react";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import RewardList from "./RewardList";
import { Content } from "antd/lib/layout/layout";
import { PATH } from "../../../../config";

const Rewards = ({formTitle, formDesc, formTitle1, formDesc1, L}) => {

    const selector = useSelector;
    const dispatch = useDispatch();
    let formData = selector ((state) => state.event.formData)

    const handleActiveTab = (RT, key) => {
        dispatch({type: 'setFormData', payload: {...formData, [RT + '_type'] : key}})
    }


    const setFormValue = (RT, value) => {
        dispatch({type: 'setFormData', payload: {...formData, [RT+'_value'] : parseInt(value)}})
    } 

    const switchTab = (RT, key) => {
                
        switch (parseInt(key)) {
            case 1:
            case 2:
            case 3:
                return (
                    <>
                        <label htmlFor={RT+'-form-element'} className="elabel">{L.amount}</label>
                        <div className="einput">
                            <input
                                className="input eform-input"
                                type="text"
                                defaultValue={formData[RT+'_value']}
                                name={RT+'-form-element'}
                                onChange={(e) => setFormValue(RT, e.target.value)}
                            />
                            <div className="einput-suffix"><img src={PATH + RewardList(RT,'key')[formData[RT+'_type']].icon} alt=""/>{RewardList(RT,'key')[formData[RT+'_type']].keyname.toUpperCase()}</div>
                        </div>
                    </>
                );
             
            default:
                break;
        }
    };

    
    return <>
        <div className="eform-content_head">
            <h2 className="eform-content_ttl">{formTitle}</h2>
            <p className="eform-content_desc">{formDesc}</p>
        </div>

        <div className="eform-content_body ereward-body"> 
            
            <Menu className="font-mont ereward-nav" selectedKeys={formData.reward_type+''} onClick={(e) => handleActiveTab('reward', e.key)} mode="horizontal" items={RewardList('reward')} />
            
            <Content className="font-mont ereward-amount" id={formData.reward_type}>
                {switchTab('reward', formData.reward_type)}
            </Content>
            
        </div>
    
        <div className="line-x"></div>

        <div className="eform-content_head eticket-head">
            <h2 className="eform-content_ttl">{formTitle1}</h2>
            <p className="eform-content_desc">{formDesc1}</p>
        </div>

        <div className="eform-content_body">
            <Menu className="font-mont ereward-nav" selectedKeys={formData.ticket_type+''} onClick={(e) => handleActiveTab('ticket', e.key)} mode="horizontal" items={RewardList('ticket')} />

            <Content className="font-mont ereward-amount" id={formData.ticket_type}>
                {switchTab('ticket', formData.ticket_type)}
            </Content>
        </div>
    </>
}

export default Rewards;