import React, {useEffect} from "react";
import Title from "../shared/Title";
import {Button, Row} from "antd";
import BenefitsItem from "./BenefitsItem";
import {useLocation} from "react-router-dom";
import { useSelector } from "react-redux";

const BenefitsContainer = () => {
    let L = useSelector   (state => state.settings.L);

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [location]);

    return (
        <>
            <Title title={L.metahutBenefitsTitle} className="px-8 mb-5 mt-20 sm-mt-0 text-center text-2xl text-white uppercase font-normal" />
            <Row gutter={{xs: 86, sm: 86, md: 46, lg: 44}} className="px-8 pr-0 w-full xs-col-full sm-px-0 mt-5 sm-mt-0">
                <BenefitsItem />
            </Row>
            <div className="text-center mt-5 mb-9">
                <Button type="primary" size="large" className={`border-0 text-sm font-bold py-2 px-4 h-auto rounded-md bg-cyan-500 text-indigo-900 hover:bg-cyan-400`}>
                    {L.acquireMetahut}    
                </Button>
            </div>
        </>
    )
}

export default BenefitsContainer;