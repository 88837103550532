import React, { useEffect, useState } from "react";
import {Link, NavLink} from "react-router-dom";
import {Button, Col, Layout, Row, Skeleton} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setOpenPlotOnMap} from "../../../redux/actions/landActions";
import {PATH} from "../../../config";
import {addPlotToLocalstorage} from "../../../config/CoreMethods";
import Intro from "./Intro";
import Balance from "./Balance";
import Performance from "./Performance";
import {ROUTES, ROUTES as ROUTES_CONFIG} from "../../../config/constants";
import axiosData from "../../../helpers/axiosData";
import JsxParser from 'react-jsx-parser';

const { Content } = Layout

const Lands = ({username}) => {
    
    const dispatch = useDispatch();
    const selector = useSelector;
    
    let L = selector(state => state.settings.L)
    let FrontLogin = selector ((state) => state.metahut.FrontLogin); 
    let FrontNetwork = selector ((state) => state.metahut.FrontNetwork); 
    let launchpadPlotLimit = selector ((state) => state.land.LaunchpadPlotLimit); 

    

    const [listData, setListData] = useState([])
    const [wait, setWait] = useState(1)

    
    const getAllData = (username) => {

        let url = "/api/_web3/land/get-my-lands/";
        if (typeof username != 'undefined' && username != null) {
            url += username; 
        }

        axiosData.get(url).then((res) => {

            setWait(0);

            if (res.data && res.data.data.length) {
                setListData( res.data.data ); 
                addPlotToLocalstorage(res.data.data)

            } else {
                setListData( [] );     
            }
        })
    }
    
    useEffect(() => {
        getAllData(username)
    }, [])


    const getLaunchpads = (cnt) => {
        let elements = [];

        for (let i = 0; i < cnt; i++) {

            elements.push (
                <Col xs={24} sm={24} md={8} lg={8} className="assets-col">
                    <div className="w-100 thumb">
                        <div className="thumb-asset" 
                            style={{background: `url(${PATH + 'land-claim.png'}) no-repeat center center / cover`}}>
                        </div>
                        <div className="flex flex-col items-center gap-y-3 thumb-claim">
                            <Link to={ROUTES.home.path}  onClick={()=>{dispatch(setOpenPlotOnMap(0, 3))}}>
                                <Button className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-normal gland-claim_btn">{L.claim}</Button>
                            </Link>
                            <p className="font-light font-mont mb-0 text-center gland-claim_note">
                                {L.locationNotSelected}
                            </p>
                        </div>
                    </div>
                </Col>
            )
        }

        return elements
    }

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10">
                    <Intro />
                    <div className="flex items-start dashboard">
                        <div className="content-left">
                            <Balance />
                            <Performance />
                        </div>
                        <div className="content-right">
                            <div className="block font-bold mb-10 meta-card-header">
                                <NavLink to="/profile" className="meta-nav">{L.myAssets}</NavLink> / 
                                <span className="text-white "> {L.lands} ({listData.length})</span>
                            </div>
                            <Row gutter={44} className="assets-row">
                                <Col xs={24} sm={24} md={12} lg={8} className="assets-col">
                                    <div className="w-100 thumb">
                                        <div className="flex flex-col thumb-land">
                                            <NavLink to={ROUTES_CONFIG.getDirectLand.path} className="flex items-center text-white thumb-land_buy">
                                                <i className="icon-plus flex items-center justify-center upload-icon"></i>
                                                <span className="font-light">
                                                    
                                                    {L.purchaseNewLand && <JsxParser jsx={L.purchaseNewLand} /> } 

                                                </span>
                                            </NavLink>
                                            <div className="line-x line-x_land"></div>
                                            <NavLink to={ROUTES_CONFIG.home.path} className="flex items-center text-white thumb-land_locate">
                                                <img src={PATH + 'profile/marker.svg'} alt=""/>
                                                <span className="font-light">
                                                    Locate the Land <br/> on the map
                                                </span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </Col>
                                {listData && listData.length ?
                                    listData.map((item) => {  
                                        return ( 
                                            <Col xs={24} sm={24} md={12} lg={8} key={item.id} className="assets-col">
                                                <div className="w-100 thumb">
                                                    <div className="relative thumb-asset" style={{background: `url(${PATH + 'crop/'+ item.id + '.png'}) no-repeat center center / cover`}}>
                                                        <Link to={ROUTES.home.path} onClick={()=>{dispatch(setOpenPlotOnMap(item.id, 2))}}  className="absolute top-0 rounded-t-xl left-0 w-full h-full flex flex-col items-center justify-center gap-y-2 bg-plot text-center">
                                                            <i className="icon-map-marker text-white text-xl"></i>
                                                            <span className="text-base font-light text-white">{L.viewLandPlot}</span>
                                                        </Link>
                                                    </div>
                                                    <div className="flex flex-col thumb-info thumb-info_land">
                                                        <p className="text-white mb-0 font-normal flex items-start thumb-title">
                                                            <i className="text-pink-400 icon-pin"></i>
                                                            <span className="font-light">{item.real_coord}</span>
                                                        </p>
                                                        <div className="flex items-center flex-wrap justify-between stats">
                                                            <span className="uppercase">{L.land}</span>
                                                            <span className="land-id">#{item.id}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }) : 
                                    wait ? 
                                    <Col xs={24} sm={24} md={12} lg={8} className="assets-col">
                                        <div className="w-100 thumb">
                                            <Skeleton.Image active className="land-asset_skeleton"/>
                                            <div className="flex flex-col thumb-info thumb-info_land">
                                                <p className="text-white mb-0 font-normal flex items-start thumb-title">
                                                    <i className="text-pink-400 icon-pin"></i>
                                                    <Skeleton active paragraph={false} title={{
                                                        width: "34px",
                                                        style: {margin: "0", height: "15px"}
                                                    }}></Skeleton>
                                                </p>
                                                <div className="flex items-center flex-wrap justify-between stats">
                                                    <span className="uppercase">{L.land}</span>
                                                    <span className="land-id">
                                                <Skeleton active paragraph={false}
                                                          title={{width: "41px", style: {margin: "0"}}}></Skeleton>
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                :''}
                                {FrontLogin && FrontNetwork && launchpadPlotLimit ? 
                                    getLaunchpads(launchpadPlotLimit) 
                                :''}
                            </Row>
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default Lands;