import React, {useEffect} from "react";
import {Col, Skeleton} from "antd";
import {NavLink} from "react-router-dom";
import {PATH} from "../../../../config";
import {ROUTES as ROUTES_CONFIG} from "../../../../config/constants";

const Avatars = ({GetUser, L}) => {

    let DefaultAvatar = PATH+'avatar.png';

    const AvatarBG = PATH+'avatarBG.png';
    let lastAvatar={};

    
    useEffect(() => {

        if (GetUser && GetUser.avatars && GetUser.avatars.length) {
            lastAvatar = GetUser && GetUser.avatars && GetUser.avatars.length && GetUser.avatars[GetUser.avatars.length - 1]
        }

    }, [GetUser])

    return (
        GetUser && GetUser.avatars && GetUser.avatars.length == 0 ?
        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col items-center assets-col">
            <div className="w-100 thumb">
                <div className="relative flex items-end avatar_box" style={{backgroundImage: `url(${AvatarBG})`, width: '100%', minHeight: '213px'}}>
                    <img src={PATH + 'avatar.png'} width="100%" height="213px" />
                    <div className="flex flex-col items-center justify-center z-1 thumb-buy_content">
                        <p className="text-center mb-0 font-light thumb-buy_note">
                            {L.emptyAvatarNote}
                        </p>
                    </div>
                </div>
            </div>
            <span className="font-normal flex items-center gap-x-1 asset-link">{L.avatars} (0)</span>
        </Col> :
        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col items-center assets-col">
            <NavLink to={ROUTES_CONFIG.publicAvatars.path.replace(':username', GetUser.username)} className="w-100 thumb">
                <div className="relative flex items-end avatar_box" style={{backgroundImage: `url(${AvatarBG})`, width: '100%', height: '217px'}}>
                    {lastAvatar && GetUser && GetUser.readyplayermeavatar && GetUser.readyplayermeavatar == lastAvatar.url ? <i className="icon-check text-2xl absolute top-0 right-0" style={{color: "#00A76A", margin: "10px 14px"}}></i> : ''}
                    <img src={GetUser && GetUser.avatar ? GetUser.avatar : DefaultAvatar} width="100%" height="213px" style={{objectFit: "cover"}} />
                </div>
            </NavLink>
            <NavLink to={ROUTES_CONFIG.publicAvatars.path.replace(':username', GetUser.username)} className="font-normal flex items-center gap-x-1 asset-link">
                {L.avatars} {GetUser && GetUser.avatars && GetUser.avatars.length? `(${GetUser.avatars.length})`
                : <Skeleton active paragraph={false} title={{width: "22px", style: {margin: "0", height: "22px"}}} className="inline"></Skeleton>}
            </NavLink>
        </Col>
    )
}

export default Avatars;