import React from "react";
import {Link} from "react-router-dom";
import {Button, Col, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {CheckNetwork, connectWallet, getUserBalance, InitWalletConnect, getMapSettings} from "../../redux/actions/metahutActions";
import {setOpenPlotOnMap} from "../../redux/actions/landActions";
import {PATH} from "../../config";
import {getRectSelecteds} from "../../config/CoreMethods";
import { ROUTES } from "../../config/constants";


const MyNFTs = () => {

    const dispatch = useDispatch();
    const selector = useSelector;

    let L = selector(state => state.settings.L);
    let FrontLogin = selector ((state) => state.metahut.FrontLogin); 
    let FrontNetwork = selector ((state) => state.metahut.FrontNetwork); 
    let launchpadPlotLimit = selector ((state) => state.land.LaunchpadPlotLimit); 
    let MyLands = selector ((state) => state.land.MyLands); 
    let MyLandsLoading = selector ((state) => state.land.MyLandsLoading);
    let connectWalletInMyNfts = () => {
        dispatch({ type: 'SetReloadingSite', payload: true })
        dispatch(connectWallet( (MState) => {
            dispatch(InitWalletConnect( ()=>{
                dispatch(CheckNetwork( (chainTrue)=>{
                    if ( chainTrue ) {
                        dispatch( getUserBalance( () => {
                            dispatch( getMapSettings( async () => {
                                dispatch({ type: 'SetReloadingSite', payload: false })
                            }) ) 
                        }) ) 
                    }
                } ))
            } ))

            return MState;
        })) 
        
    }

    let plotCount = getRectSelecteds(1).length

    const getLaunchpads = (cnt) => {
        let elements = [];

        for (let i = 0; i < cnt; i++) {

            elements.push (
                <Col xs={24} sm={12} md={8} lg={6} key={i} className="gutter-row py-8">
                    <div className="relative">
                        <div className="min-h-land overflow-hidden relative">
                            <div className="absolute rounded-t-xl top-0 left-0 w-full h-full flex flex-col items-center justify-center gap-y-4 bg-indigo-900 text-center">
                                <i className="icon-map-marker text-white text-xl"></i>
                                <span className="text-base font-light text-white">
                                    {L.landPlotNotSelected}
                                </span>
                                <Link to={ROUTES.home.path}  onClick={()=>{dispatch(setOpenPlotOnMap(0, 3))}}>
                                    <Button type="primary" size="small" className="border-0 text-base px-6 font-medium rounded-md bg-cyan-500 text-indigo-900">{L.claim}</Button>
                                </Link>
                            </div>
                        </div>
                        <div className="bg-indigo-900 px-8 py-8 rounded-b-xl min-h-content">
                            <div className="flex items-center justify-between flex-wrap gap-x-2 mb-6">
                                <div className="flex flex-col gap-y-4">
                                    <span className="uppercase text-gray-300">{L.land}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        }

        return elements
    }


    return (
        <div className="container mx-auto px-12 relative pt-162 min-h-calc-head py-8">
            <div className="px-12 mt-20 md-mt-0 sm-px-0">
                <Link to="/" className="flex items-center gap-x-4 mb-8 w-fit">
                    <i className="icon-back text-3xl text-white"></i>
                    <span className="text-2xl font-normal text-white">{L.map}</span>
                </Link>
                <h1 className="text-white text-5xl font-extralight">{`Land Plot${plotCount > 1 ? 's' : ''}`}</h1>
            </div>
            <div className="relative">

                <Row gutter={26} className="px-12 sm-px-0">
                    { MyLands && MyLands.length ?
                            MyLands.map((item) => {
                                return (
                                    <Col xs={24} sm={12} md={8} lg={6} key={item.id} className="gutter-row py-8">
                                        <div className="relative">
                                            <div className="overflow-hidden relative land-plot bg-indigo-900 rounded-t-xl">
                                                <Link to={ROUTES.home.path} onClick={()=>{dispatch(setOpenPlotOnMap(item.id, 2))}}  className="absolute top-0 rounded-t-xl left-0 w-full h-full flex flex-col items-center justify-center gap-y-2 bg-plot text-center">
                                                    <i className="icon-map-marker text-white text-xl"></i>
                                                    <span className="text-base font-light text-white">
                                                        {L.viewLandPlot}
                                                    </span>
                                                </Link>
                                                <img src={PATH + 'crop/'+ item.id + '.png'} alt="lnad plot"
                                                     className="w-full rounded-t-xl" 
                                                     onError={({ currentTarget }) => {
                                                         currentTarget.onerror = null;
                                                         currentTarget.src=`${PATH}crop-map.png`;
                                                     }}
                                                />
                                            </div>
                                            <div className="bg-indigo-900 px-8 py-8 rounded-b-xl">
                                                <div className="flex items-center justify-between flex-wrap gap-x-2">
                                                    <div className="flex flex-col gap-y-2">
                                                        <div className="flex items-center gap-x-1">
                                                            <i className="icon-pin text-white text-sm"></i>
                                                            <span className="text-base">{item.real_coord}</span>
                                                        </div>
                                                        <span className="uppercase text-cyan-400">ID: {item.id}</span>
                                                        <span className="uppercase text-gray-300">
                                                            {L.land}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center gap-x-1">
                                                        <img src={PATH+'bnb.svg'} alt=""/>
                                                        <span className="text-cyan-500 text-xl">$105</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        :
                        FrontLogin ?
                            FrontNetwork ?
                            <Col xs={24} sm={24} md={24} lg={24} className="mt-9 bg-indigo-900 px-8 py-12 w-full flex items-center justify-center rounded-xl">
                                <p className="text-xl text-white font-medium mb-0">
                                    {L.emptyLandPlot}
                                </p>
                            </Col>
                                :
                                MyLandsLoading ?
                                <Col xs={24} sm={24} md={24} lg={24} className="mt-9 bg-indigo-900 px-8 py-12 w-full flex items-center justify-center rounded-xl">
                                    <p className="text-xl text-white font-medium mb-0"> {L.loading} ... </p>
                                </Col>
                                : ''
                        :
                        <Col xs={24} sm={24} md={24} lg={24} className="mt-9 bg-indigo-900 px-8 py-12 w-full flex items-center justify-center rounded-xl">
                            <p className="text-xl text-white font-medium mb-0">Please <a onClick={connectWalletInMyNfts}>connect</a> your wallet to see your collection</p>
                        </Col>
                    }

                    {FrontLogin && FrontNetwork && launchpadPlotLimit ? 
                        getLaunchpads(launchpadPlotLimit) 
                    :''}
                </Row>
            </div>
        </div>
    )
}

export default MyNFTs;