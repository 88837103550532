import React from 'react';
import { useSelector } from 'react-redux';

const LoadingText = ({text}) => {
    let L = useSelector (state => state.settings.L);

    return (
        <div className="w-full h-full fixed block top-0 left-0 bg-gray-900 opacity-90 z-50 flex items-center justify-center">
            <div className="flex items-center gap-x-2">
                <span className="text-lg">{text}</span>
                <div className="loader">{L.loading}...</div>
            </div>
        </div>
    )
}

export default LoadingText;