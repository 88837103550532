import React from "react";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
    let L = useSelector(state => state.settings.L);

    return (
        <div className="container mx-auto px-12 relative pt-162 min-h-calc-head py-8">
            <div className="wrapper mt-20">
                <h1 className="text-white text-3xl font-bold mb-4">{L.privacyPolicyTitle1}</h1>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText1}
                </p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.privacyPolicyTitle2}</h3>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText2}
                </p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.privacyPolicyTitle3}</h3>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText3}
                </p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.privacyPolicyTitle4}</h3>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText4}
                </p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.privacyPolicyTitle5}</h3>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText5}
                </p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.privacyPolicyTitle6}</h3>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText6}
                </p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.privacyPolicyTitle7}</h3>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText7}
                </p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.privacyPolicyTitle8}</h3>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText8}
                </p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.privacyPolicyTitle9}</h3>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText9}
                </p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.privacyPolicyTitle10}</h3>
                <p className="font-light mb-0 mb-12 text-base">
                    {L.privacyPolicyText10}<br/>
                    <a href="mailto:contact@metafluence.com" target="_blank" className="text-cyan-500">contact@metafluence.com</a>
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy;