import React from "react";
import { useSelector } from "react-redux";

const SaftAgreement = () => {
    let L = useSelector   (state => state.settings.L);

    return (
        <div className="container mx-auto px-12 relative pt-162 min-h-calc-head py-8">
            <div className="wrapper mt-20">
                <h1 className="text-white text-3xl font-bold mb-4">{L.saftTitle}</h1>
                <p className="font-light mb-0 mb-12 text-base">{L.saftText1}</p>
                <p className="font-light mb-0 mb-4 text-base">{L.saftText2}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftText3}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.saftEvents}</h3>
                <p className="font-light mb-0 mb-4 text-base">{L.saftEventsText1}</p>
                <p className="font-light mb-0 mb-4 text-base">{L.saftEventsText2}</p>
                <p className="font-light mb-0 mb-4 text-base">{L.saftEventsText3}</p>
                <p className="font-light mb-0 mb-4 text-base">{L.saftEventsText4}</p>
                <p className="font-light mb-0 mb-4 text-base">{L.saftEventsText5}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftEventsText6}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.saftDefinitions}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.saftDefinitionsText1}</p>
                <p className="font-light mb-0 mb-8 text-base">{L.saftDefinitionsText2}</p>
                <p className="font-light mb-0 mb-8 text-base">{L.saftDefinitionsText3}</p>
                <p className="font-light mb-0 mb-4 text-base">{L.saftDefinitionsText4}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftDefinitionsText5}</p>
                <p className="font-light mb-0 mb-4 text-base">{L.saftDefinitionsText6}</p>
                <p className="font-light mb-0 mb-4 text-base">{L.saftDefinitionsText7}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftDefinitionsText8}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.saftCompanyRepresentations}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.saftCompanyRepresentationsTxt1}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftCompanyRepresentationsTxt2}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftCompanyRepresentationsTxt3}</p>    
                <p className="font-light mb-0 mb-12 text-base">{L.saftCompanyRepresentationsTxt4}</p>
                
                <h3 className="text-gray-300 text-xl font-normal mb-4">
                    {L.saftPurchaserRepresentations}
                </h3>
                <p className="font-light mb-0 mb-12 text-base">{L.saftPurchaserRepresentationsTxt1}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftPurchaserRepresentationsTxt2}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftPurchaserRepresentationsTxt3}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftPurchaserRepresentationsTxt4}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.saftProcedures}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.saftProceduresText1}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftProceduresText2}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftProceduresText3}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftProceduresText4}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.saftMiscellaneous}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.saftMiscellaneousTxt1}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftMiscellaneousTxt2}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftMiscellaneousTxt3}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftMiscellaneousTxt4}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftMiscellaneousTxt5}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftMiscellaneousTxt6}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.saftContact}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt1}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt2}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt3}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt4}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt5}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt6}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt7}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt8}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt9}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt10}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt11}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt12}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt13}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt14}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt15}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt16}</p>
                <p className="font-light mb-0 mb-12 text-base">{L.saftContactTxt17}</p>

                <h3 className="text-gray-300 text-xl font-normal mb-4">{L.saftConfidentiality}</h3>
                <p className="font-light mb-0 mb-12 text-base">{L.saftConfidentialityText1}</p>

            </div>
        </div>
    )
}

export default SaftAgreement;