import axiosData from "../../helpers/axiosData";
import { ethers } from 'ethers';
import { MetoAddress, PullContractAddress, PullPushContract } from "../../config";
import metaContract from '../../contracts/metafluence.json';


const Lang = { 
  
  youDailyLimitNotEnough : "Your daily limit not enough",
  insertTokenAmount: "Insert Token amount",
  youDontHaveDailyLimit: "you don't have exchange limit",
  SwapTokenAreYouSure: "Are you sure?",
  SwapYesIdo : " Yes Swap ", 
  TransferYesIdo : "Yes transfer"
} 



const GetLang = (callback = ()=>{}) => async (dispatch, state) => {  

    if (state().settings.waitLang == 0) {
      
      axiosData.get("/api/_web3/lang/en").then((res) => {

        if (res.data.success) {
          let LLang = {}
          res.data.data.forEach(el => {
              LLang[el.label] = el.value
          });

          for (const key in Lang) {
            if (!Object.hasOwnProperty.call(LLang, key)) {
              LLang[key] = Lang[key];
            }
          }

          dispatch({type : 'SetLang', payload : LLang});
          console.log('LLang' , LLang) 
        } 
        
        // makeSql()
      })
      .catch(error => {
        console.log('get translations error', error)
      })
      .finally(  () => dispatch({type : 'SetStatus', payload : 1})   );
    }
};



export const approve = async  (Address, amount) => {

    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

    let connectedContract = new ethers.Contract(MetoAddress, metaContract.abi, signer);

    return   await connectedContract.approve(Address, amount)      
}


export const pullMETO = async  (amount) => {
 
    const { ethereum } = window;

    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

    let connectedContract = new ethers.Contract(PullContractAddress, PullPushContract.abi, signer);

    return   await connectedContract.toOffChain(amount)
}


export const getBalance = async  (wallet) => {

  try { 

    const { ethereum } = window;

    const provider = new ethers.providers.Web3Provider(ethereum);
    
    const signer = provider.getSigner();

    let connectedContract = new ethers.Contract(MetoAddress, metaContract.abi, signer);
    
    return   await connectedContract.balanceOf(wallet)
    
  } catch (err) {
    console.log('Approve Error catched', err)
  }       
}



const makeSql = () => {
    let sql_head = "insert INTO `meta_contents` (label_key, description, screen_url, create_date, active) values " 
    let sql = sql_head;
    let list = [];
    let counter = 0;

    for (const key in Lang) {   
      // if (!Object.hasOwnProperty.call(list, key)) {  list[key] = 1;
      if (list.indexOf(key.toLowerCase()) == -1) {
           list.push(key.toLowerCase());
            let el = Lang[key];

            if (counter > 20) {
              counter=0;
              sql += " ('"+key+"' , \""+el+"\", '*', now(), 1);\n\n";
              sql += sql_head 

            } else {
              sql += "('"+key+"' , '"+el+"', '*', now(), 1),\n";
              counter +=1;
            }

      } else {
        console.log('keyyy', key)
      }
    }
    // console.log('list', list)
    console.log(sql)
}

export default GetLang;
