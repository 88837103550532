import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Button, Layout} from "antd";
import Overview from "./form/Overview";
import Details from "./form/Details";
import Venue from "./form/Venue";
import Rewards from "./form/Rewards";
import Hosts from "./form/Hosts";
import axiosData from "../../../helpers/axiosData";
import { toast } from "react-toastify";
import { FullConvertDate, redirectTo } from "../../../config/CoreMethods";
import { ROUTES } from "../../../config/constants";

const { Content } = Layout;

const EventsForm = () => {
    const selector = useSelector;
    let L = selector (state => state.settings.L);
    let activeStep = selector ((state) => state.event.activeStep);
    let formData = selector ((state) => state.event.formData);

    const [loadingBtn, setLoadingBtn] = useState({
        active: false,
        type: 0,
    });


    const saveEvent = (is_submit) => {
        
        let form_data = new FormData();
        let Form = formData;
        setLoadingBtn({active: true, type: is_submit})

        if (Object.keys(Form).length > 0) 
        {
            for (const k in Form) {
                if (Object.hasOwnProperty.call(Form, k) )  
                {
                    if (k ==='start_date' && Form.start_date!='') {
                        
                        form_data.append('start_date', FullConvertDate( Form.start_date+' '+Form.start_time+':00', 0)); 

                    } else if (k ==='end_date' && Form.end_date!='') {
                        
                        form_data.append('end_date', FullConvertDate(Form.end_date+' '+Form.end_time+':00', 0) ); 

                    } else if (k ==='Hosts' && Form.Hosts.length) {
                        
                        let ids = [];
                        for(let i=0; i<Form.Hosts.length; i++) {
                            ids.push(Form.Hosts.id)
                        }
                        form_data.append('hosts', ids); 
                        
                    } else {
                        form_data.append(k, Form[k]); 
                    }
                }
            } 
        } 

        form_data.append('approved', is_submit); 

        let Url = formData.id ? 'update/'+formData.id : 'create';

        axiosData.post("/api/_web3/event/" + Url, form_data).then((res) => {
            
            setLoadingBtn({active: false, type: is_submit})

            if (res.data.success) {
                redirectTo(ROUTES.myEvents.path)
                toast.success(`${L.eventResDataSuccess}`)

            } else {
                toast.error(res.data.message)
            }
        }).catch(er => {
            setLoadingBtn({active: false, type: is_submit})
            toast.error(`${L.eventResError}`)
        })

    }

    const switchStepComponents = (key) => {
        switch (key) {
            case 'overview':
                return (<Overview
                    formTitle={L.eventOverviewTitle}
                    formDesc={L.eventOverviewText}
                />);
            case 'details':
                return (<Details
                    formTitle={L.eventDetailsTitle}
                    formDesc={L.eventDetailsText}
                />);
            case 'venue':
                return (<Venue
                    L={L}
                    formTitle={L.eventVenueTitle}
                    formDesc={L.eventVenueText}
                />);
            case 'rewards':
                return (<Rewards
                    L={L}
                    formTitle={L.eventRewardsTitle}
                    formDesc={L.eventRewardsText}
                    formTitle1={L.eventTicketsTitle}
                    formDesc1={L.eventTicketsText}
                />);
            case 'hosts':
                return (<Hosts
                    L={L}
                    formTitle={L.eventHostsTitle}
                    formDesc={L.eventHostsText}
                    formTitle1={L.eventContactDetailsTitle}
                    formDesc1={L.eventContactDetailsText}
                />);
            default:
                break;
        }
    };

    return (
        <Content className="font-mont eform" id={activeStep}>
            <div className="eform-header">
                {activeStep === 'overview' &&
                    <div className="back-nav">
                        <i className="icon-back"></i>
                        <span>{L.back}</span>
                    </div>
                }
                <div className="eform-act">
                    <div className="eform-act_info">
                        <h3 className="eform-act_ttl">{L.submitEventHeadTitle}</h3>
                        <p className="eform-act_desc">{L.submitEventHeadText}</p>
                    </div>
                    <div className="eform-act_btn">
                        <Button onClick={() => { saveEvent(0) }} className="save-btn">
                            {loadingBtn.active && loadingBtn.type == 0 ? <span className="loading-btn"></span> : ""}
                            {L.save}
                        </Button>
                        <Button onClick={() => { saveEvent(1) }} className="submit-btn">
                            {loadingBtn.active && loadingBtn.type == 1 ? <span className="loading-btn"></span> : ""}
                            {L.submit}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="line-x"></div>
            <div className="eform-content">
                {switchStepComponents(activeStep)}
            </div>
        </Content>
    )
}

export default EventsForm;