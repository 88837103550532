import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {Skeleton} from "antd";

const Performance = ({GetUser, L}) => {

    const [limits, setLimits] = useState({})
    const [time, setTime] = useState('')

    useEffect(()=>{

        if(GetUser && GetUser.daily_task && GetUser.daily_task.limits) {
            setLimits(GetUser.daily_task.limits);
            setTime( Math.round(GetUser && GetUser.meta_time/60 * 10)/10);
        };
    }, [GetUser])

    return (
        <div className="meta-card">
            <div className="font-bold mb-6 meta-card-header">{L.performance}</div>
            <div className="mb-7 line-x"></div>
            <div className="meta-card-body">
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <span className="text-white font-light label">{L.metaTime}</span>
                    {GetUser ? <span className="amount">{time} hrs</span> :
                        <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "58px", style: {margin: "0 0 0 auto", height: "19px"}}}></Skeleton>
                    }
                </div>
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <span className="text-white font-light label">{L.engagementScore}</span>
                    {GetUser ?
                        <NumberFormat
                            value={GetUser.activity_point}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><span className="amount">{value}</span>}
                        /> :
                        <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "58px", style: {margin: "0 0 0 auto", height: "19px"}}}></Skeleton>
                    }
                </div>
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <span className="text-white font-light label">{L.earningCapacity}</span>
                    <span className="flex items-center gap-x-1 amount">
                        {GetUser ?
                            <NumberFormat
                                value={limits.max_earning}
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={(value) => value}
                            /> :
                            <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "38px", style: {margin: "0 0 0 auto", height: "17px"}}}></Skeleton>
                        } SOTO
                    </span>
                </div>
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <span className="flex items-center gap-x-1 text-white font-light label">
                        {L.tasksEfficiency}
                        <span className="text-green-500">
                            {GetUser ? ` +${limits.percent}%`
                                : <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "18px", style: {margin: "0", height: "19px"}}}></Skeleton>}
                        </span>
                    </span>
                    <span className="flex items-center gap-x-1 amount">
                        {GetUser ?
                            <NumberFormat
                                value={limits.max_earning_task}
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={(value) => value}
                            /> :
                            <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "38px", style: {margin: "0 0 0 auto", height: "17px"}}}></Skeleton>
                        } SOTO
                    </span>
                </div>
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <span className="text-white font-light label">{L.achievements}</span>
                    {GetUser ?
                        <NumberFormat
                            value={limits.achievements}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><span className="amount">{value}</span>}
                        /> :
                        <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "58px", style: {margin: "0 0 0 auto", height: "19px"}}}></Skeleton>
                    }
                </div>
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <span className="text-white font-light label">{L.traitGems}</span>
                    {GetUser ?
                        <NumberFormat
                            value={limits.gems}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><span className="amount">{value}</span>}
                        /> :
                        <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "58px", style: {margin: "0 0 0 auto", height: "19px"}}}></Skeleton>
                    }
                </div>
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <span className="text-white font-light label">{L.inventorySlots}</span>
                    {GetUser ?
                        <NumberFormat
                            value={limits.inventory_slots}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><span className="amount">{value}</span>}
                        /> :
                        <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "58px", style: {margin: "0 0 0 auto", height: "19px"}}}></Skeleton>
                    }
                </div>
                <div className="flex items-center justify-between gap-x-4 meta-card-list">
                    <span className="text-white font-light label">{L.emotesInventorySlots}</span>
                    {GetUser ?
                        <NumberFormat
                            value={limits.emotes_inventory_slots}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><span className="amount">{value}</span>}
                        /> :
                        <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "58px", style: {margin: "0 0 0 auto", height: "19px"}}}></Skeleton>
                    }
                </div>
            </div>
        </div>
    )
}

export default Performance;