import React from 'react';
import {ROUTES as ROUTES_CONFIG} from "../../../../../config/constants";
import {Button} from "antd";
import {NavLink} from "react-router-dom";
import {getProvider} from "../../../../../redux/features/userSlice";
import {useDispatch, useSelector} from "react-redux";

const ConnectMetamask = () => {
    const dispatch = useDispatch();
    let L = useSelector (state => state.settings.L);

    return (
        <div className="bg-cyan-400 border-t-4 border-indigo-600 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
            <div className="flex items-center justify-center gap-x-2">
                <svg className="h-6 w-6 text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                </svg>
                <div className="text-sm text-indigo-900">{L.connectMetamaskStake} <Button type="link" className="text-indigo-900 underline px-0 py-0 font-bold h-auto" onClick={()=>{dispatch(getProvider());}}>{L.connectHere}</Button>
                </div>
            </div>
        </div>
    )
}

export default ConnectMetamask;