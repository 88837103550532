import React, {useEffect} from "react";
import {NavLink, useLocation } from "react-router-dom";
import { Button } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import { selectUser } from "../../redux/features/userSlice";
import {basePath} from "../../config";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";

const selector = useSelector

const MenuNav = ({OnSale, accountPage, securityPage, L}) => {

    let GetUser = selector(selectUser);
    let ActiveMenu = useSelector ((state) => state.menu.ActiveMenu)

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {

        const currentURL = window.location.href        

        if (GetUser && GetUser.profile_complete==0 && GetUser.user_type==1 && currentURL.indexOf(securityPage) === -1 ) {
            console.log('securityPage need to redirect');
        }

        if (GetUser && GetUser.profile_complete==0 && GetUser.user_type==2 && currentURL.indexOf(accountPage) === -1 ) {
            console.log('accountPage need to redirect');
        }

    }, [GetUser])

    const switchMenu = () => {

        if(location.pathname.toString().includes("/metahuts")) {
            dispatch({type: 'setActiveMenu', payload: false})
        } else if (location.pathname.toString().includes("/admin")) {
            dispatch({type: 'setActiveMenu', payload: true})
        } else {
            dispatch({type: 'setActiveMenu', payload: false})
        }
    }


    useEffect(() => {

        switchMenu();

    }, [location, location.pathname])


    return (
        <>
            {location.pathname == '/login' || location.pathname == '/register' ? '' :
                <div className={`text-2xl menu-active bg-${location.pathname == '/' || location.pathname == '/pfpnft-avatar' ? 'transparent' : 'indigo-800'}`}>
                    <div className="container mx-auto px-8 pr-4 flex items-center justify-between gap-x-2">
                        <div className="flex items-center gap-x-6 menu-nav visible-lg">
                            <NavLink to={ROUTES_CONFIG.discoveryOverview.path} className="text-gray-light pb-2 font-mont">{L.navDiscovery}</NavLink>
                            
                            <NavLink to={ROUTES_CONFIG.events.path} className="text-gray-light pb-2 font-mont">{L.navEvents}</NavLink>
                            <NavLink to={ROUTES_CONFIG.pfpNftAvatar.path} className="text-gray-light pb-2 font-mont">PFP NFT Avatar</NavLink>
                            <NavLink to={ROUTES_CONFIG.home.path} className="text-gray-light pb-2 font-mont">{L.navMap}</NavLink>
                            {/* <NavLink to={ROUTES_CONFIG.quests.path} className="text-gray-light pb-2 font-mont">Quests</NavLink> */}
                            <NavLink to={ROUTES_CONFIG.staking.path} className="text-gray-light pb-2 font-mont">{L.navStaking}</NavLink>

                            {GetUser &&  GetUser.id ?
                                <> 
                                    <div className="menu-y"></div>
                                    <NavLink to={ROUTES_CONFIG.profile.path} className={`text-gray-light pb-2 font-mont`}>{L.navProfile}</NavLink>
                                    <NavLink to={ROUTES_CONFIG.referralLink.path} className={`text-gray-light pb-2 font-mont`}>{L.navReferrals}</NavLink>
                                </>
                            : ''}

                            {GetUser && GetUser.is_admin == 1 ?
                               <>
                                <NavLink to={ROUTES_CONFIG.quiz.path} className="text-gray-light pb-2 font-mont">{L.navQuiz}</NavLink>
                                <NavLink to={ROUTES_CONFIG.adminMetahutRooms.path} className="text-gray-light pb-2 font-mont">{L.navMetahuts}</NavLink>
                               </>
                               : ''
                            }
                        </div>
                        <div className="flex items-center justify-end gap-x-4 flex-1">
                            <div className="flex items-center" id="onsalearea" style={{display: OnSale && document.location.pathname==basePath ? "" : "none"}}>
                                <Button type="primary" id="onsaleareaclick" className="uppercase text-sm border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">
                                    {L.onSaleButton}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MenuNav;