import React from "react";
import {useSelector} from "react-redux";

const AuthNote = () => {
    let L = useSelector(state => state.settings.L)
    return (
        <div className="login-note">
            {L.authNote}
            <div>
                <i className="icon-lock-closed text-green-400 mr-1"></i>
                <span className="text-green-400"> https://</span>app.metafluence.com
            </div>
        </div>
    )
}

export default AuthNote;