import React from "react";
import {Button, Progress} from "antd";
import {useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../config/constants";
import { redirectTo } from "../../config/CoreMethods";

const ClaimLoading = () => {

    let dispatch = useDispatch();
    let ShowHideClaimLoading = useSelector(state => state.land.ShowHideClaimLoading)
    let ShowHideClaimLoadingMessage = useSelector(state => state.land.ShowHideClaimLoadingMessage)

    let Loadings = [
        [],
        ['25', false, ''],
        ['50', false, ''],
        ['100', true, ''],
        ['100', true, 'exception'],
    ];


    return (
        <>
        { ShowHideClaimLoading ?

            <div className="fixed top-0 left-0 h-full w-100 z-111 flex flex-col items-center justify-center gap-y-10" style={{backgroundColor: 'rgba(28, 26, 66, .85)'}}>
                <div className="flex flex-col items-center px-6">
                    <p className="text-base">{ShowHideClaimLoadingMessage}</p>
                    <Progress type="circle" percent={Loadings[ShowHideClaimLoading][0]} width={80} className="pay-progress" status={Loadings[ShowHideClaimLoading][2]} />
                </div>
                {Loadings[ShowHideClaimLoading][1] ?
                    <div className="flex items-center justify-center">
                        <Link to={ROUTES.lands.path}>
                            <Button onClick={() => {redirectTo(ROUTES.lands.path); dispatch({type: 'SetShowHideClaimLoading', payload: 0})}} type="primary" size="large" className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md" >OK</Button>
                        </Link>
                    </div>
                : ""}
            </div>
            :"" }
        </>
        
        
    )
}

export default ClaimLoading;