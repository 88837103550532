import React, { useEffect, useState } from "react";
import EventsFilter from "../events/EventsFilter";
import {Col, Row} from "antd";
import {Content} from "antd/lib/layout/layout";
import TicketItem from "./TicketItem";
import axiosData from "../../../helpers/axiosData";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const MyTickets = () => {
    let L = useSelector (state => state.settings.L);

    const[ActiveEvents, setActiveEvents] = useState([]);
    const[ExpireEvents, setExpireEvents] = useState([]);

    const getEvents = (inFilter) => {

        let internal = inFilter ? '-internal' : '';

        axiosData.get("/api/_web3/event/my-tickets").then((res) => {
            
            if (res.data.success) {
                setActiveEvents(res.data.data.active);
                setExpireEvents(res.data.data.expires);
            } else {
                toast.error(L.myTicketResError)
            }
        })
    }

    useEffect(()=>{ 
        getEvents()
    },[])

    return (
        <div className="container mx-auto px-12 pr-0 relative pt-162 min-h-calc-head py-8">
            <div className="wrapper mt-16 mb-20">
                <EventsFilter />
                <Content className="font-mont events-list">
                    <h3 className="events-ttl">{L.activeTickets}</h3>
                    <Row gutter={30} className="mb-20">
                        {ActiveEvents && ActiveEvents.length ? 
                            ActiveEvents.map(element => {
                                return  (
                                    <Col xs={24} sm={24} md={12} lg={6}>
                                        <TicketItem element={element}/>
                                    </Col>
                                )
                            })
                        :''}
                    </Row>
                    <h3 className="events-ttl">{L.expiredTickets}</h3>
                    <Row gutter={30} className="mb-20">
                        {ExpireEvents && ExpireEvents.length ? 
                            ExpireEvents.map(element => {
                                return  (
                                    <Col xs={24} sm={24} md={12} lg={6}>
                                        <TicketItem element={element}/>
                                    </Col>
                                )
                            })
                        :''}
                    </Row>
                </Content>
            </div>
        </div>
    )
}

export default MyTickets;