import React from "react";
import { useSelector } from "react-redux";

const MyCardItem = ({item, nft_name, bg}) => {
    let L = useSelector(state => state.settings.L);

    const {active} = item;

    return (
        <div
            className="w-full h-320 flex flex-col justify-between py-4 px-6 rounded-xl overlay-card relative"
            style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="mt-5 z-1">
                <h3 className="text-white mb-0 text-3xl font-bold mb-5">{nft_name}</h3>
                <div className="flex items-center gap-x-2">
                    <i className="icon-pin text-xl text-pink-400"></i>
                    <span className="text-xl">{L.metafluenceCity}</span>
                </div>
            </div>
            <div className="flex items-center justify-between gap-x-1 flex-wrap z-1">
                <div className="flex items-center gap-x-2">
                </div>
                <div className="flex items-center gap-x-2">
                    {0 && active ? <span className="text-green-500 uppercase font-medium">{L.active}</span> : ''}
                    <i className="icon-settings text-2xl text-white"></i>
                </div>
            </div>
        </div>
    )
}

export default MyCardItem;