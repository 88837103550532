import React  from 'react';
import { Modal, Button } from 'antd';
import {basePath} from "../../config";
import { useSelector } from 'react-redux';

const SuccessModal = () => {

    const  handleCancel = () => {}

    let L = useSelector (state => state.settings.L);
    let redirectUrl = '/', title = '';
    
    let locat = window.location.href;

    if ( locat.search('localhost') == -1 ) {
        redirectUrl = basePath; 
    }


    if ( locat.search('land') > -1 ) {

        redirectUrl += 'nfts'; 

        title = L.successfullyPurchased;

    } else if ( locat.search('metahut') > -1 ) {

        redirectUrl += 'metahuts/mycollection'; 

        title = L.successfullyPurchased;

    } else if ( locat.search('claim') > -1 ) {

        redirectUrl += 'nfts'; 
        title = L.successfullyClaimed;
    }

    return (
        <>
            <Modal footer={false} centered={true} visible="1" onCancel={handleCancel} className="modal modal-90p modal-content-center">
                <div className="text-center">
                    <i className="icon-check text-cyan-400 text-5xl"></i>
                    <h3 className="text-white mt-5 text-2xl">{title}</h3>
                </div>
                <div className="flex items-center justify-center mt-10">
                    <Button onClick={() => window.location.href= redirectUrl} type="primary" className="border-0 px-6 py-2 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md text-2xl h-auto" >Ok</Button>
                </div>
            </Modal>
        </>
    );
};

export default SuccessModal;