import React, {useEffect, useState} from "react";
import {Button, Layout} from "antd";
import {NavLink, useParams} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {PATH} from "../../config";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";
import axiosData from "../../helpers/axiosData";
import { fetchUser } from "../../redux/features/userSlice";
import AuthNote from "./AuthNote";
import {useSelector} from "react-redux";

const { Content } = Layout

const ApproveEmail = () => {

    let L = useSelector(state => state.settings.L);
    let { hash } = useParams();

    const fetchUserData = async (dispatch, getState) => {
        try {
            await dispatch(fetchUser())
        } catch (error) {
            console.log('fetchUser error', error)
        } 
    };

    
    const sendApproveEmailword = (hash) => {

        if (typeof hash == 'string' && /^([a-z0-9]{44,46})$/.test(hash) ) {
            
            axiosData.post("/api/_web3/user/activate/", {hash: hash}).then((res) => {
                if (res.data.success) { 
                    
                    fetchUserData()
                    toast.success(`${L.approveSuccessAcc}`);
                    
                } else {
                    toast.error(`${L.approveErrorAcc}`)
                }
            })
        } else {
            toast.error(`${L.validData}`)
        }
    }

    useEffect(()=>{
        sendApproveEmailword(hash)
    },[hash])

    return (
        <div className="min-h-calc-head h-full flex items-center justify-center" style={{paddingTop: "92px", background: `url(${PATH + 'bg-auth.png'}) center center / cover`}}>
            <div className="container mx-auto">
                <Layout className="bg-transparent h-full">
                    <Content className="pt-4 pb-4 pl-14 pr-14 xs-p-2">
                        <div className="flex flex-col items-center gap-y-5">
                            <AuthNote />
                            <ToastContainer />
                            <div className="flex justify-center">
                                <div className="login-form form-box">
                                    <div className="text-center">
                                        <i className="icon-check text-cyan-400 text-5xl"></i>
                                        <h1 className="text-white font-normal text-2xl mt-4 mb-4">
                                            {L.verified}
                                        </h1>
                                        <p className="text-sm text-gray-300">{L.verifySuccessMsg}</p>
                                    </div>
                                    <div className="flex justify-center mt-7 mb-6">
                                        <NavLink to={ROUTES_CONFIG.loginPage.path}>
                                            <Button type="submit"  className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium text-base login-btn flex items-center justify-center gap-x-3">
                                                {L.goToLogin}
                                            </Button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>
        </div>
    )
}

export default ApproveEmail;