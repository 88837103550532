import React from 'react';

const DateTimeDisplay = ({ value, type }) => {
    return (
        <div className="countdown-block">
            <span>{type}</span>
            <div>{value}</div>
        </div>
    );
};

export default DateTimeDisplay;
