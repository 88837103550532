import {getCookie, getItems, getRectSelecteds, saveBuyedNFT, log_, get} from '../../config/CoreMethods'
import {ethers} from "ethers";
import {API, BusdAddress, MetaABI, MetoAddress, NFTABI, NFTAddress} from "../../config";
import { getUserBalance } from './metahutActions';


export const setOpenPlotOnMap = (id, type, callback=()=>{}) => async (dispatch, state) => { 

    dispatch({type: 'SetOpenLandOnMap', payload: {id: id, type: type}})

    if(type === 3) {
        dispatch( ClearSelectedRects() );
        localStorage.setItem('claimmode', 1);
    }
}


// Clear Coding Start

/*--------------------------------*/
export const MyLands = (callback=()=>{}) => async (dispatch, state) => {

    let my_lands = [];

    try {

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(NFTAddress, NFTABI, signer);

        let Account = typeof state != 'undefined' ? state().metahut.Account : getCookie('account');

        await connectedContract.myCollection(Account).then(result=>{
            return result;
        })
        .catch (error=>{
            console.log('MyLands Error', error);
        })
        .then(async (myCollection)=>{
                        
            if (typeof myCollection != 'undefined' && myCollection.length) {
    
                for (let i = 0 ; i < myCollection.length; i++) {
    
                    my_lands.push( ethers.BigNumber.from(myCollection[i]).toNumber() )
                }

            } else {
                callback()
            }

            await connectedContract.launchpadLands(Account)
            .then(result=>{
                return result;
            }).catch(error=>{
                console.log('Claimable PublicLands error', error)
            })
            .then((data) => {
                if ( typeof data != 'undefined' ) {
                    let totalpnch = ethers.BigNumber.from(data.ClaimableCount).toNumber();
                    let Usedpnch = ethers.BigNumber.from(data.ClaimedCount).toNumber();
                    
                    dispatch({type: 'SetLaunchpadPlotLimit', payload: (totalpnch-Usedpnch)})
                }  else {
                    dispatch({type: 'SetLaunchpadPlotLimit', payload: 0})
                } 
            })

            get(API+'get-coord.php?ids='+my_lands.toString(), function(result) {
                
                let data = [];
                
                if (result != '' && result != null) {
                    try {
                        data = JSON.parse(result);
                    } catch (error) {
                        console.log('error land parse', error)
                    }
                }

                let lists = {};

                if(data.length) {
                    for (let i = 0; i < data.length; i++) {
                        lists[data[i].id] = data[i];
                    }
                }

                dispatch({type: 'SetMyLands', payload: data})
                localStorage.setItem("myCollection", JSON.stringify(lists));

                callback()
            });
        })

    } catch (error) {
        console.log(error)
    }

}


/*---------------------------------*/
export const ClearSelectedRects = () => (dispatch, state) => {

    localStorage.setItem("selectedrects", '{}');

    let list = document.querySelectorAll('.selected-plot');
    
    for (let i = list.length - 1; i >= 0; i--) {
        list[i].setAttribute('class', 'gen-plot');
    }

    if (document.getElementById('pinned-svg-content')) {
        document.getElementById('pinned-svg-content').innerHTML = '';
    }

    localStorage.removeItem('claimmode');
    dispatch({type: "SetMapSelectedBarVisible", payload : [0, 0]})
    dispatch({type: 'SetSelectedLandCount', payload: 0})

}


/*--------------------------------*/
export const CheckIsSoldLands = (nfts, callback=()=>{}) => async (dispatch, state) => {

    try {
        if (nfts.length) { 

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(NFTAddress, NFTABI, signer);

            let Buyed =[], NotBuyed = []; 

            for (let i=0; i<nfts.length; i++) {
                    
                await connectedContract.ownerOf(nfts[i]).then(data => {
                    dispatch(RemoveItemFromList(nfts[i],1));
                    Buyed.push(nfts[i])
                }).catch(error=>{
                    NotBuyed.push(nfts[i])
                });
            }

            callback(Buyed, NotBuyed)
        }

    } catch (error) { 
        log_({'action':"CheckIsSoldLands", 'nfts': nfts, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto });
        dispatch({type: 'SetLandBuyingLoading', payload: 4, message: 'Error occured'})
    }
}


/*-----------------------------------*/
export const approveNFT = (is_meto, total_price, callback=()=>{}) => async (dispatch, state) => {

    try {
        const { ethereum } = window; 
        let nfts = getRectSelecteds(1);
        let nftTxn;
        total_price = Math.round(total_price);
        let LandSaleIsOpen = state().land.LandSaleIsOpen;
        
        if (ethereum && nfts.length &&  LandSaleIsOpen) {
            
            const provider = new ethers.providers.Web3Provider(ethereum); 
            const signer = provider.getSigner(); 
            let connectedContract; 

            dispatch({type: 'SetLandBuyingLoading', payload: 1});

            dispatch(CheckIsSoldLands(nfts, async (Buyed, NotBuyed) => { 

                if (Buyed.length) { 
                    dispatch({type: 'SetLandBuyingLoading', payload: 4, message: ('('+Buyed.toString()+') land'+(Buyed.length>1?'s':'')+' already bought')});
                    return false;
                } 
                
                connectedContract = new ethers.Contract((is_meto ? MetoAddress : BusdAddress), MetaABI, signer)
                

                await connectedContract.approve(NFTAddress, total_price + '000000000000000000')
                .then((result) => {
                    return result;
                })
                .catch(error => {
                    console.log('approve error : ', error); 
                    log_({'action':"approveNFT-error", 'price':total_price, 'nfts': nfts, 'is_meto': is_meto, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto, 'error': error});
                })
                .then (data => { 
                    log_({'action':"approveNFT", 'price':total_price, 'txtn': nftTxn, 'nfts': nfts, 'is_meto': is_meto, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto });
                    console.log({'action':"approveNFT", 'price':total_price, 'txtn': nftTxn, 'nfts': nfts, 'is_meto': is_meto, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto });

                    if ( typeof data != 'undefined' ) {
                        setTimeout(()=>{
                            dispatch(BuyNFTs(nfts, is_meto));
                        }, 7000)
                    } else {
                        dispatch({type: 'SetLandBuyingLoading', payload: 4})
                    }
                })
            })
            )

        } else {
            console.log("Ethereum object doesn't exist")
        }

    } catch (error) {
        console.log('approveNFT try error : ', error)
        dispatch({type: 'SetLandBuyingLoading', payload: 4})  
        log_({'action':"approveNFT-error", 'price':total_price, 'is_meto': is_meto, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto, 'error': error});
    }

}


    /*----------------------------------*/
export const RemoveItemFromList = (item, Na) => async (dispatch, state) => {

    let items = getItems("selectedrects", 0);

    if (Object.keys(items).length !== 0) {

        if (typeof items[item] != 'undefined') {
            delete items[item];
        }
    }

    localStorage.setItem("selectedrects", JSON.stringify(items));

    let mode = parseInt(localStorage.getItem("claimmode"));

    dispatch({type: 'SetMapSelectedBarVisible', payload: [Object.keys(items).length, (mode?2:1)]})
    dispatch({type: 'SetSelectedLandCount', payload: Object.keys(items).length})
}


    /*-------------------------------*/
export const BuyNFTs = (nfts, is_meto) => async (dispatch, state) => {
    try {
        const { ethereum } = window;
        let nftTxn;
        let State = state();
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner()
        const connectedContract = new ethers.Contract(NFTAddress, NFTABI, signer)


        dispatch({type: 'SetLandBuyingLoading', payload: 2});
        
        const AfterThen = (data, State, nfts, dispatch) => {
            
            if ( typeof data != 'undefined' ) {
                log_({'action':"BuyNFTs", 'txtn': nftTxn, 'nfts': nfts, 'is_meto': is_meto, 'BalanceBusd': State.metahut.BalanceBusd, 'BalanceMeto': State.metahut.BalanceMeto });
                
                saveBuyedNFT(State.metahut.Account, nfts.toString())
                dispatch(ClearSelectedRects());
 
                dispatch({type: 'SetLandBuyingLoading', payload: 3})
                dispatch(getUserBalance())

                dispatch(MyLands())

            } else {
                dispatch({type: 'SetLandBuyingLoading', payload: 4, message : "METO/BUSD couldn't be transfered. Please Try Again"});
            }
        }
        
        if (is_meto) { 
            await connectedContract.mintWithMeto(nfts)
            .then((data__) => {  return data__; })
            .catch(errors => { /* console.log('buy land error: ', errors); */ })
            .then(async data => {  
                AfterThen(data, State, nfts, dispatch)
             })

        } else {
            await connectedContract.mintWithBusd(nfts)
            .then((data__) => {  return data__; })
            .catch(errors => { /* console.log('buy land error: ', errors); */ })
            .then(async data => {  
                AfterThen(data, State, nfts, dispatch)
             })
        }


    } catch (error) {

        console.log('BuyNFTs-error', error)
        dispatch({type: 'SetLandBuyingLoading', payload: 4, message: "METO/BUSD couldn't be transfered. Please Try Again"})
        log_({'action':"BuyNFTs-error", 'nfts': nfts, 'is_meto': is_meto, 'BalanceBusd':state().metahut.BalanceBusd, 'BalanceMeto':state().metahut.BalanceMeto, 'error': error});

    }
}


export const ClaimSelecteds = () => async (dispatch, state) => {

    try {

        const { ethereum } = window;
        let account = state().metahut.Account;
        let selectedrects = getRectSelecteds(1);

        if (ethereum && selectedrects.length) {

            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            let connectedContract = new ethers.Contract(NFTAddress, NFTABI, signer)
            
            dispatch({type: 'SetShowHideClaimLoading', payload: 1, message: "Checking lands ..."})

            dispatch(CheckIsSoldLands(selectedrects, async (Buyed, NotBuyed) => { 

                if (Buyed.length) { 
                    dispatch({type: 'SetShowHideClaimLoading', payload: 1, message: ('('+Buyed.toString()+') land'+(Buyed.length>1?'s':'')+' already bought')});
                    return false;
                } 
                
                dispatch({type: 'SetShowHideClaimLoading', payload: 2})

                await connectedContract.claim(selectedrects)
                 .then((data) => {

                    return data;

                 }).catch(error => {
                    dispatch({type: 'SetShowHideClaimLoading', payload: 4, message : "Error occured"});
                 })
                 .then((data) => {
                    
                    if (typeof data != 'undefined') {
                        saveBuyedNFT(account, selectedrects.toString());
                        dispatch({type: 'SetShowHideClaimLoading', payload: 3, message: 'Success!'});
                    }

                 }); 

                dispatch(ClearSelectedRects());
                localStorage.setItem('claimmode', 0);
            
            }))

        } else {
            dispatch({type: 'SetShowHideClaimLoading', payload: 0})
        }
    } catch (error) {
        dispatch({type: 'SetShowHideClaimLoading', payload: 0})
    }
}



    /*-------------------------------*/
export const GetWhitelistStatus = () => async (dispatch, state) => {
    try {
        const { ethereum } = window;
        let account = state().metahut.Account;
        
        if (ethereum && account) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner()
            const connectedContract = new ethers.Contract(NFTAddress, NFTABI, signer)

            await connectedContract.whiteListAddresses(account)
            .then(data => {
                dispatch({type:'SetIsWhiteList', payload: (data?true:false)});
            })
            .catch(error=>{
                // console.log('error whiteList', error);
                dispatch({type:'SetIsWhiteList', payload: false});
            })
        } 
    } catch (error) {
        // console.log('GetWhitelistStatus error', error)
    }
}




export const GetPublicOrWhiteListSaleStatus = () => async (dispatch, state) => {

    try {

        const {ethereum} = window;

        if (ethereum) {

            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner()

            const connectedContract = new ethers.Contract(NFTAddress, NFTABI, signer)

            await connectedContract.publicSaleStatus()
            .then((data_1)=>{ return data_1; })
            .catch(error_1=>{ /* console.log('publicSaleStatus error', error_1) */ })
            .then(async (data_1)=>{
                
                await connectedContract.whiteListSaleStatus()
                .then((data_2)=>{ return data_2; })
                .catch(error_2 => { /* console.log(' error', error_2) */ })                
                .then(data_2 =>{
                    
                    // console.log('data_1',data_1, 'data_2', data_2);

                    if(typeof data_1 != 'undefined' || typeof data_2 != 'undefined') {
                        dispatch({type: 'SetLandSaleIsOpen', payload: true})
                    } else {
                        dispatch({type: 'SetLandSaleIsOpen', payload: false})
                    }
                })    
            })
        } else {
            console.log("Ethereum object doesn't exist")
        }
    } catch (error) {
        // console.log('GetPublicOrWhiteListSaleStatus error:', error)
    }
}


// Clear Coding End 


//  New Coding 

/*-----------------------------------*/
export const approveNFTNotSelected = (is_meto, total_price, land_count, callback=()=>{}) => async (dispatch, state) => {

    try {
        const { ethereum } = window;  
        let nftTxn;
        total_price = Math.round(total_price);
        let LandSaleIsOpen = state().land.LandSaleIsOpen;
        
        if (ethereum &&  LandSaleIsOpen) {
            
            const provider = new ethers.providers.Web3Provider(ethereum); 
            const signer = provider.getSigner(); 
            let connectedContract; 
            
            let ApproveAddress = is_meto ? MetoAddress : BusdAddress;

            dispatch({type: 'SetLandBuyingLoading', payload: 1});
            
                connectedContract = new ethers.Contract(ApproveAddress, MetaABI, signer)
                
                await connectedContract.approve(NFTAddress, total_price + '000000000000000000')
                .then((result) => {
                    return result;
                })
                .catch(error => {
                    // console.log('approve error : ', error); 
                    log_({'action':"approveNFT-error", 'price':total_price, 'is_meto': is_meto, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto, 'error': error});
                })
                .then (data => { 
                    log_({'action':"approveNFT", 'price':total_price, 'txtn': nftTxn, 'is_meto': is_meto, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto });

                    if ( typeof data != 'undefined' ) {
                        dispatch(BuyNFTNotSelected(land_count, is_meto));
                        // alert('buying')
                    } else {
                        dispatch({type: 'SetLandBuyingLoading', payload: 4})
                    }
                })
  
        } else {
            console.log("Ethereum object doesn't exist")
        }

    } catch (error) {
        // console.log('approveNFT try error : ', error)
        dispatch({type: 'SetLandBuyingLoading', payload: 4})  
        log_({'action':"approveNFT-error", 'price':total_price, 'is_meto': is_meto, 'BalanceBusd': state().metahut.BalanceBusd, 'BalanceMeto': state().metahut.BalanceMeto, 'error': error});
    }

}


    /*-------------------------------*/
    export const BuyNFTNotSelected = (land_count, is_meto) => async (dispatch, state) => {
        try {
            const { ethereum } = window;
            let nftTxn;
            let State = state();
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner()
            const connectedContract = new ethers.Contract(NFTAddress, NFTABI, signer)
    
            dispatch({type: 'SetLandBuyingLoading', payload: 2});
            
                const AfterThen = (data, State, land_count, dispatch) => {
                
                if ( typeof data != 'undefined' ) {
                    log_({'action':"BuyNFTs", 'txtn': nftTxn, 'land_count':land_count, 'is_meto': is_meto, 'BalanceBusd': State.metahut.BalanceBusd, 'BalanceMeto': State.metahut.BalanceMeto });
    
                    // saveBuyedNFT(State.metahut.Account, nfts.toString())
                    // dispatch(ClearSelectedRects());
     
                    dispatch({type: 'SetLandBuyingLoading', payload: 3})
                    dispatch(getUserBalance())
    
                    dispatch(MyLands())
    
                } else {
                    dispatch({type: 'SetLandBuyingLoading', payload: 4, message : "METO/BUSD couldn't be transfered. Please Try Again"});
                }
            }
            
            if (is_meto) { 
                await connectedContract.pay4LandWithMeto(land_count)
                .then((data__) => {  return data__; })
                .catch(errors => { /* console.log('buy land error: ', errors); */ })
                .then(async data => {  
                    AfterThen(data, State, land_count, dispatch)
                 })
    
            } else {
                await connectedContract.pay4LandWithBusd(land_count)
                .then((data__) => {  return data__; })
                .catch(errors => { /* console.log('buy land error: ', errors); */ })
                .then(async data => {  
                    AfterThen(data, State, land_count, dispatch)
                 })
            }
    
    
        } catch (error) {
    
            console.log('BuyNFTs-error', error)
            dispatch({type: 'SetLandBuyingLoading', payload: 4, message: "METO/BUSD couldn't be transfered. Please Try Again"})
            log_({'action':"BuyNFTs-error", 'land_count': land_count, 'is_meto': is_meto, 'BalanceBusd':state().metahut.BalanceBusd, 'BalanceMeto':state().metahut.BalanceMeto, 'error': error});
    
        }
    }
    



    export const getLandPriceMeto = (callback=()=>{}) => async () => {
        try {
            const { ethereum } = window;
            
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner()
                const connectedContract = new ethers.Contract(NFTAddress, NFTABI, signer)
    
                await connectedContract.LAND_PRICE_METO()
                .then(data => {
                    console.log('getLandPriceMeto', data,  (parseInt(data)) ) 
                    callback(parseInt(data))
                    // dispatch({type:'SetLandPriceMeto', payload: (parseInt(data)/10**18)});
                })
                .catch(error=>{

                    console.log('error getLandPriceMeto', error);
                    // dispatch({type:'SetLandPriceMeto', payload: 0});
                })
            } 
        } catch (error) {
            // console.log('GetWhitelistStatus error', error)
        }
    }
    


