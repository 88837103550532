import React, {useEffect, useState} from "react";
import {Button, Col, Layout, message, Modal, Row, Skeleton} from "antd";
import {PATH} from "../../../config";
import axiosData from "../../../helpers/axiosData";
import Intro from "./Intro";
import Balance from "./Balance";
import Performance from "./Performance";
import {Link, NavLink} from "react-router-dom";
import {ROUTES as ROUTES_CONFIG} from "../../../config/constants";
import {useSelector} from "react-redux";
import JsxParser from 'react-jsx-parser';


const { Content } = Layout

const Avatars = () => {

    const selector = useSelector;

    const DefaultAvatar = PATH+'avatar.png';
    const AvatarBG = PATH+'avatarBG.png';

    let L = selector(state => state.settings.L)
    let CurrentAccount = selector ((state) => state.metahut.Account)

    const [file,  setFile] = useState(DefaultAvatar)
    const [RPMFrame, setRPMFrame] = useState('');
    const [loadingImage, setLoadingImage] = useState(false);
    const [UserData, setUserData] = useState({})
    const [messageApi, contextHolder] = message.useMessage();
    const [AvatarFile, setAvatarFile] = useState(PATH+'avatar.png')


    const getUserData = () => {

        axiosData
        .get(`/api/user`)
        .then((res) => {

            if( res.data ) {
                let UserData_ = res.data;
                setUserData(UserData_)
            }
        })
        .catch((error) => {
            console.log('put error: ', error)
        })
    }

    const sendUserData = (profile) => {

        axiosData.post(`/api/_web3/user/change-avatar`, {
            avatar_url: profile,  
        })
        .then((res) => {
            successMsg()
            console.log('settings send data', res)
        })
        .catch((error) => {
            console.log('error', error)
            errorMsg()

        })
    }

    const removeAvatar = (profile) => {

        axiosData.post(`/api/_web3/user/remove-history-avatar`, {
            avatar_url: profile,  
        })
        .then((res) => {
            successMsg()
            console.log('settings send data', res)
        })
        .catch((error) => {
            console.log('error', error)
            errorMsg()
        })
    }

    const successMsg = () => {
        messageApi
            .open({
                type: 'loading',
                content: L.actionProgress,
                duration: 2,
            })
            .then(() => {return message.success(L.profileUpdated, 2)})
            .then(() => window.location.reload())
    };

    const errorMsg = () => {
        messageApi
            .open({
                type: 'loading',
                content: L.actionProgress,
                duration: 2,
            })
            .then(() => {return message.error(L.updateProfileErr, 2)})
            .then(() => window.location.reload())
    };

    const openAvatarFrame = () => {
        const frame =
            <div className="modal-root">
                <div className="ant-modal-centered ant-modal-wrap">
                    <div className="ant-modal-mask"></div>
                    <div role="dialog" aria-labelledby="rc_unique_0" aria-modal="true" className="ant-modal ant-col-xs-24">
                        <div className="ant-modal-content">
                            <button onClick={()=>{setRPMFrame('')}} type="button" aria-label="Close" className="ant-modal-close">
                                <span className="ant-modal-close-x">
                                    <span role="img" aria-label="close" className="anticon anticon-close ant-modal-close-icon">
                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em"
                                        fill="currentColor" aria-hidden="true"><path
                                        d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
                                    </span>
                                </span>
                            </button>
                            <div className="ant-modal-header">
                                <div className="ant-modal-title" id="rc_unique_0">{L.changeAvatar}</div>
                            </div>
                            <div className="ant-modal-body"><iframe id="readyplayermeIframe" src={`https://metafluence.readyplayer.me/avatar?frameApi`} className="frame ant-col-xs-24" allow="camera *; microphone *"></iframe> </div>
                        </div>
                    </div>
                </div>
            </div>

        setRPMFrame(frame)

        window.addEventListener('message', subscribe);
        document.addEventListener('message', subscribe);

        function subscribe(event) {
            const json = parse(event);
            if (json?.source !== 'readyplayerme') {
                return;
            }

            // Susbribe to all events sent from Ready Player Me once frame is ready
            if (json.eventName === 'v1.frame.ready') {

                const frame = document.getElementById('readyplayermeIframe');
                frame.contentWindow.postMessage(
                    JSON.stringify({
                        target: 'readyplayerme',
                        type: 'subscribe',
                        eventName: 'v1.**'
                    }),
                    '*'
                );
            }

            // Get avatar GLB URL
            if (json.eventName === 'v1.avatar.exported') {

                setLoadingImage(true)

                if (typeof json.data.url !='undefined' && json.data.url) {

                    let form_data = new FormData();

                    form_data.append('avatar_url', json.data.url);

                    axiosData.post(`/api/_web3/user/change-avatar`, form_data)
                        .then((res) => {

                            window.location.reload()
                            setFile(res.data.image)
                        })
                        .catch((error) => {
                            // console.log('error', error)
                        })
                        .finally(() => setLoadingImage(false));

                }

                setRPMFrame('');
            }

            // Get user id
            if (json.eventName === 'v1.user.set') {
                // console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
            }
        }

    }

    function parse(event) {
        try {
            return JSON.parse(event.data);
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {

        getUserData();

    }, []);


    useEffect(() => {

        if (UserData && UserData.avatar) {
            setAvatarFile(UserData.avatar)
        }

    }, [UserData, CurrentAccount])

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                {contextHolder}
                <Content className="pt-8 pb-8 pl-10 pr-10">
                    {RPMFrame}
                    <Intro />
                    <div className="flex items-start dashboard">
                        <div className="content-left">
                            <Balance />
                            <Performance />
                        </div>
                        <div className="content-right">
                            <div className="block font-bold mb-10 meta-card-header">
                                <NavLink to={ROUTES_CONFIG.profile.path} className="meta-nav">
                                    {L.myAssets}    
                                </NavLink> / 
                                <span className="text-white "> {L.avatars} ({UserData.avatars && UserData.avatars.length})</span>
                            </div>

                            <Row gutter={34}>
                                <Col xs={12} sm={24} md={12} lg={8} className="mb-10">
                                    <div onClick={openAvatarFrame} className="relative cursor-pointer avatar_box avatar_upload flex flex-col justify-center gap-y-5 items-center" style={{height: "209px"}}>
                                        {loadingImage ?
                                            <div className="loading-overlay">
                                                <span className="loading-image"></span>
                                            </div> : ""
                                        }
                                        <i className="icon-plus text-base flex items-center justify-center upload-icon"></i>
                                        <span className="text-center text-base font-light">
                                            {L.createNewAvatar && <JsxParser jsx={L.createNewAvatar} />  } 
                                        </span>
                                    </div>
                                </Col>
                                {UserData && UserData.avatars ?
                                    UserData.avatars.map((item, key) => {
                                        return (
                                            <Col xs={12} sm={24} md={12} lg={8} key={key} className="mb-10">
                                                <div className="relative avatar_box rounded-10px" 
                                                    style={{backgroundImage: `url(${AvatarBG})`, width: '100%', minHeight: '190px'}}
                                                >
                                                    {UserData.readyplayermeavatar == item.url ? 
                                                        <i className="icon-check text-2xl absolute top-0 right-0" style={{color: "#00A76A", margin: "10px 14px"}}></i> 
                                                    : ''}
                                                    <img src={item.half_image} width="100%" height="100%" />
                                                    <div className="avatar_actions flex items-center justify-center bg-cyan-500 rounded-10px text-indigo-900">
                                                        <Button onClick={() => sendUserData(item.url)} className="border-0 bg-transparent px-2 uppercase font-medium text-indigo-900 text-xs">
                                                            {L.setProfile}    
                                                        </Button>|
                                                        <Button onClick={() => removeAvatar(item.url)} className="border-0 bg-transparent px-2 uppercase font-medium text-indigo-900 text-xs">
                                                            {L.remove}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }) :
                                    <Col xs={24} sm={24} md={12} lg={8} className="flex flex-col items-center assets-col">
                                        <div className="w-100 thumb">
                                            <div className="relative flex items-end avatar_box" style={{backgroundImage: `url(${AvatarBG})`, width: '100%', height: '217px'}}>
                                                <img src={AvatarFile} width="100%" height="213px" style={{objectFit: "cover"}} />
                                            </div>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default Avatars;