import React from "react";
import {Row} from "antd";
import Lands from "./assets/Lands";
import Metahuts from "./assets/Metahuts";
import Avatars from "./assets/Avatars";
import { useSelector } from "react-redux";

const Assets = () => {

    let L = useSelector(state => state.settings.L);
    return (
        <div className="assets">
            <span className="block font-bold mb-10 meta-card-header">{L.assets}</span>
            <Row gutter={44} className="assets-row">
                <Metahuts L={L} />
                <Lands L={L} />
                <Avatars L={L} />
            </Row>
        </div>
    )
}

export default Assets;