import React, {useEffect, useState} from "react";
import {PATH} from "../../config";
import {Link, NavLink} from "react-router-dom";
import {Button, Col, Row} from "antd";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";
import { useSelector } from "react-redux";

const HomePage = () => {
    let L = useSelector (state => state.settings.L);

    const [opSysName, setOpSysName] = useState('');

    const operSys = () => {
        let os = navigator.userAgent;
        if (os.search('Windows')!==-1){
            setOpSysName('Windows')
        }
        else if (os.search('Mac')!==-1){
            setOpSysName('MacOS')
        }
    }

    useEffect(() => {
        operSys();
    }, [])


    return (
        <>
            <div className="video-layout">
                <div className="video-gradient"></div>
                <video loop autoPlay muted className="video-bg" src={PATH + 'video/site_cover_2.mp4'} />
                <div className="container mx-auto px-12 relative pt-162 py-8">
                    <div className="flex flex-col video-content">
                        <h2 className="font-mont font-bold mb-0 text-white video-content-title">
                            {L.homePageVideoLayoutTitle}
                        </h2>
                        <p className="font-mont font-normal mb-0 video-content-desc">
                            {L.homePageVideoLayoutTxt}
                        </p>
                        {opSysName == 'MacOS' ?
                            <a target="_blank" href={'https://app.metafluence.com/launcher/mac/'} className="video-btn-link">
                                <Button className="border-0 rounded-10px font-bold font-mont flex items-center justify-center video-content-btn">
                                    <img src={PATH + 'mac.svg'} alt=""/>
                                    <span>{L.downloadBtnMac}</span>
                                </Button>
                            </a>
                            :
                            <a target="_blank" href={'https://app.metafluence.com/launcher/windows/'} className="video-btn-link">
                                <Button className="border-0 rounded-10px font-bold font-mont flex items-center justify-center video-content-btn">
                                    <img src={PATH + 'windows.svg'} alt=""/>
                                    <span>{L.downloadBtnWindows}</span>
                                </Button>
                            </a>
                        }
                    </div>
                </div>
            </div>
            <div className="container mx-auto px-12 py-8">
                <div className="avatars-3d mt-20">
                    <Row gutter={{lg: 70}} className="join-steps mt-20">
                        <Col xs={24} sm={12} md={12} lg={12}>
                            <img src="https://i.ibb.co/Tq0LjGb/pfp-nft.png" className="avatars-3d_img" />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12}>
                            <h2 className="font-mont font-bold text-white avatars-3d_ttl mb-16">
                                3D Avatars
                            </h2>
                            <p className="avatars-3d_txt font-mont text-lg">With Metafluence's cutting-edge technology, take your self-expression to a whole new level with our fully customizable avatars. Simply upload your picture and watch as our advanced system seamlessly converts your image into your very own personalized avatar, or you can choose from our ready to use templates.</p>
                            <p className="avatars-3d_txt font-mont text-lg mb-20">If you have a favorite PFP that represents you or your brand, our exclusive "PFP NFT to Avatar Converter" allows you to transform your 2D or 3D PFP image into a dedicated avatar accessible only to you. Experience the thrill of watching your NFT come to life in the world of web3.</p>   
                            <a href={ROUTES_CONFIG.pfpNftAvatar.path} className="tcard-button font-mont font-bold text-center text-indigo-900 inline-block">Learn More</a>
                        </Col>
                    </Row>
                </div>
                <div className="join-layout mt-20">
                    <div className="flex flex-col items-center mx-auto join-header">
                        <h2 className="font-mont mb-0 font-bold text-white text-center join-header-title">
                            {L.homePageJoinLayoutTitle}
                        </h2>
                        <p className="font-mont mb-0 font-normal text-center join-header-desc">
                            {L.homePageJoinLayoutTxt}
                        </p>
                    </div>

                    <Row gutter={{lg: 70}} className="join-steps mt-20">
                        <Col xs={24} sm={8} md={8} lg={8}>
                            <div className="flex flex-col items-center relative join-box" style={{background: `url(${PATH + 'land-box-bg.png'}) no-repeat center center / cover`}}>
                                <div className="join-box-bg"></div>
                                <h3 className="mb-0 text-white font-mont font-bold join-box-title">
                                    {L.homePageJoinBoxTitle1}
                                </h3>
                                <p className="font-mont font-normal mb-0 text-center join-box-desc">
                                    {L.homePageJoinBoxText1}
                                </p>
                                <NavLink to={ROUTES_CONFIG.getDirectLand.path} className="join-box-link">
                                    <Button className="border-0 bg-white text-indigo-900 rounded-10px uppercase font-bold font-mont join-box-btn">
                                        {L.homePageJoinBoxButton1}
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8}>
                            <div className="flex flex-col items-center join-box" style={{background: `url(${PATH + 'metahut-box-bg.png'}) no-repeat center center / cover`}}>
                                <div className="join-box-bg"></div>
                                <h3 className="mb-0 text-white font-mont font-bold join-box-title">
                                    {L.homePageJoinBoxTitle2}
                                </h3>
                                <p className="font-mont font-normal mb-0 text-center join-box-desc">
                                    {L.homePageJoinBoxText2}
                                </p>
                                <NavLink to={ROUTES_CONFIG.getMetahut.path} className="join-box-link">
                                    <Button className="border-0 bg-white text-indigo-900 rounded-10px uppercase font-bold font-mont join-box-btn">
                                        {L.homePageJoinBoxButton2}
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8}>
                            <div className="flex flex-col items-center join-box" style={{background: `url(${PATH + 'experience-box-bg.png'}) no-repeat center center / cover`}}>
                                <div className="join-box-bg"></div>
                                <h3 className="mb-0 text-white font-mont font-bold join-box-title">
                                    {L.homePageJoinBoxTitle3}
                                </h3>
                                <p className="font-mont font-normal mb-0 text-center join-box-desc">
                                    {L.homePageJoinBoxText3}
                                </p>
                                <NavLink to={ROUTES_CONFIG.loginPage.path} className="join-box-link">
                                    <Button className="border-0 bg-white text-indigo-900 rounded-10px uppercase font-bold font-mont join-box-btn">
                                        {L.homePageJoinBoxButton3}
                                    </Button>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default HomePage;