import React, { useEffect } from "react";
import {Button, Layout} from "antd";
import {Content} from "antd/lib/layout/layout";
import {NavLink} from "react-router-dom";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";
import {PATH} from "../../config";
import { logUrl } from "../../config/CoreMethods";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";

const Page404 = () => {

    let L = useSelector(state => state.settings.L);
    let GetUser = useSelector(selectUser);

    useEffect(()=>{
        logUrl(( GetUser && GetUser.id ? GetUser.id :0))
    }, [])

    return (
        <Layout className="pt-162 min-h-calc-head h-full page404" style={{background: `#1c1a42 url(${PATH + 'page404-bg.png'}) no-repeat center center / cover`}}>
            <Content className="container mx-auto pl-10 pr-10 bg-transparent h-full page404-container">
                <div className="page404-content">
                    <h2 className="text-white font-mont page404-symbol">404</h2>
                    <h3 className="text-white font-mont font-bold page404-title">{L.error404Title}</h3>
                    <p className="font-normal font-mont page404-text">
                        {L.error404Text}
                    </p>
                    <NavLink to={ROUTES_CONFIG.homePage.path} className="inline-block">
                        <Button className="border-0 text-white rounded-10px uppercase font-bold font-mont page404-btn">{L.error404Button}</Button>
                    </NavLink>
                </div>
            </Content>
        </Layout>
    )
}

export default Page404;