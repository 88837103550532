import React, {useState} from "react";
import {Button, Layout, Row, Col, message} from "antd";
import {PATH} from "../../../config";
import {NavLink} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { selectUser } from "../../../redux/features/userSlice";
import ReferralsSidebar from "./ReferralsSidebar";

const { Content } = Layout

const ReferralLink = () => {
    
    const selector = useSelector; 
    const Traveler = PATH+'traveler.svg';
    const Resident = PATH+'resident.svg';
    const Metapreneur = PATH+'metapreneur.svg';

    let GetUser = selector(selectUser);
    let L = selector (state => state.settings.L);
    
    let btnId = '';
 
    const [copyVal, setCopyVal] = useState({ referral: "", code: "" }); 
    const [messageApi, contextHolder] = message.useMessage();
    const Statuses = {
        1 : {name: L.traveler, icon : Traveler},
        2 : {name: L.resident, icon : Resident},
        3 : {name: L.metapreneur, icon : Metapreneur},
    }

    const handleClick = e => {
        btnId = e.currentTarget.id;
        console.log(e.currentTarget.id, btnId);
    };

    const onChange = (e) => {
        setCopyVal({...copyVal, [e.target.name]: e.target.value});
    }

    const onCopy = () => {
        message.success(' Copied! ', 2)
    }

    return <div className="container mx-auto pt-162 min-h-calc-head h-full">
        <Layout className="h-full settings-layout">
            <ReferralsSidebar />
            {contextHolder}
            <Content className="pt-8 pl-10 pr-10 settings-content">
                <h3 className="text-xl text-white font-bold mb-8 settings-title">{L.refLink}</h3>
                <Row gutter={44} className="mb-12">

                    {GetUser ?
                        <>
                            <Col xs={24} sm={24} md={12} lg={8} className="mb-8">
                                <div className="text-center">
                                    <img src={`${PATH+(GetUser &&  'ranks/'+GetUser.status+'/'+GetUser.rank)}.png`} alt="" style={{width: "100px"}} />
                                    <p className="text-lg font-light">You are currently in <br/>
                                        <span className={`font-bold color-${GetUser && GetUser.status}`}>{GetUser && Statuses[GetUser.status].name}</span> status
                                    </p>

                                    { GetUser.status < 3 ?
                                        <NavLink to="/settings/status/">
                                            <Button className="border-0 mt-2 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium avatar_btn">
                                                {L.statusDropdownUpgrade}
                                            </Button>
                                        </NavLink>
                                    :''}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={16} className="mb-8">
                                <div className="mb-8">
                                    <label htmlFor="code" className="text-gray-300 text-lg font-light mb-4 block">{L.referralCode}</label>
                                    <div className="flex items-center">
                                        <input
                                            className="bg-indigo-700 border-0 input rounded-xl rounded-r-0 text-white w-100"
                                            style={{maxWidth: "226px"}}
                                            type="text"
                                            autoComplete="off"
                                            placeholder={L.referralCode}
                                            defaultValue={GetUser.referral_code}
                                            name="code"
                                            id="code"
                                            onChange={onChange}
                                        />
                                        <CopyToClipboard onCopy={onCopy} text={GetUser.referral_code}>
                                            <Button id="btn2" onClick={handleClick} className="h-46 bg-cyan-500 border-0 rounded-l-0 rounded-xl text-lg text-indigo-900">{L.copy}</Button>
                                        </CopyToClipboard>

                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="referral" className="text-gray-300 text-lg font-light mb-4 block">{L.navReferral}</label>
                                    <div className="flex items-center">
                                        <input
                                            className="bg-indigo-700 border-0 input rounded-xl rounded-r-0 text-white w-100"
                                            type="text"
                                            autoComplete="off"
                                            placeholder={`https://app.metafluence.com/register/?referrer=${GetUser.referral_code}`}
                                            defaultValue={`https://app.metafluence.com/register/?referrer=${GetUser.referral_code}`}
                                            name="referral"
                                            id="referral"
                                            onChange={onChange}
                                        />
                                        <CopyToClipboard onCopy={onCopy} text={`https://app.metafluence.com/register/?referrer=${GetUser.referral_code}`}>
                                            <Button id="btn1" onClick={handleClick} className="h-46 bg-cyan-500 border-0 rounded-l-0 rounded-xl text-lg text-indigo-900">{L.copy}</Button>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </Col>
                        </>
                         : '' }
                </Row>
            </Content>
        </Layout>
    </div>
}

export default ReferralLink;