import React from "react";
import { useDispatch, useSelector } from "react-redux";

const Participant = ({L}) => {

    const min = 1;
    const max = 100;

    const handleChange = event => {
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        dispatch({type: 'setFormData', payload: {...formData, attend_time_percent : value}})
    };

    let formData = useSelector ((state) => state.event.formData)
    let dispatch = useDispatch()
    const setFormData = (type, value) => {}


    return (
        <>
            <div className="eform-item">
                <label htmlFor="eventName" className="elabel">{L.participantsLabel}</label>
                <div className="einput">
                    <input
                        className="input eform-input"
                        type="text"
                        defaultValue={formData.participant_count}
                        onChange={(e) => { setFormData('participant_count', e.target.value) }}
                        name="participant"
                    />
                    <p className="einput-txt">{L.participantsText}</p>
                </div>
            </div>

            <div className="eform-item">
                <label htmlFor="eventName" className="elabel">{L.participantsPercentage} (%)</label>
                <div className="einput">
                    <input
                        className="input eform-input"
                        type="number"
                        defaultValue={formData.attend_time_percent}
                        min={0}
                        max={100}
                        name="attend_time_percent"
                        value={formData.attend_time_percent}
                        onChange={handleChange}
                    /> 
                </div>
            </div>
        </>
    )
}

export default Participant;