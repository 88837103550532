import React, {useEffect, useState} from "react";
import {Button, Col, Layout, Radio, Row, Select} from "antd";
import { ToastContainer,toast } from 'react-toastify';
import axiosData from "../../helpers/axiosData";
import {useSelector} from "react-redux";
import { Option } from "antd/lib/mentions";

const { Content } = Layout

const Quiz = () => {
    let L = useSelector (state => state.settings.L);

    const [ApiData, setApiData] = useState([{}]); 
    const [trigger, setTrigger] = useState(0);
    const [errors, setErrors] = useState(0);
    const [editList, setEditList] = useState([]);  
    const [MetahutList, setMetahutList] = useState([]);  
    const [selectedMetahut, setSelectedMetahut] = useState(0);

    const default_option = L.quizDefaultQuestions
    
    const setFormData = (data, index, key, type='text') => {
        
        let newApiData = typeof ApiData[index] != 'undefined' ? ApiData[index] : {} ; 

        if (type === 'text' || type === 'check') { 
    
            if(type === 'check') {
                newApiData[key] =  data ? 1 : 0;
            } else {
                newApiData[key] = data.trim();
            }
            
            if (typeof ApiData[index] != 'undefined') {
                ApiData[index] = newApiData;
            } else {
                ApiData.push(newApiData);
            }
        }  

        setTrigger(trigger+1)
    }

    const changeMetahut = (value) => {
        getAllQuizes(value);
        setSelectedMetahut(value)
    }

    /* ------ AddNewRow ---------*/
    const AddNewRow = () => {
        ApiData.push({level: '', true_variant: '',  question: '', variant_a: '', variant_b: '', variant_c: '', variant_d: ''});
        setTrigger(trigger+1);
    };

    /* ------ getAllQuizes ---------*/
    const getAllQuizes = (metahut_id) => {
        
        setApiData([])
        axiosData.get("/api/_web3/quiz/get-all/?metahut="+metahut_id).then((res) => {
            
            if (res.data.data.length) {
                 setApiData( res.data.data ); 
            } else {
                setApiData([])
                // AddNewRow()
            }

            setTrigger(trigger+1);
       
        })
    }

    /* ------ SaveQuizes ---------*/
    const SaveQuizes = () => {
        
        if (Object.keys(errors).length) {
            return false;
        }
        
        var formData = new FormData();

        for (let i = 0; i <= ApiData.length; i++) {

            if (editList.indexOf(i) === -1) {
                continue;
            }
            let Adata = ApiData[i];

            if (Object.keys(Adata).length > 0) {
                for (let x in Adata) {
                    formData.append(x+'_'+(i+1), Adata[x]); 
                }   
            }
        }
        
        formData.append('metahut', selectedMetahut);        

        axiosData.post(`/api/_web3/quiz/set-quizes/`, formData).then((res) => {
            toast.success(L.quizResSuccess)
        })
        .catch((error)=>
        {
            if(error.response.data.errors) {
                setErrors(error.response.data.errors)
                
                Object.values(error.response.data.errors).map((errorText, i) => {
                    return toast.error(errorText.toString())
                })
              }
              else{
                toast.error(L.quizResError)
            }

        })
    }

    const getMetahuts = (search) => {

        let search_text = search != '' ? "?search="+search : "";

        axiosData.get("/api/_web3/metahuts/filter/recent/0/" + 100 + "/" + search_text).then((res) => {

            if (res.data.data.length) {
                makeOptions( res.data.data );
            } else {
                makeOptions( [] );     
            }
        }).catch(error => {
            console.log('error get data', error)
        })
            
    }

    const makeOptions = (data) => {
        let list = [];

        if (data && data.length) {
                
            list.push(<Option value="0" key="0" selected={true}> {default_option} </Option>)
            data.map((key) => { 
                 list.push(<Option value={key.id} key={key.id}> {key.land_name} </Option>)
            })
        }

        setMetahutList(list)
    }

    useEffect(() => {
        getAllQuizes();
        getMetahuts();
    }, [])

    /* ------ AddNewQuiz ---------*/
    const AddNewQuiz = (quiz, key) => {

        return  (
            <Row gutter={40} className="room-nfts-row card d-flex" key={key}>
                <Col className="formItem col mt-4 py-5" xs={24} sm={12} md={12} lg={12}>
                    <div className="mb-5">
                        <label htmlFor="question" className="text-white text-base mb-4 block">
                            {key + 1}. {L.question}
                        </label>
                        <textarea
                            className="bg-indigo-700 border-0 rounded-md text-white account-textarea w-100"
                            type="text"
                            rows="9"
                            cols="75"
                            defaultValue={quiz.question}
                            name="description"
                            onChange={e => { setFormData(e.target.value, key, 'question') }}
                            maxLength={220} 
                        />
                    </div>
                    <div className="mb-5">
                        <label className="text-white text-base mb-4 block">{L.quizQuestionLevel}</label>
                        <select
                            className={`bg-indigo-700 input select-style rounded-md text-white px-2 py-2 w-100`}
                            onChange={e => { setFormData(e.target.value, key, 'level') }} 
                            defaultValue={quiz.level?quiz.level:1} 
                        > 
                        {[1,2,3].map((elem)=>{
                            return <option value={elem} key={elem} selected={parseInt(quiz.level)==elem?true:false}>{elem}</option>
                        })}
                        </select>
                    </div>
                    <div className="mb-5">
                        <h4 className="text-white text-base mb-4 block">{L.quizRightAnswer}</h4>
                        <Radio.Group value={quiz.true_variant}>
                            <Radio value={'1'} onClick={e => { setFormData(e.target.value, key, 'true_variant') }}  className="text-white text-base mb-4">A</Radio>
                            <Radio value={'2'} onClick={e => { setFormData(e.target.value, key, 'true_variant') }}  className="text-white text-base mb-4">B</Radio>
                            <Radio value={'3'} onClick={e => { setFormData(e.target.value, key, 'true_variant') }}  className="text-white text-base mb-4">C</Radio>
                            <Radio value={'4'} onClick={e => { setFormData(e.target.value, key, 'true_variant') }}  className="text-white text-base mb-4">D</Radio>
                        </Radio.Group>
                    </div>
                </Col>
                <Col className="formItem col mt-4 py-5" xs={24} sm={12} md={12} lg={12}>
                    <div className="mb-5">
                        <label className="text-white text-base mb-4 block">{L.variantA}</label>
                        <input
                            className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style w-100`}
                            type="text"
                            name="variant[]"
                            placeholder={L.quizTypeHere}
                            defaultValue={quiz.variant_a}
                            onChange={e => { setFormData(e.target.value, key, 'variant_a') }} 
                            maxLength={40} 

                        />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="variant_b" className="text-white text-base mb-4 block">
                        {L.variantB}
                        </label>
                        <input
                            className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style w-100`}
                            type="text"
                            name="variant[]"
                            placeholder={L.quizTypeHere}
                            defaultValue={quiz.variant_b}
                            onChange={e => { setFormData(e.target.value, key, 'variant_b') }} 
                            maxLength={40} 
                        />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="variant" className="text-white text-base mb-4 block">
                        {L.variantC}
                        </label>
                        <input
                            className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style w-100`}
                            type="text"
                            name="variant[]"
                            placeholder={L.quizTypeHere}
                            defaultValue={quiz.variant_c}
                            onChange={e => { setFormData(e.target.value, key, 'variant_c') }} 
                            maxLength={40} 
                        />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="variant" className="text-white text-base mb-4 block">
                        {L.variantD}
                        </label>
                        <input
                            className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style w-100`}
                            type="text"
                            name="variant[]"
                            placeholder={L.quizTypeHere}
                            defaultValue={quiz.variant_d}
                            onChange={e => { setFormData(e.target.value, key, 'variant_d') }} 
                            maxLength={40} 
                        />
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-14 pr-14 xs-p-2">

                    <ToastContainer />
                    <div className="flex items-center justify-between">
                        <h1 className="text-white text-5xl font-extralight mb-0">{L.quiz}</h1>
                        <Button 
                            className="float-right border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md"
                            onClick={SaveQuizes}
                            type="button" >{L.send}</Button>
                    </div>

                    <div> <br/> <br/> </div>
                    <div className="flex items-center justify-between">
                        <Select name="accounts" key="1"
                            showSearch
                            placeholder={L.selectOneAccount}
                            optionFilterProp="children"
                            className="custom-select"
                            onChange={changeMetahut}
                            defaultValue={default_option}
                        >
                            {MetahutList}
                        </Select> 
                    </div>

                    {ApiData && ApiData.length ?
                        ApiData.map((quiz, key) => {  return AddNewQuiz(quiz, key); } ) : ''
                    }

                    <div className="text-center">
                        <Button className="border-0 bg-cyan-500 mt-4 text-indigo-900 hover:bg-cyan-400 rounded-md" onClick={AddNewRow}>{L.quizAddQustion}</Button>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default Quiz;