import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {Layout} from "antd";
import "./publicProfile/Profile.css";
import Intro from "./publicProfile/Intro";
import Performance from "./publicProfile/Performance";
import Assets from "./publicProfile/Assets";
import Badges from "./publicProfile/Badges";
import axiosData from "../../helpers/axiosData";
import { redirectTo } from "../../config/CoreMethods";
import { useSelector } from "react-redux";

const { Content } = Layout

export default function  PublicProfile() {

    let { username } = useParams();
    let L = useSelector (state => state.settings.L);

    const[UserData, setUserData] = useState({});
        
    /* ------ getAllQuizes ---------*/
    const getUserData = (username) => {

        axiosData.get("/api/_web3/user/public-profile/"+username).then((res) => {
            
            if (res.data && res.data.success) {
                 setUserData( res.data.data ); 
            } else {
                redirectTo('/')
            }
        })
    }
    
    useEffect(() => { 
        getUserData(username);  
    }, [username]);
    
    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10">
                    <Intro GetUser={UserData} />
                    <div className="flex items-start dashboard mb-8">
                        <div className="content-left">
                            <Performance GetUser={UserData} L={L} />
                        </div>
                        <div className="flex flex-col content-right">
                            <Assets GetUser={UserData}/>
                            <div className="line-x"></div>
                            <Badges GetUser={UserData} L={L} />
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

