import React, {useState, useEffect} from "react";
import {Button, Layout, Table, Row, Col, message, Input} from "antd";
import {PATH} from "../../../config";
import axiosData from "../../../helpers/axiosData";
import NumberFormat from "react-number-format";
import ReferralsSidebar from "./ReferralsSidebar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const { Content } = Layout

const InfluenceNetwork = () => {

    let L = useSelector (state => state.settings.L);
    const DefaultAvatar = PATH+'avatar.png';
    const Traveler = PATH+'traveler.svg';
    const Resident = PATH+'resident.svg';
    const Metapreneur = PATH+'metapreneur.svg';
    const pageSize = 20;

    const [messageApi, contextHolder] = message.useMessage();
    const [UserData, setUserData] = useState([]);
    const [LevelData, setLevelData] = useState({1:0, 2:0, 3:0});
    const [packages, setPackages] = useState({turnover:0});
    const [SummaryData, setSummaryData] = useState({soto:[0,0], meto:[0,0],busd:[0,0]});
    const [UserDataWallet, setUserDataWallet] = useState([]);
    const [searchVisible, setSearchVisible] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    const Levels = {1 : 'Referral', 2: "Sub-Referral (Lv2)", 3: "Sub-Referral (Lv3)"}
    const Statuses = {
        1 : {name: L.traveler, icon : Traveler},
        2 : {name: L.resident, icon : Resident},
        3 : {name: L.metapreneur, icon : Metapreneur},
    }

    const columns = [
        {
            title: (
                <>
                    <div className="w-100 flex items-center justify-between gap-x-2">
                        {searchVisible ?
                            <Input size="large" placeholder="Search by username..." className="input input-search-table text-white"
                                onChange={(e) => searchItems(e.target.value)}
                                   defaultValue={searchInput}
                            /> :
                            <span className="uppercase">{L.referral}</span>
                        }
                        <Button type="link" className="px-2" onClick={() => clearSearchFilter()}>
                            <i className={`text-cyan-500 icon-${searchVisible ? 'close' : 'search'}`}></i>
                        </Button>
                    </div>
                </>
            ),
            width: 200,
            dataIndex: 'username',
            key: 'username',
            fixed: 'left',
        },
        {
            title: L.signUpDate,
            width: 200,
            dataIndex: 'date',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            key: 'date',
        },
        {
            title: L.depth,
            dataIndex: 'depth',
            key: 'depth',
            width: 200,
        },
        {
            title: L.status,
            dataIndex: 'status',
            key: 'status',
            width: 200,
        },
    ];

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput != '' && searchInput.length > 0) {
            const filterData = UserData.referrals.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filterData)
        } else {
            setFilteredResults(UserData)
        }
    }

    const clearSearchFilter = () => {
        setSearchVisible(!searchVisible)
        setSearchInput('')
        setFilteredResults(UserData)
    }

    const dataReferral = (UserData) => {

        let list = [];
        if (searchInput.length > 1) {
            for (let u in filteredResults) {
                let user = filteredResults[u];

                list.push (
                    {
                        key: user.id,
                        username: (
                            <div className="flex items-center gap-x-3">
                                <span>{+u + 1}. </span>
                                <div className="relative avatar_box"
                                     style={{backgroundColor: user.bgcolor, width: "30px", height: "30px", minWidth: "30px"}}
                                >
                                    <img src={user.avatar} onError={(elem) => { elem.target.src=DefaultAvatar }}  width="100%" height="100%" className="avatar_img" />
                                </div>
                                <Link to={user.name!='' && user.name!=null ? "/user/"+user.name: '#'}>
                                    <span style={{color: "#00CCFF", maxWidth: "130px"}} className="text-wrap block">@{user.name != '' ? user.name : user.username}</span>
                                </Link>
                            </div>
                        ),
                        date: user.date,
                        depth: (
                            <div className="flex flex-col">
                                <span>{Levels[user.level]}</span>
                                <div className="text-xs">{L.invitedBy} 
                                    <Link to={user.ref_username!='' && user.ref_username!=null ? "/user/"+user.ref_username: '#'}>
                                        <span style={{color: "#00CCFF"}}> @{user.ref_username}</span>
                                    </Link>
                                </div>
                            </div>
                        ),
                        status: (
                            <div className="flex items-center gap-x-3">
                                <img src={PATH + 'ranks/' + user.have_resident + '/' + user.rank+'.png'} style={{width: '50px', marginRight: "-10px"}} />
                                <span>{Statuses[user.have_resident].name}</span>
                            </div>
                        ),
                    },
                )
            }
        } else {
            if (UserData && UserData.referrals && UserData.referrals.length) {
                for (let u in UserData.referrals) {
                    let user = UserData.referrals[u];

                    list.push (
                        {
                            key: user.id,
                            username: (
                                <div className="flex items-center gap-x-3">
                                    <span>{+u + 1}. </span>
                                    <div className="relative avatar_box"
                                         style={{backgroundColor: user.bgcolor, width: "30px", height: "30px", minWidth: "30px"}}
                                    >
                                        <img src={user.avatar} onError={(elem) => { elem.target.src=DefaultAvatar }}  width="100%" height="100%" className="avatar_img" />
                                    </div>
                                    <Link to={user.name!='' && user.name!=null ? "/user/"+user.name: '#'}>
                                        <span style={{color: "#00CCFF", maxWidth: "130px"}} className="text-wrap block">@{user.name != '' ? user.name : user.username}</span>
                                    </Link>
                                </div>
                            ),
                            date: user.date,
                            depth: (
                                <div className="flex flex-col">
                                    <span>{Levels[user.level]}</span>
                                    <div className="text-xs">{L.invitedBy} 
                                        <Link to={user.ref_username!='' && user.ref_username!=null ? "/user/"+user.ref_username: '#'}>
                                            <span style={{color: "#00CCFF"}}> @{user.ref_username}</span>
                                        </Link>    
                                    </div>

                                </div>
                            ),
                            status: (
                                <div className="flex items-center gap-x-3">
                                    <img src={PATH + 'ranks/' + user.have_resident + '/' + user.rank+'.png'} style={{width: '50px', marginRight: "-10px"}} />
                                    <span>{Statuses[user.have_resident].name}</span>
                                </div>
                            ),
                        },
                    )
                }
            }
        }

        return list;
    };


    const getUserData = () => {

        axiosData
            .get('/api/_web3/user/referral/statictics')
            .then((res) => {
                if ( res) {
                    let data = res.data;
                    setUserData(data);
                    setLevelData(data.summary)
                    setPackages(data.package)
                    setSummaryData(res.data.total)
                    setUserDataWallet(res.data.list.list)
                }
            })
            .catch((error) => {
                console.log('status error: ', error)
            })
    }

    useEffect(() => {
        getUserData()
    }, [])

    useEffect(() => {
        searchItems(searchInput)
    },[searchInput])

    return <div className="container mx-auto pt-162 min-h-calc-head h-full">
        <Layout className="h-full settings-layout">
            <ReferralsSidebar />
            {contextHolder}
            <Content className="pt-8 pl-10 pr-10 settings-content">
                <h3 className="text-white text-xl font-bold settings-title">{L.navNetwork}</h3>
                <p className="font-light mb-0">{L.infNetworkDesc}</p>
                <Row gutter={44} className="mt-16">
                    <Col xs={24} sm={24} md={12} lg={8} className="mb-4">
                        <div className="flex items-center gap-x-4 bg-indigo-900 rounded-xl px-6 py-5">
                            <div className="bg-gray-300 px-5 py-4 referral-stats-icon">
                                <i className="icon-user-angle-right text-2xl text-indigo-900"></i>
                            </div>
                            <div className="text-gray-light">
                                <span className="font-bold text-3xl">
                                    <NumberFormat
                                        value={LevelData[1]}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={(value) =><>{value}</>}
                                    />
                                </span>
                                <h4 className="text-base font-light text-gray-light mb-0">
                                    {L.navReferrals}
                                </h4>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} className="mb-4">
                        <div className="flex items-center gap-x-4 bg-indigo-900 rounded-xl px-6 py-5">
                            <div className="bg-gray-300 px-5 py-4 referral-stats-icon">
                                <i className="icon-people text-2xl text-indigo-900"></i>
                            </div>
                            <div className="text-gray-light">
                                <span className="font-bold text-3xl">
                                    <NumberFormat
                                        value={LevelData[2]}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={(value) =><>{value}</>}
                                    />
                                </span>
                                <h4 className="text-base font-light text-gray-light mb-0">
                                    {L.subRefLv2}
                                </h4>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} className="mb-4">
                        <div className="flex items-center gap-x-4 bg-indigo-900 rounded-xl px-6 py-5">
                            <div className="bg-gray-300 px-5 py-4 referral-stats-icon">
                                <i className="icon-people text-2xl text-indigo-900"></i>
                            </div>
                            <div className="text-gray-light">
                                <span className="font-bold text-3xl">
                                    <NumberFormat
                                        value={LevelData[3]}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={(value) =><>{value}</>}
                                    />
                                </span>
                                <h4 className="text-base font-light text-gray-light mb-0">
                                    {L.subRefLv3}
                                </h4>
                            </div>
                        </div>
                    </Col>
                </Row>
                <h3 className="text-gray-300 text-lg font-light mt-16">{L.myRef} {searchInput.length > 1 && filteredResults.length}</h3>
                { UserData ?
                    <Table
                        className="referral-table mt-9"
                        columns={columns}
                        pagination={dataReferral(UserData).length > pageSize && {pageSize}}
                        dataSource={dataReferral(UserData)}
                        scroll={{
                            x: 800,
                        }}
                    />
                    : ''}
            </Content>
        </Layout>
    </div>
}

export default InfluenceNetwork;