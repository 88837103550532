import React, {useState} from "react";
import {Button, Modal} from "antd";
import {Link} from "react-router-dom";
import {useDispatch, useSelector } from 'react-redux';
import { ClaimSelecteds, ClearSelectedRects } from "../../redux/actions/landActions";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";


const BuySelectedPlots = ({L}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    let MapSelectedBarVisible = useSelector ((state) => state.land.MapSelectedBarVisible)
    let dispatch = useDispatch();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        dispatch(ClearSelectedRects())
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        
        <>
        <Modal footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} className="modal">
            <p className="text-center text-white text-lg mb-0">{L.selectPlotRemoveModalTxt1}</p>
            <p className="text-center text-white text-lg">{L.selectPlotRemoveModalTxt2}</p>
            <div className="flex items-center justify-center gap-x-4 mt-10">
                <Button onClick={() => handleCancel()} className="min-w-24 border-0 bg-indigo-600 text-gray-300 hover:bg-indigo-800 rounded-md">{L.selectPlotRemoveModalBtnCancel}</Button>
                <Button onClick={() => handleOk()} className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">{L.selectPlotRemoveModalBtnOk}</Button>
            </div>
        </Modal>
        { MapSelectedBarVisible[0] ? 
        <div className="py-4 bg-indigo-900 fixed z-5 w-full bottom-bar" id="bottom-bar">
            <div className="flex justify-between items-center container mx-auto px-4 bottom-bar-info flex-wrap gap-y-2 gap-x-4">
                <span>{MapSelectedBarVisible[0]} {L.countSelectPlotTxt}</span>
                <div className="flex items-center gap-x-6">
                    <div className="flex items-center gap-x-2">
                        <Button type="primary" onClick={()=>showModal()} className="min-w-24 border-0 bg-indigo-600 text-gray-300 hover:bg-indigo-800 rounded-md">{L.mapFooterBtnCancel}</Button>
                        {MapSelectedBarVisible[1]==1 ?
                            // <Link to={ROUTES_CONFIG.buyPlots.path}>
                            //     <Button type="primary" className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">Buy</Button>
                            // </Link>
                            <Link to={ROUTES_CONFIG.getMapLand.path}>
                                <Button type="primary" className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">{L.mapFooterBtnBuy}</Button>
                            </Link>
                            :  <Button type="primary" onClick={()=>dispatch(ClaimSelecteds())} className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">{L.mapFooterBtnClaim}</Button>
                        }
                    </div>
                </div>
            </div>
        </div>
        : "" }
        </>
    )
}

export default BuySelectedPlots;