import React from "react";
import { Layout } from "antd";
import DiscoveryFilter from "./DiscoveryFilter";
import DiscoveryItems from "./DiscoveryItems";
import { useSelector } from "react-redux";

const {Content} = Layout;

const DiscoveryOverview = () => { 
    let L = useSelector(state => state.settings.L);

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10 sm:pr-10">
                    <DiscoveryFilter />            
                    <DiscoveryItems title={L.discoveryPopular} type="popular" limit="4" image="popular.svg" />
                    <DiscoveryItems title={L.discoveryFeatured} type="featured" limit="4" image="featured.svg" />
                    <DiscoveryItems title={L.discoveryRate} type="rate" limit="4" image="rated.svg" />
                    <DiscoveryItems title={L.discoveryRecent} type="recent" limit="4" image="recently.svg" />

                </Content>
            </Layout>
        </div>
    )
}

export default DiscoveryOverview;