import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const NotifyAddNetwork = (text_) => { 
    toast.warn(text_, { // L.WrongNetworkDetected
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
})};

export const installMetamask = () => toast.warn('Please install Metamask extension', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
});

export const SuccessStake = (notification_) => {
    
    toast.success(notification_, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        toastId: 'stakeSuccess',
    });
}

export const claimUnstakeNotify = (txt) => {
    toast.success(txt, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });
}

export const useDesktop = () => {
    toast.warn('Use desktop version', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        limit: 1
    });
}
