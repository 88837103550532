import React, { Component } from "react";
import axios from "axios";
import MetahutCard from "../../components/Metahut";
import {Link, Navigate} from "react-router-dom";
import { connect } from "react-redux";
import axiosData from "../../../../helpers/axiosData";
import {Button, Col, Layout, Row } from "antd";
import {ToastContainer} from "react-toastify";
import SelectSearch from "../../../custom/SelectSearch";
import Sidebar from "../Sidebar";

const { Content } = Layout;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needTo: axios.create({
        withCredentials: true,
        baseURL: "https://app.metafluence.com/api/v1/public",
        mode: "cors",
        rooms: 0,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      }),

      metahuts: [],
      loading: true,
    };
  }

  changeAccount = (val) => {
 
    this.state.needTo
      .get("/api/_web3/metahuts?user_id="+val)
      .then((res) => {
        this.setState({
          metahuts: res.data,
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        // alert(error);
      });


    console.log('changeAccount', val)
    return false;
  }


  componentDidMount() {
    
    this.changeAccount('')
    // this.sd()
    axiosData
        .get("/api/_web3/rooms")
        .then((res) => {
          this.setState({
            rooms: res.data.length,
          });
          // console.log('res', res.data.length, this.state.rooms)
        })
        .finally(() => this.setState({ loading: false }));

  }

  async componentDidUpdate() {
    if (!this.state.metahuts.length) {
      // console.log('this.state.metahuts', this.state.metahuts)
      // problem burdadi  
      // await this.setState({
      //       loading: true
      //   })
    }
  }

  deleteMetahut(id, e) {
    this.state.needTo.post(`/api/_web3/metahut/${id}/delete`).then((res) => {
      e.target.closest(".nft_item").remove();
    });
  }

  render() {
    if(this.props.user){
      return (
          <div className="container mx-auto pt-162 min-h-calc-head h-full">
              <Layout className="bg-transparent h-full">
                  <Sidebar />
                  <Content className="pt-8 pb-8 pl-14 pr-0 settings-content"><ToastContainer />
                      <div className="pr-4 py-8">
                          
                          <SelectSearch changeAccount={this.changeAccount}/> 
                          
                          <div className="md-mt-0 sm-px-0 flex items-center justify-between">
                              <h1 className="text-white text-5xl font-extralight mb-0">Metahuts</h1>
                              {this.state.rooms != undefined && this.state.rooms > 0 ?
                                  <Link
                                      to="/admin/metahut/create"
                                      className="float-right"
                                  >
                                      <Button type="link" className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">Create</Button>
                                  </Link> : ''
                                  // <Button className="float-right border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md" onClick={() => {
                                  //     toast.error("OOPS! You don't have any rooms.")
                                  // }}>Create</Button>
                              }
                          </div>
                          <div className="lawyer-details_header mt-4">
                              {console.log('this.state.metahuts', this.state)}
                              {this.state.loading  ?
                                  <div className="flex items-center gap-x-4 mt-5">
                                      <h1 className="text-white text-2xl mb-0">Loading</h1>
                                      <div className="loading-spinner"></div>
                                  </div> :
                                  this.state.rooms != undefined && this.state.rooms > 0 ?
                                  this.state.metahuts.length > 0 ?
                                      <Row gutter={26} className="grid-container mt-5">
                                          {this.state.metahuts.map((item, i) => {
                                              return (
                                                  <Col xs={24} sm={12} md={12} lg={8} key={i} className="gutter-row py-5 grid-item">
                                                      <MetahutCard metahut={item} />
                                                  </Col>
                                              );
                                          })}
                                      </Row> :

                                      <div className="mt-9 bg-indigo-900 px-8 py-12 w-full flex items-center justify-center rounded-xl">
                                          <p className="text-xl text-white font-medium mb-0">You don't have any metahuts. Create metahut.</p>
                                      </div>
                                      :
                                      <div className="mt-9 bg-indigo-900 px-8 py-12 w-full flex flex-col justify-center items-center gap-y-4 rounded-xl">
                                          <p className="text-xl text-white font-medium mb-0">You don't have any rooms.</p>
                                          <Link to="/metahuts/new-metahut" className="bg-cyan-500 border-0 rounded-2xl px-6 py-3 text-indigo-900 text-base">Get rooms</Link>
                                      </div>
                              }
                          </div>
                      </div>
                  </Content>
              </Layout>
          </div>

      );
    }else{
      return <Navigate to={'/admin/metahut'} />
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(Index);