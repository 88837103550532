const INITIAL_STATE = { 
    ShowHideSelectedBar : [0,0],
    SelectedLandCount : 1,
    PayErrorLoadingText: '',
    ShowPayErrorLoading: 0,
    LandBuyed: false,
    TrigMyCollection: 0,
    
    
    LaunchpadPlotLimit: "-",
    MyLands : [],
    MyLandsLoading : true,
    MapSelectedBarVisible: [0,0],
    LandBuyingLoading: 0,
    LandBuyingLoadingMessage : '',
    ShowHideClaimLoading: 0,
    ShowHideClaimLoadingMessage: 'Waiting land checking ...',
    OpenLandOnMap: {id:0, type:0},
    IsWhiteList: 0,
    LandSaleIsOpen: 0,
    ProdVersion : 0,
    T: 0,
    LandCountForBuy : 0,
    PaymentMethod : 0,
}


const landReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) { 

       
        case "SetLandBuyingLoading" : return {...state, LandBuyingLoading: action.payload, LandBuyingLoadingMessage: (typeof action.message != 'undefined' ? action.message : 'Error Occured')}; 
        case "SetShowHideClaimLoading" : return {...state, ShowHideClaimLoading: action.payload, ShowHideClaimLoadingMessage: (typeof action.message != 'undefined' ? action.message : "Waiting Claim...")}; 
        case "ResetLand" : return  INITIAL_STATE;

        default : 
            if ( action.type.indexOf('Set') > -1) {
                state[action.type.replace('Set', '')] =  action.payload;
            }
            return {...state, T : state.T+1};    
    }
}


export default landReducer;