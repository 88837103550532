import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {PATH} from "../../../config";
import {selectUser} from "../../../redux/features/userSlice";
import {Skeleton} from "antd";
import Social from "./Social";
import {NavLink} from "react-router-dom";
import {ROUTES as ROUTES_CONFIG} from "../../../config/constants";


const Intro = () => {
    const selector = useSelector
    let GetUser = selector(selectUser);
    let CurrentAccount = selector ((state) => state.metahut.Account)
    
    let L = selector (state => state.settings.L);


    const [AvatarFile, setAvatarFile] = useState(PATH+'avatar.png')
    const Statuses = {
        1: {name: L.traveler, icon: PATH+'traveller.png'},
        2: {name: L.resident, icon: PATH+'resident.svg'},
        3: {name: L.metapreneur, icon: PATH+'metapreneur.svg'}
    }

    useEffect(() => {

        if (GetUser && GetUser.avatar) {
            setAvatarFile(GetUser.avatar)
        }

    }, [GetUser, CurrentAccount, L])

    return (
        <div className="mb-20 intro">
            <div className="bg" 
                style={{background: `url(${PATH + '/intro-bg.png'}) no-repeat center center / cover`}}>
                <div className="flex flex-col items-center gap-y-3 profile">
                    <NavLink to={ROUTES_CONFIG.avatar.path}>
                        <div className="profile-avatar">
                            <div className="avatar-bg" style={{background: `url(${PATH+'avatarBG.png'}) no-repeat center center / cover`}}>
                                <img src={AvatarFile} className="avatar-img"/>
                            </div>
                        </div>
                    </NavLink>
                    <div className="text-white flex flex-col items-center gap-x-3">
                        {GetUser ?
                            <>
                                <span className="font-medium name">{GetUser.name}</span>
                                <span className="font-light username">@{GetUser.username}</span>
                            </>
                            :
                            <>
                                <Skeleton 
                                    active 
                                    paragraph={false} 
                                    style={{width: "auto"}} 
                                    title={{width: "108px", style: {margin: "0", height: "23px"}}}
                                >
                                </Skeleton>
                                <Skeleton 
                                    active 
                                    paragraph={false} 
                                    style={{width: "auto"}} 
                                    title={{width: "63px", style: {margin: "0", height: "23px"}}}
                                >
                                </Skeleton>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-between gap-x-3 info">
                <div className="flex items-center info-left">
                    <div className="flex items-center status">
                        {GetUser ? <img src={`${PATH+('ranks/'+GetUser.status+'/'+GetUser.rank)}.png`} className="status-icon" />
                            : <Skeleton active className="rank-load" avatar={{style: {width: "28px", height: "28px", margin: "10px 0"}}} paragraph={false} title={false}></Skeleton>
                        }
                        {GetUser ? <span className={`color-${GetUser.status} font-bold status-label`}>{Statuses[GetUser.status] && Statuses[GetUser.status].name}</span>
                            : <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "115px", style: {margin: "0 0 0 auto", height: "23px"}}}></Skeleton>
                        }
                    </div>
                    <div className="line-y"></div>
                    <span className="font-medium flex items-center gap-x-2 rank">{L.rank} {GetUser ? GetUser.rank : <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "12px", style: {margin: "0 0 0 auto", height: "24px"}}}></Skeleton>}</span>
                </div>
                <div className="flex items-center info-right">
                    <div className="flex items-center gap-x-3 country">
                        {GetUser ?
                            <>
                                <img src={PATH + `country-flags/${GetUser.country_flag}`} alt="" className="country-icon" />
                                <span className="font-medium country-label">{GetUser.country_name}</span>
                            </>
                            : <>
                                <Skeleton active className="social-load" avatar={{shape: "square", style: {width: "28px", height: "22px"}}} paragraph={false} title={false}></Skeleton>
                                <Skeleton active paragraph={false} style={{width: "auto"}} title={{width: "60px", style: {margin: "0", height: "23px"}}}></Skeleton>
                            </>
                        }
                    </div>
                    <Social />
                </div>
            </div>
        </div>
    )
}

export default Intro;