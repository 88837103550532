import React, { useState, useEffect } from "react";
import './Edit.css'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link, Navigate, NavLink, useParams} from "react-router-dom";
import axiosData from "../../../../helpers/axiosData";
import {Button, Col, Layout, Modal, Row} from "antd";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/features/userSlice";
import Sidebar from "../Sidebar";
const selector = useSelector
const { Content } = Layout


export default function Create() {

  const [loading, setLoading] = useState(true); 
  const [FormTrigger, setFormTrigger] = useState(1); 

  const [errors, setErrors] = useState([]); 
  const [room, setRoom] = useState([]); 
  const [isModalVisible, setIsModalVisible] = useState(false); 

  const [ApiData, setApiData] = useState({}); 
  const [roomType, setRoomType] = useState(0); 
  const [TrigNewRow, setTrigNewRow] = useState(0); 
  
  const { id } = useParams();
  const roomData = [];

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };




  const AddNewNftRow = () => {

    let newNft = {name:'', description:'', image:'', link:'', price:''}
    
    let newApiData = ApiData;

    newApiData['nfts'].push(newNft);
      
    setApiData(newApiData);
    setFormTrigger(FormTrigger+1);
    // setTrigNewRow(new Date().getSeconds());
  };


  const CheckImage = (data, MaxSize) => {
    // console.log('data', data)
    if (typeof data != 'undefined' && data && data['type']) {
      if(data['size'] <= MaxSize && (  data['type'].indexOf('png') != -1 || data['type'].indexOf('jpg') != -1 || data['type'].indexOf('jpeg') != -1 || data['type'].indexOf('gif') != -1) ) { 
        return {data:data}
      }  
    }  
    
    toast.error('Invalid image or size too big from '+(MaxSize/1048576) + 'MB');
    
    return {error : 'Select valid File'}
  };


  const setFormData = (data, key, type='text', extention) => {


    // console.log('setFormData - data, key, type', data, key, type, ' ----- ApiData', ApiData)

      let newApiData = ApiData;

    if (type == 'text') {
      
      newApiData[key] = data;
      setApiData(newApiData);
      setFormTrigger(FormTrigger+1);

    } else if (type == 'image') {

      let check_img = CheckImage(data, 2097152)
      
      if(typeof check_img.data !='undefined' && check_img.data) {
        
        newApiData[key] = data;
        setApiData(newApiData);
        setFormTrigger(FormTrigger+1);

      } else {
        // error
      }
    } else if (type == 'file' &&  data['size'] <= 52485760 &&  data['type'].indexOf(extention) != -1 ) {
      newApiData[key] = data;
      setApiData(newApiData);
      setFormTrigger(FormTrigger+1); 
    }

  }


  const setNftsFormData = (data, index, key, type='text') => {

    let newApiData = ApiData;

    console.log('setNftsFormData', data, 'ApiData', ApiData)

    if (type == 'text') {
      
      newApiData['nfts'][index][key] = data;
      setApiData(newApiData);
      setFormTrigger(FormTrigger+1);

    } else if (type == 'image') {

      let check_img = CheckImage(data, 1048576)

      if (typeof check_img.data !='undefined' && check_img.data) {
        
        newApiData['nfts'][index][key] = data;
        setApiData(newApiData);
        setFormTrigger(FormTrigger+1);

      } else {
        // error
      }


    }   
  }


  
  const setBannersFormData = (index, data, side, file_name) => {

    let newApiData = ApiData; 

    let check_img = CheckImage(data, 2097152)

    if (typeof check_img.data !='undefined' && check_img.data) {
      
      newApiData['room_banners'][index]['image'] = data;
      newApiData['room_banners'][index]['type'] = side;
      newApiData['room_banners'][index]['name'] = typeof file_name != 'undefined' ? file_name : '';
 
      setApiData(newApiData);
      setFormTrigger(FormTrigger+1);

    } else {
      // error
    }
  }


 
  useEffect(() => {
    axiosData
      .get(`/api/_web3/rooms/${id}`)
      .then((res) => {

        let data = {};

        if (Object.keys(res.data).length > 0) {
          for (let x in res.data) {
             data[x] = res.data[x];
          }
        }

        if(res.data.type.id == 3 ) {
          if(!data.nfts || !data.nfts.length) {
            data['nfts'] = []
          }
        }

        if(res.data.type.id == 1) {

          let left = res.data.room_banners.filter((banner) => banner.type === 'left');
          let right = res.data.room_banners.filter((banner) => banner.type === 'right');

          for (let i = 0; i < 4-left.length; i++) {
            res.data.room_banners.push({url:'', type: 'left'});
          }

          for (let i = 0; i < 5-right.length; i++) {
            res.data.room_banners.push({url:'', type: 'right'});
          }
          
          for (let i = 0; i < res.data.room_banners.length; i++) {
            res.data.room_banners[i]['key'] = i;
          }
          
        }

        setApiData(data);
        setRoomType(res.data.type.id)
      })
      .finally(() => setLoading(false));
  }, [id]);


  function editRoom(e) {

    if (errors.length > 0 || Object.keys(errors).length) {
        return false;
    }

      var form_data = new FormData(); 

    if (Object.keys(ApiData).length > 0) {
      for (let x in ApiData) {
        if(x != 'nfts' && x != 'room_banners') {
          form_data.append(x, ApiData[x]); 
        }
      }
    }

    for (let i = ApiData.nfts.length - 1; i >= 0; i--) {
      for (let y in ApiData.nfts[i]) {
          form_data.append(y+'_'+i, ApiData.nfts[i][y]); 
      } 
    }

    for (let i = 0; i < ApiData.room_banners.length; i++) {
      for (let y in ApiData.room_banners[i]) {
          form_data.append('banner_' + y + '_' + i, ApiData.room_banners[i][y]); 
      } 
    }

    setLoading(true);
    
    // console.log('put ApiData' , form_data)

    axiosData
      .post(`/api/_web3/rooms/${id}`, form_data)
      .then((res) => {
        console.log('response', res);

        if (res.status == 200 && res.data.success) {
          toast.success("Updated Successfully!");
        } else {
          toast.error('Error occured');
        }
        // setIsModalVisible(true);
      })
      .catch((error) => {
        console.log('put error: ', error)
        toast.error('Error occured');
        // setErrors(error.response.data.errors); 
      })
      .finally(() => setLoading(false));
  } 

  const GetNewRow = (nft, key) => {

     console.log('nft, key', nft, key)

    return  ( 
      <Row gutter={20} className="room-nfts-row card d-flex" key={key}>
                       
            <Col className="formItem col mt-4 py-5" xs={24} sm={12} md={12} lg={12}>
              <label htmlFor="nft_name" className="text-white text-base mb-4 block">NFT Name</label>
              <input
                  className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("nft_name") ? "validation-border" : null}`}
                  type="text"
                  value={nft.name}
                  onChange={(e) => setNftsFormData(e.target.value, key, 'name')}
                  name="nft_name[]"
                  placeholder="Nft name ..."
                  id="nft_name"
              />
            </Col>  
         
            <Col className="formItem col mt-4 py-5" xs={24} sm={12} md={12} lg={12}>
              <label htmlFor="nft_description" className="text-white text-base mb-4 block">NFT Description</label>
              <input
                  className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("nft_description") ? "validation-border" : null}`}
                  type="text"
                  value={nft.description}
                  onChange={(e) => setNftsFormData(e.target.value, key, 'description')}
                  name="nft_description[]"
                  placeholder="NFT description ..."
                  id="nft_description"
              />
            </Col>  
            
            <Col className="formItem col mt-4 py-5" xs={24} sm={12} md={12} lg={12}>
              <label htmlFor="nft_link" className="text-white text-base mb-4 block">NFT link</label>
              <input
                  className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("nft_link") ? "validation-border" : null}`}
                  type="text"
                  value={nft.link}
                  onChange={(e) => setNftsFormData(e.target.value, key, 'link')}
                  name="nft_link[]"
                  placeholder="NFT link ..."
                  id="nft_link"
              />
            </Col>  
            
            <Col className="formItem col mt-4 py-5" xs={24} sm={12} md={12} lg={12}>
              <label htmlFor="nft_price" className="text-white text-base mb-4 block">NFT price</label>
              <input
                  className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("nft_price") ? "validation-border" : null}`}
                  type="number"
                  value={nft.price}
                  onChange={(e) => setNftsFormData(e.target.value, key, 'price')}
                  name="nft_price[]"
                  placeholder="NFT price ..."
                  id="nft_price"
              />
            </Col>  


            <Col className="fileChoose col py-5" xs={24} sm={12} md={12} lg={12}>
              <div className="currentHeader">
                  <label className="text-white text-base mb-4 block">NFT image</label>
                  {nft.upload_image && <a href={nft.upload_image} target="_blank" rel="noreferrer">See Current</a> }
              </div>
              <input
                  type="file"
                  name="nft_image[]"
                  className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("nft_image") ? "validation-border" : null}`}
                  placeholder="NFT image ..."
                  onChange={(e) =>
                      setNftsFormData(e.target.files[0], key, 'image', 'image')
                  }
              />
            </Col>


          </Row>
          )
  }

  const removePresentationFile = (id) => {
    
    setLoading(true);
    
    axiosData
      .post(`/api/_web3/rooms/remove-presentation/${id}`, {})
      .then((res) => {
        console.log('response', res);

        if (res.status == 200 && res.data.success) {
          toast.success("Succefully Deleted!");
        } else {
          toast.error('Error occured, '+res.data.message);
        }
        // setIsModalVisible(true);
      })
      .catch((error) => {
        console.log('put error: ', error)
        toast.error('Error occured');
        // setErrors(error.response.data.errors); 
      }).then(()=>{
        setLoading(false);
      })

  }


  return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
          <Layout className="bg-transparent h-full">
            <Sidebar />
            <Content className="pt-8 pb-8 pl-14 pr-0 settings-content">
              <div className="pr-4 py-8">
                
                <Modal footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} className="modal">
                  <div className="text-center">
                    <i className="icon-check text-cyan-500 text-5xl mt-4 mb-4 inline-block"></i>
                    <p className="text-white text-lg">
                      Your changes successfully saved.
                    </p>
                  </div>
                  <div className="flex items-center justify-center gap-x-4 mt-10">
                    <Link to="/admin/rooms">
                      <Button onClick={() => handleOk()} className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">Ok</Button>
                    </Link>
                  </div>
                </Modal>

                <Link to="/admin/rooms" className="flex items-center gap-x-4 mb-8 w-fit">
                  <i className="icon-back text-3xl text-white"></i>
                  <span className="text-2xl font-normal text-white">Rooms</span>
                </Link>
                <div className="md-mt-0 sm-px-0 flex items-center justify-between">
                  <h1 className="text-white text-5xl font-extralight mb-0">Edit Room</h1>
                  <Button type="button"
                          className="float-right border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md"
                          onClick={() => editRoom()}
                  >Save</Button>
                </div>
                
                {selector(selectUser) ? 
                    loading ?
                  <div className="flex items-center gap-x-4 mt-5">
                    <h1 className="text-white text-2xl mb-0">Loading</h1>
                    <div className="loading-spinner"></div>
                  </div> :
                  
                  <>

                    <ToastContainer />
                    <Row gutter={26} className="formContainer d-flex mt-4 py-5">

                        <Col className="formItem col" xs={24} sm={12} md={12} lg={12}>
                          <label htmlFor="name" className="text-white text-base mb-4 block">Room Name</label>
                          <input
                              className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("name") ? "validation-border" : null}`}
                              type="text"
                              value={ApiData.name}
                              onChange={(e) => setFormData(e.target.value, 'name')}
                              name="name"
                              placeholder="Room Name"
                              id="name"
                          />
                        </Col>

                        <Col className="formItemColorPicker col-md-3 flex-col gap-y-2" span={6}>
                          <label className="text-white text-base block">Color 1</label>
                          <input
                              type="color"
                              className={`bg-indigo-700 border-0 ${errors.hasOwnProperty("main_led") ? "validation-border" : null}`}
                              value={ApiData.main_led}
                              onChange={(e) => setFormData(e.target.value, 'main_led')}
                              placeholder="Main Led"
                              name="main_led"
                          />
                        </Col>

                        <Col className="formItemColorPicker col-md-3 flex-col gap-y-2" span={6}>
                          <label className="text-white text-base block">Color 2</label>
                          <input
                              type="color"
                              className={`bg-indigo-700 border-0 ${errors.hasOwnProperty("room_led") ? "validation-border" : null}`}
                              value={ApiData.room_led}
                              onChange={(e) => setFormData(e.target.value, 'room_led')}
                              placeholder="Main Led"
                              name="room_led"
                          />
                        </Col>  

                    { roomType == 2 ?
                      <>
                        <Col className="formItem col" xs={24} sm={12} md={12} lg={12}>
                          <label htmlFor="description" className="text-white text-base mb-4 block">Room Description</label>
                          <input
                              className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("description") ? "validation-border" : null}`}
                              type="text"
                              value={ApiData.description?ApiData.description:''}
                              onChange={(e) => setFormData(e.target.value, 'description')}
                              name="description"
                              placeholder="Room Description"
                              id="description"
                          />
                        </Col>


                        {/* <Col className="formItem col" xs={24} sm={12} md={12} lg={12}>
                          <label htmlFor="video_url" className="text-white text-base mb-4 block">Room Video url</label>
                          <input
                              className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("video_url") ? "validation-border" : null}`}
                              type="text"
                              value={ApiData.video_url}
                              onChange={(e) => setFormData(e.target.value, 'video_url')}
                              name="video_url"
                              placeholder="Room video url ..."
                              id="video_url"
                          />
                        </Col> */}

                        
                        <Col className="formItem fileChoose col py-5" xs={24} sm={12} md={12} lg={12}>
                          <div className="currentHeader">
                              <label className="text-white text-base mb-4 block">Logo <small>(dimention 1/2, background transparent or black)</small> </label>
                              {ApiData.room_logo && typeof ApiData.room_logo == 'string' && <a href={ApiData.room_logo} target="_blank" rel="noreferrer">See Current</a> }
                          </div>
                          <input
                              type="file"
                              name="room_logo"
                              className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("room_logo") ? "validation-border" : null}`}
                              placeholder="NFT image ..."
                              onChange={(e) =>
                                  setFormData(e.target.files[0], 'room_logo', 'image')
                              }
                          />
                        </Col>

                        <Col className="formItem col" xs={24} sm={12} md={12} lg={12}>
                          <label htmlFor="audio_url" className="text-white text-base mb-4 block">Room Audio url</label>
                          <input
                              className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("audio_url") ? "validation-border" : null}`}
                              type="text"
                              value={ApiData.audio_url}
                              onChange={(e) => setFormData(e.target.value, 'audio_url')}
                              name="audio_url"
                              placeholder="Room audio url ..."
                              id="audio_url"
                          />
                        </Col>

                            
                        <Col className="formItem fileChoose col py-5" xs={24} sm={12} md={12} lg={12}>
                          <div className="currentHeader">
                              <label className="text-white text-base mb-4 block"> Presentation Files </label>
                              {ApiData.presentations && ApiData.presentations.length ?
                                ApiData.presentations.map(item => {
                                  return <div> 
                                      <a href={item.file} target="_blank" rel="noreferrer">See Current</a>
                                      <a onClick={() => removePresentationFile(item.id)} style={{marginLeft:'100px'}} rel="noreferrer">X</a>
                                    </div>
                                })
                              :""}
                          </div>
                          <input
                              type="file"
                              name="presentation"
                              className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("presentation") ? "validation-border" : null}`}
                              placeholder="NFT image ..."
                              onChange={(e) =>
                                  setFormData(e.target.files[0], 'presentation', 'file', 'pdf')
                              }
                          />
                        </Col>

                        {/* <Col className="formItemColorPicker col-md-3" span={6}>
                            <label className="text-white text-base block">Room Led</label>
                            <input
                                type="color"
                                className={`bg-indigo-700 border-0 ${errors.hasOwnProperty("room_led") ? "validation-border" : null}`}
                                placeholder="Room Led"
                                value={ApiData.room_led}
                                onChange={(e) => setFormData(e.target.value, 'room_led')}
                                name="room_led"
                            />
                        </Col> */}
                      </>
                      : roomType == 3 ?
                      <>

                      {ApiData.nfts && ApiData.nfts.length ?

                          ApiData.nfts.map((nft, key)=>

                            GetNewRow(nft, key)

                        ) : ''}

                      </> : 
                      <>

                        <Col className="formItem fileChoose col py-5" xs={24} sm={12} md={12} lg={12}>
                        
                          { ApiData && ApiData.room_banners.length ?

                            ApiData.room_banners.filter((banner) => banner.type === 'left')
                            .map((rom, key)=>{
                              return <div className="py-5" key={rom.key}>
                                <div className="currentHeader">
                                    <label className="text-white text-base mb-4 block">Banner left side <small>(ratio 1:2)</small> </label>
                                    {rom && typeof rom.full_url == 'string' && <a href={rom.full_url} target="_blank" rel="noreferrer">See Current</a> }
                                </div>
                                <input
                                    type="file"
                                    name="banners[]"
                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("room_banners") ? "validation-border" : null}`}
                                    placeholder="NFT image ..."
                                    onChange={(e) =>
                                        setBannersFormData (rom.key, e.target.files[0], 'left', rom.url)
                                    }
                                />
                          </div> 
                            })
                            :''
                          }
                        
                        </Col>

                        <Col className="formItem fileChoose col py-5" xs={24} sm={12} md={12} lg={12}>
                          { ApiData && ApiData.room_banners ?
                            ApiData.room_banners.filter((banner) => banner.type === 'right')
                            .map((rom, key)=>{
                              return <div className="py-5" key={rom.key}>
                                <div className="currentHeader">
                                    <label className="text-white text-base mb-4 block">Banner right side <small>(ratio 1:2)</small> </label>
                                    {rom && typeof rom.full_url == 'string' && <a href={rom.full_url} target="_blank" rel="noreferrer">See Current</a> }
                                </div>
                                <input type="hidden" name="urls[]" value={rom.url}/>
                                <input
                                    type="file"
                                    name="banners[]"
                                    className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${errors.hasOwnProperty("room_banners") ? "validation-border" : null}`}
                                    placeholder="NFT image ..."
                                    onChange={(e) =>
                                      setBannersFormData (rom.key, e.target.files[0], 'right', rom.url)
                                    }
                                />
                          </div> 
                            })
                            :''
                          } 
                        </Col>


                        

                      </>
                      }




                    </Row>
                    {roomType == 3 ?
                        <div className="text-center">
                          <Button className="border-0 bg-cyan-500 mt-4 text-indigo-900 hover:bg-cyan-400 rounded-md" onClick={AddNewNftRow}> Add New NFT  </Button>
                        </div> : ''
                    }

                  </>
              : <Navigate to={`/admin/rooms/${id}`} />
                }
              </div>
            </Content>
          </Layout>
      </div>
  )

}
