import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import { ROUTES as ROUTES_CONFIG } from './config/constants';
import {basePath} from './config';
import { createBrowserHistory } from "history";
import { Button, Layout, notification } from "antd";
import 'antd/dist/antd.css';
import './app.css'; 
import "./fonts";
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, fetchUser } from './redux/features/userSlice';
import {
    ClaimLoading,
    Home,
    MyNFTs,
    MyMetaHut,
    NewMetahut,
    SuccessModal,
    AdminNftRooms,
    AdminMetahutRooms,
    AdminCreateMetahut,
    AdminEditMetahut,
    AdminEditNftRooms,
    Account,
    Register,
    LoginPage,
    SettingsSecurity,
    FagContainer,
    Profile,
    Quiz
} from './components/pages';
import { setCookie } from './config/CoreMethods';
import  actions  from "./redux/actions/";
import { getRectSelecteds, showHoverPlot, showBuyedPlot } from './config/landMethods';
import { GetPublicOrWhiteListSaleStatus } from './redux/actions/landActions';
import Status from "./components/views/settings/Status";
import Wallet from "./components/views/settings/Wallet";
import Avatars from "./components/views/profile/Avatars";
import Metahuts from "./components/views/profile/Metahuts";
import Lands from "./components/views/profile/Lands";
import GetMapLand from "./components/views/GetMapLand";
import GetDirectLand from "./components/views/GetDirectLand";
import ForgotPass from "./components/views/ForgotPass";
import NewPass from "./components/views/NewPass";
import DiscoveryOverview from "./components/views/DiscoveryOverview";
import Claims from "./components/views/settings/Claims";
import SecurityPrivacy from "./components/views/settings/SecurityPrivacy";
import DiscoveryPopular from "./components/views/DiscoveryPopular";
import DiscoveryTopRated from "./components/views/DiscoveryTopRated";
import DiscoveryFeatured from "./components/views/DiscoveryFeatured";
import DiscoveryRecently from "./components/views/DiscoveryRecently";
import GetMetahut from "./components/views/GetMetahut";
import Marketplace from "./components/views/Marketplace";
import Staking from "./components/views/staking/Staking";
import EventsPage from "./components/views/EventsPage";
import Footer from "./components/footer/Footer";
import InfluenceNetwork from "./components/views/settings/InfluenceNetwork";
import MonthlyPerformance from "./components/views/settings/MonthlyPerformance";
import ReferralLink from "./components/views/settings/Referrals";
import PublicProfile from "./components/views/PublicProfile";
import PublicAvatars from "./components/views/publicProfile/PublicAvatars";
import PublicMetahuts from "./components/views/publicProfile/PublicMetahuts";
import PublicLands from "./components/views/publicProfile/PublicLands";
import HomePage from "./components/views/HomePage";
import Header from "./components/header/Header";
import TermsConditions from "./components/views/TermsConditions";
import PrivacyPolicy from "./components/views/PrivacyPolicy";
import SaftAgreement from "./components/views/SaftAgreement";
import Page404 from "./components/views/Page404";
import ApproveEmail from './components/views/ApproveEmail';
import EventsStep from "./components/views/events/EventsStep";
import EventsSingle from "./components/views/EventsSingle";
import PastEvents from "./components/views/PastEvents";
import MyEvents from "./components/views/MyEvents";
import MyTickets from "./components/views/events/MyTickets";
import EventUpdate from './components/views/events/EventUpdate';
import {baseName} from './config'
import GetLang from './redux/actions/SettingsAction';
import PfpNftAvatar from './components/views/PfpNftAvatar';
import Quests from './components/views/Quests';

const { InitWalletConnect, CheckNetwork, getUserBalance, connectWallet, getMapSettings, OnAccountChanged, OnNetworkChanged, checkBrowser,
    MyLands, RemoveItemFromList, GetWhitelistStatus } = actions;

const history = createBrowserHistory({ basename: baseName });

const App = () => {
    const selector = useSelector
    const dispatch = useDispatch()

    let L = selector(state => state.settings.L);
    let FrontLogin = selector ((state) => state.metahut.FrontLogin);
    

    let user = selector(selectUser)
    const fetchUserData = async (dispatch) => {
        try {
            await dispatch(fetchUser())
        } catch (error) { 
        }
    };

    let LaunchpadPlotLimit = selector((state) => state.land.LaunchpadPlotLimit);
    

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referrer = urlParams.get('referrer');
    const mode = urlParams.get('mode');

    console.log('window.location', window.location.pathname);


    if (typeof referrer == 'string' && /^([0-9a-zA-Z]{4,30})$/.test(referrer)) {
        setCookie('referrer', referrer)
    }

    if (typeof mode == 'string') {
        setCookie('mode', mode)
    }


    /*------------cetin------------------------*/
    const setRectSelected = (elem, line) => {

        elem = elem.target
        let id = elem.id;
        let className = elem.classList.value

        if (className === 'selected-plot') {

            elem.setAttribute('class', 'gen-plot');
            dispatch(RemoveItemFromList(id, 1));

        } else {

            let startClaimMode = parseInt(localStorage.getItem('claimmode'));
            let items = getRectSelecteds()
            let type = 1;

            if (startClaimMode === 1)  {
                if (window.ethereum && FrontLogin) {
                    if (LaunchpadPlotLimit > Object.keys(items).length) {
                        type = 2;
                    } else {
                        notification['warning']({
                            message: 'Error',
                            description: L.startClaimErr,
                        });
                        return false;
                    }
                } else {
                    localStorage.setItem('claimmode', 0);
                }
            }

            if (typeof items[id] == 'undefined') {
                items[id] = {
                    x: elem.getAttribute('x'),
                    y: elem.getAttribute('y'),
                    real_coord: elem.getAttribute('real_coord'),
                    transform: elem.getAttribute('transform')
                }
            }

            elem.setAttribute('class', 'selected-plot');

            dispatch({type: "SetMapSelectedBarVisible", payload: [Object.keys(items).length, type]})
            localStorage.setItem("selectedrects", JSON.stringify(items));

        }

        return true;
    }

    const key = `open${Date.now()}`;
    const close = () => {
        notification.close(key)
    };

    let status = 0;
    let start = 0;

    const mouseUp = (e) => {

        if (start && !status) {
            let className = e.target.classList[0]
            if (className == 'gen-plot' || className == 'selected-plot') {
                setRectSelected(e, 22);
            }
        }
        start = 0
    }

    const mouseDown = (e) => {
        start = 1;
    }

    const touchEnd = function (e) {
        let className = e.target.classList[0]

        if (!status && className == 'gen-plot' || className == 'selected-plot') {
            setRectSelected(e, 11);
        }
        start = status = 0;
    }

    const touchMove = function (e) {
        status = 1;

        if (document.getElementById('tooltip-area')) {
            document.getElementById('tooltip-area').innerHTML = '';
        }
    }

    const mouseMove = function (e) {
        if (start === 1) {
            status = 1;
        } else {
            status = 0
        }
    }

    const Events = {
        mouseUp: mouseUp,
        mouseDown: mouseDown,
        mouseMove: mouseMove,
        touchEnd: touchEnd,
        touchMove: touchMove,
    }

    useEffect(() => {

        if (user && user.id) {
            dispatch({type : 'SetBackLogin', payload:true});
        } else {
            dispatch(fetchUserData);
            dispatch({type : 'SetBackLogin', payload:false})
        }   

        dispatch( GetLang() )

        dispatch(InitWalletConnect(() => {
            dispatch(CheckNetwork((chainTrue) => {
                if (chainTrue) {
                    dispatch(getUserBalance(state => {
                        dispatch(getMapSettings())
                        dispatch(GetWhitelistStatus())
                        dispatch(GetPublicOrWhiteListSaleStatus());

                        dispatch(MyLands());
                    }))
                }
            }))
        }))

        dispatch(OnAccountChanged(() => {
            dispatch(InitWalletConnect(() => {
                dispatch(CheckNetwork((chainTrue) => {
                }))
            }))
        }))
        dispatch(OnNetworkChanged(() => {
            dispatch(InitWalletConnect(() => {
                dispatch(CheckNetwork((chainTrue) => {
                }))
            }))
        }))

        dispatch(checkBrowser());

        document.addEventListener('mouseleave', function (e) {
            if (document.getElementById('tooltip-area')) {
                document.getElementById('tooltip-area').innerHTML = '';
            }
        })

        document.addEventListener('mousemove', function (e) {
            let className = e.target.classList[0]

            if (document.getElementById('tooltip-area')) {
                document.getElementById('tooltip-area').innerHTML = '';
            }

            if (className == 'gen-plot') {
                showHoverPlot(e.target)
            }

            if (className == 'buyed-plot') {
                showBuyedPlot(e.target)
            }
        })

    }, [user])


    // <Router history={history} basename={process.env.REACT_APP_ENVIRONMENT === 'development' ? '/' : '/'}>

    const openNotification = () => {
        const btn = (
            <Button type="primary" size="small" onClick={() => connectWallet()}>{L.connect}</Button>
        );
        notification.open({
            message: L.connectWalletMsgTtl,
            description: L.connectWalletMsgTxt,
            btn,
            key,
            onClose: close,
        });
    };

    return (
        <div className={`${window.location.pathname == '/pfpnft-avatar' ? 'bg-pfp' : 'bg-indigo-800'} font-sans text-xs text-white min-h-screen`}>
            <Router history={history} basename={baseName}>
                <Header accountPage={ROUTES_CONFIG.account.path} securityPage={ROUTES_CONFIG.settingsSecurity.path} />
                <ClaimLoading/>
                <Routes>
                    <Route exact path={ROUTES_CONFIG.home.path}
                           element={<Home touchEnd={touchEnd} mouseUp={mouseUp} Events={Events}/>}/>
                    <Route path={ROUTES_CONFIG.myNFTs.path} element={<MyNFTs openNotification={openNotification}/>}/>
                    <Route path={ROUTES_CONFIG.myMetahuts.path} element={<MyMetaHut/>}/>
                    <Route path={ROUTES_CONFIG.adminNftRooms.path} element={<AdminNftRooms/>}/>
                    <Route path={ROUTES_CONFIG.fag.path} element={<FagContainer/>}/>
                    <Route path={ROUTES_CONFIG.adminMetahutRooms.path} element={<AdminMetahutRooms/>}/>
                    <Route path={ROUTES_CONFIG.adminCreateMetahut.path} element={<AdminCreateMetahut/>}/>
                    <Route path={ROUTES_CONFIG.adminEditMetahut.path} element={<AdminEditMetahut/>}/>
                    <Route path={ROUTES_CONFIG.adminEditNftRooms.path} element={<AdminEditNftRooms/>}/>
                    <Route path={ROUTES_CONFIG.newMetahut.path} element={<NewMetahut/>}/>
                    <Route path={ROUTES_CONFIG.account.path} element={<Account/>}/>
                    <Route path={ROUTES_CONFIG.register.path} element={<Register/>}/>
                    <Route path={ROUTES_CONFIG.loginPage.path} element={<LoginPage/>}/>
                    <Route path={ROUTES_CONFIG.settingsSecurity.path} element={<SettingsSecurity/>}/>
                    <Route exact path={ROUTES_CONFIG.referralLink.path} element={<ReferralLink/>}/>
                    <Route exact path={ROUTES_CONFIG.influenceNetwork.path} element={<InfluenceNetwork/>}/>
                    <Route exact path={ROUTES_CONFIG.monthlyPerformance.path} element={<MonthlyPerformance/>}/>
                    <Route path={ROUTES_CONFIG.status.path} element={<Status/>}/>
                    <Route path={ROUTES_CONFIG.wallet.path} element={<Wallet/>}/>
                    <Route path={ROUTES_CONFIG.claims.path} element={<Claims/>}/>
                    <Route path={ROUTES_CONFIG.securityPrivacy.path} element={<SecurityPrivacy/>}/>
                    <Route path={ROUTES_CONFIG.landSuccess.path} element={<SuccessModal/>}/>
                    <Route path={ROUTES_CONFIG.metahutSuccess.path} element={<SuccessModal/>}/>
                    <Route path={ROUTES_CONFIG.claimSuccess.path} element={<SuccessModal/>}/>
                    <Route path={ROUTES_CONFIG.quiz.path} element={<Quiz/>}/>
                    <Route path={ROUTES_CONFIG.profile.path} element={<Profile/>}/>
                    <Route path={ROUTES_CONFIG.avatar.path} element={<Avatars/>}/>
                    <Route path={ROUTES_CONFIG.metahuts.path} element={<Metahuts/>}/>
                    <Route path={ROUTES_CONFIG.lands.path} element={<Lands/>}/>
    
                    <Route path={ROUTES_CONFIG.publicAvatars.path} element={<PublicAvatars/>}/>
                    <Route path={ROUTES_CONFIG.publicMetahuts.path} element={<PublicMetahuts/>}/>
                    <Route path={ROUTES_CONFIG.publicLands.path} element={<PublicLands/>}/>
                    
                    <Route path={ROUTES_CONFIG.getMapLand.path} element={<GetMapLand/>}/>
                    <Route path={ROUTES_CONFIG.getDirectLand.path} element={<GetDirectLand/>}/>
                    <Route path={ROUTES_CONFIG.forgotPass.path} element={<ForgotPass/>}/>
                    <Route path={ROUTES_CONFIG.newPass.path} element={<NewPass/>}/>
                    <Route exact path={ROUTES_CONFIG.discoveryOverview.path} element={<DiscoveryOverview/>}/>
                    <Route exact path={ROUTES_CONFIG.discoveryPopular.path} element={<DiscoveryPopular/>}/>
                    <Route exact path={ROUTES_CONFIG.discoveryTopRated.path} element={<DiscoveryTopRated/>}/>
                    <Route exact path={ROUTES_CONFIG.discoveryFeatured.path} element={<DiscoveryFeatured/>}/>
                    <Route exact path={ROUTES_CONFIG.discoveryRecently.path} element={<DiscoveryRecently/>}/>
                    <Route path={ROUTES_CONFIG.getMetahut.path} element={<GetMetahut/>}/>
                    <Route path={ROUTES_CONFIG.events.path} element={<EventsPage/>}/>
                    <Route path={ROUTES_CONFIG.eventsStep.path} element={<EventsStep/>}/>
                    <Route path={ROUTES_CONFIG.eventsUpdate.path} element={<EventUpdate/>}/>

                    <Route path={ROUTES_CONFIG.eventsSingle.path} element={<EventsSingle/>}/>
                    <Route path={ROUTES_CONFIG.pastEvents.path} element={<PastEvents/>}/>
                    <Route path={ROUTES_CONFIG.myEvents.path} element={<MyEvents/>}/>
                    <Route path={ROUTES_CONFIG.myTickets.path} element={<MyTickets/>}/>
                    <Route path={ROUTES_CONFIG.marketplace.path} element={<Marketplace/>}/>
                    <Route path={ROUTES_CONFIG.staking.path} element={<Staking/>}/>
                    <Route path={ROUTES_CONFIG.homePage.path} element={<HomePage/>}/>
                    <Route path={ROUTES_CONFIG.publicProfile.path} element={<PublicProfile/>}/>
                    <Route path={ROUTES_CONFIG.terms.path} element={<TermsConditions/>}/>
                    <Route path={ROUTES_CONFIG.privacy.path} element={<PrivacyPolicy/>}/>
                    <Route path={ROUTES_CONFIG.saft.path} element={<SaftAgreement/>}/>
                    <Route path={ROUTES_CONFIG.approveEmail.path} element={<ApproveEmail/>}/>
                    <Route path="*" element={<Page404/>} />
                    <Route path={ROUTES_CONFIG.pfpNftAvatar.path} element={<PfpNftAvatar/>} />
                    <Route path={ROUTES_CONFIG.quests.path} element={<Quests/>} />
                </Routes>
                <Footer/>

            </Router>
        </div>
    )
}

export default App;
  