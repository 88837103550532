import React, {useEffect, useState} from "react";
import {Button, Layout, Modal} from "antd";
import SettingsSidebar from "./SettingsSidebar";
import {PATH} from "../../../config";
import NumberFormat from "react-number-format";
import axiosData from "../../../helpers/axiosData";
import { fetchUser, login } from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { redirectTo, getAppSettings } from "../../../config/CoreMethods";

import JsxParser from 'react-jsx-parser';

const {Content} = Layout;

const SecurityPrivacy = () => {

    const dispatch = useDispatch();
    let GetUserData = useSelector ((state) => state.user.user);
    let L = useSelector (state => state.settings.L);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [usernameExists, setUsernameExists] = useState(false);
    const [Error, setError] = useState(false);
    const [UserData, setUserData] = useState({})
    const [FormTrigger, setFormTrigger] = useState(1);
    const [errors, setErrors] = useState([]);
    const [changeUsernameCost, setChangeUsernameCost] = useState(0);
 
    const sendConfirmation = () => {
            
        axiosData.post("/api/_web3/user/send-activation/").then((res) => {
            if (res.data.success) {
                toast.success(L.approveSuccessAcc)
            } else {
                toast.error(L.approveErrorAcc)
            }
        })
    }
    const handleOk = () => { 
        
        setIsModalVisible(false); 
        setFormData(1, 'delete_user');
        updateSettings(()=>{
            redirectTo('/');
        });
    }

    useEffect(()=>{
        getAppSettings((data) => {
            setChangeUsernameCost( data.username_change_price );
        })
    }, [])

    const handleCancel = () => { setIsModalVisible(false) }

    // add form data to state
    const setFormData = (data, key, type='text') => {

        let newUserData = UserData;

        if (type == 'text' || type == 'check') {

            if(type == 'check') {

                newUserData[key] =  data ? 1 : 0;
            } else {
                newUserData[key] = data;
            }

            setUserData(newUserData);
            setFormTrigger(FormTrigger+1);
        }
    }
    

    let Timeout;
    
    // Setting username
    const setUsername = (username) => {

        clearTimeout(Timeout)  
        
        if ( /^([A-Za-z0-9_.]{4,30})$/.test(username) ) {
            setError(false)
        } else { 
            setUsernameExists(false)
            setError(true);

            return false
        }

        if ( username != '') {

            Timeout = setTimeout(function() {
                axiosData
                 .get(`/api/_web3/user/getusername/${username}`)
                 .then((res) => {
                    if (res.data.exists && res.data.exists == '1') {
                        setUsernameExists(true)
                    } else {
                        setFormData(username, 'username')
                        setUsernameExists(false)
                    }
                 })
                .catch((error) => {
                    console.log('get error: ', error)
                })
            }, 300);
        }
    }

    const changePassword = () => {
        console.log("UserData", UserData)
        if (!UserData || !UserData.old_password || UserData.old_password.length == 0) {
            toast.error(L.typeOldPass)
            return false;
        }

        if (!UserData || !UserData.new_password || UserData.new_password.length == 0) {
            toast.error(L.typeNewPass)
            return false;
        }
        
        if ( UserData.new_password != UserData.new_password_repeat ) {
            toast.error(L.newPassRepeatErr)
            return false;
        }
        
        updateSettings()
    }

    const updateSettings = (callback=()=>{}) => {

        if (usernameExists || Error) {
            toast.error(L.typeCorrectUsername);
            return false;
        }

        if (errors.length > 0 || Object.keys(errors).length) {
            return false;
        }   

        let form_data = new FormData; 

        if (Object.keys(UserData).length > 0) {
            for (let x in UserData) {
                if(x != 'nfts' &&  UserData[x] != null) {
                    form_data.append(x, UserData[x]); 
                }
            }
        } else {
            return false;
        }

        console.log('put UserData' , form_data)
        axiosData
         .post(`/api/_web3/user/settings/`, form_data)
         .then((res) => {
             console.log('response', res);

             if (res.data && res.data.success) {                
                 toast.success(L.claimRefSuccess);
                 callback()
            } else {
                toast.error(L.errorOccured)
            }
         })
        .catch((error) => {
            toast.error(error.response.data.message);
        })
    };

    
    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="h-full settings-layout">
                <SettingsSidebar />
                <ToastContainer />

                <Modal footer={false} closable={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} className="modal modal-logo" style={{background: `#1c1a42 url(${PATH+'logo-icon-gray.svg'}) no-repeat center center / 140%`}}>
                    <div className="text-center">
                        <h3 className="font-normal text-lg text-white mb-4">
                            {L.deleteAccountConfirmMsg}
                        </h3>
                        <p className="font-light text-base text-gray-light">
                            {L.deleteAccountConfirmNote}
                        </p>
                    </div>
                    <div className="flex items-center justify-center gap-x-4 mt-12">
                        <Button onClick={() => handleCancel()} className="min-w-24 border-0 bg-gray-200 text-indigo-900 hover:bg-gray-300 rounded-2xl font-large">
                            {L.deleteAccountModalCancel}
                        </Button>
                        <Button onClick={() => handleOk()} className="min-w-24 border-0 bg-pink-300 text-white hover:bg-pink-400 rounded-2xl font-large">
                            {L.deleteAccountModalOk}
                        </Button>
                    </div>
                </Modal>
                <Content className="pt-8 pl-10 pr-10 settings-content">
                    <h3 className="text-xl text-white font-bold mb-8 settings-title">
                        {L.userDropSecurity}
                    </h3>
                    <div>
                        <h3 className="text-gray-300 text-lg font-light mb-8">{L.accountInfo}</h3>
                        <label htmlFor="username" className="text-white text-base font-normal mb-2 inline-block">{L.usernameLabel}</label>
                        <div className="flex items-center gap-x-8 mb-10">
                            <input
                                className={`bg-indigo-700 border-0 input input-security rounded-md text-white ${Error?'input-error' : ''}`}
                                type="text"
                                defaultValue={GetUserData?GetUserData.username:''}
                                name="username"
                                onChange={(e) => {  
                                    return  GetUserData &&  GetUserData.soto_balance  &&  GetUserData.soto_balance > changeUsernameCost ? setUsername(e.target.value) : false }
                                 }
                            />
                            <span className="font-light text-xs text-gray-200">
                                {L.usernameRequiredNote}
                            </span>
                            { usernameExists ? <span className="text-red text-sm">
                                {L.alreadyUsed}
                            </span> : ''}
                        </div>
                    </div>
                    <div className="flex items-center gap-x-2 mb-10">
                        <img src={PATH + 'burn.svg'} alt=""/>
                        <p className="mb-0 text-sm font-light">{L.usernameChangeCostInfo && <>
                                <NumberFormat 
                                    value={changeUsernameCost} 
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    renderText={ (value) =>  <JsxParser jsx={L.usernameChangeCostInfo.replace("_COST_", '<span className="text-cyan-400">'+value+'</span>') } />  } 
                                    />                                 
                        </>}
                        </p>
                    </div>
                    <div className="flex items-center gap-x-6">
                        {GetUserData ?
                            GetUserData.soto_balance > changeUsernameCost ?
                            <Button type="primary" onClick={updateSettings} className="border-0 text-base px-6 font-medium rounded-2xl bg-cyan-500 hover:bg-cyan-400 text-indigo-900">
                                {L.change}
                            </Button>
                            :
                            <>
                                <Button type="primary" disabled="disable" className={`border-0 text-base px-6 font-medium rounded-2xl bg-indigo-600 text-white opacity-50 cursor-not-allowed`}>
                                    {L.change}
                                </Button>
                                <span className="text-red font-light text-xs">
                                    {L.notEnoughSoto}
                                </span>
                            </>
                        : ''
                        }
                    </div>
                    <div className="line-x mt-12 mb-12"></div>
                    <h3 className="text-gray-300 text-lg font-light mb-8">{L.accountSecurity}</h3>
                    <label htmlFor="email" className="text-white text-base mb-2 font-normal block">
                        {L.email}
                    </label>
                    <input
                        className="bg-indigo-700 border-0 input input-security rounded-md text-white"
                        type="text"
                        defaultValue={GetUserData?GetUserData.email:''}
                        name="email"
                        onChange={(e) => setFormData(e.target.value, 'email')}
                    />
                    <div className="flex items-center gap-x-1 mt-10 mb-10">
                        {GetUserData && GetUserData.email_verify != 1 ?
                            <>
                                <img src={PATH + 'profile/warning.svg'} alt=""/>
                                <p className="mb-0 text-xs font-light">{L.emailNotVerified} <span onClick={sendConfirmation} className="text-cyan-400 pointer" style={{cursor:"pointer"}}>{L.sendConfirmation}</span></p>
                            </>
                            :''
                        }
                    </div>
                    <Button onClick={updateSettings} type="primary" className="border-0 text-base px-6 font-medium rounded-2xl bg-cyan-500 text-indigo-900 hover:bg-cyan-400">
                        {L.saveChange}
                    </Button>

                    <div className="line-x mt-12 mb-12"></div>
                    <h3 className="text-gray-300 text-lg font-light mb-8">
                        {L.blockchainConnection}
                    </h3>
                    <div className="flex items-center gap-x-8">
                        <div className="flex items-center gap-x-2">
                            <img src={PATH + 'metamask.svg'} alt=""/>
                            <div>
                                <h4 className="font-bold text-base text-white mb-0">{L.PrivacyPageMetamask}</h4>
                                <span className="font-normal text-sm text-gray-light">
                                    {L.metamaskBtnTxt}
                                </span>
                            </div>
                        </div>
                            
                            {GetUserData ?
                                GetUserData.account !='' && GetUserData.account != null?  
                                <>  
                                    <div className="flex items-center gap-x-2">{GetUserData.account}</div>
                                    <Button type="primary" disabled="disable" className="border-0 text-base px-6 font-medium rounded-2xl opacity-50 cursor-not-allowed bg-indigo-600 text-white">{L.disconnect}</Button>
                                </>
                                : 
                                <Button type="primary" onClick={() => dispatch(login('asdasd'))} className="border-0 text-base px-6 font-medium rounded-2xl bg-cyan-500 text-indigo-900 hover:bg-cyan-400">{L.connect}</Button>
                                :''
                            }
                    </div>
                    <div className="line-x mt-12 mb-12"></div>
                    <h3 className="text-gray-300 text-lg font-light mb-2">{L.resetPassword}</h3>
                    <p className="font-light text-sm">{L.resetPasswordNote}</p>
                    <div className="mt-12">
                        <div className="mb-10">
                            <label htmlFor="oldPass" className="text-white text-base font-normal mb-2 inline-block">{L.oldPassword} <span className="text-red">*</span></label>
                            <div className="flex items-center gap-x-8">
                                <input
                                    className="bg-indigo-700 border-0 input input-security rounded-md text-white"
                                    type="text"
                                    onChange={(e) => setFormData(e.target.value, 'old_password')}
                                    name="oldPass"
                                />
                                <span className="font-light text-xs text-gray-200">
                                    {L.inputPrevPass}
                                </span>
                            </div>
                        </div>
                        <div className="mb-10">
                            <label htmlFor="newPass" className="text-white text-base font-normal mb-2 inline-block">{L.newPassword} <span className="text-red">*</span></label>
                            <div className="flex items-center gap-x-8">
                                <input
                                    className="bg-indigo-700 border-0 input input-security rounded-md text-white"
                                    type="text"
                                    onChange={(e) => setFormData(e.target.value, 'new_password')}
                                    name="newPass"
                                />
                                <span className="font-light text-xs text-gray-200">
                                    {L.inputNewPass}
                                </span>
                            </div>
                        </div>
                        <div className="mb-10">
                            <label htmlFor="confirmPass" className="text-white text-base font-normal mb-2 inline-block">{L.confirmPass} <span className="text-red">*</span></label>
                            <div className="flex items-center gap-x-8">
                                <input
                                    className="bg-indigo-700 border-0 input input-security rounded-md text-white"
                                    type="text"
                                    onChange={(e) => setFormData(e.target.value, 'new_password_repeat')}
                                    name="confirmPass"
                                />
                                <span className="font-light text-xs text-gray-200">
                                    {L.inputConfirmPass}
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center gap-x-2 mb-10">
                            <i className="icon-info text-xl text-cyan-400"></i>
                            <span className="text-sm font-light">
                                {L.passUpdated}
                            </span>
                        </div>
                        <Button type="primary" onClick={changePassword} className="border-0 text-base px-6 font-medium rounded-2xl bg-cyan-500 text-indigo-900 hover:bg-cyan-400">
                            {L.saveChange}
                        </Button>
                        <div className="line-x mt-12 mb-12"></div>
                        <h3 className="text-gray-300 text-lg font-light mb-8">{L.deleteAccount}</h3>
                        <div className="flex items-center gap-x-2 mb-10">
                            <img src={PATH + 'profile/delete.svg'} alt=""/>
                            <span className="text-sm font-light">{L.deleteAccountNote}</span>
                        </div>
                        <Button type="primary" onClick={() => setIsModalVisible(true)} className="border-0 text-base px-6 font-medium rounded-2xl bg-cyan-500 text-indigo-900 hover:bg-cyan-400">{L.delete}</Button>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default SecurityPrivacy;