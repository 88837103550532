import React, {useState} from "react";
import FilterContainer from "./filter/FilterContainer";
import MyCardContainer from "./card/MyCardContainer";
import Title from "./shared/Title";
import {PATH} from "../../config";

const MyMetahutsContainer = ({}) => {

    const [searchField, setSearchField] = useState('');

    const card = [
        {
            bg: PATH+'metahut-owner.jpg',
            title: "by Tuivasa Sheck",
            active: true,
            landPlot: "A12-278",
            profileLink: "https://metafluence.com/camillahut"
        },
        {
            bg: PATH+'metahut-owner.jpg',
            title: "by Tuivasa Sheck",
            active: true,
            landPlot: "A12-278",
            profileLink: "https://metafluence.com/camillahut"
        },
        {
            bg: PATH+'metahut-owner.jpg',
            title: "by Tuivasa Sheck",
            active: true,
            landPlot: "A12-278",
            profileLink: "https://metafluence.com/camillahut"
        },
    ]

    const onSearchChange = (e) => {
        const searchString = e.target.value.toLocaleLowerCase();
        setSearchField(searchString);
        console.log(searchField, searchString)
    }

    return (
        <>
            <FilterContainer onSearchChange={onSearchChange} />
            <Title title="My Collection" className="px-8 mb-9 text-white uppercase font-normal" />
            <MyCardContainer card={card}/>
        </>
    )
}

export default MyMetahutsContainer;