import React, {Component, useState} from "react";
// import axios from "axios";
import "./Create.css"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosData from "../../../../helpers/axiosData";
import {Button, Col, Layout, Modal, Row} from "antd";
import { connect } from "react-redux";
import {Link, Navigate, NavLink, useParams} from "react-router-dom";
import SelectSearch from "../../../custom/SelectSearch";
import Sidebar from "../Sidebar";
 

const { Sider, Content } = Layout
   
class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: [],
      loading: true,
      banners: {}, 
      errors: {},
      room_types: null,
      
      isModalVisible: false,
      trigger : 0,
      userAccount : 0,
      lands : [],

      UserData : {
        land_id : 0, 
        land_name: '',
        event_room_id: null,
        nft_room_id: null,
        shopping_room_id: null,
        main_color : '',
        stairs_color : '',
        ad_banner_photo_2 : '',
        ad_banner_photo_1 : '',
        ad_banner_link_1 : '',
        ad_banner_link_2 : '',
        user_limit: 30
        
      },

    };

    // this.setMetahutRooms = this.setMetahutRooms.bind(this);
  }
  
  
  changeAccount = (val) => {

    this.getAllRooms(val)
    this.setState({userAccount : val})

    console.log('changeAccount', val)
  }

  handleOk() {
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel() {
    this.setState({
      isModalVisible: false,
    });
  }

  componentDidMount() {
    
    this.getAllRooms()
    this.getLands()
  }

  getAllRooms(user_id=0) {

    axiosData
      .get("/api/_web3/rooms/types")
      .then((res) => {
        this.setState({
          room_types: res.data,
        });
        
        axiosData.get("/api/_web3/rooms?metahut=0&user_id="+user_id).then((res) => {
          this.setState({
            rooms: res.data,
          });
        });
      })
      .catch(error => {
        console.log('getAllRooms error', error)
      })
      .finally(() => this.setState({ loading: false }));

  }

  
  getLands(user_id=0) {

    axiosData
      .get("/api/_web3/metahut/lands?user_id="+user_id)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({
            lands: res.data.lands
          });        
        }
      })

  }

  

  setBanner(data, type) {

      if (typeof data != 'undefined' && data && data['type']) {

          if(data['size'] <= 5242880 && (  data['type'].indexOf('png') != -1 || data['type'].indexOf('jpg') != -1 || data['type'].indexOf('jpeg') != -1 || data['type'].indexOf('gif') != -1) ) { 
              let nBanner = this.state.banners;
              nBanner[type] = data; 

              let key_ = this.state.errors;
              key_['banner_'+type] = null;

              let ntrigger = this.state.trigger+1

              this.setState({
                errors : key_,
                banners : nBanner,
                trigger : ntrigger
              }); 
              
              console.log('errors', key_, 'banners', nBanner)
              return true;
          } 
      }  

      let key_ = this.state.errors;
      key_['banner_'+type] = 'Select valid File';
      let ntrigger = this.state.trigger+1

      console.log('errors', key_, 'ntrigger', ntrigger)

      this.setState({
        errors : key_,
        trigger : ntrigger
      }); 
  };


  CheckImage (data) {
    // console.log('data', data)
    if (typeof data != 'undefined' && data && data['type']) {
        if(data['size'] <= 5242880 && (  data['type'].indexOf('png') != -1 || data['type'].indexOf('jpg') != -1 || data['type'].indexOf('jpeg') != -1 || data['type'].indexOf('gif') != -1) ) { 
            return {data:data}
        }  
    }  

    return {error : 'Select valid File'}
  };


  setFormData (data, key, type='text') {

    console.log('setFormData - data, key, type', data, key, type, ' ----- UserData', this.state.UserData)

    let newUserData = this.state.UserData; 

    if (type == 'text' || type == 'check') { 

        if(type == 'check') {

            newUserData[key] =  data ? 1 : 0;
        } else {
            newUserData[key] = data;
        }

        this.setState({UserData: newUserData});
        // setFormTrigger(FormTrigger+1);

    } else if (type == 'image') {

        let check_img = this.CheckImage(data)

        if(typeof check_img.data !='undefined' && check_img.data) {
            newUserData[key] = data;
            this.setState({UserData: newUserData});
        } else {
            // error
        }
    }   
    
    console.log('FormData ', this.state.UserData)

  }

  
  createMetahut(e) {

    if ( Object.keys(this.state.errors).length && this.state.errors.banner_1!=null && this.state.errors.banner_2!=null && this.state.errors.banner_3!=null) {
        return false;
    }

    var formData = new FormData();
    
    formData.append('user_id', this.state.userAccount);
    
    console.log('this.state.UserData', this.state.UserData)

    if (Object.keys(this.state.UserData).length > 0) {
      for (let x in this.state.UserData) {
        formData.append(x, this.state.UserData[x]); 
      }
    }
    

    axiosData.post(`/api/_web3/metahuts`, formData)
      .then((res) => {
        console.log('create-result', res.data);
        // toast.success("Created Successfully!");
        this.setState({
          isModalVisible: true,
        });
      })
      .catch((error) => {
        if(error.response.data.errors){
          this.setState({ errors: error.response.data.errors });
          
          Object.values(error.response.data.errors).map((errorText, i) => {
              return toast.error(errorText.toString())
          })
        }
        else {
          toast.error('OOPS! Something went wrong.')
        }

      })
  }



  render() {
    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
          <div className="container mx-auto min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
              <Sidebar />
              <Content className="pt-8 pb-8 pl-14 pr-0 settings-content">
                {/* <ToastContainer /> */}
                <div className="pr-4 py-8">
                  
                  <SelectSearch changeAccount={this.changeAccount}/>
                  
                  <Modal footer={false} visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel} className="modal">
                    <div className="text-center">
                      <i className="icon-check text-cyan-500 text-5xl mt-4 mb-4 inline-block"></i>
                      <p className="text-white text-lg">
                        Your changes successfully saved.
                      </p>
                    </div>
                    <div className="flex items-center justify-center gap-x-4 mt-10">
                      <Link to="/admin/metahut">
                        <Button onClick={() => this.handleOk()} className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">Ok</Button>
                      </Link>
                    </div>
                  </Modal>

                  <Link to="/admin/metahut" className="flex items-center gap-x-4 mb-8 w-fit">
                    <i className="icon-back text-3xl text-white"></i>
                    <span className="text-2xl font-normal text-white">Metahuts</span>
                  </Link>
                  <div className="md-mt-0 sm-px-0 flex items-center justify-between">
                    <h1 className="text-white text-5xl font-extralight mb-0">Create Metahut</h1>
                    <Button type="button"
                            className="float-right border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md"
                            onClick={() => this.createMetahut()}
                    >
                      Save
                    </Button>
                  </div>
                  {this.props.user ?
                      this.state.loading ?
                          <div className="flex items-center gap-x-4 mt-5">
                            <h1 className="text-white text-2xl mb-0">Loading</h1>
                            <div className="loading-spinner"></div>
                          </div> :
                          <>
                            
                            <ToastContainer />

                            <div className="mt-4">
                              <Row gutter={46} className="formContainer d-flex">
                                
                                <Col className="col-8" xs={24} sm={12} md={12} lg={12}>
                                  <div className="py-5">
                                    <label htmlFor="metahutName" className="text-white text-base mb-4 block">Metahut Name</label>
                                    <input
                                        className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("land_name") ? "validation-border" : null}`}
                                        type="text"
                                        value={this.state.UserData.land_name}
                                        onChange={(e) => this.setFormData(e.target.value, 'land_name')}
                                        placeholder="Metahut Name..."
                                        maxLength={25}
                                    />
                                  </div>

                                  {this.state.room_types.map((type, i) => {
                                    return (
                                        <div className="py-5" key={i}>
                                          <label className="text-white text-base mb-4 block">{type["name"]}</label>
                                          <select
                                              key={i}
                                              onChange={ (e) => this.setFormData(e.target.value, type["key"]+'_id') }
                                              className={`bg-indigo-700 input select-style rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty(type["key"]+"_id") ? "validation-border" : null}`}
                                          >
                                            <option value={""}>Select</option>
                                            {this.state.rooms
                                                .filter((room) => room.type_id === type["id"])
                                                .map((item, l) => {
                                                  return (
                                                      <option key={l} value={item["id"]}>
                                                        {item["name"] + " - " +item["id"]}
                                                      </option>
                                                  );
                                                })}
                                          </select>
                                        </div>
                                    );
                                  })}

                                  <div className="pr-14">
                                      <div className="py-1 pr-10 flex items-center justify-between mb-4 gap-x-14">
                                          <label className="text-white text-base block">Outside Color</label>
                                          <input
                                              type="color"
                                              className={`bg-indigo-700 border-0`}
                                              onChange={(e) => this.setFormData(e.target.value, 'main_color')}
                                              placeholder="outside color ..."
                                          />
                                      </div>

                                      <div className="py-1 pr-10 flex items-center justify-between mb-4 gap-x-14">
                                          <label className="text-white text-base block">Stairs Color</label>
                                          <input
                                              type="color"
                                              className={`bg-indigo-700 border-0`}
                                              onChange={(e) => this.setFormData(e.target.value, 'stairs_color')}
                                              placeholder="stairs color"
                                          />
                                      </div>

                                      <div className="py-1 pr-10 flex items-center justify-between mb-4 gap-x-14">
                                          <label className="text-white text-base block">Metahut Color 1</label>
                                          <input
                                              type="color"
                                              className={`bg-indigo-700 border-0`}
                                              onChange={(e) => this.setFormData(e.target.value, 'color_1')}
                                              placeholder="outside color ..."
                                          />
                                      </div>

                                      <div className="py-1 pr-10 flex items-center justify-between mb-4 gap-x-14">
                                          <label className="text-white text-base block">Metahut Color 2</label>
                                          <input
                                              type="color"
                                              className={`bg-indigo-700 border-0`}
                                              onChange={(e) => this.setFormData(e.target.value, 'color_2')}
                                              placeholder="color 2"
                                          />
                                      </div>
                                  </div>


                                  <div className="fileChoose py-5">
                                    <div className="currentHeader">
                                        <label className="text-white text-base mb-4 block">Description</label>
                                    </div>
                                    <textarea
                                        className="bg-indigo-700 border-0 rounded-md text-white account-textarea input-style"
                                        type="text" 
                                        rows="5"
                                        cols="55"
                                        defaultValue=""
                                        name="description"
                                        maxLength="100"
                                        onChange={(e) => this.setFormData(e.target.value, 'description')}
                                    />
                                  </div>

                                 
                                  <div className="py-5">
                                      <label htmlFor="ad_banner_link_1" className="text-white text-base mb-4 block">Advertisement Banner 1 Link</label>
                                      <input
                                          className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("ad_banner_link_1") ? "validation-border" : null}`}
                                          type="text"
                                          value={this.state.UserData.ad_banner_link_1}
                                          onChange={(e) => this.setFormData(e.target.value, 'ad_banner_link_1')}
                                          name="ad_banner_link_1"
                                          placeholder="Enter Link..."
                                          id="ad_banner_link_1"
                                          // maxLength={20}
                                      />
                                  </div>

                                  <div className="fileChoose py-5">
                                      <div className="currentHeader">
                                        <label className="text-white text-base mb-4 block">Advertisement Banner 1 Photo</label>
                                      </div>
                                      <input
                                          type="file" 
                                          accept="image/*"
                                          className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("ad_banner_photo_1") && this.state.errors.ad_banner_photo_1 != null ? this.state.errors.ad_banner_photo_1+ " validation-border" : ''}`}
                                          placeholder="Ad Banner ..."
                                          onChange={(e) => this.setFormData(e.target.files[0], 'ad_banner_photo_1', 'image')}
                                      />
                                  </div>


                                  <div className="py-5">
                                      <label htmlFor="user_limit" className="text-white text-base mb-4 block">User Limit</label>
                                      <input
                                          className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("user_limit") ? "validation-border" : null}`}
                                          type="number"
                                          value={this.state.UserData.user_limit}
                                          onChange={(e) => this.setFormData(e.target.value, 'user_limit')}
                                          name="user_limit"
                                          // placeholder="Enter Link..."
                                          id="user_limit"
                                          // maxLength={20}
                                      />
                                  </div>
                                </Col>

                                <Col className="col-8" xs={24} sm={12} md={12} lg={12}>
                                  <div className="py-5">
                                    <label className="text-white text-base mb-4 block"> Land plot </label>
                                    <select
                                        onChange={(e) => this.setFormData(e.target.value, 'land_id')}
                                        className={`bg-indigo-700 input select-style rounded-md text-white px-2 py-2 input-style`}
                                        defaultValue=""
                                    >
                                      <option value={""}>Select land</option>
                                      {this.state.lands.map((item, l) => {
                                        return (
                                            <option key={l} value={item["id"]}>
                                              {item["sector"] + item["zone"] + " - " +item["plot_id"]}
                                            </option>
                                        );
                                      })}
                                    </select>
                                  </div>

                                  <div className="fileChoose py-5">
                                    <label className="text-white text-base mb-4 block">Banner 1 (1920x500)</label>
                                    <input
                                        type="file" placeholder="Banner 1"
                                        className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("banner_1")&&this.state.errors.banner_1!=null ? "validation-border" : ''}`}
                                        onChange={(e) => this.setFormData(e.target.files[0], 'banner_1', 'image')}
                                    />
                                  </div>

                                  <div className="fileChoose py-5">
                                    <label className="text-white text-base mb-4 block">Banner 2 (1920x500)</label>
                                    <input
                                        type="file" placeholder="Banner 2"
                                        className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("banner_2")&&this.state.errors.banner_2!=null ? "validation-border" : ''}`}                                        
                                        onChange={(e) => this.setFormData(e.target.files[0], 'banner_2', 'image')}

                                    />
                                  </div>

                                  <div className="fileChoose py-5">
                                    <label className="text-white text-base mb-4 block">Banner 3 (1920x500)</label>
                                    <input
                                        type="file"
                                        className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("banner_3")&&this.state.errors.banner_3!=null ? "validation-border" : ''}`}
                                        placeholder="Banner 3"
                                        onChange={(e) => this.setFormData(e.target.files[0], 'banner_3', 'image')}

                                    />
                                  </div>


                                      
                                  <div className="fileChoose py-5">
                                      <div className="currentHeader">
                                          <label className="text-white text-base mb-4 block">Cover image (ratio 1:1)</label>
                                      </div>
                                      <input
                                          type="file"
                                          className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("cover_image") ? "validation-border" : null}`}
                                          placeholder="Cover image ..."
                                          onChange={(e) => this.setFormData(e.target.files[0], 'cover_image', 'image')}
                                      />
                                  </div>

                                  <div className="fileChoose py-5">
                                      <div className="currentHeader">
                                          <label className="text-white text-base mb-4 block">Welcome message</label>
                                      </div>
                                      <textarea
                                          className="bg-indigo-700 border-0 rounded-md text-white account-textarea input-style"
                                          type="text"
                                          rows="5"
                                          cols="55"
                                          defaultValue=""
                                          name="welcome_message"
                                          maxLength="100"
                                          onChange={(e) => this.setFormData(e.target.value, 'welcome_message')}
                                      />
                                  </div>
                                  
                                  <div className="py-5">
                                      <label htmlFor="ad_banner_link_2" className="text-white text-base mb-4 block">Advertisement Banner 2 Link</label>
                                      <input
                                          className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("ad_banner_link_2") ? "validation-border" : null}`}
                                          type="text"
                                          value={this.state.UserData.ad_banner_link_2}
                                          onChange={(e) => this.setFormData(e.target.value, 'ad_banner_link_2')}
                                          name="ad_banner_link_2"
                                          placeholder="Enter Link..."
                                          id="ad_banner_link_2"
                                          // maxLength={20}
                                      />
                                  </div>

                                  <div className="fileChoose py-5">
                                      <div className="currentHeader">
                                          <label className="text-white text-base mb-4 block">Advertisement Banner 2 Photo</label>
                                      </div>
                                      <input
                                          type="file" 
                                          accept="image/*"
                                          className={`bg-indigo-700 input rounded-md text-white px-2 py-2 input-style ${this.state.errors.hasOwnProperty("ad_banner_photo_2") && this.state.errors.ad_banner_photo_2 != null ? this.state.errors.ad_banner_photo_2+ " validation-border" : ''}`}
                                          placeholder="Ad Banner ..."
                                          onChange={(e) => this.setFormData(e.target.files[0], 'ad_banner_photo_2', 'image')}
                                      />
                                  </div>

                                </Col>
                              </Row>
                            </div>
                          </>
                          : ''
                      // : <Navigate to={'/admin/metahut/create'} />
                  }
                </div>
              </Content>
            </Layout>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(Create);