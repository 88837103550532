import React from "react";
import MapSvg from "../map/MapSvg";
import BuySelectedPlots from "../footer/BuySelectedPlots";
import { useSelector } from "react-redux";

const Home = ({  Events }) => {
    let L = useSelector (state => state.settings.L);
    return (
        <>
            <MapSvg Events={Events} L={L} />
            <BuySelectedPlots L={L} />
        </>
    )
}

export default Home;