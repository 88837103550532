import React from "react";
import {Button, Progress} from "antd";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";

const GetLandLoading = () => {

    const dispatch = useDispatch();
    let L = useSelector (state => state.settings.L);
    let LandBuyingLoadingMessage = useSelector ((state) => state.land.LandBuyingLoadingMessage)

    let LoadingType = useSelector ((state) => state.land.LandBuyingLoading)

    let Loadings = [
        [],
        [L.loadingPaymentProcessing,'25', false,''],
        [L.loadingPaymentProcessing,'50', false,''],
        [L.loadingPaymentComleted,'100', true,''],
        [LandBuyingLoadingMessage,'100', true,'exception'],
    ];

    const fireEvet = (LoadingType) => {

        if( LoadingType == 3 ) {
            window.dataLayer.push({'event': 'land-buyed-successfully'})
        }
    }


    return ( 

        <>
            <div className="absolute top-0 left-0 h-full bg-indigo-900 w-100 z-1 flex flex-col items-center justify-center gap-y-10">
                <div onLoad={ fireEvet(LoadingType) } className="flex flex-col items-center px-6">

                    <p className="text-center text-base">{Loadings[LoadingType][0]}</p>
                    <Progress type="circle" percent={Loadings[LoadingType][1]} width={80} className="pay-progress" status={Loadings[LoadingType][3]} />
                </div>
                {Loadings[LoadingType][2] ?
                    LoadingType == 3 ?
                        <div className="flex flex-col items-center justify-center gap-y-4 px-8 w-100">
                            <Link to={ROUTES_CONFIG.lands.path}>
                                <Button type="primary" className="border-0 bg-indigo-600 text-gray-300 hover:bg-indigo-800 rounded-3xl w-100 gland-loading_btn" onClick={() => dispatch({type: 'SetLandBuyingLoading', payload: 0})}>{L.myLands}</Button>
                            </Link>
                            <Link to={ROUTES_CONFIG.home.path}>
                                <Button type="primary" className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl w-100 gland-loading_btn" onClick={() => dispatch({type: 'SetLandBuyingLoading', payload: 0})}>
                                    {L.ownLandPlot}
                                </Button>
                            </Link>
                        </div>
                        :
                        <div className="flex items-center justify-center px-8 w-100">
                            <Button type="primary" className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl w-100 gland-loading_btn" onClick={() => dispatch({type: 'SetLandBuyingLoading', payload: 0})}>{L.pleaseTryAgain}</Button>
                        </div>

                    : ""}
            </div>
        </>
    )
}

export default GetLandLoading;