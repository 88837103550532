import React, {useEffect, useState} from "react";
import {Button, Col, Layout, message, Row, Table} from "antd";
import SettingsSidebar from "./SettingsSidebar";
import {PATH} from "../../../config";
import axiosData from "../../../helpers/axiosData";
import NumberFormat from "react-number-format";
import {NavLink} from "react-router-dom";
import {selectUser} from "../../../redux/features/userSlice";
import {useSelector} from "react-redux";

const {Content} = Layout;

const Status = () => {
    const selector = useSelector;
    let L = selector(state => state.settings.L);

    const [messageApi, contextHolder] = message.useMessage();
    const [statusData, setStatusData] = useState({});
    const [packageData, setPackageData] = useState({});

    let GetUser = selector(selectUser);

    const Levels = {1: 'Referral', 2: 'Sub-referral (Lv2)', 3: 'Sub-referral (Lv3)'}

    const getPackage = () => {
        axiosData
        .get('/api/_web3/user/packages')
        .then((res) => {
            if ( res) {
                let data_ = res.data.data;
                let package_ = res.data.packages;
                console.log('res.data', res.data)
                setStatusData(data_)
                setPackageData(package_)
            }
        })
        .catch((error) => {
            console.log('status error: ', error)
        })
    }

    useEffect(() => {
        getPackage()
    }, [])

    const getPackageData = (packageData, GetUser) => {
        let List = []
        for (let p in packageData) {
            List.push(
                <Col xs={24} sm={24} md={8} lg={8} key={packageData[p].id}>
                    <div className="text-center">                         
                        <img src={`${PATH+'ranks/'+packageData[p].id+'/'+GetUser.rank}.png`} alt="" style={{height: "100px"}} />
                        <h2 className={`uppercase text-base font-bold color-${packageData[p].id}`}>{packageData[p].name}</h2>
                        {GetUser.status == packageData[p].id ?
                            <div className="relative avatar_box status_box"
                                 style={{backgroundColor: GetUser && GetUser.bgcolor}}
                            >
                                <img src={GetUser ? GetUser.avatar : PATH + 'avatar.png'} width="100%" height="100%" className="avatar_img"/>
                            </div> :
                            <div className="relative avatar_box status_box status_empty"></div>
                        }
                        <p className="text-gray-light text-sm" style={packageData[p].id == 1 ? {padding: "0 24px"} : {padding: "0 12px"}}>{packageData[p].description}</p>
                        {GetUser.status == packageData[p].id ?
                            <div className="flex items-center justify-center gap-x-2 mt-10">
                                <span className="status_badge">{L.current}</span>
                                <i className="icon-check text-lg" style={{color: "#00A76A"}}></i>
                            </div>
                            :
                            GetUser.status == 1 || (GetUser.status == 2 && packageData[p].id == 3) ?
                            <div>
                                <NavLink to={packageData[p].id == 2 ? '/city' : packageData[p].id == 3 ? '/metahuts/new-metahut' : '/'} className="text-cyan-500 font-bold">
                                    <Button className="border-0 mt-2 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium avatar_btn">
                                        {L.statusDropdownUpgrade}
                                    </Button>
                                </NavLink>
                                <span className="text-white text-xs block mt-3">{packageData ? packageData[p].id == 3 ? 'Become a Metahut owner' : 'Become a land owner' : ''}
                                </span>
                            </div>
                            : ''
                        }
                    </div>
                </Col>
            )
        }

        return List;
    }
    


    const columnsStatus = (statusData) => {
        return [
            {
                title: '',
                dataIndex: 'benefits',
            },
            {
                title: (
                    <div className="flex items-center justify-center gap-x-3 pt-4">
                        <h1 className={`text-base font-bold mb-0 uppercase color-${statusData[1][1][0].package_id}`}>
                            {statusData[1][1][0].package_name}
                        </h1>
                        {GetUser.status === statusData[1][1][0].package_id ? <i className="icon-check text-lg" style={{color: "#00A76A"}}></i> : ''}
                    </div>
                ),
                dataIndex: 'traveller',
                align: 'center',
                className: `${GetUser.status === statusData[1][1][0].package_id ? 'active' : ''}`,
                onCell: (_, index) => {
                    if (index === 1 || index === 5 || index === 9) {
                        return {
                            rowSpan: 3,
                        };
                    }
                    if (index === 2 || index === 3 || index === 6 || index === 7 || index === 10 || index === 11) {
                        return {
                            rowSpan: 0,
                        };
                    }
                    return {};
                },
            },
            {
                title: (
                    <div className="flex items-center justify-center gap-x-3 pt-4">
                        <h1 className={`text-base font-bold mb-0 uppercase color-${statusData[1][2][0].package_id}`}>
                            {statusData[1][2][0].package_name}
                        </h1>
                        {GetUser.status === statusData[1][2][0].package_id ? <i className="icon-check text-lg" style={{color: "#00A76A"}}></i> : ''}
                    </div>),
                dataIndex: 'resident',
                align: 'center',
                className: `${GetUser.status === statusData[1][2][0].package_id ? 'active' : ''}`,
            },
            {
                title: (
                    <div className="flex items-center justify-center gap-x-3 pt-4">
                        <h1 className={`text-base font-bold mb-0 uppercase color-${statusData[1][3][0].package_id}`}>
                            {statusData[1][3][0].package_name}
                        </h1>
                        {GetUser.status === statusData[1][3][0].package_id ? <i className="icon-check text-lg" style={{color: "#00A76A"}}></i> : ''}
                    </div>),
                dataIndex: 'metapreneur',
                align: 'center',
                className: `${GetUser.status === statusData[1][3][0].package_id ? 'active' : ''}`,
            },
        ]
    };

    const dataStatus = (statusData) => {

        let list = [];

        for (let i in statusData) {

            list.push({
                0: (
                    <div>
                        <h2 className="text-xl font-bold text-white">{statusData[i][1][0].category_name}</h2>
                        <p className="text-light" style={{width: "300px", color: "#b1afcd"}}>
                            {statusData[i][1][0].category_name == L.userOnboarding ?
                                L.userOnboardingStatus 
                                : statusData[i][1][0].category_name == L.sellingProperty ?
                                L.sellingPropertyStatus 
                                : L.userRegisterStatusMsg
                            }
                        </p>
                    </div>
                ),
                1: '',
                2: '',
                3: '',
            })
            let row = {0:[], 1:[], 2:[], 3:[]};

            for (let j = 1; j < 4; j++) {
                row[0].push(Levels[j]);

                if (statusData[i][j]) {
                    for (let z in statusData[i][j]) {
                        let item = statusData[i][j][z];
                        row[j].push({ currency: item.currency_short, text : (item.percent != null ? item.percent + "%" : (item.currency == null ? item.max_earn_usd : item.value))});
                    }
                }
            }

            for (let m = 0; m < 3; m++) {
                list.push({
                    0: (<h4 className="text-xl font-light text-white mb-0 text-right">{row[0][m]}</h4>),
                    1: (row[1][m] && row[1][m].currency ?
                        <div className="flex items-center">
                            <img src={PATH+row[1][m].currency.toLowerCase()+'_icon.svg'} style={{height: '24px', marginRight: '4px'}} alt=""/>
                            <span className="text-base">
                                <NumberFormat
                                    value={row[1][m].text}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={(value) =><>{value}</>}
                                /> {row[1][m].currency}
                            </span>
                        </div> : (row[1][m] && row[1][m].text ? <div className="flex items-center justify-center"><span className="text-base">{row[1][m].text}</span></div> : '')),
                    2: (row[2][m] && row[2][m].currency ?
                        <div className="flex items-center">
                            <img src={PATH+row[2][m].currency.toLowerCase()+'_icon.svg'} style={{height: '24px', marginRight: '4px'}} alt=""/>
                            <span className="text-base">
                                <NumberFormat
                                    value={row[2][m].text}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={(value) =><>{value}</>}
                                /> {row[2][m].currency}
                            </span>
                        </div> : (row[2][m] && row[2][m].text ? <div className="flex items-center justify-center"><span className="text-base">{row[2][m].text}</span></div> : '')),
                    3: (row[3][m] && row[3][m].currency ?
                        <div className="flex items-center">
                            <img src={PATH+row[3][m].currency.toLowerCase()+'_icon.svg'} style={{height: '24px', marginRight: '4px'}} alt=""/>
                            <span className="text-base">
                                <NumberFormat
                                    value={row[3][m].text}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={(value) =><>{value}</>}
                                /> {row[3][m].currency}
                            </span>
                        </div> : (row[3][m] && row[3][m].text ? <div className="flex items-center justify-center"><span className="text-base">{row[3][m].text}</span></div> : '')),
                })
            }


        }
        let newList = [];

        for (const im in list) {
            newList.push({ benefits:list[im][0], traveller:list[im][1], resident:list[im][2], metapreneur:list[im][3]})
        }


        let footer = {
            benefits: (<h3 className="text-xl font-bold text-white mb-0">Monthly Profit Allowance</h3>),
            traveller: (
                <div>
                    <img src={PATH+'bnb.svg'} style={{height: '24px', marginRight: '4px'}} alt=""/>
                    <span className="text-lg">
                        <NumberFormat
                            value={statusData[1][1][0].max_earn_usd}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><>{value}</>}
                        /> BUSD
                    </span>
                </div>
            ),
            resident: (
                <div>
                    <img src={PATH+'bnb.svg'} style={{height: '24px', marginRight: '4px'}} alt=""/>
                    <span className="text-lg">
                        <NumberFormat
                            value={statusData[1][2][0].max_earn_usd}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><>{value}</>}
                        /> BUSD
                    </span>
                </div>
            ),
            metapreneur: (
                <div>
                    <img src={PATH+'bnb.svg'} style={{height: '24px', marginRight: '4px'}} alt=""/>
                    <span className="text-lg">
                        <NumberFormat
                            value={statusData[1][3][0].max_earn_usd}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value) =><>{value}</>}
                        /> BUSD
                    </span>
                </div>
            ),
        };

        newList.push(footer)

        return newList;

    }


    return <div className="container mx-auto pt-162 min-h-calc-head h-full">
        <Layout className="h-full settings-layout">
            <SettingsSidebar />
            {contextHolder}
            <Content className="pt-8 pl-10 pr-10 settings-content">
                <h3 className="text-xl text-white font-bold mb-12 settings-title">{L.sidebarStatus}</h3>

                <h3 className="text-gray-300 text-lg font-light">{L.yourStatus}</h3>
                <p className="font-light">You have the potential to increase your earnings by inviting a greater number of individuals.</p>
                <div className="bg-indigo-900 rounded-xl px-4 py-8 mb-20 mt-10">
                    <Row gutter={44}>
                        {packageData && GetUser ? getPackageData(packageData, GetUser) : ''}
                    </Row>
                </div>


                <h3 className="text-gray-300 text-lg font-light">{L.earnBenefits}</h3>
                <p className="font-light">Information regarding interest rates and rewards will be tailored to the user's status.</p>
                {statusData && statusData[1] && GetUser?
                <Table
                    columns={columnsStatus(statusData)}
                    dataSource={dataStatus(statusData)}
                    pagination={false}
                    className="status-table mt-10"
                    scroll={{
                        x: 800,
                    }}
                />:''
                }

            </Content>
        </Layout>
    </div>
}

export default Status;