import { Button } from "antd";
import { PATH } from "../../../../config";
import { useSelector } from "react-redux";

const RewardList = (filter='reward', type = '') => { 
    let L = useSelector (state => state.settings.L);
    let list =  [ 
            {
                label: <Button><img src={PATH + 'free.svg'} alt=""/><h3>{L.free}</h3></Button>,
                keyname: 'free',
                icon: 'free.svg',
                key: 0,
                ticket: 1,
                reward: 0
            },
            {
                label: <Button><img src={PATH + 'bnb.svg'} alt=""/><h3>BUSD</h3></Button>,
                icon: 'bnb.svg',
                keyname: 'busd',
                key: 1,
                ticket: 1,
                reward: 1,
            },
            {
                label: <Button><img src={PATH + 'meto_icon.svg'} alt=""/><h3>METO</h3></Button>,
                icon: 'meto_icon.svg',
                keyname: 'meto',
                key: 2,
                ticket: 1,
                reward: 1
            },
            {
                label: <Button><img src={PATH + 'soto_icon.svg'} alt=""/><h3>SOTO</h3></Button>,
                icon: 'soto_icon.svg',
                keyname: 'soto',
                key: 3,
                ticket: 1,
                reward: 1
            },
            {
                label: <Button><img src={PATH + 'mistery-box.svg'} alt=""/><h3>{L.mysteryBox}</h3><span>{L.soon}</span></Button>,
                icon: 'mistery-box.svg',
                keyname: 'misteryBox',
                disabled: true,
                key: 4,
                ticket: 1,
                reward: 1
            },
            {
                label: <Button><img src={PATH + 'custom-token.svg'} alt=""/><h3>{L.customToken}</h3><span>{L.soon}</span></Button>,
                icon: 'custom-token.svg',
                keyname: 'customToken',
                disabled: true,
                key: 5,
                ticket: 1,
                reward: 1
            },
            {
                label: <Button><img src={PATH + 'nft.svg'} alt=""/><h3>{L.customNFT}</h3><span>{L.soon}</span></Button>,
                icon: 'nft.svg',
                keyname: 'customNFT',
                disabled: true,
                key: 6,
                ticket: 0,
                reward: 1
            },
    ]

    list = list.filter((e) => e[filter]===1)

    let ReturnList = {};

    if (type != '') {
        
        for (let i = 0; i < list.length; i++) {
            ReturnList[list[i][type]] = list[i];
        }
 
    } else {

        ReturnList = []

        for (let i = 0; i < list.length; i++) {
            ReturnList.push( {label: list[i].label, key: list[i].key, disabled: list[i].disabled } );
        }
    }

    return ReturnList;
}

export default RewardList