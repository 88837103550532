import React from 'react';

import JsxParser from 'react-jsx-parser';

import { useSelector } from 'react-redux';


const SubjectLink = (props) => {

    let L = useSelector(state => state.settings.L);
    
    const {title} = props;
    return (
        <div className="flex flex-col gap-y-2 mb-10 mt-20">
            <h1 className="text-3xl text-white font-bold opacity-90">{title}</h1>
            <p className="text-slate-400">{L.subjectLinkNotify && <JsxParser jsx={L.subjectLinkNotify.replace('_LINK_START_', '<a href="https://t.me/metafluence" target="_blank" rel="noreferrer" className="underline text-cyan-500">').replace('_LINK_END_', '</a>') } />}</p>

            {/* <p className="text-slate-400">If you don’t see an answer to your questions below, please, <a href="https://t.me/metafluence" target="_blank" rel="noreferrer" className="underline text-cyan-500">join our community</a> and let us know.</p> */}
        </div>
    )
}

export default SubjectLink;