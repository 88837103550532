import React, {useState} from "react";
import {Button, Layout, Modal} from "antd";
import {Link} from "react-router-dom";
import axiosData from "../../helpers/axiosData";
import { ToastContainer, toast } from "react-toastify";
import {PATH} from "../../config";
import { redirectTo } from "../../config/CoreMethods";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../redux/features/userSlice";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";
import AuthNote from "./AuthNote";
 
const { Content } = Layout

const LoginPage = () => {

    const dispatch = useDispatch();

    const [UserData, setUserData] = useState({})
    const [loadingBtn, setLoadingBtn] = useState(false);

    let L = useSelector(state => state.settings.L)
    let GetUser = useSelector(state => state.user.user)
    

    const setFormData = (data, key, type='text') => {

        let newUserData = UserData; 

        if (type == 'text' || type == 'check') { 
            
            if(type == 'check') {

                newUserData[key] =  data ? 1 : 0;
            } else {
                newUserData[key] = data;
            }

            setUserData(newUserData);
        }   

    }

    const execLogin = () => {

        let form_data = new FormData;

        if (Object.keys(UserData).length > 0) { 
            for (let x in UserData) { 
                form_data.append(x, UserData[x]); 
            }
        }

        setLoadingBtn(true);

        axiosData.post(`/api/_web3/login/`, form_data)
         .then((res) => {
            

            if (res.data.success && res.data.success == true) {
                redirectTo(ROUTES_CONFIG.account.path);
            } else {
                toast.error(`${L.errorOccured}`);
            }
            setLoadingBtn(false);
         })
        .catch((error) => {
            console.log('put error: ', error)
        })
        .finally(() => setLoadingBtn(false));
    }
    // if (GetUser && GetUser.id) {
    //     redirectTo('/profile')
    // }


    const DoLogin = (e) => {
        if (e.keyCode == 13) {
            execLogin()   
        }
    }


    return (
        <div className="min-h-calc-head h-full flex items-center justify-center pb-8" style={{paddingTop: "92px", background: `url(${PATH + 'bg-auth.png'}) center center / cover`}}>
            <div className="container mx-auto">
                <Layout className="bg-transparent h-full">
                    <Content className="pt-4 pb-4 pl-14 pr-14 xs-p-2">
                        <div className="flex flex-col items-center gap-y-5">
                            <AuthNote />
                            <ToastContainer />
                            <div className="flex justify-center">
                                <div className="login-form form-box">
                                    <h1 className="text-center text-white font-normal text-xl mb-6">{L.welcomeBack}</h1>
                                    <label htmlFor="acc_username" className="text-white text-base font-light mb-2 block">{L.emailUserLabel}</label>
                                    <div className="flex items-center gap-x-6 mb-4 xs-w-100">
                                        <input
                                            className="bg-indigo-700 border-0 rounded-md text-white login-input w-100"
                                            type="text"
                                            name="username"
                                            onChange={(e) => setFormData(e.target.value, 'username')}
                                            onKeyDown={DoLogin}

                                        />
                                    </div>

                                    <label htmlFor="acc_desc" className="text-white text-base font-light mb-2 block">{L.passwordLabel}</label>
                                    <input
                                        className="bg-indigo-700 border-0 login-input w-100 rounded-md text-white"
                                        type="password"
                                        name="password"
                                        onChange={(e) => setFormData(e.target.value, 'password')}
                                        onKeyDown={DoLogin}

                                    />
                                    <div className="flex justify-center mt-7">
                                        <Button onClick={execLogin} type="submit" className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium text-base login-btn flex items-center justify-center gap-x-3">
                                            {loadingBtn ? <span className="loading-btn"></span> : ""}
                                            {L.login}
                                        </Button>
                                    </div>
                                    <p className="text-center text-base mt-4 font-light" style={{marginBottom: "5px"}}>{L.orLogin}</p>
                                    <Button onClick={()=>{dispatch(login());}} className="metamask-btn border-0 focus:outline-none text-base font-bold text-white rounded-md flex items-center ">
                                        <img src={PATH+'metamask.png'} alt="" className="button--img" />
                                        <div>
                                            <h4 className="mb-0" style={{lineHeight: "18px"}}>MetaMask</h4>
                                            <p className="mb-0 text-sm font-normal" style={{color: "#272448"}}>{L.metamaskBtnTxt}</p>
                                        </div>
                                    </Button>
                                    <div className="mt-5 flex items-center justify-center gap-x-1 gap-y-2 flex-wrap">
                                        <span>{L.noAccount}</span>
                                        <div>
                                            • <Link to="/register" className="text-sm text-cyan-500">{L.newAccount}</Link> • <Link to={ROUTES_CONFIG.forgotPass.path} className="text-sm text-cyan-500">{L.forgotPass}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>
        </div>
    )
}

export default LoginPage;