import Home from "../components/views/Home";
import MyNFTs from "../components/views/MyNFTs";
import MyMetaHut from "../components/views/MyMetaHut";
import NewMetahut from "../components/views/NewMetahut";
import AdminNftRooms from "../components/admin/pages/NFTSRooms";
import AdminMetahutRooms from "../components/admin/pages/Metahut/Index";
import AdminCreateMetahut from "../components/admin/pages/Metahut/Create";
import AdminEditMetahut from "../components/admin/pages/Metahut/Edit";
import AdminEditNftRooms from "../components/admin/pages/Room/Edit";
import Account from "../components/views/settings/Account";
import Register from "../components/views/Register";
import LoginPage from "../components/views/LoginPage";
import SettingsSecurity from "../components/views/settings/Security";
import FagContainer from "../components/views/FagContainer";
import Quiz from "../components/views/Quiz";
import Referrals from "../components/views/settings/Referrals";
import Status from "../components/views/settings/Status";
import Wallet from "../components/views/settings/Wallet";
import Profile from "../components/views/Profile";
import Avatars from "../components/views/profile/Avatars";
import Metahuts from "../components/views/profile/Metahuts";
import Lands from "../components/views/profile/Lands";
import GetMapLand from "../components/views/GetMapLand";
import GetDirectLand from "../components/views/GetDirectLand";
import ForgotPass from "../components/views/ForgotPass";
import NewPass from "../components/views/NewPass";
import DiscoveryOverview from "../components/views/DiscoveryOverview";
import Claims from "../components/views/settings/Claims";
import SecurityPrivacy from "../components/views/settings/SecurityPrivacy";
import DiscoveryPopular from "../components/views/DiscoveryPopular";
import DiscoveryTopRated from "../components/views/DiscoveryTopRated";
import DiscoveryFeatured from "../components/views/DiscoveryFeatured";
import DiscoveryRecently from "../components/views/DiscoveryRecently";
import GetMetahut from "../components/views/GetMetahut";
import EventsPage from "../components/views/EventsPage";
import Marketplace from "../components/views/Marketplace";
import Staking from "../components/views/staking/Staking";
import InfluenceNetwork from "../components/views/settings/InfluenceNetwork";
import MonthlyPerformance from "../components/views/settings/MonthlyPerformance";
import PublicProfile from "../components/views/PublicProfile";
import HomePage from "../components/views/HomePage";
import TermsConditions from "../components/views/TermsConditions";
import PrivacyPolicy from "../components/views/PrivacyPolicy";
import SaftAgreement from "../components/views/SaftAgreement";
import ApproveEmail from "../components/views/ApproveEmail";
import EventsStep from "../components/views/events/EventsStep";
import EventUpdate from "../components/views/events/EventUpdate";

import EventsSingle from "../components/views/EventsSingle";
import PastEvents from "../components/views/PastEvents";
import MyEvents from "../components/views/MyEvents";
import MyTickets from "../components/views/events/MyTickets";
import PfpNftAvatar from "../components/views/PfpNftAvatar";
import Quests from "../components/views/Quests";

export const ROUTES = {

    homePage: {
        path: '/',
        element: HomePage
    },

    home: {
        path: '/city',
        element: Home
    },
    myNFTs: {
        path: '/nfts',
        element: MyNFTs
    },
    fag: {
        path: '/faq',
        element: FagContainer
    },
    myMetahuts: {
        path: '/metahuts/mycollection',
        element: MyMetaHut
    },
    // discovery: {
    //     path: '/metahuts/discovery',
    //     element: Discovery
    // },
    newMetahut: {
        path: '/metahuts/new-metahut',
        element: NewMetahut
    },
    
    landSuccess: {
        path: '/land/success',
    },
    metahutSuccess: {
        path: '/metahut/success',
    },
    claimSuccess: {
        path: '/claim/success',
    },
    adminNftRooms: {
        path: '/admin/rooms',
        element: AdminNftRooms
    },
    adminEditNftRooms: {
        path: '/admin/rooms/:id',
        element: AdminEditNftRooms
    },
    adminMetahutRooms: {
        path: '/admin/metahut',
        element: AdminMetahutRooms
    },
    adminCreateMetahut: {
        path: '/admin/metahut/create',
        element: AdminCreateMetahut
    },
    adminEditMetahut: {
        path: '/admin/metahut/:id',
        element: AdminEditMetahut
    },
    account: {
        path: '/settings/account',
        element: Account
    },
    register: {
        path: '/register',
        element: Register
    },
    loginPage: {
        path: '/login',
        element: LoginPage
    },
    settingsSecurity: {
        path: '/settings/security',
        element: SettingsSecurity
    },
    // myAvatar: {
    //     path: '/settings/avatar',
    //     element: MyAvatar
    // },
    lands: {
        path: '/lands',
        element: Lands
    },
    avatar: {
        path: '/avatars',
        element: Avatars
    },
    metahuts: {
        path: '/metahuts',
        element: Metahuts
    },
    publicLands: {
        path: '/user/:username/lands',
        element: Lands
    },
    publicAvatars: {
        path: '/user/:username/avatars',
        element: Avatars
    },
    publicMetahuts: {
        path: '/user/:username/metahuts',
        element: Metahuts
    },
    getMetahut: {
        path: '/get-metahut',
        element: GetMetahut
    },
    referralLink: {
        path: '/referrals/referral-link',
        element: Referrals
    },
    influenceNetwork: {
        path: '/referrals/influence-network',
        element: InfluenceNetwork
    },
    monthlyPerformance: {
        path: '/referrals/monthly-performance',
        element: MonthlyPerformance
    },
    status: {
        path: '/settings/status',
        element: Status
    },
    wallet: {
        path: '/settings/wallet',
        element: Wallet
    },
    claims: {
        path: '/settings/claims',
        element: Claims
    },
    securityPrivacy: {
        path: '/settings/security-privacy',
        element: SecurityPrivacy
    },
    quiz: {
        path: '/quiz',
        element: Quiz
    },
    profile: {
        path: '/profile',
        element: Profile
    },
    getMapLand: {
        path: '/get-map-land',
        element: GetMapLand
    },
    getDirectLand: {
        path: '/get-direct-land',
        element: GetDirectLand
    },
    forgotPass: {
        path: '/forgot-password',
        element: ForgotPass
    },
    newPass: {
        path: '/new-password/:hash',
        element: NewPass
    },
    approveEmail: {
        path: '/activate/:hash',
        element: ApproveEmail
    },
    discoveryOverview: {
        path: '/discovery/overview',
        element: DiscoveryOverview
    },
    discoveryPopular: {
        path: '/discovery/popular',
        element: DiscoveryPopular
    },
    discoveryTopRated: {
        path: '/discovery/top-rated',
        element: DiscoveryTopRated
    },
    discoveryFeatured: {
        path: '/discovery/featured',
        element: DiscoveryFeatured
    },
    discoveryRecently: {
        path: '/discovery/recently-created',
        element: DiscoveryRecently
    },
    events: {
        path: '/events',
        element: EventsPage
    },
    pastEvents: {
        path: '/past-events',
        element: PastEvents
    },
    myEvents: {
        path: '/my-events',
        element: MyEvents
    },
    myTickets: {
        path: '/my-tickets',
        element: MyTickets
    },
    marketplace: {
        path: '/marketplace',
        element: Marketplace
    },
    staking: {
        path: '/staking',
        element: Staking
    },
    publicProfile: {
        path: '/user/:username',
        element: PublicProfile
    },

    terms: {
        path: '/terms-and-conditions',
        element: TermsConditions
    },
    privacy: {
        path: '/privacy-policy',
        element: PrivacyPolicy
    },
    saft: {
        path: '/saft-agreement',
        element: SaftAgreement
    },
    eventsStep: {
        path: '/events/submit',
        element: EventsStep
    },
    eventsUpdate: {
        path: '/event/update/:event_id',
        element: EventUpdate
    },
    eventsSingle: {
        path: '/event/:event_id',
        element: EventsSingle
    },
    pfpNftAvatar: {
        path: '/pfpnft-avatar',
        element: PfpNftAvatar
    },
    quests: {
        path: '/quests',
        element: Quests
    },

};
 
 
   
 