import React, {useEffect, useState} from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import UnstakeConfirmModal from "./UnstakeConfirmModal";

const  FindStakerTable = ({
     showHideButtons, stakerInfo, packageItem, convertTime, stakeReward, stakePenalty, stakeStablePenalty,
     claim, setUnstakeConfirm, unstakeConfirm, remainingDays, tableInlineLoading
}) => {
    
    let L = useSelector (state => state.settings.L);

    const [penaltyPrice, setPenaltyPrice] = useState("0");
    const [claimItem, setClaimItem] = useState("0");
    const [IsSuperPool, setIsSuperPool] = useState(0);
    
    let FrontLogin = useSelector ((state) => state.metahut.FrontLogin)
    let FrontNetwork = useSelector ((state) => state.metahut.FrontNetwork)

    const showUnstakeModal = (pp, dd) => {
        setPenaltyPrice (pp);
        setClaimItem(dd);
        setUnstakeConfirm(true);
    };


    const hideUnstakeModal = () => {
        setUnstakeConfirm(false);
    };

    useEffect(()=>{
        if (stakerInfo && stakerInfo.length) {
            setIsSuperPool(typeof stakerInfo[0].claimable !='undefined' ? 1 : 0)
        }
    }, [stakerInfo])

    return (
        
    <>

    {FrontLogin && FrontNetwork && stakerInfo.length  ?
        <div className="py-4 px-14 mx-auto w-4/5 overflow-hidden bg-indigo-400 rounded-b-2xl pool-body">
            <div className="overflow-x-auto">
                <div className="align-middle inline-block min-w-full">
                    <div className="overflow-hidden">
                        <table className="min-w-full stake-table">
                            <thead>
                                <tr>
                                    <th className="py-3 text-left text-xs font-medium text-cyan-500 uppercase">{L.stakerTableDate}</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-cyan-500 uppercase">{L.stakerTableDeposit}</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-cyan-500 uppercase">{L.stakerTableReward}</th>
                                    {packageItem.lockUp !== false ?
                                        <th className="px-6 py-3 text-left text-xs font-medium text-cyan-500 uppercase">{L.stakerTableRemainingDays}</th>
                                    : null}
                                </tr>
                            </thead>
                            <tbody>
                            {
                                stakerInfo.map(item =>
                                    <tr className="border-b border-indigo-600 border-solid text-sm text-gray-300 font-light"
                                        key={item.stakedAt+Math.floor(Math.random() * 100)} >
                                        <td className="py-3">
                                            <span>{convertTime(item.stakedAt)}</span>
                                        </td>
                                        <td className="px-6 py-3">
                                            <NumberFormat
                                                value={(item.amount / (10**18)).toFixed()}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value, props) =><span {...props}>{value} $METO</span>}
                                            />
                                        </td>
                                        <td className="px-6 py-3">
                                            <NumberFormat
                                                value={typeof item.claimable !='undefined' ? parseInt(item.claimable/(10**18)) : item.CalculatedReward}
                                                // value={typeof item.claimable !='undefined' ? parseInt(item.claimable/(10**18)) : stakeReward(item.amount, (item.percent?parseInt(item.percent):packageItem.APY), packageItem.lockUp, item.claimedAt, packageItem.dateSecond)}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value, props) =><span {...props}>{value} $METO</span>}
                                            />
                                        </td>
                                        {packageItem.lockUp !== false ?
                                            <td className="px-6 py-3">
                                                <span>{remainingDays(item.stakedAt, packageItem.dateSecond) > 0 ? remainingDays(item.stakedAt, packageItem.dateSecond) + ` ${L.lockupDays}` : '-'}</span>
                                            </td>
                                        : null }
                                        <td className="py-3 text-right text-sm font-medium">
                                            {!item.claimedAt && packageItem.lockUp !== false ?
                                                <div className="flex gap-x-2 justify-end">
                                                    <button className={`border-0 bg-indigo-800 text-gray-300 cursor-pointer hover:bg-indigo-600 py-1 px-3 rounded-md focus:outline-none focus:shadow-outline ${showHideButtons(1, packageItem.dateSecond, item.stakedAt)}`}
                                                            onClick={() => showUnstakeModal(stakePenalty(item.amount, item.stakedAt, packageItem.dateSecond, packageItem.penaltyPercentage, packageItem.divideValue), item.stakedAt)}>
                                                                {L.unstake}
                                                            </button>
                                                    <button className={`border-0 bg-cyan-500 text-indigo-900 cursor-pointer hover:bg-cyan-400 py-1 px-3 rounded-md focus:outline-none focus:shadow-outline ${showHideButtons(2, packageItem.dateSecond, item.stakedAt)}`}
                                                            id={item.stakedAt}
                                                            onClick={() => claim(item.stakedAt, 1)}>
                                                                {L.claim}
                                                            </button>
                                                </div>
                                                :
                                                <div className="flex gap-x-2 justify-end">
                                                    <button className={`border-0 bg-indigo-800 text-gray-300 cursor-pointer hover:bg-indigo-600 py-1 px-3 rounded-md focus:outline-none focus:shadow-outline`}
                                                            onClick={() => showUnstakeModal(stakeStablePenalty(item.amount, packageItem.penaltyPercentage), item.stakedAt)}>
                                                                {L.unstake}
                                                            </button>
                                                    <button className={`border-0 bg-cyan-500 text-indigo-900 cursor-pointer hover:bg-cyan-400 py-1 px-3 ml-2 rounded-md focus:outline-none focus:shadow-outline`}
                                                            id={item.stakedAt}
                                                            onClick={() => claim(item.stakedAt, 1)}>
                                                                {L.claim}
                                                            </button>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    : FrontLogin && FrontNetwork && tableInlineLoading ? <div className="py-4 px-14 mx-auto w-4/5 overflow-hidden bg-indigo-400 rounded-b-2xl text-center">{L.loading}...</div> : ''}

    {unstakeConfirm && penaltyPrice > 0 ?
        <UnstakeConfirmModal
            hideUnstakeModal={hideUnstakeModal}
            claim={claim}
            stakerInfo={stakerInfo}
            stakePenalty={stakePenalty}
            packageItem={packageItem}
            penaltyPrice={penaltyPrice}
            claimItem={claimItem}
            IsSuperPool={IsSuperPool}
        /> : ''}
    </>
    )
}

export default FindStakerTable;

