import React, { useEffect, useState } from "react";
import Slider from "./events/Slider";
import EventsFilter from "./events/EventsFilter";
import "./events/events.css";
import {Button, Col, Layout, Menu, Row} from "antd";
import Sider from "antd/lib/layout/Sider";
import { useSelector } from "react-redux";
import EventsCard from "./events/EventsCard";
import { Content } from "antd/lib/layout/layout";
import axiosData from "../../helpers/axiosData";
import { toast, ToastContainer } from "react-toastify";
import {PATH} from "../../config";

const EventsPage = () => {

    const[Categories, setCategories] = useState([]);
    const[ActiveCategory, setActiveCategory] = useState(-1);
    const[Events, setEvents] = useState([]);
    const[drawer, setDrawer] = useState(false);

    let GetUser = useSelector ((state) => state.user.user);
    let L = useSelector(state => state.settings.L);


    const getCategories = () => {
        axiosData.get("/api/_web3/event/categories/").then((res) => {

            let data = res.data.data

            if (res.data.success) {
                let list = [{
                    label: <Button><i className="icon-category"></i> All Categories <span className="events-ctg_count">{data.total}</span></Button>,
                    key: 0,
                }];
                
                for (let i = 0; i < data.list.length; i++) {
                    const el = data.list[i];
                    list.push({
                        label: <Button><i className={el.icon}></i> {el.name} {el.event ? <span className="events-ctg_count">{el.event}</span> : ''}</Button>,
                        key: el.id,
                    })
                }

                setCategories(list)                
            } else {
                toast.error(`${L.eventCategoriesErr}`)
            }
        })
    }


    const getEvents = (inFilter) => {

        let internal = inFilter ? '-internal' : '';

        axiosData.get("/api/_web3/event/list"+internal).then((res) => {
            
            if (res.data.success) {
                setEvents(res.data.data);
            } else {
                toast.error(`${L.eventListErr}`)
            }
        })
    }


    const IWantToGo = (id) => {
        axiosData.post("/api/_web3/event/want-to-go/", {id : id}).then((res) => {
            
            if (res.data.success) {
                getEvents(1)
            } else {
                toast.error(`${L.wantToGoEventErr}`)
            }
        }).catch(er => {
            toast.error(`${L.wantToGoErr}`)
        })
    }

    useEffect(()=>{
        getCategories()
        getEvents(GetUser && GetUser.id)
        setActiveCategory(0)
    },[GetUser])

    return (
        <div className="container mx-auto px-12 pr-0 relative pt-162 min-h-calc-head py-8">
            <div className="wrapper mt-16">
                
                <ToastContainer />
                <EventsFilter />
                
                {Events.length ? 
                    <Slider Items={Events} IWantToGo={IWantToGo} LoggedIn={GetUser && GetUser.id?1:0}/>
                : 
                ''}

                <Layout className="events-layout" hasSider={true}>
                    <Sider className="events-ctg" width="280px">
                        <div className="events-all">
                            <h2 className="events-ctg-ttl">{L.categories}</h2>
                            <Button type="link" onClick={() => setDrawer(!drawer)}>{L.allCategories}</Button>
                        </div>
                        <div className={`events-menu ${drawer ? 'show' : ''}`}>
                            <Button type="link" onClick={() => setDrawer(!drawer)}>
                                <img src={PATH+'close-icon.svg'} alt="" /> {L.close}</Button>
                            <Menu className="font-mont events-ctg_nav" selectedKeys={ActiveCategory} onClick={(e) => setActiveCategory(e.key)} theme="dark" mode="inline" items={Categories} />
                        </div>
                    </Sider>
                    
                    <Content className="font-mont events-list" id={ActiveCategory}>
                        <Row gutter={30}>
                            {Events.length ? 
                                Events.filter((el) => { return ActiveCategory == 0 || el.category_ids.indexOf(parseInt(ActiveCategory)) > -1 } ).map(li => {
                                    
                                    return <Col xs={24} sm={12} md={12} lg={8} key={li.id}>
                                        <EventsCard params={li} IWantToGo={IWantToGo} LoggedIn={GetUser && GetUser.id?1:0}/>
                                    </Col>
                                })
                            :''}
                        </Row>
                    </Content>
                </Layout>
            </div>
        </div>
    )
}

export default EventsPage;