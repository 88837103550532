import React, {useEffect, useState} from "react";
import {Button, Col, Layout, Row, Radio, notification} from "antd";
import {PATH} from "../../config";
import {approveNFTNotSelected} from "../../redux/actions/landActions";
import {updateUserAccount} from "../../config/CoreMethods";
import {useDispatch, useSelector} from "react-redux";
import {getMapSettings, switchNetwork} from "../../redux/actions/metahutActions";
import {login} from "../../redux/features/userSlice";
import GetLandLoading from "../loaders/GetLandLoading";
import GetLandContent from "./GetLandContent";

const { Content } = Layout

const GetDirectLand = () => {

    const dispatch = useDispatch();
    
    let L = useSelector(state => state.settings.L);
    let State = useSelector(state => state);
    let MetoConvertRate = useSelector ((state) => state.metahut.MapSettings.meto_price);
    let BalanceBusd = useSelector ((state) => state.metahut.BalanceBusd);
    let BalanceMeto = useSelector ((state) => state.metahut.BalanceMeto);
    let FrontNetwork = useSelector ((state) => state.metahut.FrontNetwork);
    let FrontLogin = useSelector ((state) => state.metahut.FrontLogin);
    let BackLogin = useSelector ((state) => state.metahut.BackLogin);
    let LandBuyingLoading = useSelector ((state) => state.land.LandBuyingLoading);
    let paymentTitle;
    let LandPriceMeto = 95; // IsWhiteList ? 85 : 95;
    let LandPriceBusd = 105; // IsWhiteList ? 95 : 105;
    let LandPriceMetoDiscount = 10;

    const [LandCountForBuy, SetLandCountForBuy] = useState(1)

    const [paymentType, SetPaymentType] = useState('')
    const [metoSpend, setMetoSpend] = useState(0)
    const [TotalMetoWillSpend, setTotalMetoWillSpend] = useState(0) 
    const tokenType = [
        {name: 'METO', icon: 'meto_icon.svg'},
        {name: 'BUSD', icon: 'bnb.svg'},
        {name: 'USD', icon: 'binance_usd.svg'}
    ]

    const PaymentTypeChange = (e) => {
        SetPaymentType(e.target.value);
    };

    const DoBuyingLand = (is_meto, State) => {
        if (State.user.user.account == '' || State.user.user.account == null) {
            updateUserAccount(State.metahut.Account)
        }

        let LandSaleIsOpen = State.land.LandSaleIsOpen

        if (LandSaleIsOpen) {

            paymentTitle = is_meto ? "METO" : "BUSD";
            notification.destroy()

            let balanceSwitched = is_meto ? BalanceMeto : BalanceBusd; 

            if ( balanceSwitched >= TotalMetoWillSpend ) {
                dispatch(approveNFTNotSelected(is_meto, TotalMetoWillSpend, LandCountForBuy))
            } else {
                // openNotificationWithIcon('error', `You don't have enough ${paymentTitle} in your balance`)
                console.log('paymentTitle', paymentTitle)
                openNotificationWithIcon('error', L.youDontHaveEnoughToken.replace('_TOKEN_', paymentTitle))
                window.scrollTo(0, 0);
            }

            return true;

        } else {
            openNotificationWithIcon('error', L.saleLandIsOpenErr)
            return false;
        }
    }

    const StartLandBuying = async (is_meto) => {

        if (FrontLogin && BackLogin) {

            if ( FrontNetwork ) {
                DoBuyingLand(is_meto, State);

            } else {
                dispatch(switchNetwork(state => {
                    // console.log('state', state);
                }))
            }
        } else if (window.ethereum) {
            dispatch(login())

        } else {
            openNotificationWithIcon('error', L.installMetamask)
        }
    }

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Error',
            description: message,
            maxCount: 1,
        });
    };

    useEffect(() => {
        dispatch( getMapSettings() );  

        if ( MetoConvertRate  &&  LandCountForBuy) {
            setTotalMetoWillSpend(Math.round(LandPriceMeto/parseFloat(MetoConvertRate)) * LandCountForBuy);
            setMetoSpend(Math.round(LandPriceMeto/parseFloat(MetoConvertRate)));
        }

    }, [TotalMetoWillSpend,BalanceBusd, BalanceMeto, MetoConvertRate, LandCountForBuy, LandPriceMeto])

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10 font-mont">
                    <Row gutter={84} className="mt-10">
                        <Col xs={24} sm={24} md={16} lg={16} className="mb-8">
                            <GetLandContent L={L} />
                            <div className="gland-list">
                                <div className="flex items-center gap-x-2 gland-list_header">
                                    <img src={PATH + 'map-icon.svg'} alt=""/>
                                    <h3 className="font-bold mb-0 gland-list_ttl">
                                        {L.landLocationNotSelected}
                                    </h3>
                                </div>
                                <Row gutter={44}>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <div className="w-100 relative thumb thumb-buy">
                                            <div className="thumb-buy_bg"
                                                 style={{background: `url(${PATH + 'land-empty.png'}) no-repeat center center / cover`}}></div>
                                            <div className="flex flex-col items-center justify-center z-1 thumb-buy_content">
                                                <p className="text-center mb-0 font-light font-mont thumb-claim_note">{L.chooseLandLocationLater}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col gap-y-1 mb-8">
                            <div className="gland-pay">
                                <h4 className="gland-pay_ttl">{L.payment}</h4>
                                <Radio.Group onChange={PaymentTypeChange} value={paymentType} className="flex flex-col gland-pay_check">
                                    {tokenType && tokenType.map((item, i) => {
                                        return (
                                            <Radio value={i+1} key={i+1} className={`gland-pay-token ${i == 2 ? 'opacity-50' : ''}`} disabled={i == 2 ? true : false}>
                                                <div className="flex items-center gap-x-1 font-normal text-white gland-pay_label">
                                                    {L.payWith} <img src={PATH + item.icon} alt="" style={{height: "16px", marginLeft: "4px"}} /> {item.name}
                                                </div>
                                                <span className="font-bold uppercase gland-pay-type">{i != 2 ? 'crypto' : 'fiat'}</span>
                                                {i == 0 ? <span className="font-bold gland-pay-discount">{LandPriceMetoDiscount}% {L.discountOff}</span> : ''}
                                            </Radio>
                                        )
                                    })}
                                </Radio.Group>
                            </div>
                            {paymentType ?
                                <div className="relative gland-amount">
                                    {LandBuyingLoading ? <GetLandLoading /> : ""}
                                    <h4 className="gland-pay_ttl">{L.amount}</h4>
                                    <div className="flex items-start gap-x-8 gland-amount_row">
                                        <div className="flex flex-col gland-amount_price">
                                            <p className="mb-0 font-normal gland-amount_token">{paymentType == 1 ? metoSpend + ` ${tokenType[paymentType - 1].name}` : LandPriceBusd + ` ${tokenType[paymentType - 1].name}`}</p>
                                            {paymentType != 3 ? <span className="font-normal gland-amount_usd">≈ {paymentType == 1 ? LandPriceMeto : LandPriceBusd} USD</span> : ''}
                                        </div>
                                        <span>x</span>
                                        <div className="flex items-center gap-x-2 gland-amount_land">
                                            <input
                                                className="bg-white text-indigo-700 font-bold border-0 rounded-md text-center font-mont gland-amount_input"
                                                type="text"
                                                defaultValue="1"
                                                name="landLimit"
                                                onChange={(e) => SetLandCountForBuy(e.target.value)}
                                            />
                                            <span className="font-normal font-mont gland-amount_limit">{L.maxLandBuyPerUser}</span>
                                        </div>
                                    </div>
                                    <div className="gland-amount-total">
                                        <h5 className="gland-amount_ttl">{L.completeAmount}</h5>
                                        <div className="flex flex-col gland-amount_price">
                                            <div className="flex items-center gap-x-1 gland-amount_token">
                                                {LandCountForBuy ? paymentType == 1 ? (!isNaN(TotalMetoWillSpend) ? TotalMetoWillSpend : '-') : (LandPriceBusd * LandCountForBuy) : '-' }
                                                <img src={PATH + tokenType[paymentType - 1].icon} style={{height: "16px", marginLeft: "3px"}} alt=""/>
                                                {tokenType[paymentType - 1].name}
                                            </div>
                                            {paymentType != 3 ? <span className="gland-amount_usd">≈ {paymentType == 1 ? (LandPriceMeto*LandCountForBuy) + ' USD' : (LandPriceBusd*LandCountForBuy) + ' USD'}</span> : ''}
                                        </div>
                                        {paymentType == 1 ? <p className="font-light gland-amount_discount">{L.LandBuyReducingInfo && L.LandBuyReducingInfo.replace('_AMOUNT_', ((LandPriceBusd*LandCountForBuy*LandPriceMetoDiscount)/100) )}</p> : ''}
                                    </div>
                                    <div className="line-x"></div>
                                    <p className="font-light font-sans gland-amount_note">
                                        {L.getLandAmountNote}
                                    </p>
                                    <div className="text-center">
                                        <Button
                                            disabled={LandCountForBuy ? '' : 'disable'}
                                            className={`border-0 mb-5 rounded-3xl font-normal gland-amount_btn ${LandCountForBuy ? 'bg-cyan-500 text-indigo-900 hover:bg-cyan-400' : 'bg-indigo-600 text-white opacity-50 cursor-not-allowed'}`}
                                            onClick={() => StartLandBuying(paymentType)}
                                        >{L.getLandAmountButton}</Button>
                                    </div>
                                </div> 
                            :''}
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    )
}

export default GetDirectLand;