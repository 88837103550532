
import metaContract from '../contracts/metafluence.json';
import NFTContract from '../contracts/LandNewContract.json';
import MetahutContract from '../contracts/Metahut.json';
import NFTRoomContract from '../contracts/NFTRoom.json'; 
import PullPushContract from '../contracts/PullPush.json'; 

let TEST_ENV = 0;
const baseName = '/'


let MetoAddress = "0xa78775bba7a542F291e5ef7f13C6204E704A90Ba";
let BusdAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
let NFTAddress = "0xb20b17a146D0CeAAAeC707a3703d790139f747bf";
let MetahutAddress = "0x499Fd001d9C1Ba221fde968c817c1ED4aFcbB444";
let ChainId = 56;
let ChainName = "BNB Smart Chain";
let ChainRPCUrls = ["https://bsc-dataseed.binance.org/"];
let ChainBlockExplorerUrls = ["https://bscscan.com"];
let NFTRoomAddress = '';
let $ROOT = 'https://app.metafluence.com/api/get-coord.php?'; 
let  PullContractAddress = '0xb5B2ca4e378A080b3C905D27964959f71B7477eD' //'0xf9648b077fbDCC367E5072bF281f57CbfD1DBdE9';

// const PATH = "http://localhost/meto-front-all/meto_nft/public/images/";
// const API = "http://localhost/meto-front-all/meto_nft/api/";
// const API = "https://keepface.com/metonft/api/";
// let $ROOT = 'http://localhost/meto-front-all/map/get-coord.php?';


const PATH = "https://dcdn.metafluence.com/images/";
const API = "https://app.metafluence.com/api/";

const basePath = window.location.href.search('localhost') > -1 ? '/' : '/'+window.location.href.replace('://', '').split('/')[1]+'/';

if (TEST_ENV === 1) {
    
    MetoAddress = "0xc39A5f634CC86a84147f29a68253FE3a34CDEc57";
    BusdAddress = "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee";
    NFTAddress  = "0xa291569fc861d71D662Bc0CBc4C034a15E64BBCd"; // "0xcd310F92074EefF758819B57E00a174eF062Bd50" // "0x0dab317917D47B2A68A6378bB9E35f3C4E8dBF38"; 
    MetahutAddress = "0x7E6B0CC044C6fC317D6150A5800aA1C98238F718";
    ChainId = 97;
    
    ChainName = "Smart Chain - Testnet";
    ChainRPCUrls = ["https://data-seed-prebsc-1-s1.binance.org:8545/"];
    ChainBlockExplorerUrls = ["https://testnet.bscscan.com"];

    NFTRoomAddress = "0x59f967894B9185C8a4b6f8FcE51584547F63cf71"; 

    // $ROOT = 'https://keepface.com/metonftnew/api/get-coord.php?';
    
}


const MetaABI = metaContract.abi;
const NFTABI = NFTContract.abi;
const MetahutABI = MetahutContract.abi;
const NFTRoomABI = NFTRoomContract.abi;


export {baseName, TEST_ENV, PATH, API, basePath, MetoAddress, BusdAddress, NFTAddress, MetahutAddress, ChainId, ChainName, ChainRPCUrls, ChainBlockExplorerUrls, MetaABI, NFTABI, MetahutABI, NFTRoomABI, NFTRoomAddress, $ROOT, PullContractAddress, PullPushContract}
