import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";

const NameDesc = ({L}) => {

    let formData = useSelector ((state) => state.event.formData);
    let dispatch = useDispatch()

    const [valueCalc, setValueCalc] = useState({
        name: "0",
        description: "0"
    });

    const calculateWords = (e) => {
        setValueCalc({
            ...valueCalc,
            [e.target.name]: e.target.value.length
        })
        dispatch({type: 'setFormData', payload: {...formData, [e.target.name] : e.target.value}})
    }
    return (
        <> {console.log('formdata', formData.name)}
            <div className="eform-item">
                <label htmlFor="eventName" className="elabel">{L.eventName}</label>
                <div className="einput">
                    <input
                        className="input eform-input"
                        type="text"
                        defaultValue={formData.name}
                        name="name"
                        maxLength="32"
                        onChange={(e) => calculateWords(e)}
                    />
                    <div className="einput-limit">{valueCalc.name} / 32</div>
                </div>
            </div>
            <div className="eform-item">
                <label htmlFor="eventDesc" className="elabel">{L.eventDesc}</label>
                <textarea
                    className="eform-textarea"
                    type="text"
                    rows="4"
                    cols="49"
                    defaultValue={formData.description}
                    name="description"
                    onChange={(e) => calculateWords(e)}
                    maxLength="250"
                />
                <div className="einput-limit">{valueCalc.description} / 250</div>
            </div>
        </>
    )
}


export default NameDesc;