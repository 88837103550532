import React, {useState, useEffect} from "react";
import {Button, Layout, Modal} from "antd";
import { ToastContainer, toast } from "react-toastify";
import axiosData from "../../../helpers/axiosData";
import SelectSearch from "../../custom/SelectSearch";
import SettingsSidebar from "./SettingsSidebar";
import { useSelector } from "react-redux";

const { Content } = Layout

const SettingsSecurity2 = () => {

    let L = useSelector (state => state.settings.L);

    const [UserData, setUserData] = useState({});
    const [FormTrigger, setFormTrigger] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [errors, setErrors] = useState([]);
    const [Error, setError] = useState(false);
    const [userAccount, setUserAccount] = useState(0);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [usernameExists, setUsernameExists] = useState(false);

    let Timeout;
    
    const setFormData = (data, key, type='text') => {

        let newUserData = UserData;

        if (type == 'text' || type == 'check') {

            if(type == 'check') {

                newUserData[key] =  data ? 1 : 0;
            } else {
                newUserData[key] = data;
            }

            setUserData(newUserData);
            setFormTrigger(FormTrigger+1);
        }
    }


    const editSettings = () => {

        if (usernameExists || Error) {
            toast.error('Type correct username');
            return false;
        }

        if (errors.length > 0 || Object.keys(errors).length) {
            return false;
        }   

        var form_data = new FormData; 

        if (Object.keys(UserData).length > 0) {
            for (let x in UserData) {
                if(x != 'nfts' &&  UserData[x] != null) {
                    form_data.append(x, UserData[x]); 
                }
            }
        }

        form_data.append('user_id', userAccount); 

        setLoadingBtn(true);
        axiosData
         .post(`/api/_web3/user/settings/`, form_data)
         .then((res) => {
            setIsModalVisible(true)
            setLoadingBtn(false);
         })
        .catch((error) => {
            toast.error(error.response.data.message);
        })
        .finally(() => setLoadingBtn(false));

    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Setting username
    const setUsername = (username) => {

        clearTimeout(Timeout) 
        if ( /^([A-Za-z0-9_]{4,30})$/.test(username) ) {
            setError(false)
        } else { 
            setUsernameExists(false)
            setError(true);

            return false
        }

        if ( username != '') {

            Timeout = setTimeout(function() {
                axiosData
                 .get(`/api/_web3/user/getusername/${username}`)
                 .then((res) => {
                    if (res.data.exists && res.data.exists == '1') {
                        setUsernameExists(true)
                    } else {
                        setFormData(username, 'username')
                        setUsernameExists(false)
                    }
                 })
                .catch((error) => {
                    console.log('get error: ', error)
                })
            }, 300);
        }
    }

    
    const changeAccount = (val) => {
        setUserAccount(val)
        getUserData(val)
    }

    const getUserData = (userId) => {

        let uId = userId ? userId : userAccount;

        axiosData
         .get(`/api/user?user_id=${uId}`)
         .then((res) => { 

            if( res.data ) {  
                let UserData_ = res.data;
                setUserAccount(UserData_.id)
                setUserData(UserData_)
            }

         })
        .catch((error) => {
            console.log('error', error)
        })
    }

    useEffect(() => {
        getUserData();
    }, [])


    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="h-full settings-layout">
                <SettingsSidebar />
                <Content className="pt-8 pl-10 pr-10 settings-content">

                    {userAccount ?  
                        <SelectSearch  changeAccount={changeAccount} selectedItem={userAccount}/>
                    : ''}

                    <h3 className="text-xl text-white font-bold mb-8 settings-title">
                        {L.sidebarSecurity}
                    </h3>

                    <ToastContainer />

                    <div>
                        <label htmlFor="acc_username" className="text-white text-base mb-2 inline-block">
                            {L.usernameLabel}
                        </label>
                        <div className="flex items-center gap-x-6 mb-6">
                            <input
                                className={`bg-indigo-700 border-0 input rounded-md text-white ${Error?'input-error' : ''}` }
                                    type="text" 
                                    autoComplete="off"
                                    defaultValue={UserData.username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    name="username"
                                />
                            { usernameExists ? <span className="text-red text-sm">
                                {L.alreadyUsed}
                            </span> : ''}
                        </div>

                        <label htmlFor="acc_username" className="text-white text-base mb-2 block">
                            {L.emailLabel}
                        </label>
                        <div className="mb-6">
                            <input
                                className="bg-indigo-700 border-0 input rounded-md text-white"
                                type="email"
                                defaultValue={UserData.email}
                                name="email"
                                onChange={(e) => setFormData(e.target.value, 'email')}
                            />
                        </div>


                        <label htmlFor="acc_desc" className="text-white text-base mb-2 block">
                            {L.passwordLabel}
                        </label>
                        <input
                            className="bg-indigo-700 border-0 input rounded-md text-white"
                            type="password"
                            defaultValue=""
                            autoComplete="off"
                            name="password"
                            onChange={(e) => setFormData(e.target.value, 'password')}
                        />

                        <div className="mt-10">
                            <Button type="submit" onClick={editSettings} className="rounded-2xl bg-cyan-500 border-0 px-12 flex items-center gap-x-3">
                                {loadingBtn ? <span className="loading-btn"></span> : ""}
                                {L.save}
                            </Button>
                        </div>
                    </div>
                    <Modal footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} className="modal">
                        <div className="text-center">
                        <i className="icon-check text-cyan-400 text-5xl mb-2"></i>
                            <p className="text-center text-white text-lg">
                                {L.successfullySaved}
                            </p>
                        </div>
                        <div className="flex items-center justify-center gap-x-4 mt-10">
                            <Button onClick={() => handleOk()} className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">Ok</Button>
                        </div>
                    </Modal>
                </Content>
            </Layout>
        </div>
    )
}

export default SettingsSecurity2;