import React from "react";
import {PATH} from "../../../config";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";
import {Skeleton} from "antd";

const Badges = ({L}) => {
    const selector = useSelector;

    let GetUser = selector(selectUser);

    const badges = [L.tester, L.firstmover, L.member];

    return (
        <div className="badges">
            <span className="block font-bold mb-10 meta-card-header">{L.badges}</span>
            <div className="flex items-start badge-list">
                {GetUser && GetUser.badges.length == 0 ?
                    <div className="flex items-center badge badge-empty">
                        <span className="text-center font-light badge-note">
                            {L.emptyBadgeNote}
                        </span>
                    </div> :
                    GetUser && GetUser.badges ?
                        GetUser.badges.map((item, index) => {
                            return (
                                <div className="flex flex-col items-center badge" key={index}>
                                    <img src={PATH + `badges/${item.image}`} className="badge-icon" alt=""/>
                                    <span className="uppercase badge-label">{item.name}</span>
                                </div>
                            )
                        }) :
                    <div className="flex flex-col items-center badge badge-empty">
                        <Skeleton.Image active className="badge-icon_skeleton"></Skeleton.Image>
                        <Skeleton active paragraph={false} title={{style: {margin: "0", height: "14px"}}}></Skeleton>
                    </div>
                }
            </div>
        </div>
    )
}

export default Badges;