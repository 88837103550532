import React from "react";
import {Col, Row} from "antd";
import NavMenu from "./NavMenu";
import Action from "./Action";
import './filter.css';
import { useSelector } from "react-redux";

const FilterContainer = () => {
    let L = useSelector (state => state.settings.L);

    return (
        <Row gutter={{xs: 86, sm: 86, md: 46, lg: 36}} className="mb-20 sm-mt-0 mt-20 w-full">
            <Col md={24} lg={24} className="gutter-row justify-end sm-justify-center flex flex-wrap gap-x-2 gap-y-4">
                <NavMenu L={L} />
                <Action L={L} />
            </Col>
        </Row>
    )
}

export default FilterContainer;