import React, {useState, useEffect} from "react";
import {Button, Col, Layout, Row, Modal, Select} from "antd";
import axiosData from "../../../helpers/axiosData";
import SelectSearch from "../../custom/SelectSearch";
import SettingsSidebar from "./SettingsSidebar";
import {fetchUser, login} from "../../../redux/features/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {PATH} from "../../../config";
import "./Settings.css";
import {NavLink} from "react-router-dom";
import {ROUTES as ROUTES_CONFIG} from "../../../config/constants";

const { Content } = Layout;

const Account = () => {

    const selector = useSelector;
    const dispatch = useDispatch();

    let L = selector (state => state.settings.L);
    let selectedCountry = selector ((state) => state.settings.selectedCountry);

    const DefaultAvatar = PATH+'avatar.png';
    const AvatarBG = PATH+'avatarBG.png';

    const [file,  setFile] = useState(DefaultAvatar)
    const [UserData, setUserData] = useState({})
    const [FormTrigger, setFormTrigger] = useState(1);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);

    const [errors, setErrors] = useState([]); 
    
    const [loading, setLoading] = useState(true); 

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userAccount, setUserAccount] = useState(0);
    const [listCountries, setListCountries] = useState([]);

    const { Option } = Select;

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const AvatarOnError = () => {
        setFile(DefaultAvatar)
    }

    const onChange = (value) => {
        console.log(`selected ${value}`);
        setFormData(value, L.countrySelect);
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const CheckImage = (data) => {
        if (typeof data != 'undefined' && data && data['type']) {
            if(data['size'] <= 5242880 && (  data['type'].indexOf('png') != -1 || data['type'].indexOf('jpg') != -1 || data['type'].indexOf('jpeg') != -1 || data['type'].indexOf('gif') != -1) ) { 
                return {data:data}
            }  
        }  

        return {error : 'Select valid File'}
    };


    const setFormData = (data, key, type='text') => {

        let newUserData = UserData; 

        if (type == 'text' || type == 'check') { 

            if(type == 'check') {
                newUserData[key] = data ? 1 : 0;
            } else { 
                newUserData[key] = data;
            }

            setUserData(newUserData);
            setFormTrigger(FormTrigger+1);

        } else if (type == 'image') {

            let check_img = CheckImage(data)

            if(typeof check_img.data !='undefined' && check_img.data) {

                newUserData[key] = data;
                setUserData(newUserData);
                setFormTrigger(FormTrigger+1);

            } else {
                // error
            }
        }   
    }
     

    const editSettings = () => {

        if (errors.length > 0 || Object.keys(errors).length) {
            return false;
        }   

        let form_data = new FormData; 

        if (Object.keys(UserData).length > 0) {
            for (let x in UserData) {
                if(x != 'nfts'  &&  UserData[x] != null) {
                    form_data.append(x, UserData[x]); 
                }
            }
        }

        form_data.append('user_id', userAccount);  

        getCountries(UserData.country);
        setLoadingBtn(true);

        axiosData
         .post(`/api/_web3/user/settings/`, form_data)
         .then((res) => {
            setIsModalVisible(true)
            setLoadingBtn(false);
            dispatch(fetchUserData)
         })
        .catch((error) => {
            console.log('put error: ', error)
        })
        .finally(() => setLoadingBtn(false));

    };

    const changeAccount = (val) => {
        setUserAccount(val)
        getUserData(val)
    }


    const getUserData = (userId) => {

        let uId = userId ? userId : userAccount;

        axiosData
         .get(`/api/user?user_id=${uId}`)
         .then((res) => { 

             if( res.data ) {  

                let UserData_ = res.data;

                setUserAccount(UserData_.id)
                setUserData(UserData_)
                setFile(UserData_.avatar)
                getCountries(UserData_.country);
            }

         })
        .catch((error) => {
            console.log('put error: ', error)
        })
        .finally(() => setLoadingImage(false));
    }

    const fetchUserData = async (dispatch, getState) => {
        try {
            await dispatch(fetchUser())
        } catch (error) {
            console.log('fetchUser error', error)
        } 
    };

    useEffect(() => {
        getUserData(); 
        dispatch(fetchUserData);
    }, []);


    useEffect(() => {
        makeOptions()
    }, [UserData, UserData.country])

        
    // getCountries
    const getCountries = (Country) => {
        
        axiosData
         .get(`/api/_web3/countries/`)
         .then((res) => { 
            if (res.data.countries  &&  res.data.countries.length > 0) {
                makeOptions(res.data.countries, Country);
            } 
         })
        .catch((error) => {
            console.log('get error: ', error)
        });
    }

    // makeOptions for country select
    const makeOptions = (Countries, Country) => {
        let list = [];

        if (Countries && Countries.length) {
            
            let CountryName = L.countrySelectOne;

            list.push(<Option value="0" key="0"> {L.countrySelectOne} </Option>)

            Countries.map((key) => { 

                if(Country == key.id) {
                    list.push(<Option value={key.id} key={key.id} selected={true}> {key.name} </Option>)
                    CountryName = key.name
                    console.log(key.name, CountryName)
                } else {
                    list.push(<Option value={key.id} key={key.id}> {key.name} </Option>)
                }
            })

            dispatch({type : 'setSelectedCountry', payload: CountryName})
            setListCountries(list)
        }
    }


    return (
        
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="h-full settings-layout">
                <SettingsSidebar />
                <Content className="pt-8 pl-10 pr-10 settings-content">
                    {userAccount ?  
                        <SelectSearch  changeAccount={changeAccount} selectedItem={userAccount}/>
                        : ''}
                    
                        <h3 className="text-xl text-white font-bold mb-8 settings-title">
                            {L.sidebarAccount}
                        </h3>
                        
                        <div className="mb-12">
                            <h4 className="text-gray-300 font-light text-xl">{L.settingsPersonalInfoTtl}</h4>
                            <p className="text-white text-sm font-light">{L.settingsPersonalInfoDesc}</p>
                        </div>
                        <div className="flex items-center justify-between account-form">
                            <div>

                                <label htmlFor="acc_name" className="text-white text-base mb-2 inline-block">{L.fullName}</label>
                                <div className="flex items-center gap-x-6 mb-6 switch-input">
                                    <input
                                          className="bg-indigo-700 border-0 input rounded-md text-white"
                                          type="text" 
                                          defaultValue={UserData.name}
                                          name="name"
                                          onChange={(e) => setFormData(e.target.value, 'name')}
                                      />
                                    <div className="flex items-center gap-x-3">
                                        <span className="text-white text-sm font-light">{L.hide}</span>
                                        <label className="switch">                                             
                                            <input type="checkbox"
                                                onChange={(e) => setFormData(e.target.checked, 'show_name', 'check')} 
                                                checked={UserData.show_name ? true : false}
                                            />
                                          <span className={`slider round switch ${UserData.show_name ? 'true' : 'false'}`}></span>
                                        </label>
                                        <span className="text-white text-sm font-light">{L.show}</span>
                                    </div>
                                </div>

                                <div className="w-354">
                                    <label htmlFor="acc_desc" className="text-white text-base mb-2 block">{L.countrySelect}</label>
                                    {selectedCountry ? 
                                    <Select
                                        className="custom-select country-select mb-6 input"
                                        showSearch
                                        placeholder="Select one"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        defaultValue={selectedCountry}
                                        
                                    >
                                        {listCountries}
                                    </Select>
                                    :''}
                                </div>

                                <label htmlFor="acc_desc" className="text-white text-base mb-2 inline-block">{L.accountTextarea}</label>
                                <div className="flex items-center gap-x-6 mb-6 switch-input">

                                    <textarea
                                        className="bg-indigo-700 border-0 rounded-md text-white account-textarea"
                                        type="text" 
                                        rows="7"
                                        cols="55"
                                        defaultValue={UserData.description}
                                        name="description"
                                        onKeyUp={(e) => setFormData(e.target.value, 'description')}
                                      />
                                    
                                        
                                    <div className="flex items-center gap-x-3">
                                        <span className="text-white text-sm font-light">{L.hide}</span>
                                        <label className="switch">
                                        { typeof UserData.show_description !=='undefined'?  
                                            <input type="checkbox"  
                                                checked={UserData.show_description ? true : false} 
                                                onChange={(e) => setFormData(e.target.checked, 'show_description', 'check')}
                                            />
                                            :''}
                                            <span className={`slider round switch ${UserData.show_description ? 'true' : 'false'}`}></span>
                                        </label>
                                        <span className="text-white text-sm font-light">{L.show}</span>
                                  
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center gap-y-9">
                                <div className="flex flex-col gap-y-10 items-center">
                                    <div className="relative avatar_box" style={{backgroundImage: `url(${AvatarBG})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                                        {loadingImage ?
                                            <div className="loading-overlay">
                                                <span className="loading-image"></span>
                                            </div> : ""
                                        }
                                        <img src={file} onError={AvatarOnError} width="125" height="125" className="avatar_img" />
                                    </div>
                                    <div className="upload-btn-wrapper">
                                        <NavLink to={ROUTES_CONFIG.avatar.path} className="text-cyan-500 font-bold">
                                            <Button className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium avatar_btn">
                                                {L.changeAvatar}
                                            </Button>
                                        </NavLink>
                                    </div>
                                </div>
                            
                                    
                                <div className="flex items-center gap-x-3">
                                    <span className="text-white text-sm font-light">{L.hide}</span>
                                    <label className="switch">
                                    { typeof UserData.show_avatar != 'undefined' ? 
                                        <input type="checkbox"   
                                            checked={UserData.show_avatar ? true : false} 
                                            onChange={(e) => setFormData(e.target.checked, 'show_avatar', 'check')}
                                        />
                                        :''}
                                        <span className={`slider round switch ${UserData.show_avatar ? 'true' : 'false'}`}></span>
                                    </label>
                                    <span className="text-white text-sm font-light">{L.show}</span>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12">
                            <h4 className="text-gray-300 font-light text-xl">{L.socialMedia}</h4>
                            <p className="text-white text-sm font-light">{L.socialMediaDesc}</p>
                        </div>
                        <Row gutter={46} className="account-social">
                            <Col span={12} className="gutter-row xs-p-0">
                                <div className='mb-9 flex flex-col'>
                                    <label className="text-base text-white mb-2 inline-block">{L.twitter}</label>
                                    <div className="relative">
                                        <input
                                              className="bg-indigo-700 w-100 border-0 rounded-md input-prefix input-twitter text-white h-46"
                                              type="text"
                                            defaultValue={UserData.twitter}

                                              name="acc_twitter"
                                              onChange={(e) => setFormData(e.target.value, 'twitter')}
                                        />
                                        <i className="icon-twitter"></i>
                                    </div>
                                    <span className="text-gray-300 text-sm mt-2 inline-block">
                                        {L.enterTwitterUsername}
                                    </span>
                                </div>
                                <div className='mb-9 flex flex-col'>
                                    <label className="text-base text-white mb-2 inline-block">
                                        {L.youtube}
                                    </label>
                                    <div className="relative">
                                        <input
                                              className="bg-indigo-700 border-0 rounded-md w-100 input-prefix input-youtube text-white h-46"
                                              type="text"
                                              name="youtube"
                                              defaultValue={UserData.youtube}
                                              onChange={(e) => setFormData(e.target.value, 'youtube')}
                                          />
                                        <i className="icon-youtube"></i>
                                    </div>
                                    <span className="text-gray-300 text-sm mt-2 inline-block">
                                        {L.enterYoutubeID}
                                    </span>
                                </div>
                                <div className='mb-9 flex flex-col'>
                                    <label className="text-base text-white mb-2 inline-block">{L.facebook}</label>
                                    <div className="relative">
                                        <input
                                              className="bg-indigo-700 border-0 w-100 rounded-md input-prefix input-facebook text-white h-46"
                                              type="text"
                                              name="acc_facebook"
                                              defaultValue={UserData.facebook}
                                              onChange={(e) => setFormData(e.target.value, 'facebook')}
                                          />
                                        <i className="icon-facebook"></i>
                                    </div>
                                      <span className="text-gray-300 text-sm mt-2 inline-block">
                                        {L.enterFacebookUsername}
                                      </span>
                                </div>
                                <div className='mb-9 flex flex-col'>
                                    <label className="text-base text-white mb-2 inline-block">{L.linkedin}</label>
                                    <div className="relative">
                                        <input
                                            className="bg-indigo-700 border-0 w-100 rounded-md input-prefix input-linkedin text-white h-46"
                                            type="text"
                                            name="acc_linkedin"
                                            defaultValue={UserData.linkedin}
                                            onChange={(e) => setFormData(e.target.value, 'linkedin')}
                                        />
                                        <i className="icon-linkedin"></i>
                                    </div>
                                    <span className="text-gray-300 text-sm mt-2 inline-block">
                                        {L.enterLinkedinUsername}
                                    </span>
                                </div>
                            </Col>
                            <Col span={12} className="xs-p-0">
                            <div className='mb-9 flex flex-col'>
                                    <label className="text-base text-white mb-2 inline-block">{L.telegram}</label>
                                    <div className="relative">
                                        <input
                                              className="bg-indigo-700 border-0 w-100 rounded-md input-prefix input-telegram text-white h-46"
                                              type="text"
                                              name="acc_telegram"
                                              defaultValue={UserData.telegram}
                                              onChange={(e) => setFormData(e.target.value, 'telegram')}
                                        />
                                        <i className="icon-telegram"></i>
                                    </div>
                                    <span className="text-gray-300 text-sm mt-2 inline-block">
                                        {L.enterTelegramUsername}
                                    </span>
                                </div>
                                <div className='mb-9 flex flex-col'>
                                    <label className="text-base text-white mb-2 inline-block">
                                        {L.instagram}
                                    </label>
                                    <div className="relative">
                                        <input
                                              className="bg-indigo-700 w-100 border-0 rounded-md input-prefix input-instagram text-white h-46"
                                              type="text"
                                              name="acc_instagram"
                                              defaultValue={UserData.instagram}
                                              onChange={(e) => setFormData(e.target.value, 'instagram')}
                                        />
                                        <i className="icon-instagram"></i>
                                    </div>
                                      <span className="text-gray-300 text-sm mt-2 inline-block">
                                        {L.enterInstagramUsername}
                                      </span>
                                </div>
                                <div className='mb-9 flex flex-col'>
                                    <label className="text-base text-white mb-2 inline-block">
                                        {L.discord}
                                    </label>
                                    <div className="relative">
                                        <input
                                              className="bg-indigo-700 border-0 w-100 rounded-md input-prefix input-discord text-white h-46"
                                              type="text"
                                              defaultValue={UserData.discord}
                                              onChange={(e) => setFormData(e.target.value, 'discord')}
                                              name="acc_discord"
                                          />
                                        <i className="icon-discord"></i>
                                    </div>
                                        <span className="text-gray-300 text-sm mt-2 inline-block">
                                            {L.enterDiscordUsername}
                                        </span>
                                </div>
                                <div className='mb-9 flex flex-col'>
                                    <label className="text-base text-white mb-2 inline-block">
                                        {L.reddit}
                                    </label>
                                    <div className="relative">
                                        <input
                                              className="bg-indigo-700 border-0 rounded-md w-100 input-prefix input-reddit text-white h-46"
                                              type="text"
                                              defaultValue={UserData.reddit}
                                              onChange={(e) => setFormData(e.target.value, 'reddit')}
                                              name="acc_reddit"
                                          />
                                        <i className="icon-reddit"></i>
                                    </div>
                                        <span className="text-gray-300 text-sm mt-2 inline-block">
                                            {L.enterRedditUsername}
                                        </span>
                                </div>
                            
                            </Col>
                        </Row>
                        <div className="flex justify-center">
                            <Button type="submit" onClick={editSettings} className="rounded-2xl bg-cyan-500 border-0 px-12 flex items-center gap-x-3 text-indigo-900 hover:bg-cyan-400">
                                {loadingBtn ? <span className="loading-btn"></span> : ""}
                                {L.save}
                            </Button>
                        </div>
                    <Modal footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} className="modal">
                        <div className="text-center">
                        <i className="icon-check text-cyan-400 text-5xl mb-2"></i>
                            <p className="text-center text-white text-lg">
                                {L.successfullySaved}
                            </p>
                        </div>
                        <div className="flex items-center justify-center gap-x-4 mt-10">
                            <Button onClick={() => handleOk()} className="min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md">Ok</Button>
                        </div>
                    </Modal>
                </Content>
            </Layout>
        </div>
    )
}

export default Account;