import React, {useEffect, useState} from "react";
import {Input} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import {PATH} from "../../config";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";

const DiscoveryFilter = () => {
    let L = useSelector(state => state.settings.L);

    const filterNav = [
        {id: 1, name: `${L.discoveryFilterOverview}`, imgName: 'overview', path: ROUTES_CONFIG.discoveryOverview.path},
        {id: 2, name: `${L.discoveryFilterPopular}`, imgName: 'popular', path: ROUTES_CONFIG.discoveryPopular.path},
        {id: 3, name: `${L.discoveryFilterRate}`, imgName: 'rated', path: ROUTES_CONFIG.discoveryTopRated.path},
        {id: 4, name: `${L.discoveryFilterFeatured}`, imgName: 'featured', path: ROUTES_CONFIG.discoveryFeatured.path},
        {id: 5, name: `${L.discoveryFilterRecent}`, imgName: 'recently', path: ROUTES_CONFIG.discoveryRecently.path},
    ]

    const dispatch = useDispatch();
    const location = useLocation();

    let DiscoverySearchText = useSelector ((state) => state.metahut.DiscoverySearchText);

    const setSearchText = (value) => {
        SettingSearchText(value)
    }

    let Timeout;

    const SettingSearchText = (value) => {
    
        clearTimeout(Timeout)
    
        Timeout = setTimeout(function() {
            dispatch({ type: 'SetDiscoverySearchText', payload: value })
        }, 500);
    }

    return (
        <div className="flex items-center flex-wrap gap-y-8 gap-x-13 mb-20 mt-7">
            <Input size="large" placeholder={L.discoverySearch} className="input input-search"
                   prefix={<i className="icon-search text-gray-300 ml-1"></i>}
                   onChange={e => setSearchText(e.target.value)}
                   defaultValue={DiscoverySearchText}
            />
            <div className="flex items-center gap-x-6 gap-y-4 flex-wrap discovery-menu">
                {filterNav.map((item, id) => {
                    return <NavLink to={item.path} key={item.id}
                        className={`${location.pathname == item.name.toLowerCase() ? 'active' : ''} flex items-center gap-x-1`}
                    >
                        <img src={PATH + `profile/${item.imgName}.svg`} alt=""/>
                        <span className="font-mont font-bold text-base">{item.name}</span>
                    </NavLink>
                })}
            </div>
        </div>
    )
}

export default DiscoveryFilter;