import React, {useState} from 'react';
import JsxParser from 'react-jsx-parser';
import '../assets/css/accordion.css';
import arrowUp from '../assets/img/arrow-up.svg';
import arrowDown from '../assets/img/arrow-down.svg';

const Accordion = () => {
    const [selected, setSelected] = useState(null);
    const toggle = (i) => {
        if(selected === i) {
            return setSelected(null)
        }
        setSelected(i);
    }
    return (
        <div className="wrapper">
            <div className="accordion">
                {data.map((item, i) => (
                    <div className="item mb-3" key={i}>
                        <div className="title bg-indigo-900 hover:bg-indigo-400 rounded-md py-6 px-8 flex items-center justify-between" onClick={() => toggle(i)}>
                            <h2 className="font-medium text-lg text-white">{item.question}</h2>
                            <img src={selected === i ? arrowUp : arrowDown} alt=""/>
                        </div>
                        <div className={`text-base text-gray-300 ${selected === i ? 'content show' : 'content'}`}>
                            <div className="py-6">
                                <JsxParser
                                    jsx={item.answer}
                                />

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}


const data = [
    {
        question: 'How to stake $METO?',
        answer: `Below are a few resources you can use to learn how to stake $METO step-by-step:
            <ul className="list-circle pl-9">
                <li className="mt-2"><a href="https://medium.com/@metafluence/how-to-stake-meto-a-step-by-step-guide-818416fab347" target="_blank" rel="noreferrer" className="underline text-cyan-500">Blog post</a> (ENG)</li>
                <li className="mt-2"><a href="https://www.youtube.com/watch?v=Fw9Qu0A-Dmc&feature=youtu.be" target="_blank" rel="noreferrer" className="underline text-cyan-500">Video guide</a> (ENG)</li>
                <li className="mt-2"><a href="https://www.youtube.com/watch?v=SoOB_g4vxhU&feature=youtu.be" target="_blank" rel="noreferrer" className="underline text-cyan-500">Video guide</a> (RU)</li>
                <li className="mt-2"><a href="https://www.youtube.com/watch?v=0dL0gzS77XA" target="_blank" rel="noreferrer" className="underline text-cyan-500">Video guide</a> (TR)</li>
            </ul>`
    },
    {
        question: 'How does it work?',
        answer: 'You can stake from 2K to 1M $METO and receive respective compounded interest based on your chosen staking pool.',
    },
    {
        question: 'What are the staking options?',
        answer: `We have four staking options offering different time periods: 
            <ul className="list-circle pl-9"> 
                <li>NANO Pool: 3 months</li>
                <li>MICRO Pool: 6 months</li>
                <li>MACRO Pool: 9 months</li>
                <li>MEGA Pool: 1 year</li>
            </ul> 
            When you stake by choosing a specific time frame, you’ll receive the respective APY based on that specified pool.`
    },
    {
        question: 'How much is the APY, effective rate and monthly gain for each period?',
        answer: `The longer the staking period is, the higher the rewards are.
            <ul className="list-circle pl-9">
                <li className="mt-2">3 months (NANO Pool):
                    <ul className="list-disc pl-9">
                        <li>APY: 12%</li>
                        <li>The effective rate for the period: 3%</li>
                        <li>Monthly gain: 1%</li>
                    </ul>
                </li>
            <li className="mt-2">6 months (MICRO Pool):
                <ul className="list-disc pl-9">
                    <li>APY: 20%</li>
                    <li>The effective rate for the period: 10%</li>
                    <li>Monthly gain: 1.67%</li>
                </ul>
            </li>
            <li className="mt-2">9 months (MACRO Pool):
                <ul className="list-disc pl-9">
                    <li>APY: 26.66%</li>
                    <li>The effective rate for the period: 20%</li>
                    <li>Monthly gain: 2.22%</li>
                </ul>
            </li>
            <li className="mt-2">1 year (MEGA Pool):
                <ul className="list-disc pl-9">
                    <li>APY: 36%</li>
                    <li>The effective rate for the period: 36%</li>
                    <li>Monthly gain: 3%</li>
                </ul>
            </li>
            </ul>`,
    },
    {
        question: 'Is there any lockup period or penalty?',
        answer: `Staking METO doesn’t require you to lock up your tokens. You can withdraw your tokens at any time you wish. However, it comes with a cost. Withdrawing the assets before the end of chosen staking period results in a penalty depending on the number of days prior to maturity.
            <p className="mt-2"><i>There’s a different initial penalty percentage for each staking pool, and it decreases linearly towards zero, day by day until maturity.</i></p>
            <p className="mt-2">So using our <a href="https://docs.google.com/spreadsheets/d/1OeyrG7fEXSzSt9SiiUv_SUzSod2kZG7W/" target="_blank" rel="noreferrer" className="underline text-cyan-500">penalty schedule</a> you can calculate the exact penalty based on the staking pool and the number of days left to the end of the staking period.</p>`,
    },
    {
        question: 'Who can stake?',
        answer: 'Anyone holding a minimum of 2K $METO in their wallet can stake via our Staking Platform on desktop.'
    },
    {
        question: 'Which wallets can I use to stake $METO',
        answer: `$METO staking is currently only possible via 
         <a href="https://metamask.io/" target="_blank" rel="noreferrer" className="underline text-cyan-500">Metamask</a>
         platform on desktop.`
    },
    {
        question: 'Is there any limit on the staking amount?',
        answer: `We have minimum and maximum limits for each staking pool (in $METO).
            <ul className="list-circle pl-9">
                <li className="mt-2">NANO pool (3 months):
                    <ul className="list-disc pl-9">  
                        <li>Minimum: 2K</li>
                        <li>Maximum: 250K</li>
                    </ul>
                </li>
                <li className="mt-2">MICRO pool (6 months):
                    <ul className="list-disc pl-9">
                        <li>Minimum: 2K</li>
                        <li>Maximum: 500K</li>
                    </ul>
                </li>
                <li className="mt-2">MACRO pool (9 months):
                    <ul className="list-disc pl-9">
                        <li>Minimum: 2K</li>
                        <li>Maximum: 750K</li>
                    </ul>
                </li>
                <li className="mt-2">MEGA pool (12 months):
                    <ul className="list-disc pl-9">
                        <li>Minimum: 2K</li>
                        <li>Maximum: 1M</li>
                    </ul>
                </li>
            </ul>`,
    },
    {
        question: 'What are the maximum capacities of each pool?',
        answer: `Staking pools’ capacities are limited. It means if the amount of staked tokens in one pool in total (by others, as well) has reached the pool’s capacity limit, the pool will become unavailable for everyone to stake more. Capacities are as follows (in $METO):
            <ul className="list-circle pl-9">
                <li className="mt-2">NANO Pool: 5M</li>
                <li className="mt-2">MICRO Pool: 10M</li>
                <li className="mt-2">MACRO Pool: 15M</li>
                <li className="mt-2">MEGA Pool: 20M</li>
            </ul>`
    },
    {
        question: 'Which devices and browsers are supported?',
        answer: `You can stake $METO only on desktop for now. Unfortunately, staking on mobile is not supported yet. In terms of desktop browsers, five of them support MetaMask. If you have any problem with these browsers, check out our related 
                 <a href="https://medium.com/@metafluence/how-to-stake-meto-a-step-by-step-guide-818416fab347#efc4" target="_blank" rel="noreferrer" className="underline text-cyan-500">blog post</a> for troubleshooting:
            <ul className="list-circle pl-9">
                <li className="mt-2">Chrome</li>
                <li className="mt-2">Firefox</li>
                <li className="mt-2">Brave</li>
                <li className="mt-2">Edge</li>
                <li className="mt-2">Opera (with additional extension)</li>
            </ul>`
    },
];

export default Accordion;