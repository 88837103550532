import React, {useEffect, useState} from "react";
import {Col, Layout, Row} from "antd";
import {PATH} from "../../../config";
import axiosData from "../../../helpers/axiosData";
import Intro from "./Intro";
import Performance from "./Performance";
import {NavLink, useParams} from "react-router-dom";
import {ROUTES as ROUTES_CONFIG} from "../../../config/constants";
import { useSelector } from "react-redux";

const { Content } = Layout

const PublicAvatars = () => {
    let L = useSelector(state => state.settings.L)

    const AvatarBG = PATH+'avatarBG.png';
    const [AvatarFile, setAvatarFile] = useState(PATH+'avatar.png');
    
    let { username } = useParams();

    const[UserData, setUserData] = useState({});
        
    /* ------ getAllQuizes ---------*/
    const getUserData = (username) => {

        axiosData.get("/api/_web3/user/public-profile/"+username).then((res) => {
            if (res.data && res.data.success) {
                setUserData( res.data.data );
                setAvatarFile(res.data.data.avatars)

            } else {
            }
        })
    }
    
    useEffect(() => { 
        getUserData(username);  
    }, [username]);

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10">
                    <Intro GetUser={UserData}/>
                    <div className="flex items-start dashboard">
                        <div className="content-left">
                            <Performance GetUser={UserData} L={L}/>
                        </div>
                        <div className="content-right">
                            <div className="block font-bold mb-10 meta-card-header">
                                <NavLink to={`${ROUTES_CONFIG.publicProfile.path.replace(':username', username)}`} className="meta-nav">{L.myAssets}</NavLink> / <span className="text-white ">{L.avatars} ({UserData.avatars && UserData.avatars.length})</span>
                            </div>

                            <Row gutter={34}>
                                {UserData && UserData.avatars ?
                                    UserData.avatars.map((item, key) => {
                                        return (
                                            <Col xs={24} sm={24} md={12} lg={8} key={key} className="mb-10">
                                                <div className="relative avatar_box rounded-10px" style={{backgroundImage: `url(${AvatarBG})`, width: '100%', minHeight: '190px'}}>
                                                    {UserData.readyplayermeavatar == item.image? <i className="icon-check text-2xl absolute top-0 right-0" style={{color: "#00A76A", margin: "10px 14px"}}></i> : ''}
                                                    <img src={item.image} width="100%" height="100%" />
                                                </div>
                                            </Col>
                                        )
                                    }) :
                                    <Col xs={24} sm={24} md={12} lg={8} className="flex flex-col items-center assets-col">
                                        <div className="w-100 thumb">
                                            <div className="relative flex items-end avatar_box" style={{backgroundImage: `url(${AvatarBG})`, width: '100%', height: '217px'}}>
                                                <img src={AvatarFile} width="100%" height="213px" style={{objectFit: "cover"}} />
                                            </div>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default PublicAvatars;