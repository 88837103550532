import React from "react";
import {NavLink} from "react-router-dom";

const Action = ({L}) => {
    
    return (
        <div className="h-auto min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl uppercase font-medium text-sm">
            <NavLink to="/metahuts/new-metahut" className="text-indigo-900 flex items-center gap-x-2 py-2 px-5">
                <i className="icon-plus text-base"></i> {L.getMetahut}
            </NavLink>
        </div>
    )
}

export default Action;