import React, {useState, useEffect} from "react";
import {Button, Layout, Table, Row, Col, message} from "antd";
import {PATH} from "../../../config";
import {Link, NavLink} from "react-router-dom";
import axiosData from "../../../helpers/axiosData";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, selectUser } from "../../../redux/features/userSlice";
import NumberFormat from "react-number-format";
import ReferralsSidebar from "./ReferralsSidebar";
import { toast, ToastContainer } from "react-toastify";

const { Content } = Layout

const MonthlyPerformance = () => {
    const selector = useSelector;

    const DefaultAvatar = PATH+'avatar.png';
    const pageSize = 20;

    let GetUser = selector(selectUser);
    let L = selector (state => state.settings.L);

    const [messageApi, contextHolder] = message.useMessage();
    const [UserData, setUserData] = useState([]);
    const [ClaimingData, setClaimingData] = useState({1:0, 2:0, 3:0});
    const [packages, setPackages] = useState({turnover:0});
    const [SummaryData, setSummaryData] = useState({soto:[0,0], meto:[0,0],busd:[0,0]});
    const [UserDataWallet, setUserDataWallet] = useState([]);

    const dispatch = useDispatch();

    const dataWallet = (dataList) => {

        let List = [];

        if (dataList && dataList.length) {
            dataList.forEach(li => {

                List.push({
                    username: (
                        <div className="flex items-center gap-x-3">
                            <div className={`relative avatar_box ${li.level == 0 ? 'game_avatar' : ''}`} style={li.level == 0 ? {background: "none"} :
                                {backgroundColor: li.bgcolor, width: "30px", height: "30px", minWidth: "30px"}}
                            >
                                <img src={li.avatar} onError={(elem) => { elem.target.src=DefaultAvatar }}  width="100%" height="100%" className="avatar_img" />
                            </div>
                            <Link to={li.name!='' && li.name!=null ? "/user/"+li.name: '#'}>
                                <span className="text-cyan-500 text-wrap block" style={{maxWidth: "130px"}}>@{li.name}</span>
                            </Link>
                        </div>),
                    date: li.date,
                    depth: li.category,
                    type: li.type,
                    rewards: (
                        <div className="flex items-center gap-x-2">
                            <img src={PATH+li.currency_short.toLowerCase()+'_icon.svg'} alt="" style={{height: "20px"}} />
                            <span className="font-light text-base">
                                <NumberFormat
                                    value={li.value}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={(value) =><>{value}</>}
                                /> {li.currency_short}</span>
                        </div>
                    )
                })
            });
        }

        return List;
    }

    const dataAccording = (data) => {
        let List = [];

        if (data && data.length) {
            data.map(li => {

                List.push({
                    date: li.date,
                    rewards: (
                        <div className="flex items-center gap-x-2">
                            <img src={PATH+li.short.toLowerCase() + '_icon.svg'} alt="" style={{height: "20px"}} />
                            <span className="font-light text-base">
                                <NumberFormat
                                    value={li.crypto_value}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={(value) =><>{value}</>}
                                /> {li.short}</span>
                        </div>
                    ),
                status : (li.claimed ? 'Added to the wallet' : <Button onClick={() => claimCryptoAsset(li.id)} className="border-0 mt-2 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-normal gland-claim_btn">{L.claim}</Button>)                 

                })
            })
        }
        return List;
    } 

    const fetchUserData = async (dispatch, getState) => {
        try {
            await dispatch(fetchUser())
        } catch (error) {
            
        } 
    };

    
    const claimCryptoAsset = (id) => {

        axiosData
            .post('/api/_web3/user/claim-referral', {claim_id:id})
            .then((res) => {
                if (res && res.data.success) {
                    toast.success(L.claimRefSuccess);            
                    dispatch(fetchUserData)

                } else {
                    toast.error(L.errorOccured); 
                }
            })
            .catch((error) => {
                toast.error(L.errorOccured); 
            }) 
    }

    const columnActivity = [
        {
            title: L.source,
            width: 200,
            dataIndex: 'username',
            key: 'username',
            fixed: 'left',
        },
        {
            title: L.date,
            width: 150,
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: L.depth,
            dataIndex: 'depth',
            key: 'depth',
            width: 150,
        },
        {
            title: L.type,
            dataIndex: 'type',
            key: 'type',
            width: 170,
        },
        {
            title: L.rewards,
            dataIndex: 'rewards',
            key: 'rewards',
            width: 180,
        },
    ];

    const columnAccording = [
        {
            title: L.month,
            width: 150,
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: L.rewards,
            dataIndex: 'rewards',
            key: 'rewards',
            width: 180,
        },
        {
            title: L.status,
            dataIndex: 'status',
            key: 'status',
            width: 170,
        },
    ];

    const getUserData = () => {

        axiosData
            .get('/api/_web3/user/referral/statictics')
            .then((res) => {
                if ( res) {
                    let data = res.data;
                    setUserData(data);
                    setClaimingData(data.claiming)
                    setPackages(data.package)
                    setSummaryData(res.data.total)
                    setUserDataWallet(res.data.list.list)
                }
            })
            .catch((error) => {
                console.log('status error: ', error)
            })
    }


    useEffect(() => {
        getUserData()
    }, [])


    return <div className="container mx-auto pt-162 min-h-calc-head h-full">
        <Layout className="h-full settings-layout">
            <ReferralsSidebar />
            <ToastContainer />
            {contextHolder}
            <Content className="pt-8 pl-10 pr-10 settings-content">
                <h3 className="text-white text-xl font-bold settings-title">{L.navPerformance}</h3>
                <p className="font-light mb-0">{L.infNetworkDesc}</p>
                <Row gutter={24} className="mt-12">
                    <Col xs={24} sm={24} md={24} lg={12} className="mt-4">
                        <div className="bg-indigo-900 rounded-xl px-8 py-7">
                            <div className="text-sm uppercase flex items-center justify-between gap-x-3 mb-12">
                                <span className="text-gray-300 font-bold uppercase">{L.volume}</span>
                                <span className="text-cyan-400 font-normal">{packages.current_date}</span>
                            </div>
                            <div className="flex items-center gap-x-4 relative">
                                <img src={PATH+'people.svg'} alt="" className="absolute h-100 right-0" style={{filter: "brightness(0) invert(1)", minWidth: "192px", opacity: ".04", transform: "translate(0px, -5px)", scale: "1.1"}} />
                                <div className="flex flex-col gap-y-10 z-1">
                                    <div className="flex items-center gap-x-4">
                                        <img src={PATH+'soto_icon.svg'} alt="" style={{height: "32px"}}/>
                                        <div className="flex flex-col gap-y-1">
                                            <span className="text-lg font-light">
                                                <NumberFormat
                                                    value={SummaryData.soto[0]}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    renderText={(value) =><>{value}</>}
                                                /> SOTO</span>
                                            <span className="text-gray-light font-light">≈ {SummaryData.soto[1]} USD</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-x-4">
                                        <img src={PATH+'meto_icon.svg'} alt="" style={{height: "32px"}}/>
                                        <div className="flex flex-col gap-y-1">
                                            <span className="text-lg font-light">
                                                <NumberFormat
                                                    value={SummaryData.meto[0]}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    renderText={(value) =><>{value}</>}
                                                /> METO</span>
                                            <span className="text-gray-light font-light">≈ {SummaryData.meto[1]} USD</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-x-4">
                                        <img src={PATH+'bnb.svg'} alt="" style={{height: "32px"}}/>
                                        <div className="flex flex-col gap-y-1">
                                            <span className="text-lg font-light">
                                                <NumberFormat
                                                    value={SummaryData.busd[0]}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    renderText={(value) =><>{value}</>}
                                                /> BUSD</span>
                                            <span className="text-gray-light font-light">≈ {SummaryData.busd[1]} USD</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col flex-1 gap-y-1 items-center z-1">
                                    <span className="text-xl font-light uppercase">{L.totalVolume}</span>
                                    <span className="text-xl font-light text-cyan-400">
                                        ≈ <NumberFormat
                                        value={packages.total_usd}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={(value) =><>{value}</>}
                                    /> USD</span>
                                    <div className="flex items-center gap-x-2">
                                        <img src={PATH+'soto_icon.svg'} alt="" style={{height: "18px"}} />
                                        <img src={PATH+'meto_icon.svg'} alt="" style={{height: "18px"}}/>
                                        <img src={PATH+'bnb.svg'} alt="" style={{height: "18px"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} className="mt-4">
                        <div className="bg-indigo-900 rounded-xl px-9 py-7">
                            <div className="text-sm uppercase flex items-center justify-between gap-x-3 mb-11">
                                <span className="text-gray-300 font-bold">{L.status}</span>
                            </div>
                            <div className="flex items-center gap-x-6">
                                <div className="flex flex-col gap-y-5">
                                    <div className="flex flex-col gap-y-2">
                                        <span className="text-base font-light text-cyan-400 capitalcase">{L.volume}</span>
                                        <div className="text-xl font-light">
                                            {packages.turnover > 0 ?
                                                <><NumberFormat
                                                    value={packages.turnover}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    renderText={(value) =><>{value}</>}
                                                /> USD</> : '-'} </div>
                                    </div>
                                    <div className="flex flex-col gap-y-2">
                                        <span className="text-base font-light text-red">
                                            {L.allowance}
                                        </span>
                                        <div className="text-xl font-light">
                                            <NumberFormat
                                                value={packages.max_earn_usd}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value) =><>{value}</>}
                                            /> USD</div>
                                    </div>
                                    <div className="flex flex-col gap-y-2">
                                        <span className="text-base font-light text-green-500">
                                            {L.earnings}
                                        </span>
                                        <div className="text-xl font-light">{packages.earning > 0 ?
                                            <><NumberFormat
                                                value={packages.earning}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value) =><>{value}</>}
                                            /> USD</> : '-'}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col flex-1 gap-y-2 items-center">
                                    <div className="text-center pl-4">
                                        <img src={PATH+'ranks/'+packages.id+'/'+packages.rank+'.png'} alt="" style={{width: "100px"}} />
                                        <p className="text-base font-light">You are currently in <br/>
                                            <span className={`font-bold color-${packages.id}`}>
                                                {packages.name}
                                            </span> {L.sidebarStatus}
                                        </p>
                                        <p className="text-xs_ font-light">You must upgrade your status this month to ensure you get all the <span className="text-cyan-500">
                                            <NumberFormat
                                                value={packages.total_usd}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value) =><>{value}</>}
                                            /> USD</span> earnings from the referral program.
                                        </p>
                                        {GetUser && GetUser.status < 3 ?
                                            <NavLink to="/settings/status/">
                                                <Button className="border-0 mt-2 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium text-base package_btn">{L.statusDropdownUpgrade}</Button>
                                            </NavLink>
                                            :'' }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <h3 className="text-gray-300 text-lg font-light mt-16">{L.refActivity}</h3>
                <Table
                    className="referral-table mt-10 mb-16"
                    columns={columnActivity}
                    pagination={UserDataWallet.length > pageSize && {pageSize}}
                    dataSource={UserDataWallet && UserDataWallet.length ? dataWallet(UserDataWallet) : dataWallet([]) }
                    scroll={{
                        x: 800,
                    }}
                />

                <h3 className="text-gray-300 text-lg font-light mt-16">{L.refEarnings}</h3>
                <Table
                    className="referral-table mt-10 mb-16"
                    columns={columnAccording}
                    pagination={ClaimingData ? dataAccording(ClaimingData).length > pageSize && {pageSize} : ''}
                    dataSource={dataAccording(ClaimingData)}
                    scroll={{
                        x: 800,
                    }}
                />
            </Content>
        </Layout>
    </div>
}

export default MonthlyPerformance;