import React from "react";
import {Col, Row} from "antd";
import Preview from "./details/Preview";
import NameDesc from "./details/NameDesc";
import FileUpload from "./details/FileUpload";
import DateTime from "./details/DateTime";
import Category from "./details/Category";
import Participant from "./details/Participant";
import { useSelector } from "react-redux";


const Details = ({formTitle, formDesc}) => {

    let L = useSelector (state => state.settings.L);
    return (
        <>
            <div className="eform-content_head">
                <h2 className="eform-content_ttl">{formTitle}</h2>
                <p className="eform-content_desc">{formDesc}</p>
            </div>
            <div className="eform-content_body">
                <Row gutter={60}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <NameDesc L={L} />
                        <FileUpload L={L} />
                        <DateTime L={L} />
                        <Category L={L} />
                        <Participant L={L} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Preview L={L} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Details;