import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import Countdown from "./CountDown";
import { useSelector } from 'react-redux';

const ExpiredNotice = () => {
    let L = useSelector(state => state.settings.L);
    return (
        <div className="expired-notice">
            <span>{L.eventTimerExpired}</span>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    let L = useSelector(state => state.settings.L);
    return (
        <>
            <DateTimeDisplay value={days} type={L.days} />
            <DateTimeDisplay value={hours} type={L.hours} />
            <DateTimeDisplay value={minutes} type={L.mins} />
            <DateTimeDisplay value={seconds} type={L.seconds} />
        </>
    );
};

const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = Countdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountdownTimer;
