import {React, useState, useEffect} from "react";
import {Button, Select, notification, Checkbox} from "antd";
import './buy.css'; 

import { useSelector, useDispatch } from 'react-redux';
import { switchNetwork } from "../../../redux/actions/metahutActions";
import {PATH} from "../../../config";
import  actions  from "../../../redux/actions/";
import BuyMetahutLoading from "../../loaders/BuyMetahutLoading";
import { login } from "../../../redux/features/userSlice";
import { updateUserAccount } from "../../../config/CoreMethods";


const BuyContainer = () => {
    let L = useSelector(state => state.settings.L)

    const { approveMetahut } = actions;
    
    const onChange = (checkedValues) => {
        
        if (checkedValues.length === 2) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    };
    
    let BalanceBusd = useSelector ((state) => state.metahut.BalanceBusd) 
    let BalanceMeto = useSelector ((state) => state.metahut.BalanceMeto) 

    let FrontNetwork = useSelector ((state) => state.metahut.FrontNetwork) 
    let FrontLogin = useSelector ((state) => state.metahut.FrontLogin) 
    let BackLogin = useSelector ((state) => state.metahut.BackLogin) 

    let MetoConvertRate = useSelector ((state) => state.metahut.MapSettings.meto_price) 
    let MetahutBuyLoading = useSelector(state => state.metahut.MetahutBuyLoading)
    let State = useSelector(state => state)
    
    const dispatch = useDispatch()
    
    const { Option } = Select;

    let MetahutPriceMeto = 4150;  // IsWhiteList ? 3500 : 3900;
    let MetahutPriceBusd = 4150; // IsWhiteList ? 3700 : 4150;

    const [metahutCount, setmetahutCount] = useState(1);

    const [disable, setDisable] = useState(false);
    const [payErrorMessage, isPayErrorMessage] = useState(false);
    const [TotalBustPrice, setTotalBustPrice] = useState(MetahutPriceBusd);
    const [TotalMetoPrice, setTotalMetoPrice] = useState(MetahutPriceMeto);
    const [metoSpend, setMetoSpend] = useState(0)
    const [TotalMetoWillSpend, setTotalMetoWillSpend] = useState(0) 
    const [tokenType, setTokenType] = useState(1);


    useEffect(() => {

        setTotalBustPrice(MetahutPriceBusd);
        setTotalMetoPrice(MetahutPriceMeto);
        
        if ( MetoConvertRate ) {
            setTotalMetoWillSpend(Math.round(MetahutPriceMeto/parseFloat(MetoConvertRate)) * metahutCount);
            setMetoSpend(Math.round(MetahutPriceMeto/parseFloat(MetoConvertRate)))
        }

    }, [TotalMetoWillSpend, BalanceBusd, BalanceMeto, MetoConvertRate])


    const handleChange = (value) => {
        setmetahutCount(value)
        setTotalBustPrice(value * MetahutPriceBusd)
        setTotalMetoPrice(value * MetahutPriceMeto)
        setTotalMetoWillSpend(Math.round(MetahutPriceMeto/parseFloat(MetoConvertRate)) * value);
    };

    const changeToken = (value) => {
        setTokenType(value)
        isPayErrorMessage(false)
    };


    /** --------------------- */
    const calculateData = (is_meto, BalanceBusd, BalanceMeto, convert_price) => {
        let totalPrice=-1, balanceSwiched=-1;

        BalanceBusd = parseFloat(BalanceBusd)
        BalanceMeto = parseFloat(BalanceMeto)
        console.log('metahutCount', metahutCount, 'MetahutPriceMeto', MetahutPriceMeto, 'convert_price', convert_price )

        if (is_meto) {
            totalPrice = metahutCount * Math.round(MetahutPriceMeto/parseFloat(convert_price));
            balanceSwiched = BalanceMeto
            paymentTitle = 'METO'
            notification.destroy()
        } else {

            totalPrice = metahutCount*MetahutPriceBusd; // 105 MetahutPriceBusd
            balanceSwiched = BalanceBusd
            paymentTitle = 'BUSD'
            notification.destroy()
        }

        console.log('totalPrice', totalPrice, 'balanceSwiched', balanceSwiched, 'MetahutPriceMeto', MetahutPriceMeto, 'metoPrice', convert_price)

        if (balanceSwiched && 0 &&  balanceSwiched <= totalPrice) {
            openNotificationWithIcon('error')
            isPayErrorMessage(true)
            window.scrollTo(0, 0);

            return false;
        }

        return totalPrice;
    }


    /**----------- */
    const HandleClick = async (is_meto) => {

        console.log('MetoSettings.meto_price', MetoConvertRate)
        let totalPrice=-1;        

        if (FrontLogin && BackLogin) { 
            
            if ( FrontNetwork ) { 

                if (State.user.user.Account == '' || State.user.user.Account == null) {
                    updateUserAccount(State.metahut.Account)
                }
        
                totalPrice = calculateData(is_meto, BalanceBusd, BalanceMeto, MetoConvertRate);
                if (totalPrice !== false) {
                    dispatch( approveMetahut(is_meto, metahutCount, totalPrice) )
                }

            } else {

                dispatch(switchNetwork(state => { 
                    console.log('state', state);
                }))
            }

        } else if (window.ethereum) {
            
            dispatch(login())
            
        } else {
            openNotificationWithIcon('error')
            isPayErrorMessage(true);
        } 
    }


    let paymentTitle;

    const openNotificationWithIcon = type => {
        if (typeof paymentTitle != 'undefined') {            

            notification[type]({
                message: 'Error',
                // description: `You don't have enough ${paymentTitle} in your balance`,
                description: L.youDontHaveEnoughToken.replace('_TOKEN_', paymentTitle),
                maxCount: 1,
            });
        } else {
            notification['error']({
                message: 'Error',
                description: L.installMetamask,
                maxCount: 1,
            });
        }
    };


    return (
        <>
            <div className="bg-indigo-900 rounded-xl py-4 px-4 relative">

                {MetahutBuyLoading ? <BuyMetahutLoading /> : ""}

                <div className="px-4 mb-2">
                    <h2 className="text-gray-300 font-normal text-xl text-center">{L.payment}</h2>
                    { tokenType == 1 ? payErrorMessage ? <p>{L.enoughtMetoMsg}</p> : '' : payErrorMessage ? <p>{L.enoughtBusdMsg}</p> : ''}
                    <div className="border-indigo-800 border-b border-solid pb-5 pt-4">
                        <h3 className="uppercase text-gray-300 opacity-50 font-normal mb-5 text-base">
                            {L.price}
                        </h3>
                        <div className="flex item-center gap-x-6">
                            <div className="flex items-center gap-x-1 text-base">
                                <span className="text-xl">{tokenType == 1 ? MetahutPriceMeto : MetahutPriceBusd} $</span>
                            </div>
                            <Select defaultValue="1" onChange={changeToken} className="select-metahut select-payment">
                                <Option value="1">
                                    <img src={PATH + 'token.svg'} width="21px" alt="" />
                                </Option>
                                <Option value="2">
                                    <img src={PATH + 'bnb.svg'} width="21px" alt="" />
                                </Option>
                            </Select>
                        </div>
                        <div className="text-xxs font-light text-gray-300">
                            <span className="text-cyan-400">{tokenType == 1 ? metoSpend + ' $METO' : MetahutPriceBusd + ' $BUSD'}</span>
                        </div>
                    </div>
                </div>
                <div className="px-4 mb-2">
                    <div className="border-indigo-800 border-b border-solid pb-5 pt-4">
                        <h3 className="uppercase text-gray-300 opacity-50 font-normal mb-5 text-base">
                            {L.amount}
                        </h3>
                        <div className="flex items-center gap-x-4 text-base">
                            <Select defaultValue="1" style={{ width: 60 }} onChange={handleChange} className="select-metahut">
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                                <Option value="6">6</Option>
                                <Option value="7">7</Option>
                                <Option value="8">8</Option>
                                <Option value="9">9</Option>
                                <Option value="10">10</Option>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="px-4 py-4">
                    <h3 className="uppercase text-gray-300 opacity-50 font-normal mb-5 text-base">
                        {L.totalPrice}
                    </h3>
                    <div className="mb-4">
                        <div className="flex items-center gap-x-1 text-base">
                            <span className="text-xl">{tokenType == 1 ? TotalMetoPrice + '$' : TotalBustPrice + '$'}</span>
                        </div>
                        <div className="text-xxs font-light text-gray-300 min-h-24">
                            <span className="text-cyan-400"> {tokenType == 1 ? (!isNaN(TotalMetoWillSpend) ? TotalMetoWillSpend+' $METO' : '-') : TotalBustPrice + '$BUSD'}</span>
                        </div>
                    </div>
                    <Checkbox.Group className="mb-2" onChange={onChange}>
                        <Checkbox className="text-white text-xs mb-4" value="first">I accept the terms and conditions of the SAFT (land and metahut purchase agreement, <a href="https://metafluence.com/saftagreement/" target="_blank" className="text-cyan-400 underline">see link</a></Checkbox>
                        <Checkbox className="text-white text-xs ml-0 mb-4" value="second">
                            {L.buyMetahutCheckLabel2}
                        </Checkbox>
                    </Checkbox.Group>
                    <Button type="primary" size="large" disabled={disable ? '' : 'disable'} onClick={() => HandleClick((tokenType == 1?1:0))} className={`border-0 text-sm font-bold py-2 px-2 h-auto rounded-md w-full ${disable ? 'bg-cyan-500 text-indigo-900 hover:bg-cyan-400' : 'bg-indigo-600 text-white opacity-50 cursor-not-allowed'}`}>
                        {L.pay}    
                    </Button> 
                </div>
            </div>
            <div className="flex items-center justify-center gap-x-2 gap-y-2 py-4 mb-4 flex-wrap">
                <div className="flex items-center gap-x-2">
                    <i className="icon-info text-slate-400 text-base"></i>
                    <span className="text-slate-400">{L.enoughMETONote}</span>
                </div>
                <a href="https://promote.mexc.com/a/meto" className="underline text-cyan-500" target="_blank">{L.getMetoBuy}</a>
            </div>
        </>
    )
}

export default BuyContainer;