import React, { useEffect } from "react";
import {Layout} from "antd";
import "./profile/Profile.css";
import Intro from "./profile/Intro";
import Balance from "./profile/Balance";
import Performance from "./profile/Performance";
import Assets from "./profile/Assets";
import Badges from "./profile/Badges";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";

const { Content } = Layout

const Profile = () => {

    const selector = useSelector;

    let L = selector(state => state.settings.L);
    let GetUser = selector(selectUser);

    useEffect(()=>{
        if ( typeof GetUser != 'undefined' && GetUser==null ) {
            // redirectTo(ROUTES.loginPage.path)
        } 
    }, [GetUser])
    
    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10">
                    <Intro />
                    <div className="flex items-start dashboard mb-8">
                        <div className="content-left">
                            <Balance L={L} />
                            <Performance L={L} />
                        </div>
                        <div className="flex flex-col content-right">
                            <Assets />
                            <div className="line-x"></div>
                            <Badges L={L} />
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default Profile;