import React, {useEffect} from "react";
import {Col, Row} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";
import {PATH} from "../../config";
import { useSelector } from "react-redux";

const Footer = () => {

    const location = useLocation();

    let L = useSelector (state => state.settings.L);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [location]);

    return location.pathname != ROUTES_CONFIG.quests.path && location.pathname != ROUTES_CONFIG.home.path && location.pathname != '*' && <div className="footer bg-indigo-900">
        <div className="container mx-auto px-8">
            <div className="footer-info">
                <Row gutter={56} className="gap-y-10">
                    <Col xs={24} sm={11} md={11} lg={11}>
                        <div className="mb-17">
                            <NavLink to={ROUTES_CONFIG.homePage.path}>
                                <img src={PATH + 'footer-logo.svg'} alt=""/>
                            </NavLink>
                        </div>
                        <div className="flex gap-x-12 gap-y-4 flex-wrap">
                            <div className="flex gap-x-9">
                                <ul className="list-style pl-0 footer-list">
                                    <li><NavLink to={ROUTES_CONFIG.homePage.path}>{L.menuHome}</NavLink></li>
                                    <li><NavLink to={'#'}>Download</NavLink></li>
                                    <li><NavLink to={ROUTES_CONFIG.staking.path}>{L.navStaking}</NavLink></li>
                                    <li><a href="https://metafluence.gitbook.io/docs/" target="_blank" className="font-mont">{L.navDocs}</a></li>
                                    <li><a href="https://blog.metafluence.com/" target="_blank">
                                        {L.menuBlog}
                                    </a></li>
                                </ul>
                                <ul className="list-style pl-0 footer-list">
                                    <li><NavLink to={ROUTES_CONFIG.fag.path}>{L.navFaq}</NavLink></li>
                                    <li>
                                        <a href="https://github.com/metafluence" target="_blank">
                                            {L.github}
                                        </a>
                                    </li>
                                    <li><NavLink to={'#'}>Support</NavLink></li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-y-5 download">
                                <a href="https://app.metafluence.com/launcher/windows/" target="_blank">
                                    <img src={PATH + 'windowsOS.png'} alt=""/>
                                </a>
                                <a href="https://app.metafluence.com/launcher/mac/" target="_blank">
                                    <img src={PATH + 'macOS.png'} alt=""/>
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={13} md={13} lg={13}>
                        <Row gutter={26} className="gap-y-10">
                            <Col xs={24} sm={24} md={10} lg={10}>
                                <h4 className="uppercase mb-17 footer-title">
                                    {L.footerTitle1}
                                </h4>
                                <ul className="list-style pl-0 footer-list">
                                    <li>
                                        <a href="https://www.gate.io/trade/METO_USDT?ref=7372003" target="_blank">
                                            <img src={PATH + 'exchange/Gate.svg'} alt=""/> {L.gate}
                                        </a>
                                    </li>
                                    <li><a href="https://promote.mexc.com/a/meto" target="_blank"><img src={PATH + 'exchange/Mexc.svg'} alt=""/> {L.MEXCGlobal}</a></li>
                                    <li><a href="https://www.bitmart.com/trade/en-US?layout=basic&symbol=METO_USDT&r=38Rhj4" target="_blank"><img src={PATH + 'exchange/BitMart.svg'} alt=""/> {L.bitMart}</a></li>
                                    <li><a href="https://pancakeswap.finance/info/tokens/0xa78775bba7a542f291e5ef7f13c6204e704a90ba" target="_blank"><img src={PATH + 'exchange/PancakeSwap.svg'} alt=""/> {L.pancakeSwap}</a></li>
                                    <li><a href="https://coinmarketcap.com/currencies/metafluence/" target="_blank"><img src={PATH + 'exchange/CoinMarketCap.svg'} alt=""/> {L.coinMarketCap}</a></li>
                                    <li><a href="https://www.coingecko.com/en/coins/metafluence" target="_blank"><img src={PATH + 'exchange/CoinGecko.svg'} alt=""/> {L.coinGecko}</a></li>
                                </ul>
                            </Col>
                            <Col xs={24} sm={24} md={14} lg={14}>
                                <h4 className="uppercase mb-17 footer-title">
                                    {L.footerTitle2}
                                </h4>
                                <div className="flex gap-x-12 flex-wrap">
                                    <div>
                                        <ul className="list-style pl-0 footer-list">
                                            <li><a href="https://twitter.com/metafluence" target="_blank"><img src={PATH + 'profile/twitter.svg'} alt=""/> {L.twitter}</a></li>
                                            <li>
                                                <img src={PATH + 'profile/telegram.svg'} alt=""/> <span> {L.telegram} </span>
                                                <a href="https://t.me/metafluence" target="_blank">
                                                     EN
                                                </a> | <a href="https://t.me/MetafluenceTR" target="_blank">TR</a> | <a href="https://t.me/metafluenceRU" target="_blank">RU</a>
                                            </li>
                                            <li><a href="https://t.me/MetafluenceAnnouncements" target="_blank"><img src={PATH + 'profile/telegram.svg'} alt=""/> {L.telegramChannel}</a></li>
                                            <li><a href="https://discord.com/invite/metafluence-official" target="_blank"><img src={PATH + 'profile/discord.svg'} alt=""/> {L.discord}</a></li>
                                            <li><a href="https://www.youtube.com/channel/UCzl9203dLnRy4eh4e5mcrbg" target="_blank"><img src={PATH + 'profile/youtube.svg'} alt=""/> {L.youtube}</a></li>
                                            <li><a href="https://medium.com/@metafluence" target="_blank"><img src={PATH + 'medium-icon.svg'} alt=""/> {L.medium}</a></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className="list-style pl-0 footer-list">
                                            <li><a href="https://www.facebook.com/metafluence/" target="_blank"><img src={PATH + 'profile/facebook.svg'} alt=""/> {L.facebook}</a></li>
                                            <li><a href="https://www.instagram.com/metafluence.network/?hl=en" target="_blank"><img src={PATH + 'profile/instagram.svg'} alt=""/> {L.instagram}</a></li>
                                            <li><a href="https://www.linkedin.com/company/metafluence/" target="_blank"><img src={PATH + 'profile/linkedin.svg'} alt=""/> {L.linkedin}</a></li>
                                            <li><a href="https://www.tiktok.com/@metafluence?lang=en" target="_blank"><img src={PATH + 'tiktok.svg'} alt=""/> {L.tiktok}</a></li>
                                            <li><a href="https://www.reddit.com/r/Metafluence/" target="_blank"><img src={PATH + 'profile/iconfinder.svg'} alt=""/> {L.reddit}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="footer-bottom flex items-end justify-between gap-x-6 gap-y-4 flex-wrap">
                <div className="footer-copyright">
                    © 2023 Metafluence | <NavLink to={ROUTES_CONFIG.privacy.path}>
                        {L.privacyPolicy}
                        </NavLink> | <NavLink to={ROUTES_CONFIG.terms.path}>{L.termOfUse}</NavLink>
                </div>
                <div className="flex items-end gap-x-2">
                    <span>{L.subsidiaryOf}</span>
                    <div className="flex flex-col items-center gap-y-2">
                        <NavLink to={ROUTES_CONFIG.homePage.path}><img src={PATH + 'logo-icon.svg'} className="footer-logo" alt=""/></NavLink>
                        <a href="https://keepface.com" target="_blank"><img src={PATH + 'keepface-logo-white.svg'} alt=""/></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Footer;