const INITIAL_STATE = {
    selectedCountry: '',
    L : {
        pfpTitle: "Transform your PFP NFT into an playable 3D avatar"
    },
    waitLang : 0,
}


const settingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "setSelectedCountry" : return {...state, selectedCountry: action.payload};
        case "SetLang" : return {...state, L: action.payload};
        case "SetLangStatus" : return {...state, waitLang: action.payload};
        default : return state;
    }
}


export default settingsReducer;