import React, { useEffect, useState } from "react";
import {Col, Skeleton} from "antd";
import {NavLink} from "react-router-dom";
import {PATH} from "../../../../config";
import {ROUTES as ROUTES_CONFIG} from "../../../../config/constants";
import axiosData from "../../../../helpers/axiosData";

const Lands = ({GetUser, L}) => {

    const [listData, setListData] = useState([])


    const getAllData = (username) => {
        axiosData.get("/api/_web3/land/get-my-lands/"+username).then((res) => {

            if (res.data.data.length) {
                setListData( res.data.data );
            } else {
                setListData( [] );
            }
        })
    }

    useEffect(() => {
        
        if (typeof GetUser != 'undefined' && typeof GetUser.username != 'undefined' && GetUser.username != '' && GetUser.username != null ) {
            getAllData(GetUser.username)
        }

    }, [GetUser])


    return (
        listData && listData.length == 0 ?
        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col items-center assets-col">
            <div className="w-100 relative thumb thumb-buy">
                <div className="thumb-buy_bg"
                     style={{background: `url(${PATH + 'land-empty.png'}) no-repeat center center / cover`}}></div>
                <div className="flex flex-col items-center justify-center z-1 thumb-buy_content">
                    <p className="text-center mb-0 font-light thumb-buy_note">
                        {L.emptyLandNote}
                    </p>
                </div>
            </div>
            <span className="font-normal flex items-center gap-x-1 asset-link">{L.lands} (0)</span> 
        </Col> :
        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col items-center assets-col">
            <NavLink to={ROUTES_CONFIG.publicLands.path.replace(':username', GetUser.username)} className="w-100 thumb">
                {listData[0] ?
                    <div className="thumb-asset"
                         style={{background: `url(${listData[0] && PATH + 'crop/' + listData[0].id + '.png'}) no-repeat center center / cover`}}></div>
                    : <Skeleton.Image active className="land-asset_skeleton"/>
                }

                <div className="flex flex-col thumb-info thumb-info_land">
                    <p className="text-white mb-0 font-normal flex items-start gap-x-1 thumb-title">
                        <i className="text-pink-400 icon-pin"></i>
                        {listData[0] ?
                            <span className="font-light">{listData[0].real_coord}</span>
                            : <Skeleton active paragraph={false} title={{
                                width: "34px",
                                style: {margin: "0", height: "15px"}
                            }}></Skeleton>
                        }
                    </p>
                    <div className="flex items-center flex-wrap justify-between stats">
                        <span className="uppercase">{L.land}</span>
                        <span className="flex items-center land-id">
                            {listData[0] ? `#${listData[0].id}`
                                : <Skeleton active paragraph={false}
                                            title={{width: "41px", style: {margin: "0"}}}></Skeleton>}
                        </span>
                    </div>
                </div>
            </NavLink>
            <NavLink to={ROUTES_CONFIG.publicLands.path.replace(':username', GetUser.username)} className="font-normal flex items-center gap-x-1 asset-link">
                {L.lands} {listData.length ? `(${listData.length})`
                :
                <Skeleton active paragraph={false} title={{width: "22px", style: {margin: "0", height: "22px"}}}
                          className="inline"></Skeleton>}
            </NavLink>
        </Col>
    )
}

export default Lands;