import React, {useEffect, useState} from "react";
import EventsFilter from "./events/EventsFilter";
import {Col, Row, Button} from "antd";
import {Content} from "antd/lib/layout/layout";
import {PATH} from "../../config";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import axiosData from "../../helpers/axiosData";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ROUTES } from "../../config/constants";
import { ConvertDate, redirectTo } from "../../config/CoreMethods";
// import { parse } from "@ethersproject/transactions";

import JsxParser from 'react-jsx-parser';

// butun bu Parse olanlari jsx parserle evez elemek lazimdi
const MyEvents = () => {
 
    let L = useSelector(state => state.settings.L);
    let GetUser = useSelector(selectUser);
    const[Events, setEvents] = useState([]);

    const getEvents = () => {
        axiosData.get("/api/_web3/event/my-list").then((res) => {
            
            if (res.data.success) {
                setEvents(res.data.data);
            } else {
                toast.error(L.getEventResError)
            }
        })
    }

    console.log('Events',Events)

    useEffect(()=>{ 
        getEvents()
    },[GetUser])

    return (
        <div className="container mx-auto px-12 pr-0 relative pt-162 min-h-calc-head py-8">
            <div className="wrapper mt-16 mb-20">
                <EventsFilter />
                <Content className="font-mont events-list">
                    <h3 className="etrending-ttl">{L.titleMyEvents}</h3>
                    <Row gutter={30}>
                        {Events && Events.length ? 
                            Events.map(params => {
                                return <Col xs={24} sm={24} md={12} lg={6} key={params.id}>
                                    <div className="events-card">
                                        <span 
                                            className="cursor-pointer"
                                            onClick={ ()=>redirectTo(ROUTES.eventsUpdate.path.replace(':event_id', params.id)) } 
                                        >
                                            <div className="events-cover" style={{background: `url(${params.cover_image ? params.cover_image : PATH + 'metahut-owner.jpg'}) center center / cover`}}></div>
                                        </span>
                                        <div className="events_details">
                                            <div className="events_header">
                                                <span className="events_date">{ConvertDate(params.start_date_, 1)} PM</span>
                                                <div className="events-price events-price_meto">
                                                    <img src={PATH + params.ticket_type + '.svg'} alt=""/>
                                                    <span>{params.ticket_value}</span>
                                                </div>
                                            </div>
                                            <h2 className="events-title">{params.name}</h2>
                                            <span className="events-author">{L.byUsername && L.byUsername.replace('_USERNAME_', '@'+params.username)} </span>
                                            <div className="events-bottom">
                                                <div className="events-pool">
                                                    <div className="events-reward">
                                                        <span>{L.reward}:</span>
                                                    </div>
                                                    <div className="events-amount">
                                                        <img src={PATH + params.reward_type + '.svg'} alt=""/>
                                                        <span>{params.reward_value} {params.reward_type.toUpperCase()}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="events-status">
                                                {params.approved == 0 ?
                                                <Button className="draft-btn">
                                                    {L.myEventStatusDraft}
                                                </Button>
                                                : params.approved == 1 ?
                                                <Button className="host-btn">
                                                    {L.myEventStatusReviewed}
                                                </Button>
                                                :
                                                <Button className="finished-btn">
                                                    {L.myEventStatusFinished}
                                                </Button>
                                                }

                                                <p className="events-status_note">{L.youAreOwnerEvent && <JsxParser jsx={L.youAreOwnerEvent} /> }</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            })
                        :''} 
                    </Row>
                </Content>
            </div>
        </div>
    )
}

export default MyEvents;