import React from "react";
import {PATH} from "../../../config";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";
import {Skeleton} from "antd";

const Social = () => {
    const selector = useSelector
    let GetUser = selector(selectUser);

    return GetUser && GetUser.id != null ?
        <div className="flex items-center gap-x-3 social">
            {GetUser.twitter !='' && GetUser.twitter != null && GetUser.twitter.length >= 6 ?
                <a href={GetUser.twitter} target="_blank">
                    <img src={PATH + `profile/twitter.svg`} alt=""/>
                </a> : ''
            }
            {GetUser.telegram !='' && GetUser.telegram != null && GetUser.telegram.length >= 6 ?
                <a href={GetUser.telegram} target="_blank">
                    <img src={PATH + `profile/telegram.svg`} alt=""/>
                </a> : ''
            }
            {GetUser.youtube !='' && GetUser.youtube != null && GetUser.youtube.length >= 6 ?
                <a href={GetUser.youtube} target="_blank">
                    <img src={PATH + `profile/youtube.svg`} alt=""/>
                </a> : ''
            }
            {GetUser.discord !='' && GetUser.discord != null && GetUser.discord.length >= 6 ?
                <a href={GetUser.discord} target="_blank">
                    <img src={PATH + `profile/discord.svg`} alt=""/>
                </a> : ''
            }
            {GetUser.linkedin !='' && GetUser.linkedin != null && GetUser.linkedin.length >= 6 ?
                <a href={GetUser.linkedin} target="_blank">
                    <img src={PATH + `profile/linkedin.svg`} alt=""/>
                </a> : ''
            }
            {GetUser.instagram !='' && GetUser.instagram != null && GetUser.instagram.length >= 6 ?
                <a href={GetUser.instagram} target="_blank">
                    <img src={PATH + `profile/instagram.svg`} alt=""/>
                </a> : ''
            }
            {GetUser.facebook !='' && GetUser.facebook != null && GetUser.facebook.length >= 6 ?
                <a href={GetUser.facebook} target="_blank">
                    <img src={PATH + `profile/facebook.svg`} alt=""/>
                </a> : ''
            }
            {GetUser.reddit !='' && GetUser.reddit != null && GetUser.reddit.length >= 6 ?
                <a href={GetUser.reddit} target="_blank">
                    <img src={PATH + `profile/iconfinder.svg`} alt=""/>
                </a> : ''
            }
        </div> :
        <div className="flex items-center gap-x-3 social">
            <Skeleton active className="social-load" avatar={{shape: "square", style: {width: "24px", height: "24px", borderRadius: "6px"}}} paragraph={false} title={false}></Skeleton>
            <Skeleton active className="social-load" avatar={{shape: "square", style: {width: "24px", height: "24px", borderRadius: "6px"}}} paragraph={false} title={false}></Skeleton>
        </div>
}

export default Social;