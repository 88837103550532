import React from "react";
import {Button, Progress} from "antd";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

const BuyMetahutLoading = () => {

    const dispatch = useDispatch();
    let L = useSelector (state => state.settings.L);
    let MetahutBuyLoadingMessage = useSelector ((state) => state.metahut.MetahutBuyLoadingMessage)
    
    let LoadingType = useSelector ((state) => state.metahut.MetahutBuyLoading)

    let Loadings = [
        [],
        [L.loadingPaymentProcessing,'25', false,''],
        [L.loadingPaymentProcessing,'50', false,''],
        [L.loadingPaymentComleted,'100', true,''],
        [MetahutBuyLoadingMessage,'100', true,'exception'],
    ];

    return (

        <>
            <div className="absolute top-0 left-0 h-full bg-indigo-900 w-100 z-1 flex flex-col items-center justify-center gap-y-10">
                <div className="flex flex-col items-center px-6">
                    <p className="text-base">{Loadings[LoadingType][0]}</p>
                    <Progress type="circle" percent={Loadings[LoadingType][1]} width={80} className="pay-progress" status={Loadings[LoadingType][3]} />
                </div>
                {Loadings[LoadingType][2] ?
                    LoadingType == 3 ?
                    <div className="flex items-center justify-center gap-x-6">
                        <Link to="/nfts">
                            <Button type="primary" size="large" className="border-0 bg-indigo-600 text-gray-300 hover:bg-indigo-800 rounded-md" onClick={() => dispatch({type: 'SetMetahutBuyLoading', payload: 0})}>{L.myMetahuts}</Button>
                        </Link>
                        <Link to="/">
                            <Button type="primary" size="large" className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md" onClick={() => dispatch({type: 'SetMetahutBuyLoading', payload: 0})}>{L.ownMetahutPlot}</Button>
                        </Link>
                    </div>
                    : 
                    <div className="flex items-center justify-center">
                        <Button type="primary" size="large" className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-md" onClick={() => dispatch({type: 'SetMetahutBuyLoading', payload: 0})}>Ok</Button>                        
                    </div>

                : ""}
            </div>
        </>
    )
}

export default BuyMetahutLoading;