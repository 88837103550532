const INITIAL_STATE = {
    activeTab: 'all',
    activeStep: 'overview',
    activeReward: 'busd',
    activeTicket: 'free',
    formData : {
        name: '',
        description:'',
        cover_image: '',
        cover_image_src: '',
        start_date:'',
        start_time:'23:59',
        end_date:'',
        end_time:'23:59',
        categories: 1,
        metahut_id:0,
        reward_type: 1,
        reward_value:0,
        ticket_type: 0,
        ticket_value:0,
        whatsapp: '',
        discord: '',
        linkedin: '',
        telegram: '',
        Hosts : [],
        participant_count: 100,
        metahuts : [],
        trigger : 1,
        executed : 0,
        attend_time_percent : 50
    },
    // trigger : 1
}


const eventReducer = (state = INITIAL_STATE, action) => {
    // state = {...state, trigger: state.trigger+1}
    switch (action.type) {
        case "setActiveTab" : return {...state, activeTab: action.payload};
        case "setActiveStep" : return {...state, activeStep: action.payload};
        case "setActiveReward" : return {...state, activeReward: action.payload};
        case "setActiveTicket" : return {...state, activeTicket: action.payload};
        case "setFormData" : return {...state, formData: action.payload};
        case "setAllHosts" : return {...state, AllHosts: action.payload};
        default : return state;
    }
}


export default eventReducer;