import React, {useEffect, useState} from "react";
import {Drawer, Button, Skeleton, Modal, Dropdown, Menu, Space} from "antd";
import {Link, NavLink, useLocation} from 'react-router-dom';
import Sidebar from "./Sidebar";
import "./Navbar.css";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from 'react-redux';
import {login, destroyUser} from '../../redux/features/userSlice';
import {PATH} from '../../config';
import { selectUser } from "../../redux/features/userSlice";
import  actions  from "../../redux/actions/"; 
import { getCookie } from "../../config/CoreMethods";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";


const { InitWalletConnect, CheckNetwork, getUserBalance, getMapSettings, switchNetwork } = actions;

const Navbar = ({ OnSale, Is404Page, L }) => {

    const selector = useSelector;

    const Traveler = PATH+'traveler.svg';
    const Resident = PATH+'resident.svg';
    const Metapreneur = PATH+'metapreneur.svg';

    const [AvatarFile, setAvatarFile] = useState(PATH+'avatar.png')

    const AvatarOnError = () => {
        setAvatarFile(PATH+'avatar.png')
    }

    const location = useLocation();
    const dispatch = useDispatch();

    let GetUser = selector(selectUser);
    let launchpadPlotLimit = useSelector ((state) => state.land.LaunchpadPlotLimit);
    let CurrentAccount = selector ((state) => state.metahut.Account)
    let ShowDrawer = selector ((state) => state.menu.ShowDrawer)
    let BackLogin = selector ((state) => state.metahut.BackLogin)
 
    const Statuses = {
        1: {name: L.traveler, icon: Traveler},
        2: {name: L.resident, icon: Resident},
        3: {name: L.metapreneur, icon: Metapreneur}
    }
    

    const menuDropdown = (
        <Menu
            className="user-dropdown"
            items={[
                {
                    label: <NavLink to={ROUTES_CONFIG.account.path}>{L.userDropAccount}</NavLink>,
                    key: '1',
                },
                {
                    label: <NavLink to={ROUTES_CONFIG.status.path}>{L.userDropStatus}</NavLink>,
                    key: '2',
                },
                {
                    label: <NavLink to={ROUTES_CONFIG.wallet.path}>{L.userDropWallet}</NavLink>,
                    key: '3',
                },
                {
                    label: <NavLink to={ROUTES_CONFIG.claims.path} className="flex items-center gap-x-4" style={{display: "flex"}}>Claims {(GetUser && GetUser.referral_claimable && GetUser.referral_claimable.length) || launchpadPlotLimit!='-' ? <span className="font-bold text-center counter">{ (GetUser && GetUser.referral_claimable && GetUser.referral_claimable.length ? GetUser.referral_claimable.length : 0) +(launchpadPlotLimit!='-'?launchpadPlotLimit:0)}</span>:''}</NavLink>,
                    key: '4',
                },
                {
                    label: <NavLink to={ROUTES_CONFIG.securityPrivacy.path}>{L.userDropSecurity}</NavLink>,
                    key: '5',
                },
                {
                    label: <NavLink to={ROUTES_CONFIG.account.path}>{L.userDropSettings}</NavLink>,
                    key: '6',
                },
                {
                    label: <a onClick={()=> { dispatch(destroyUser()) }}>{L.userDropLogOut}</a>,
                    key: '7',
                },
            ]}
        />
    );

    const walletDropdown = (
        <Menu
            className="user-dropdown wallet-dropdown"
            items={[
                {
                    label: (
                        <div className="flex items-center justify-between gap-x-14">
                            <div className="flex items-center gap-x-2">
                                <img src={PATH+'soto_icon.svg'} alt="" width="24px" />
                                {GetUser && typeof GetUser.soto_balance != 'null' ?
                                    <NumberFormat value={GetUser.soto_balance} displayType={'text'}
                                                  thousandSeparator={true} className="text-zinc-300 text-base font-light"
                                                  renderText={(value, props) =><span {...props}>{value}</span>}
                                    />
                                    :
                                    <Skeleton.Button active size={"small"} /> 

                                }
                            </div>
                        </div>
                    ),
                    key: '0',
                },
                {
                    label: (
                        <div className="flex items-center justify-between gap-x-14">
                            <div className="flex items-center gap-x-2">
                                <img src={PATH+'meto_icon.svg'} alt="" width="24px" />
                                {GetUser && typeof GetUser.meto_balance != 'null' ?
                                    <NumberFormat value={GetUser.meto_balance} displayType={'text'}
                                                  thousandSeparator={true} className="text-zinc-300 text-base font-light"
                                                  renderText={(value, props) =><span {...props}>{value}</span>}
                                    />
                                    :
                                    <Skeleton.Button active size={"small"} /> 

                                }
                            </div>
                            <a href="https://promote.mexc.com/a/meto" target="_blank" className="get-link">{L.getMETO}</a>
                        </div>
                    ),
                    key: '1',
                },
                {
                    label: (
                        <div className="flex items-center gap-x-2">
                            <img src={PATH+'bnb.svg'} alt="" width="24px" />
                            {GetUser && typeof GetUser.busd_balance != 'null' ?
                                <NumberFormat value={GetUser.busd_balance} displayType={'text'}
                                              thousandSeparator={true} className="text-zinc-300 text-base font-light"
                                              renderText={(value, props) => <span {...props}>{value}</span>}
                                />
                                :
                                <Skeleton.Button active size={"small"} /> 

                            }
                        </div>
                    ),
                    key: '2',
                },
                {
                    label: (
                        <div className="text-center">
                            <NavLink to="settings/wallet" style={{padding: "0", display: "inline-block"}}>
                                <Button className="border-0 bg-cyan-500 mt-2 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium text-sm drop-btn">
                                    {L.walletDropWallet}
                                </Button>
                            </NavLink>
                        </div>
                    ),
                    key: '3',
                },
            ]}
        />
    );

    const statusDropdown = (
        <Menu
            className="user-dropdown status-dropdown"
            items={[
                {
                    label: (
                        <div className="text-center">
                            <img src={`${PATH+(GetUser && 'ranks/'+GetUser.status+'/'+GetUser.rank)}.png`} alt=""  width="80px" height="80px" />
                            <p className="text-lg font-light text-white mb-0">You are currently in <br/>
                                <span className={`drop-status color-${GetUser &&  GetUser.status}`}>{GetUser && Statuses[GetUser.status].name}</span> status
                            </p>

                            { GetUser && GetUser.status < 3 ?
                                <NavLink to="/settings/status/">
                                    <Button className="border-0 mt-2 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-medium text-sm drop-btn">
                                        {L.statusDropdownUpgrade}
                                    </Button>
                                </NavLink>
                                :''}
                        </div>
                    ),
                    key: '0',
                },
            ]}
        />
    );

    const showSidebar = () => {
        dispatch({type: 'setShowDrawer', payload: true});
    };

    const drawerClosed = () => {
        dispatch({type: 'setShowDrawer', payload: false});
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    
    const StartNetworkSwitch = () => {

        dispatch(switchNetwork( () => {
            dispatch(CheckNetwork( (chainTrue)=>{
                if ( chainTrue ) {
                    dispatch(InitWalletConnect( ()=>{
                        dispatch( getUserBalance( () => {
                            dispatch( getMapSettings( async (State) => {  }) ) 
                        }) ) 
                    } ))
                }
            } ))
        }))

    }

    useEffect(() => {

        if (GetUser && GetUser.avatar) {
            setAvatarFile(GetUser.avatar)
        }

    }, [GetUser, CurrentAccount])


    let mode = getCookie('mode'); 
    
    return (
        <div className={`px-4 py-4 z-21 bg-${location.pathname == '/' || location.pathname == '/pfpnft-avatar' ? 'transparent' : 'indigo-900'}`} style={{minHeight:'92px'}}>
            <Modal footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} className="modal modal-middle">
                {
                    <div className="px-6 py-8 h-70 flex flex-col justify-center gap-y-4">
                        {mode !='game' ?
                            window.ethereum ? 
                                <Button onClick={()=>{dispatch(login());}} className="button--sign border-0 focus:outline-none text-base font-bold text-white rounded-md flex items-center">
                                    <img src={PATH+'metamask.png'} alt="" className="button--img" />
                                    {L.connectMetamask}
                                </Button>
                                :
                                <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank">
                                    <Button className="button--sign border-0 focus:outline-none text-base font-bold text-white rounded-md flex items-center">
                                        <img src={PATH+'metamask.png'} alt="" className="button--img" />
                                        {L.installMetamask}
                                    </Button>
                                </a>
                            :''
                        }
                        <Link onClick={() => setIsModalVisible(false)} to="/login" className="button--sign border-0 focus:outline-none text-base font-bold text-white rounded-md flex items-center">
                            <img src={PATH+'email.png'} alt="" className="button--img" />
                            {L.signInWithEmail}
                        </Link>
                    </div>
                }

            </Modal>
            <nav className="flex justify-between items-center container mx-auto">
                <div className="flex items-center gap-x-2">
                    <NavLink to={ROUTES_CONFIG.homePage.path}><img src={PATH+'logo.svg'} className="w-auto" alt=""/></NavLink>
                </div>
                <div className="flex items-center gap-x-2">
                    <div className="flex items-center gap-x-6 visible-lg nav-right">
                        { BackLogin ?
                            !Is404Page ? 
                            <>
                                <Dropdown overlay={walletDropdown} getPopupContainer={triggerNode => triggerNode.parentElement} overlayClassName="wallet-pos" arrow={false} trigger={['click']} placement="bottomLeft">
                                    <a onClick={(e) => e.preventDefault()} style={{marginRight: "-10px"}} className="nav-wallet">
                                        <Space style={{columnGap: "10px"}}>
                                            <img src={PATH+'coin.svg'} width="100%" height="100%"/>
                                            <span className="text-lg font-mont font-medium">
                                                {L.walletDropWallet}
                                            </span>
                                        </Space>
                                    </a>
                                </Dropdown>
                                <Dropdown overlay={statusDropdown} getPopupContainer={triggerNode => triggerNode.parentElement} overlayClassName="wallet-pos" arrow={false} trigger={['click']} placement="bottomLeft">
                                    <a onClick={(e) => e.preventDefault()} className="nav-status">
                                        <Space>
                                            <img src={`${PATH+(GetUser && 'ranks/'+GetUser.status+'/'+GetUser.rank)}.png`} width="54px" height="54px"  style={{marginRight: "-10px"}} />
                                            <span className={`text-lg font-mont font-medium color-${GetUser && GetUser.status}`}>{GetUser && Statuses[GetUser.status] ? Statuses[GetUser.status].name:''}</span>
                                        </Space>
                                    </a>
                                </Dropdown>
                            </> : ''
                            :''} 
                            { BackLogin ?
                                <Dropdown overlay={menuDropdown} getPopupContainer={triggerNode => triggerNode.parentElement} overlayClassName="wallet-pos" arrow={false} trigger={['click']} placement="bottomLeft">
                                    <a onClick={(e) => e.preventDefault()} className="nav-user">
                                        <Space style={{columnGap: "10px"}}>
                                            <div className="relative avatar_box user-box"
                                                style={{backgroundColor: GetUser && GetUser.bgcolor}}
                                            >
                                                <img src={AvatarFile} onError={AvatarOnError} width="100%" height="100%" className="user-avatar"/>
                                            </div>
                                            <span className="text-lg font-mont font-medium">{GetUser ? GetUser.name && GetUser.name!=='' ? GetUser.name : (GetUser.username && GetUser.username !=='' ? GetUser.username : String(GetUser.account).substring(0, 6)+'...' + String(GetUser.account).substring(38)) : '...' }</span>
                                            <i className="icon-angle-right drop-icon"></i>
                                        </Space>
                                    </a>
                                </Dropdown>
                                :
                                <div className="flex items-center gap-x-2">
                                    <NavLink to={ROUTES_CONFIG.loginPage.path}>
                                        <Button className="button--gradient button-join border-0 focus:outline-none text-sm text-white py-1 px-7 rounded-2xl uppercase">{L.joinButton}</Button>
                                    </NavLink>
                                </div>
                        }
                    </div>


                    <Button className="hidden-md" type="link" onClick={showSidebar}>
                        <img src={PATH+'menu-icon.svg'} alt=""/>
                    </Button>
                    <Drawer
                        title={<NavLink to="/" onClick={() => drawerClosed()}><img src={PATH+'logo.svg'} alt="" /></NavLink>}
                        placement="right"
                        className="menu-drawer hidden-md"
                        closable={true}
                        onClose={drawerClosed}
                        visible={ShowDrawer}
                        closeIcon={<img src={PATH+'close-icon.svg'} alt="" />}
                        width="100%"
                    >
                        <Sidebar switchNetwork={StartNetworkSwitch} OnSale={OnSale} statuses={Statuses} />
                    </Drawer>
                </div>
            </nav>
        </div>
    )
}
export default Navbar;