import React from "react";
import {Progress} from "antd";
import {useSelector} from "react-redux";

const ProgressBar = () => {

    const selector = useSelector

    let LaunchpadPlotLimit = selector ((state) => state.land.LaunchpadPlotLimit);
    let BalanceBusd = selector ((state) => state.metahut.BalanceBusd);
    let BalanceMeto = selector ((state) => state.metahut.BalanceMeto);
    let FrontNetwork = selector ((state) => state.metahut.FrontNetwork)
    let FrontLogin = selector ((state) => state.metahut.FrontLogin)
    let MetoSettings = selector ((state) => state.metahut.MapSettings)
    let LandSaleIsOpen = selector ((state) => state.land.LandSaleIsOpen)
    let IsWhiteList = selector ((state) => state.land.IsWhiteList)

    let List = [FrontLogin?1:0, FrontNetwork?1:0, (BalanceBusd!='-'?1:0), (BalanceMeto=='-'?0:1), LaunchpadPlotLimit!='-'?1:0,
        MetoSettings.meto_price?1:0, LandSaleIsOpen!==0?1:0, IsWhiteList!==0?1:0];

    let ProgressCalc = () => {

        if (!window.ethereum || !FrontNetwork || (window.ethereum && !FrontLogin)) {
            return 100;
        }

        let counter = 0;
        for (let i = 0; i < List.length; i++) {
            counter += List[i]? 1 : 0;
        }
        return (counter*100)/List.length;
    };

    return <div className="absolute w-full">

        {/*==== Status bar on the top of menu ====*/}
        {ProgressCalc() < 100 ?
            <Progress
                strokeLinecap="butt"
                percent={ProgressCalc()} format={() => ``}
                status="disable"
                className="relative statusbar"
            />
            :""}
    </div>
}

export default ProgressBar;