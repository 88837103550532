import React, { useEffect, useState } from "react";
import {Button, Col} from "antd";
import {NavLink} from "react-router-dom";
import {PATH} from "../../../../config";
import {ROUTES as ROUTES_CONFIG} from "../../../../config/constants";
import axiosData from "../../../../helpers/axiosData";

const Metahuts = ({L}) => {
      
    const [listData, setListData] = useState([]);

    const getAllData = () => {
        axiosData.get("/api/_web3/metahuts/my-list").then((res) => {

            if (res.data.data.length) {
                setListData( res.data.data ); 
            } else {
                setListData( [] );     
            }
        })
    }
    
    useEffect(() => {
        
        getAllData()

    }, [])


    return (
        listData && listData.length ?
        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col items-center assets-col">
            <NavLink to={ROUTES_CONFIG.metahuts.path} className="w-100 thumb">
                <div className="relative thumb-asset list-metahut" 
                    style={{background: `url(${listData[0].cover_image !='' ? listData[0].cover_image : PATH+'/profile/metahut.png'})  no-repeat center center / contain`}}></div>
                <div className="flex flex-col thumb-info">
                    <p className="text-white mb-0 font-normal thumb-title">{listData[0].land_name}</p>
                    <div className="flex items-center flex-wrap stats">
                        <div className="flex items-center stats-item">
                            <i className="icon-people"></i>
                            <span>{listData[0].followers}</span>
                        </div>
                        <div className="flex items-center stats-item">
                            <i className="icon-eye"></i>
                            <span>{listData[0].visitors}</span>
                        </div>
                        <div className="flex items-center stats-item">
                            <i className="icon-star"></i>
                            <span>{listData[0].rate}</span>
                        </div>
                    </div>
                </div>
            </NavLink>
            <NavLink to={ROUTES_CONFIG.metahuts.path} className="font-normal asset-link">
                {L.metahuts} ({listData.length})
            </NavLink>
        </Col> :
        <Col xs={24} sm={24} md={8} lg={8} className="flex flex-col items-center assets-col">
            <div className="w-100 relative thumb thumb-buy">
                <div className="thumb-buy_bg" 
                    style={{background: `url(${PATH+'metahut-empty.png'}) no-repeat center center / cover`}}></div>
                <div className="flex flex-col items-center justify-center z-1 thumb-buy_content">
                    <p className="text-center mb-0 font-light thumb-buy_note">
                        {L.emptyMetahutNote}
                    </p>
                    <NavLink to={ROUTES_CONFIG.getMetahut.path}>
                        <Button type="primary" className="min-w-24 border-0 text-indigo-900 hover:bg-cyan-400 thumb-buy_btn">{L.metahutBuyBtn}</Button>
                    </NavLink>
                </div>
            </div>
            <span className="font-normal asset-link">{L.metahuts} (0)</span>
        </Col>
    )
}

export default Metahuts;