import React, {useEffect, useState} from "react";
import {MenuNav, Navbar, ProgressBar} from "../pages";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";
import {useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";
import { Modal, Button } from "antd";

const Header = ({accountPage, securityPage}) => {

    const selector = useSelector;
    const dispatch = useDispatch();

    let L = selector (state => state.settings.L);
    let IsMetamask = selector((state) => state.metahut.IsMetamask)
    let IsMobile = selector((state) => state.metahut.IsMobile)

    const location = useLocation();

    const [Is404Page, setIs404Page] = useState(0);


    useEffect(() => {
        window.scrollTo(0,0);
        
        let founded=0;

        for (const key in ROUTES_CONFIG) {
            if (Object.hasOwnProperty.call(ROUTES_CONFIG, key)) {                
                if (ROUTES_CONFIG[key].path == location.pathname) {
                    founded=1; 
                    break;
                }
            }
        }
        
        if (founded) {
            setIs404Page(0)
        } else {
            setIs404Page(1)
        }

    }, [location]);

    return location.pathname != ROUTES_CONFIG.quests.path &&
            <div className={`w-full z-20 ${location.pathname == '/' || location.pathname == '/pfpnft-avatar' ? 'bg-gradient absolute' : 'fixed'}`}>

                <ProgressBar/>
                <ToastContainer />
                <Navbar 
                    Is404Page={Is404Page} 
                    L={L} 
                />
                <MenuNav 
                    accountPage={accountPage}
                    securityPage={securityPage} 
                    L={L}
                />
                {IsMobile && !IsMetamask ?
                    <div
                        className="visible-xs flex p-2 bg-cyan-400 text-indigo-900 text-base items-center gap-x-2 justify-center w-full">
                        <a href="https://metamask.app.link/dapp/app.metafluence.com/city/index.html"
                            className="text-indigo-900 underline">{L.goToMetamask}</a>
                    </div>
                    : ''}
            </div>
}

export default Header;