import React, {useEffect, useState} from "react";
import EventsFilter from "./events/EventsFilter";
import {Col, Row} from "antd";
import {Content} from "antd/lib/layout/layout";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import axiosData from "../../helpers/axiosData";
import { toast, ToastContainer } from "react-toastify";
import EventsCard from "./events/EventsCard";

const PastEvents = () => {
    const selector = useSelector

    const[Events, setEvents] = useState([]);

    let GetUser = selector(selectUser);
    let L = selector(state => state.settings.L);

    const getEvents = (inFilter) => {

        let internal = inFilter ? '-internal' : '';
        axiosData.get("/api/_web3/event/list"+internal+"?past=1").then((res) => {
            
            if (res.data.success) {
                setEvents(res.data.data);
            } else {
                toast.error(L.eventListErr)
            }
        })
    }


    useEffect(()=>{ 
        getEvents(GetUser && GetUser.id)
    },[GetUser])

    return (
        <div className="container mx-auto px-12 pr-0 relative pt-162 min-h-calc-head py-8">
            <div className="wrapper mt-16 mb-20">

                <ToastContainer />
                <EventsFilter />
                <Content className="font-mont events-list">
                    <h3 className="etrending-ttl">{L.titlePastEvents}</h3>
                    <Row gutter={30}>
                         
                        {Events.length ? 
                            Events.map(li => {  
                                return <Col xs={24} sm={24} md={12} lg={6} key={li.id}>
                                    <EventsCard params={li} />
                                </Col>
                            })
                        :''}
                        
                    </Row>
                </Content>
            </div>
        </div>
    )
}

export default PastEvents;