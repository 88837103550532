import { useEffect, useState } from 'react';

const Countdown = (targetDate) => {

    const countDownDate = new Date(targetDate).getTime();
    const now = new Date().getTime();

    const [countDown, setCountDown] = useState(countDownDate - now);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    let SECOND = 1000;
    let MINUTE = SECOND * 60;
    let HOUR = MINUTE * 60;
    let DAY = HOUR * 24;

    const days = Math.floor(countDown / (DAY));
    const hours = Math.floor((countDown % (DAY)) / (HOUR));
    const minutes = Math.floor((countDown % (HOUR)) / (MINUTE));
    const seconds = Math.floor((countDown % (MINUTE)) / SECOND);

    return [days, hours, minutes, seconds];
};

export default Countdown;
