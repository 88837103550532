import React, {useState} from "react";
import {PATH} from "../../../config";
import {Button} from "antd";
import {useSelector} from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../config/constants";
import { ConvertDate } from "../../../config/CoreMethods";

const EventsCard = ({params, IWantToGo, LoggedIn}) => {
    let L = useSelector(state => state.settings.L);

    const [AvatarFile, setAvatarFile] = useState(PATH+'avatar.png')

    const AvatarOnError = () => {
        setAvatarFile(PATH+'avatar.png')
    } 

    return (
        
        <div className="events-card">
            <Link to={ROUTES.eventsSingle.path.replace(":event_id", params.id)}>
            <div className="events-cover" 
                style={{background: `url(${params.cover_image ? params.cover_image : PATH + 'metahut-owner.jpg'}) center center / cover`}}
            >
            {params.status === 'live' ? <span className="events-badge">{L.eventStatusLive}</span> : ''}
            </div>
            </Link>
            <div className="events_details">
                <div className="events_header">
                    <span className="events_date">{ConvertDate(params.start_date_, 1)} PM</span>
                    <div className="events-price events-price_soto">
                        {params.ticket_value ? 
                            <>
                                <img src={PATH + params.ticket_type + '.svg'} alt=""/>
                                <span>{params.ticket_value}</span>
                            </> :
                            <span>{L.free}</span>
                        }
                    </div>
                </div>
                <h2 className="events-title">{params.name}</h2>
                <span className="events-author">by @{params.username}</span>
                <div className="events_action">
                    <div className="events-visitor">
                        {params.want_users && params.want_users.length ?
                            <>
                                {params.want_users.map((i, index) => {
                                    return (
                                    <div className="relative avatar_box user-box" style={{backgroundColor: i.bgcolor}} key={index}>
                                        <img src={i.avatar} onError={AvatarOnError} width="100%" height="100%" className="user-avatar" alt="" />
                                    </div>
                                    )
                                })}
                                <div className="events-visitor_count">{params.total_want_go}</div>
                            </>
                        :''}
                    </div> 
                    {LoggedIn ?
                        IWantToGo ? 
                            params.going ?
                            <Button type="primary" disabled="disable" className="events-btn btn-disable">
                                {L.goingBtn}
                            </Button>
                            :
                            <Button type="primary" onClick={()=> IWantToGo(params.id)} className="events-btn">
                                {L.wantToGoBtn}
                            </Button>
                        :''
                    : IWantToGo ? 
                        <Link to={ROUTES.loginPage.path}>
                            <Button type="primary" className="events-btn">
                                {L.wantToGoBtn}
                            </Button>
                        </Link>
                    :''}
                    
                </div>
                <div className="events-bottom">
                    <div className="events-pool">
                        {params.reward_value?
                            <>
                                <div className="events-reward">
                                    <span>{L.reward}:</span>
                                </div>
                                <div className="events-amount">
                                    <img src={PATH + params.reward_type + '.svg'} alt=""/>
                                    <span>{params.reward_value} {params.reward_type.toUpperCase()}</span>
                                </div>
                            </>
                        :''}
                    </div>
                    <div className="events-share">
                        <i className="icon-share"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventsCard;