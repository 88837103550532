import React from "react";
import MyMetahutsContainer from "../metahuts/MyMetahutsContainer";

const MyMetaHut = ({ }) => {
    return (
        <div className="container mx-auto relative pt-162 min-h-calc-head py-8">
            <MyMetahutsContainer />
        </div>
    )
}

export default MyMetaHut;