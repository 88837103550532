import React from 'react';

const Subject = (props) => {
    const {title, subTitle} = props;
    return (
        <div className="flex flex-col gap-y-2 mb-10 mt-10">
            <h1 className="text-3xl text-white font-bold opacity-90">{title}</h1>
            <p className="text-slate-400">{subTitle}</p>
        </div>
    )
}

export default Subject;