import React from "react";
import {PATH} from "../../config";
import {Button} from "antd";

const MetahutLeasing = ({paymentType, metoSpend, tokenTypes, MetahutPriceMeto, MetahutPriceBusd, updateMetahutCount, metahutCount, TotalMetoWillSpend, LandPriceMetoDiscount, L}) => {
    return <>
            <h4 className="gland-pay_ttl">{L.amount}</h4>
            <div className="flex items-start gap-x-8 gland-amount_row">
                <div className="flex flex-col gland-amount_price">
                    <p className="mb-0 font-normal gland-amount_token">{paymentType == 1 ? metoSpend + ` ${tokenTypes[paymentType - 1].name}` : MetahutPriceBusd + ` ${tokenTypes[paymentType - 1].name}`}</p>
                    {paymentType != 3 ? <span className="font-normal gland-amount_usd">≈ {paymentType == 1 ? MetahutPriceMeto : MetahutPriceBusd} USD</span> : ''}
                </div>
                <span>x</span>
                <div className="flex items-center gap-x-2 gland-amount_land">
                    <input
                        className="bg-white text-indigo-700 font-bold border-0 rounded-md text-center font-mont gland-amount_input"
                        type="text"
                        defaultValue="1"
                        name="landLimit"
                        onChange={(e) => updateMetahutCount(e.target.value)}
                    />
                    <span className="font-normal font-mont gland-amount_limit">max 94</span>
                </div>
            </div>
            <div className="gland-amount-total">
                <h5 className="gland-amount_ttl">{L.completeAmount}</h5>
                <div className="flex flex-col gland-amount_price">
                    <div className="flex items-center gap-x-1 gland-amount_token">
                        {metahutCount ? paymentType == 1 ? (!isNaN(TotalMetoWillSpend) ? TotalMetoWillSpend : '-') : (MetahutPriceBusd * metahutCount) : '-' }
                        <img src={PATH + tokenTypes[paymentType - 1].icon} style={{height: "16px", marginLeft: "3px"}} alt=""/>
                        {tokenTypes[paymentType - 1].name}
                    </div>
                    {paymentType != 3 ? <span className="gland-amount_usd">≈ {paymentType == 1 ? (MetahutPriceMeto*metahutCount) + ' USD' : (MetahutPriceBusd*metahutCount) + ' USD'}</span> : ''}
                </div>
                {paymentType == 1 ? <p className="font-light gland-amount_discount">You're reducing your payment by {(MetahutPriceBusd*metahutCount*LandPriceMetoDiscount)/100} USD</p> : ''}
            </div>
            <div className="line-x"></div>
            <p className="font-light font-sans gland-amount_note">{L.getLandAmountNote}</p>
            <div className="text-center">
                <Button
                    disabled={metahutCount ? '' : 'disable'}
                    className={`border-0 mb-5 rounded-3xl font-normal gland-amount_btn ${metahutCount ? 'bg-cyan-500 text-indigo-900 hover:bg-cyan-400' : 'bg-indigo-600 text-white opacity-50 cursor-not-allowed'}`}
                >{L.getLandAmountButton}</Button>
            </div>
        </>
}

export default MetahutLeasing;