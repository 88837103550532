import React, { useState } from "react"; 
import ContactDetails from "./ContactDetails";
import { Button, Col, Row, Select } from "antd";
import axiosData from "../../../../helpers/axiosData";
import { selectUser } from "../../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../../../../config";

const Hosts = ({formTitle, formDesc, formTitle1, formDesc1, L}) => {


    let Timeout;
    const defaultAvatar = PATH+'avatar.png';    
    const [Options, setOptions] = useState([]);
    
    const selector = useSelector;
    const dispatch = useDispatch();

    const AllHosts = selector ((state) => state.event.formData.Hosts)    
    let GetUser = selector(selectUser);

    const searchUser = (text) => {
        clearTimeout(Timeout)

        Timeout = setTimeout(function() {
            getUserSearch(text)
        }, 500);
    }

    let formData = selector ((state) => state.event.formData)

    const getUserSearch = (text) => {

        if (text != null && text.length > 3) {
    
            axiosData.get("/api/_web3/event/search-user?search="+text).then((res) => {                
                
                if (res.data.success && res.data.data && res.data.data.length) {

                    let options = [];
                    let data = res.data.data;
                    let gHost = AllHosts;

                    for (let i = 0; i < data.length; i++) {
                        options.push({
                            label: "@"+data[i].username,
                            value: data[i].id,
                        });
                        gHost.push(data[i])
                    }

                    dispatch({type: 'setFormData', payload: {...formData, Hosts : gHost}})

                    setOptions(options)
                } 
            }).catch(error => {
                // toast.error('OOPS! Something went wrong.')
            })
        } 
    }

    const removeHost = (id) => {
        let List = AllHosts.filter(el => parseInt(el.id) !== parseInt(id));
        dispatch({type: 'setFormData', payload: {...formData, Hosts : List}})

    } 
 


    return <>
        <div className="eform-content_head">
            <h2 className="eform-content_ttl">{formTitle}</h2>
            <p className="eform-content_desc">{formDesc}</p>
        </div>
        <div className="eform-content_body ehosts-body">
            <div className="ehosts-form">
            <div className="ehosts-search">

                {AllHosts ? console.log(AllHosts): '--'}
                <Select
                    // mode="multiple"
                    allowClear
                    className="input input-search"
                    placeholder={L.hostsSearchPlaceholder}
                    defaultValue={[]}
                    onSearch={e => { searchUser(e)} }
                    // onInputKeyDown={(t, e, f) => { console.log('---', t, e, f) }}
                    options={Options}
                    showSearch
                    loading={true}
                    // tagRender={tagRender}
                    showArrow={false}
                />
                {/*<Input size="large" placeholder="Search bu username and email" className="input input-search"*/}
                {/*       prefix={<i className="icon-search text-gray-300"></i>}*/}
                {/*       onChange={e => setSearchText(e.target.value)}*/}
                {/*       defaultValue={hostSearch}*/}
                {/*/>*/}
            </div>
            {/* <Button className="ehosts-add">Add Host</Button> */}
            </div>
            
            <div className="ehosts-list">
                <Row gutter={30}>
                    {GetUser && GetUser.id ?

                        <Col xs={24} sm={24} md={12} lg={6}>
                            <div className="ehosts-card">
                                <span className="ehosts-status">{L.owner}</span>
                                <div className="relative avatar_box user-box" style={{backgroundColor: GetUser && GetUser.bgcolor}}>
                                    <img src={GetUser.avatar} onError={el =>  el.target.src = defaultAvatar } alt="avatar" width="100%" height="100%" className="user-avatar"/>
                                </div>
                                <h3 className="ehosts-fname">{GetUser.name}</h3>
                                <span className="ehosts-username">@{GetUser.username}</span>
                            </div>
                        </Col>
                        :''
                    }
                    
                    {AllHosts && AllHosts.length? 
                        AllHosts.map( host => {
                            return <Col xs={24} sm={24} md={12} lg={6}> 
                                    <div className="ehosts-card">
                                        <span className="ehosts-status">{L.allHosts}</span>
                                        <div className="relative avatar_box user-box" style={{backgroundColor: GetUser && GetUser.bgcolor}}>
                                            <img src={host.avatar} onError={el =>  el.target.src = defaultAvatar } alt="avatar" width="100%" height="100%" className="user-avatar"/>
                                        </div>
                                        <h3 className="ehosts-fname">{host.name}</h3>
                                        <span className="ehosts-username">@{host.username}</span>
                                        <Button onClick={()=>removeHost(host.id)} className="ehosts-remove">{L.remove}</Button>
                                    </div>
                                </Col>
                        })
                    :''
                    }
                </Row>
            </div>
        
        </div>

        <div className="line-x"></div>
        <div className="eform-content_head ehosts-head">
            <h2 className="eform-content_ttl">{formTitle1}</h2>
            <p className="eform-content_desc">{formDesc1}</p>
        </div>
        <div className="eform-content_body">
            <ContactDetails/>
        </div>
    </>
}

export default Hosts;