import React, {useEffect} from "react";
import {Col, Row} from "antd";
import MyCardItem from "./MyCardItem";
import './card.css';
import {PATH} from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { getMyMetahutCollection } from "../../../redux/actions/metahutActions";

const MyCardContainer = ({card}) => {

    let dispatch = useDispatch();
    let MetahutCollections = useSelector(state => state.metahut.MetahutCollections)
    let Account = useSelector(state => state.metahut.Account)

    useEffect(() => { 

        if (Account != '') {
            dispatch( getMyMetahutCollection() )
        }
 
    }, [Account])
    
    return (

        <Row gutter={{xs: 86, sm: 86, md: 46, lg: 36}} className="px-8 pr-0 w-full sm-px-0">

            {MetahutCollections.length ? MetahutCollections.map((item, index) => {  
                return (
                    <Col md={12} className="gutter-row mb-8" key={index}>
                        <MyCardItem item={card[0]} nft_name={item.nft_name} bg={PATH + 'metahut-cover.jpg'} />
                    </Col>
                )
            }):''}
        </Row>
    )
}

export default MyCardContainer;