import React, {useState, useEffect} from "react";
import {Button, Col, Modal, Row, Radio} from "antd";
import {PATH} from "../../../../config";
import {Link, NavLink} from "react-router-dom";
import {ROUTES as ROUTES_CONFIG} from "../../../../config/constants";
import axiosData from "../../../../helpers/axiosData";
import { useDispatch, useSelector } from "react-redux";

const Venue = ({formTitle, formDesc, L}) => {

    const [myMetahuts, setMyMetahuts] = useState([])
    const [defaultMetahuts, setDefaultMetahuts] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const DefaultCoverImage = PATH+'/metahuts-empty.png';
    
    let formData = useSelector ((state) => state.event.formData)
    const metahut_id = formData.metahut_id;

    const [MyList, setMyList] = useState([])
    const [DefaultList, setDefaultList] = useState([])
    const [SelectedMetahut, setSelectedMetahut] = useState('')

    const dispatch = useDispatch();
    
    const getMyData = () => {
        axiosData.get("/api/_web3/metahuts/my-list").then((res) => {
             
            if (res.data.data.length) {
                let data = res.data.data.filter(li => li.published)
                
                setMyList(data)
                setMyMetahuts( makeMetahutFromJson(data) ); 
            } 
        })
    }

    const getAllData = () => {
        axiosData.get("/api/_web3/metahuts/filter/recent/0/2?ids=11,64").then((res) => {
            if (res.data.data.length) {

                let data = res.data.data.filter(li => li.published)
                setDefaultList(data)
                setDefaultMetahuts ( makeMetahutFromJson(data) );
            } 
        })

    }


    const onChangeValue = ({ target: { value } }) => {

        dispatch({type: 'setFormData', payload: {...formData, metahut_id : value,  executed:1}})   

        let selected = MyList.concat(DefaultList).filter(el => {return console.log('el.id==value ', el.id==value, el.id, value) || el.id==value} )

        setSelectedMetahut( makeMetahutFromJson(selected) );            
    };

    const handleSelect = () => {
        if (metahut_id !='') {
            setIsModalVisible(false);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const makeMetahutFromJson = (list) => {
        let metahuts = [];

        for (let i = 0; i < list.length; i++) {
            const li = list[i];
            
            if (li.published) {
                metahuts.push(
                <Col key={i} xs={24} sm={24} md={12} lg={8} 
                    className="flex flex-col items-center assets-col"
                >
                    <Radio.Button value={li.id}>
                        <div className="w-100 thumb">
                            <div className="relative text-right thumb-asset list-metahut" 
                            style={{background: `url(${li.cover_image !='' ? li.cover_image : DefaultCoverImage})  no-repeat center center / contain`}}>
                                <span className="font-medium inline-block thumb-ctg thumb-ctg_publish">
                                    {L.publish}
                                </span>
                            </div>
                            <div className="flex flex-col thumb-info">
                                <p className="text-white mb-0 font-normal thumb-title">{li.land_name}</p>
                                <div className="flex items-center flex-wrap stats">
                                    <div className="flex items-center stats-item">
                                        <i className="icon-people"></i>
                                        <span>{li.followers}</span>
                                    </div>
                                    <div className="flex items-center stats-item">
                                        <i className="icon-eye"></i>
                                        <span>{li.visitors}</span>
                                    </div>
                                    <div className="flex items-center stats-item">
                                        <i className="icon-star"></i>
                                        <span>{li.rate}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Radio.Button>
                </Col>)
            }
        }
        
        return metahuts;
    }

    useEffect(() => {
        
        getMyData()
        getAllData() 

    }, [])

    return <>
        { ( DefaultList.length || MyList.length) && formData.metahut_id && !formData.executed ? onChangeValue({target: {value: formData.metahut_id }}) :'' }

        <Modal 
            footer={false} 
            onCancel={() => setIsModalVisible(false)} 
            closeIcon={<i className="icon-close"></i>} 
            centered 
            visible={isModalVisible} 
            className="modal emodal"
        >
            <div className="emodal-header">
                <h2 className="emodal-ttl">{L.venueModalTitle}</h2>
                <Button className="submit-btn" onClick={handleSelect}>{L.select}</Button>
            </div>
            <div className="line-x"></div>
            <Radio.Group onChange={onChangeValue} value={metahut_id} className="w-100">
                <div className="emodal-list">
                    <h3 className="emodal-list_sbj">{L.myOwnMetahuts}</h3>
                    <Row gutter={70}>
                        {myMetahuts && myMetahuts.length ? myMetahuts
                            :
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <div className="emodal-list_empty">{L.emptyPublishMetahut}</div>
                            </Col> 
                        }
                    </Row>
                </div>
                <div className="emodal-list">
                    <h3 className="emodal-list_sbj">{L.otherMetahuts}</h3>
                    <Row gutter={70}>
                        {defaultMetahuts && defaultMetahuts.length ? defaultMetahuts
                            :
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <div className="emodal-list_empty">{L.emptyDefaultMetahuts}</div>
                            </Col> 
                        }
                    </Row>
                </div>
            </Radio.Group>
        </Modal>

        <div className="eform-content_head">
            <h2 className="eform-content_ttl">{formTitle}</h2>
            <p className="eform-content_desc">{formDesc}</p>
        </div>
        <div className="eform-content_body">
            <div className="evenue-opt">
                <div className="emodal-list">
                    <Row gutter={40}>
                        {SelectedMetahut ? SelectedMetahut : '' }
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <div className="evenue-opt_item cursor-pointer" onClick={showModal}>
                                <img src={PATH + 'mouse-circle.svg'} alt="" className="evenue-opt_icon"/>
                                    {metahut_id ?
                                        <h3 className="evenue-opt_ttl">{L.changeVenue}</h3>
                                        :
                                        <h3 className="evenue-opt_ttl">{L.bookVenue}</h3>
                                    }
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <NavLink to={ROUTES_CONFIG.getMetahut.path}>
                                <div className="evenue-opt_item">
                                    <img src={PATH + 'metahut-icon.svg'} alt="" className="evenue-opt_icon"/>
                                    <h3 className="evenue-opt_ttl">{L.eventCreateMetahut}</h3>
                                </div>
                            </NavLink>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <div className="evenue-opt_item">
                                <img src={PATH + 'rent-icon.svg'} alt="" className="evenue-opt_icon"/>
                                <h3 className="evenue-opt_ttl">{L.rentalVenue}</h3>
                                <span>{L.soon}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </>
}

export default Venue;