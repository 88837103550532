import React, {useState, useEffect} from 'react';
import {SuccessStake, claimUnstakeNotify} from "./components/Alerts/Alerts";
import {ToastContainer} from 'react-toastify';
import {Packages} from "./components/Package/PackagesData";

import ListStacking from "./components/ListStacking";
import Loading from "./components/Loading";
import LoadingText from "./components/LoadingText";
import Subject from "./components/Subject";
import Accordion from "./components/Accordion";
import SubjectLink from "./components/SubjectLink";
import ChangeNetwork from "./components/Alerts/ChangeNetwork";
import InstallMetamsk from "./components/Alerts/InstallMetamask";

import { ethers } from 'ethers';
import ConnectMetamask from "./components/Alerts/ConnectMetamask";
import {useSelector} from "react-redux";
import { log_ } from '../../../config/CoreMethods';
import { TEST_ENV } from '../../../config';


const Staking = () => {
    const selector = useSelector;

    let L = selector (state => state.settings.L);

    const PackagesData = Packages(TEST_ENV)

    const [walletAccount, setWalletAccount] = useState("");
    const [connectStatus, setConnectStatus] = useState("");
    const [connectButtonText, setConnectButtonText] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState(false);
    const [loadingStaking, setLoadingStaking] = useState(false);
    const [loadingUnstaking, setLoadingUnstaking] = useState(false);
    const [loadingClaim, setLoadingClaim] = useState(false);
    const [balancePackage, setBalancePackage] = useState("");
    const [isLogin, setIsLogin] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [SubTotalValueLocked, setSubTotalValueLocked] = useState(0);
    const [SubTotalBalance, setSubTotalBalance] = useState(0);
    const [SubTotalEarnings, setSubTotalEarnings] = useState(0);
    const [networkIsTrue, setNetworkIsTrue] = useState(true);
    const [refreshTable, setRefreshTable] = useState(true);
    const [showMetamask, setShowMetamask] = useState(false);
    const [showChangeNetworkAlert, setShowChangeNetworkAlert] = useState(false);
    const [successStakeToast, setSuccessStakeToast] = useState(false);
    const [successUnstakeToast, setSuccessUnstakeToast] = useState(0);
    const [unlocked, setUnlocked] = useState(false);
    const [MyLevel, setMyLevel] = useState(1);

    let TotalData = {balance : [], earnings: [], value_locked:[]};
    let FrontNetwork = selector ((state) => state.metahut.FrontNetwork)
    let FrontLogin = selector ((state) => state.metahut.FrontLogin)
    let Account = useSelector ((state) => state.metahut.Account)

    let myProviderTarget = 56;
    
    let LevelsFileName='Levels.json';
    let LevelsContractAddress='0xbe31131FF411B7a74EE83D4C74C62EF2C3E60a8b';

    if (TEST_ENV == 1) {
        myProviderTarget = 97
        LevelsFileName = 'Levels.json';
        LevelsContractAddress = '0x0e3E46FF993F140bFA5B46030f4591b41BC4E602';
    }  

    let connectedContract;
    let provider = false;
    let signer = false;


    const  setSubTotalData = (arr, type) => {
        let breaking=false;

        if (type == 'reset') {
            TotalData = {balance : [], earnings: [], value_locked:[]};
            setSubTotalBalance(0);
            setSubTotalEarnings(0);
            setSubTotalValueLocked(0);
            return ;
        }

        for (let i=0; i < TotalData[type].length; i++) {
            if (TotalData[type][i][0] == arr[0]) {
                breaking = true;
                break;
            }
        }

        if (!breaking) {
            TotalData[type].push(arr);
        }

        let Balance = 0;

        for (let i=0; i < TotalData[type].length; i++) {
            Balance += TotalData[type][i][1];
        }

        if (type == 'balance') {
            setSubTotalBalance(Balance);
        } else if (type == 'earnings') {
            setSubTotalEarnings(Balance);
        } else if (type == 'value_locked') {
            setSubTotalValueLocked(Balance);
        }
    }



    const userBalance = async (currentUser='') => {

       /*  const { ethereum } = window;

        if (ethereum) {

            const UserWallet = currentUser && currentUser !== '' ? currentUser : walletAccount;

            /*contractStaking = new web3.eth.Contract(
                contractMetafluenceBuild.abi,
                metaToken
            )* /

            // contractStaking.methods.balanceOf(UserWallet)
            // .call({from: walletAccount})
            let item = PackagesData[0];
            const  contractMetafluenceBuild =  require('./contracts/Metafluence.json');

            connectedContract = new ethers.Contract(item.metaToken, contractMetafluenceBuild.abi, signer);

            await connectedContract.balanceOf(UserWallet)
                .then(result => {
                    // alert('balance' +result);
                    setBalancePackage((parseInt(result)/(10**18)).toFixed());
                })
                .catch(err => {
                    // console.log(err.message)
                })
        } */
    }


    const CheckWalletIsConnected = async (callback=()=>{}) => {
        const ethereum = window.ethereum;

        if( ethereum ) {

            provider = new ethers.providers.Web3Provider(window.ethereum);
            signer = provider.getSigner();

            const { chainId } = await provider.getNetwork()

            if (chainId === myProviderTarget) {
                setNetworkIsTrue(true);
                setShowChangeNetworkAlert(false)

            } else {
                setNetworkIsTrue(false);
                setShowChangeNetworkAlert(true);
            }

            setLoading(true);
            const accounts = await provider.listAccounts();

            setUnlocked(accounts.length > 0)


            // MetaMask is installed!
            await ethereum.request({method: 'eth_accounts'})
                .then(accounts => {
                    setLoading(false);
                    if (localStorage.getItem('isLogin') === '0') {
                        setIsLogin(false)
                        setConnectButtonText("");
                        setWalletAccount("");
                    } else {
                        if (accounts.length > 0 && accounts[0] && typeof accounts[0] != 'undefined') {

                            localStorage.setItem('account', accounts[0]);
                            setWalletAccount(accounts[0]);
                            setConnectButtonText(`${String(accounts[0]).substring(0, 6)} ... ${String(accounts[0]).substring(38)}`);
                            userBalance(accounts[0]);
                            setIsLogin(true);
                            setNetworkIsTrue(true);
                            setShowChangeNetworkAlert(false);

                        } else {
                            setIsLogin(false)
                            setConnectButtonText("");
                            setWalletAccount("");
                        }
                    }
                })
                .catch(error => {
                    setLoading(false);
                    if (error.code === 4001) {
                        setConnectStatus(L.userRejectedRequest);
                        setLoading(false);
                    } else {
                        setConnectStatus(error.message)
                    }
                }).then(()=>{
                    callback()
                })
        }
    }


    // calculate totalStaked
    const totalStaked = () => {

        return ;
        try {
            const { ethereum } = window;

            if (ethereum) {

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();

                // console.log('signer', signer, 'provider', provider)

                PackagesData.map(async (packageItem) => {
                    if (packageItem.active >= 0) {

                        let StakingJson = require('./contracts/'+packageItem.fileJson);

                        const connectedContract = new ethers.Contract(packageItem.stakingToken, StakingJson.abi, signer);

                        await connectedContract.totalStaked()
                            .then((totalBalance) =>  {
                                
                                log_({type:'totalStaked then', result:totalBalance, packageItem: packageItem})
                                
                                totalBalance = parseInt(totalBalance)/(10**18);
                                setSubTotalData([packageItem.id, totalBalance], 'balance');
                                // console.log('then totalBalance', totalBalance, 'packageItem', packageItem)
                            }).catch(err=>{
                                log_({type:'totalStaked error', result: err, packageItem: packageItem})

                                console.log('totalStaked err', err)
                            });
                    }

                })

            }
        } catch (error) {
            console.log('totalStaked error: ', error)
        }

    }


    const GetMyLevelsData = async (Account) => {

        try {
            const { ethereum } = window; 
            
            if (ethereum && Account && LevelsFileName!='') {

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                
                let LevelsAbi = require('./contracts/'+LevelsFileName);

                const LevelsContract = new ethers.Contract(LevelsContractAddress, LevelsAbi, signer);

                await LevelsContract.getLevel(Account)
                    .then((Level) =>  {
                        let level = parseInt(Level) > 0 ? parseInt(Level): 1;
                        setMyLevel(level)

                    }).catch(err=>{
                        console.log('LevelsContract err', err)
                        setMyLevel(1)
                    });
            }
        } catch (error) {
            console.log('LevelsContract error: ', error)
        }

    }

    useEffect(() => {

        CheckWalletIsConnected(()=>{

            if (FrontLogin && FrontNetwork) { 
                GetMyLevelsData(Account)
            }
        })

        
        setTimeout(() => {
            setLoading(false)
        }, 4000)

        const { ethereum } = window;

        if (!ethereum) {
            setShowMetamask(true)
        }

    }, [Account, FrontLogin, FrontNetwork]) 


    return (
        <div className="pt-162 min-h-calc-head staking">

            {loading ? <Loading /> : ''}
            {loadingText ? <LoadingText text={L.waitForApproval} /> : ''}
            {loadingStaking ? <LoadingText text={L.waitForStaking} /> : ''}
            {loadingClaim ? <LoadingText text={L.waitForClaiming} /> : ''}
            {loadingUnstaking ? <LoadingText text={L.waitForUnstaking} /> : ''}
            {/* {showMetamask ? <InstallMetamsk /> : !unlocked ? <ConnectMetamask /> : !FrontNetwork ? <ChangeNetwork/> : '' } */}
            {successStakeToast ?
                <ToastContainer
                    position="top-center"
                    theme="colored"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />: ''}
            {successUnstakeToast == 1 ?
                <ToastContainer
                    position="top-center"
                    theme="colored"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />: ''}
            {successUnstakeToast == 2 ?
                <ToastContainer
                    position="top-center"
                    theme="colored"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />: ''}
            <div className="container mx-auto py-8 relative">

                <div className="pl-10 pr-10 sm:pr-10">
                    <Subject
                        title={L.metoStaking}
                        subTitle={L.ss}
                    />
                    {(refreshTable || isLogin) && MyLevel?
                        PackagesData.map((packageItem) => {
                            return (
                                <ListStacking
                                    key={packageItem.id}
                                    metaToken={packageItem.metaToken}
                                    stakingToken={packageItem.stakingToken}
                                    walletAccount={walletAccount}
                                    setWalletAccount={setWalletAccount}
                                    connectStatus={connectStatus}
                                    setConnectStatus={setConnectStatus}
                                    setConnectButtonText={setConnectButtonText}
                                    packageItem_={packageItem}
                                    setLoading={setLoading}
                                    setLoadingText={setLoadingText}
                                    setLoadingStaking={setLoadingStaking}
                                    setLoadingClaim={setLoadingClaim}
                                    setBalancePackage={setBalancePackage}
                                    balancePackage={balancePackage}
                                    isLogin={isLogin}
                                    setIsLogin={setIsLogin}
                                    loadingData={loadingData}
                                    setLoadingData={setLoadingData}
                                    setLoadingUnstaking={setLoadingUnstaking}
                                    setSubTotalData={setSubTotalData}
                                    setNetworkIsTrue={setNetworkIsTrue}
                                    myProviderTarget={myProviderTarget}
                                    setRefreshTable={setRefreshTable}
                                    networkIsTrue={networkIsTrue}
                                    refreshTable={refreshTable}
                                    setShowChangeNetworkAlert={setShowChangeNetworkAlert}
                                    SuccessStake={SuccessStake}
                                    setSuccessStakeToast={setSuccessStakeToast}
                                    claimUnstakeNotify={claimUnstakeNotify}
                                    setSuccessUnstakeToast={setSuccessUnstakeToast}
                                    totalStaked={totalStaked}
                                    userBalance={userBalance}
                                    MyLevel={MyLevel}
                                />
                            )

                        })
                        : ''}
                    <SubjectLink title={L.faq} />
                    <Accordion />
                </div>
            </div>
        </div>
    );
}

export default Staking;
