const INITIAL_STATE = {
    Account : '',
    BalanceBusd : "-",
    BalanceMeto : "-",
    FrontNetwork : false,
    FrontLogin : false,
    following : false,
    BackLogin : 0,
    MapSettings : {meto_price: 0},
    errors : [],
    loading : false,
    ReloadingSite : true,
    IsMobile : false,
    IsMetamask : false,
    ShowHideSelectedBar : [0,0],
    MetahutBuyLoading: 0,
    MetahutBuyLoadingMessage : '',
    MetahutCollections : [],
    T : 0,
    DiscoverySearchText : ''
}


const metahutReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        
        case "SUCCESS" : return {...state, value: action.payload};
        case "SetFollowing" : return {...state, value: action.payload};
        case "ResetMetahut" : return  INITIAL_STATE;
        case "SetMetahutBuyLoading" : return {...state, MetahutBuyLoading: action.payload, MetahutBuyLoadingMessage: (typeof action.message != 'undefined' ? action.message : 'Error Occured')};

        default : 
            if ( action.type.indexOf('Set') > -1) {
                state[action.type.replace('Set', '')] =  action.payload;
            }
            
            return {...state, T : state.T+1};
    }
}


export default metahutReducer;