import React from "react";
import {NavLink} from "react-router-dom";
import {Layout} from "antd";
const { Sider } = Layout

const Sidebar = () => {
    return (
        <Sider className="pt-8 pb-8 pl-10 pr-4 bg-indigo-900 fixed sidebar-bg h-full z-1 visible-md" width="260px">
            <h3 className="text-gray-300 font-light text-base uppercase">My Metahuts</h3>
            <ul className="pl-4 text-lg flex-col flex mt-10 vert_menu">
                <li>
                    <NavLink to="/admin/rooms" className="text-white font-light" >Rooms</NavLink>
                </li>
                <li>
                    <NavLink to="/admin/metahut" className="text-white font-light">Metahuts</NavLink>
                </li>
            </ul>
        </Sider>
    )
}

export default Sidebar;