import React from "react";
import {PATH} from "../../../config";
import {Skeleton} from "antd";

const Social = ({GetUser}) => {

    let Socials = [
        ['twitter', 'twitter.svg'], 
        ['telegram', 'telegram.svg'], 
        ['youtube', 'youtube.svg'], 
        ['discord', 'discord.svg'], 
        ['linkedin', 'linkedin.svg'], 
        ['instagram', 'instagram.svg'], 
        ['facebook', 'facebook.svg'], 
        ['reddit', 'iconfinder.svg'], 
    ] 

    return  typeof GetUser != 'undefined'  &&  GetUser.status ?
        <div className="flex items-center gap-x-3 social">
            {Socials.map(li => {
                                
                return  typeof GetUser[li[0]] != 'undefined' && GetUser[li[0]]!='' && GetUser[li[0]] != null && GetUser[li[0]] != 'null' ?
                        <a key={li[0]} href={GetUser[li[0]]} target="_blank">
                            <img src={PATH + 'profile/'+li[1]} alt={li[0]}/>
                        </a>  : ''
            })}
        </div> :
        <div className="flex items-center gap-x-3 social">
            <Skeleton active className="social-load" avatar={{shape: "square", style: {width: "24px", height: "24px", borderRadius: "6px"}}} paragraph={false} title={false}></Skeleton>
            <Skeleton active className="social-load" avatar={{shape: "square", style: {width: "24px", height: "24px", borderRadius: "6px"}}} paragraph={false} title={false}></Skeleton>
        </div>
}

export default Social;