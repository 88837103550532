import { C } from "./CoreMethods";
import { PATH } from "./"

export const setInitialCoords = (transform) => {
    localStorage.setItem("map_coords", JSON.stringify(transform));
    // setTrigInitialCoords(transform)
}

export const getInitialCoords = () => {

    let coords = getItems('map_coords')
    let CoordData = {x: coords.x, y: coords.y, scale: coords.scale}
    
    return CoordData
    // setTrigInitialCoords([coords[0], coords[1], coords[2]])
}


export const getItems = (key, is_array) => {

    let item = localStorage.getItem(key);

    try{ item =  JSON.parse(item); } catch(err) { /*console.log('err parse', err);*/ item = []; }
    // console.log('item === ', item != null ,'&&', item != 'null' ,'&&', item != '', '&&', (typeof item.length != 'undefined'), typeof item == 'object' )

    item = item != null && item != 'null' && item != '' && (typeof item == 'object' || typeof item.length != 'undefined') ? item : (is_array?[]:{});
    // console.log('item => ', item)

    return item;
}

/*-----------------------*/
export const getRectSelecteds = (return_array) => {

    let items = getItems('selectedrects', return_array)

    if(return_array) {

        let items_ = [];

        for (let i in items) {
            items_.push(i)
        }

        return items_;
    }

    return items;
}



export const showHoverPlot = (elem) => {

    let x = parseInt(elem.getAttribute('x'));
    let y = parseInt(elem.getAttribute('y'));
    let real_coord = elem.getAttribute('real_coord');
    let real_id = elem.getAttribute('id');

    x += 85
    y -= 25

    let Tooltip = '<g>';
    let xperc = -0.31;

    Tooltip += '<rect x="' + (x-C(5,xperc)) + '" y="' + (y-C(60, xperc)) +'" rx="20" ry="20" style="fill:#1c1a42;width:' + C(550) +'px;height:'+C(265)+'px;"/>'
    Tooltip += '<rect x="' + (x-C(10,xperc)) + '" y="' + (y-C(50, xperc)) +'" width="'+C(100)+'" height="'+C(100)+'" rx="0" ry="0" style="fill:#1c1a42;transform: rotate(45deg);transform-origin: '+x+'px '+y+'px;"/>'
    Tooltip += '<text x="' + (x+C(44)) + '" y="' + (y+C(26)) +'" style="font-family: Roboto;text-transform: uppercase;fill: #B1AFCD;font-size: '+C(26)+'px;font-weight: 300;">Land Plot</text>/>'
    Tooltip +=  '<image href="'+PATH+'land.svg" x="'+(x+C(34))+'" y="'+(y+C(13))+'" height="'+C(184)+'px" width="'+C(176)+'px"/>'

    if(real_coord != 'null') {

        Tooltip += '<text x="' + (x+C(244)) + '" y="' + (y+C(103)) +'" style="font-family: Roboto;font-weight: 400;text-transform: uppercase;fill: #fff;font-size: '+C(30)+'px;">'+real_coord+'</text>/>'
        Tooltip += '<text x="' + (x+C(244)) + '" y="' + (y+C(133)) +'" style="font-family: Roboto;font-weight: 300;text-transform: uppercase;fill: #CCC9C9;font-size: '+C(24)+'px;">ID: '+real_id+'</text>/>'
    }

    Tooltip += '</g>';

    document.getElementById('tooltip-area').innerHTML = Tooltip;

}


export const showBuyedPlot = (elem) => {

    let x = parseInt(elem.getAttribute('x'));
    let y = parseInt(elem.getAttribute('y'));
    let account = elem.getAttribute('acc');
    let real_coord = elem.getAttribute('real_coord');
    let real_id = elem.getAttribute('id');

    x += 85
    y -= 20

    let Tooltip = '<g>';
    let xperc = -0.31;

    Tooltip += '<rect x="' + (x-C(20, xperc)) + '" y="' + (y-C(50, xperc)) +'" rx="20" ry="20" style="fill:#1c1a42;width:'+C(640)+'px;height:'+C(489)+'px;"/>'
    Tooltip += '<rect x="' + (x-C(10, xperc)) + '" y="' + (y-C(50, xperc)) +'" width="'+C(100)+'" height="'+C(100)+'" rx="0" ry="0" style="fill:#1c1a42;transform: rotate(45deg);transform-origin: '+x+'px '+y+'px;"/>'
    Tooltip += '<text x="' + (x+C(30)) + '" y="' + (y+C(10)) +'" style="font-family: Roboto;text-transform: uppercase;fill: #B1AFCD;font-size: '+C(26)+'px;font-weight: 300;">Owner</text>/>'
    Tooltip += '<text x="' + (x+C(40)) + '" y="' + (y+C(84)) +'" style="font-family: Roboto;text-transform: uppercase;fill: #ffffff;font-size: '+C(26)+'px;font-weight: 300;"> '+account+' </text>/>'
    Tooltip += '<text x="' + (x+C(236)) + '" y="' + (y+C(172)) +'" style="font-family: Roboto;text-transform: uppercase;fill: #9796CF;font-size: '+C(24)+'px;font-weight: 300;">Properties</text>/>'
    Tooltip += '<rect x="' + (x+C(394)) + '" y="' + (y+C(164)) +'" style="fill:#9796CF;width:'+C(182)+'px;height:1px;" />'
    Tooltip += '<rect x="' + (x+C(34)) + '" y="' + (y+C(164)) +'" style="fill:#9796CF;width:'+C(182)+'px;height:1px;" />'
    Tooltip += '<text x="' + (x+C(40)) + '" y="' + (y+C(254)) +'" style="font-family: Roboto;text-transform: uppercase;fill: #B1AFCD;font-size: '+C(26)+'px;font-weight: 400;">Land Plot</text>/>'
    Tooltip +=  '<image href="'+PATH+'land.svg" x="'+(x+C(30))+'" y="'+(y+C(241))+'" height="'+C(184)+'" width="'+C(176)+'"/>'

    if(real_coord != 'null') {

        Tooltip += '<text x="' + (x+C(244)) + '" y="' + (y+C(331)) +'" style="font-family: Roboto;text-transform: uppercase;fill: #ffffff;font-size: '+C(30)+'px;font-weight: 400;">'+real_coord+'</text>/>'
    }
        Tooltip += '<text x="' + (x+C(244)) + '" y="' + (y+C(361)) +'" style="font-family: Roboto;text-transform: uppercase;fill: #CCC9C9;font-size: '+C(26)+'px;font-weight: 300;">ID: '+real_id+'</text>/>'

    Tooltip += '</g>';

    document.getElementById('tooltip-area').innerHTML = Tooltip;

}


    