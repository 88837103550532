import React, { useEffect, useState } from "react";
import {Col, Layout, Row} from "antd";
import Intro from "./Intro";
import Balance from "./Balance";
import Performance from "./Performance";
import {NavLink} from "react-router-dom";
import {PATH} from "../../../config";
import {ROUTES as ROUTES_CONFIG} from "../../../config/constants";
import axiosData from "../../../helpers/axiosData";
import { useSelector } from "react-redux";

import JsxParser from 'react-jsx-parser';


const { Content } = Layout

const Metahuts = () => {


    let L = useSelector(state => state.settings.L)
    const [listData, setListData] = useState([])

    const DefaultCoverImage = PATH+'/metahuts-empty.png';

    const getAllData = () => {

        axiosData.get("/api/_web3/metahuts/my-list").then((res) => {

            if (res.data.data.length) {
                setListData( res.data.data );
            } else {
                setListData( [] );
            }
        })
    }

    useEffect(() => {
        getAllData()
    }, [])

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10">
                    <Intro />
                    <div className="flex items-start dashboard">
                        <div className="content-left">
                            <Balance />
                            <Performance />
                        </div>
                        <div className="content-right">
                            <div className="block font-bold mb-10 meta-card-header">
                                <NavLink to={ROUTES_CONFIG.profile.path} className="meta-nav">
                                    {Layout.myAssets}
                                </NavLink> / 
                                <span className="text-white "> {L.metahutsCount.replace('_COUNT_', (listData && listData.length ?listData.length : 0)) }</span>
                            </div>

                            <Row gutter={34}>
                                <Col xs={24} sm={24} md={12} lg={8} className="mb-10">
                                    <NavLink to={ROUTES_CONFIG.getMetahut.path}>
                                        <div className="relative cursor-pointer avatar_box avatar_upload flex flex-col justify-center gap-y-5 items-center" style={{height: "217px"}}>
                                            <i className="icon-plus text-base flex items-center justify-center upload-icon"></i>
                                            <span className="text-center text-base font-light text-white">
                                                {L.purchaseNewLand && <JsxParser jsx={L.purchaseNewMetahut} /> } 
                                            </span>
                                        </div>
                                    </NavLink>
                                </Col>


                                {listData && listData.length ?
                                    listData.map(li => {
                                        return <Col xs={24} sm={24} md={12} lg={8} 
                                                className="flex flex-col items-center assets-col"
                                            >
                                            <div className="w-100 thumb">
                                                <div className="relative text-right thumb-asset list-metahut" style={{background: `url(${li.cover_image !='' ? li.cover_image : DefaultCoverImage})  no-repeat center center / contain`}}>
                                                    {li.published ?
                                                        <span className="font-medium inline-block thumb-ctg thumb-ctg_publish">{L.publish}</span>
                                                        :
                                                        <span className="font-medium inline-block thumb-ctg thumb-ctg_draft">{L.draft}</span>
                                                    }
                                                </div>
                                                <div className="flex flex-col thumb-info">
                                                    <p className="text-white mb-0 font-normal thumb-title">{li.land_name}</p>
                                                    <div className="flex items-center flex-wrap stats">
                                                        <div className="flex items-center stats-item">
                                                            <i className="icon-people"></i>
                                                            <span>{li.followers}</span>
                                                        </div>
                                                        <div className="flex items-center stats-item">
                                                            <i className="icon-eye"></i>
                                                            <span>{li.visitors}</span>
                                                        </div>
                                                        <div className="flex items-center stats-item">
                                                            <i className="icon-star"></i>
                                                            <span>{li.rate}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col> 
                                    })
                                : ''}
                            </Row>
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}

export default Metahuts;