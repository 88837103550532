import React, { useEffect, useState } from "react";
import { Alert, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import { PATH } from "../../config";

const Quests = () => {

  let BalanceMeto = useSelector ((state) => state.metahut.BalanceMeto);
    let ap = 0;
    const [APPP, setAPPP] = useState(0);


    
    useEffect(()=>{
      setTimeout(() => {
        
        setAPPP(1)
      }, 2000);
    }, [APPP])

    return (
      <div className="relative min-h-calc-head">
        <div className="wrapper h-dvh">
        
          {APPP && BalanceMeto ?  
          <iframe id="sesame-app"  src="https://sesamelabs.xyz/metafluence/?tab=home" name='{"hideLogo": true, "overrideWidth": 1360, "leftNavBar": true}"' width="100%" height="100%" frameborder="0"></iframe>
            :
            <Spin
                wrapperClassName="z-20 opacity-80 bg-indigo-900 max-h-fit fixed top-0 min-h-screen w-full flex items-center justify-center"
                size="large"
                indicator={<img src={PATH+'spinner.gif'} alt="" />}
            >
                <Alert
                    description="Loading"
                    type="info"
                    className="mt-20 bg-transparent border-0 text-white"
                />
            </Spin> 
          }
        
        </div>
      </div>
    )
}

export default Quests;