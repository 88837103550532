import React, {useEffect} from "react";
import {Col, DatePicker, Row, TimePicker} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
dayjs.extend(weekday)
dayjs.extend(localeData)

const DateTime = ({L}) => {

    let formData = useSelector ((state) => state.event.formData)
    let dispatch = useDispatch()
    
    const setFormData = (type, value) => {
        dispatch({type: 'setFormData', payload: {...formData, [type] : value}})
    }

    const date = new Date();

    let currentDay= String(date.getDate()).padStart(2, '0');
    let nextDay= String(date.getDate() + 1).padStart(2, '0');
    let currentMonth = String(date.getMonth()+1).padStart(2,"0");
    let currentYear = date.getFullYear();
    let currentDate;
    let nextDate = `${currentYear}-${currentMonth}-${nextDay}`;
        currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

    useEffect(()=>{

        if( formData.start_date == '') {
            setFormData('start_date', currentDate);
        }

        if( formData.end_date == '') {
            setFormData('end_date', nextDate);
        }

    }, [formData])
        
   

    return (
        <div className="eform-item eform-group"> 
            <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <label htmlFor="startDate" className="elabel">{L.startDate}</label>
                    <DatePicker format="YYYY-MM-DD" onChange={(a, b)=>{setFormData('start_date', b)}} showToday={false} defaultValue={dayjs(formData.start_date !='' ? formData.start_date : currentDate, "YYYY-MM-DD")} name="startDate" className="eform-picker" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <label htmlFor="startTime" className="elabel">{L.startTime}</label>
                    <div className="flex items-center gap-x-4">
                        <TimePicker
                            format="HH:mm"
                            value={moment(formData.start_time, "HH:mm")}
                            onChange={(value) => console.log(value)}
                            name="startTime"
                            onSelect={(value) => { setFormData('start_time', moment(value).format("HH:mm")) }}
                            popupClassName="timepicker"
                            className="eform-picker"
                            allowClear={false}
                            showNow={false}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <label htmlFor="endDate" className="elabel">{L.endDate}</label>
                    <DatePicker onChange={(a, b)=>{setFormData('end_date', b)}} showToday={false} defaultValue={dayjs(formData.end_date !='' ? formData.end_date : nextDate, "YYYY-MM-DD")} name="endDate" className="eform-picker" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <label htmlFor="endTime" className="elabel">{L.endTime}</label>
                    <div className="flex items-center gap-x-4">
                        <TimePicker
                            format="HH:mm"
                            value={moment(formData.end_time, "HH:mm")}
                            onChange={(value) => console.log(value)}
                            name="endTime"
                            onSelect={(value) => { setFormData('end_time', moment(value).format("HH:mm")) }}
                            popupClassName="timepicker"
                            className="eform-picker"
                            allowClear={false}
                            showNow={false}
                        />
                    </div>
                </Col>
            </Row>
            
        </div>
    )
}

export default DateTime;