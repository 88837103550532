import React, {useEffect, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {Button, List, Menu, Modal, Skeleton, Space} from "antd";
import NumberFormat from "react-number-format";
import {destroyUser, login, selectUser} from "../../redux/features/userSlice";
import { useDispatch, useSelector } from 'react-redux'
import {PATH} from "../../config";
import { getCookie } from "../../config/CoreMethods";
import {ROUTES as ROUTES_CONFIG} from "../../config/constants";


const Sidebar = () => {
    const selector = useSelector;

    let L = selector (state => state.settings.L);
    let GetUser = selector(selectUser);
    
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [AvatarFile, setAvatarFile] = useState(PATH+'avatar.png')

    let IsMetamask = selector ((state) => state.metahut.IsMetamask)
    let IsMobile = selector ((state) => state.metahut.IsMobile)
    let CurrentAccount = selector ((state) => state.metahut.Account)
    let BackLogin = selector ((state) => state.metahut.BackLogin)
    let FrontLogin = selector ((state) => state.metahut.FrontLogin)
    let FrontNetwork = selector ((state) => state.metahut.FrontNetwork)

    const AvatarOnError = () => {
        setAvatarFile(PATH+'avatar.png')
    }

    useEffect(() => {

        if (GetUser && GetUser.avatar) {
            setAvatarFile(GetUser.avatar)
        }

    }, [GetUser, CurrentAccount])


    const menu = (
        <Menu
            items={[
                {
                    key: '0',
                    label: <NavLink to="/settings/account" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.sidebarAccount}</NavLink>,
                },
                {
                    key: '1',
                    label: <a onClick={()=> {dispatch(destroyUser()); dispatch({type: 'setShowDrawer', payload: false}); navigate('/discovery', {replace: true});}}>{L.sidebarLogOut}</a>,
                },
            ]}
        />
    );

    const handleOk      = () => { setIsModalVisible(false); };
    const handleCancel  = () => { setIsModalVisible(false); };

    let mode = getCookie('mode'); 

    return (
        <>
            <Modal footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} className="modal modal-middle">
                {
                    <div className="px-6 py-8 h-70 flex flex-col justify-center gap-y-4">
                        
                        {mode!='game' ? 
                        window.ethereum ? 
                            <Button onClick={()=>{dispatch(login());}} className="button--sign border-0 focus:outline-none text-base font-bold text-white rounded-md flex items-center">
                                <img src={PATH+'metamask.png'} alt="" className="button--img" />
                                {L.connectMetamask}
                            </Button>
                            :
                            <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank">
                                <Button className="button--sign border-0 focus:outline-none text-base font-bold text-white rounded-md flex items-center">
                                    <img src={PATH+'metamask.png'} alt="" className="button--img" />
                                    {L.installMetamask}
                                </Button>
                            </a>
                        :''
                        }

                        <Link onClick={() => setIsModalVisible(false)} to="/login" className="button--sign border-0 focus:outline-none text-base font-bold text-white rounded-md flex items-center">
                            <img src={PATH+'email.png'} alt="" className="button--img" />
                            {L.signInWithEmail}
                        </Link>
                    </div>
                }
            </Modal>
            
            
            {!IsMobile && IsMetamask ?
                <div className="visible-xs text-center">
                    <a href="https://metamask.app.link/dapp/app.metafluence.com/city/index.html" className="inline-block py-2 px-4 min-w-24 border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-2xl mb-4">{L.goToMetamask}</a>
                </div>
            : '' }
            
            <div className="flex flex-col gap-y-2 font-sans pb-4 border-b-indigo-600 border-b border-solid mb-5 visible-xs">
                <>
                    { BackLogin ?
                        <div className="flex justify-center">
                            <Space className="text-white font-sans text-base">
                                <div className="relative avatar_box user-box"
                                    style={{backgroundColor: GetUser && GetUser.bgcolor}}
                                >
                                    <img src={AvatarFile} onError={AvatarOnError} width="100%" height="100%" className="user-avatar"/>
                                </div>
                                {GetUser && GetUser.name && GetUser.name!=='' ? GetUser.name : (GetUser.username && GetUser.username !=='' ? GetUser.username : String(GetUser.account).substring(0, 6)+'...' + String(GetUser.account).substring(38)) }
                            </Space>
                        </div>
                        :
                        <div className="text-center">
                            <NavLink to={ROUTES_CONFIG.loginPage.path} onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>
                                <Button className="button--gradient button-join border-0 focus:outline-none text-sm text-white py-1 px-7 rounded-2xl uppercase">{L.joinButton}</Button>
                            </NavLink>
                        </div>
                    }

                { FrontLogin && FrontNetwork &&
                    <>
                        <div className="flex items-center gap-x-4 gap-y-2 justify-center">
                            <div className="flex items-center gap-x-2">
                                <img src={PATH + 'soto_icon.svg'} alt="" width="18px"/>
                                {GetUser && typeof GetUser.soto_balance != 'null' ?
                                    <NumberFormat value={GetUser.soto_balance} displayType={'text'}
                                                  thousandSeparator={true} className="text-zinc-300 text-sm"
                                                  renderText={(value, props) =><span {...props}>{value}</span>}
                                    />
                                    :
                                    <Skeleton.Button active size={"small"} />

                                }
                            </div>
                            <div className="flex items-center gap-x-2">
                                <img src={PATH + 'meto_icon.svg'} alt="" width="18px"/>
                                {GetUser && typeof GetUser.meto_balance != 'null' ?
                                    <NumberFormat value={GetUser.meto_balance} displayType={'text'}
                                                  thousandSeparator={true} className="text-zinc-300 text-sm"
                                                  renderText={(value, props) =><span {...props}>{value}</span>}
                                    />
                                    :
                                    <Skeleton.Button active size={"small"} />

                                }
                            </div>
                            <div className="flex items-center gap-x-2">
                                <img src={PATH + 'bnb.svg'} alt="" width="18px"/>
                                {GetUser && typeof GetUser.busd_balance != 'null' ?
                                    <NumberFormat value={GetUser.busd_balance} displayType={'text'}
                                                  thousandSeparator={true} className="text-zinc-300 text-sm"
                                                  renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                    :
                                    <Skeleton.Button active size={"small"} />

                                }
                            </div>
                        </div>
                    </>
                }
                </>
            </div>
            
            <List>
                <List.Item className="border-0 justify-center">
                    <NavLink to={ROUTES_CONFIG.discoveryOverview.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>
                        {L.navDiscovery}
                    </NavLink>
                </List.Item>
                <List.Item className="border-0 justify-center">
                    <NavLink to={ROUTES_CONFIG.events.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>
                        {L.navEvents}
                    </NavLink>
                </List.Item>
                <List.Item className="border-0 justify-center">
                    <NavLink to={ROUTES_CONFIG.pfpNftAvatar.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>
                        PFP NFT Avatar
                    </NavLink>
                </List.Item>
                <List.Item className="border-0 justify-center">
                    <NavLink to={ROUTES_CONFIG.home.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.navMap}</NavLink>
                </List.Item>
                <List.Item className="border-0 justify-center">
                    <NavLink to={ROUTES_CONFIG.quests.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>
                        Quests
                    </NavLink>
                </List.Item>
                <List.Item className="border-0 justify-center">
                    <NavLink to={ROUTES_CONFIG.staking.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>
                        {L.navStaking}
                    </NavLink>
                </List.Item>
                <List.Item className="border-0 justify-center">
                    <a href="https://metafluence.gitbook.io/docs/" target="_blank" className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.navDocs}</a>
                </List.Item>
                <List.Item className="border-0 justify-center">
                    <NavLink to={ROUTES_CONFIG.fag.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.navFaq}</NavLink>
                </List.Item>
            </List>
            {GetUser &&  GetUser.id ?
                    <>
                        <div className="sidebar-title mb-2 text-gray-300 font-sans text-base">Referrals</div>
                        <List className="sidebar-menu">
                            <List.Item className="border-0 justify-center">
                                <NavLink to={ROUTES_CONFIG.referralLink.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.navReferral}</NavLink>
                            </List.Item>
                            <List.Item className="border-0 justify-center">
                                <NavLink to={ROUTES_CONFIG.influenceNetwork.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.navNetwork}</NavLink>
                            </List.Item>
                            <List.Item className="border-0 justify-center">
                                <NavLink to={ROUTES_CONFIG.monthlyPerformance.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.navPerformance}</NavLink>
                            </List.Item>
                        </List>
                        <div className="sidebar-title mb-2 text-gray-300 font-sans text-base">
                            {L.sidebarSettings}
                        </div>
                        <List>
                            <List.Item className="border-0 justify-center">
                                <NavLink to="/settings/account" className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.sidebarAccount}</NavLink>
                            </List.Item>
                            <List.Item className="border-0 justify-center">
                                <NavLink to={ROUTES_CONFIG.securityPrivacy.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.sidebarSecurity}</NavLink>
                            </List.Item>
                            <List.Item className="border-0 justify-center">
                                <NavLink to="/settings/status" className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>
                                    {L.sidebarStatus}
                                </NavLink>
                            </List.Item>
                            <List.Item className="border-0 justify-center">
                                <NavLink to="/settings/wallet" className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>
                                    {L.sidebarWallet}
                                </NavLink>
                            </List.Item>
                        </List>
                        <div className="sidebar-title mb-2 text-gray-300 font-sans text-base">User</div>
                        <List>
                            <List.Item className="border-0 justify-center">
                                <NavLink to={ROUTES_CONFIG.profile.path} className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.navProfile}</NavLink>
                            </List.Item>
                            <List.Item className="border-0 justify-center">
                                <NavLink to="/settings/account" className="text-white font-sans text-base" onClick={() => dispatch({type: 'setShowDrawer', payload: false})}>{L.sidebarAccount}</NavLink>
                            </List.Item>
                            <List.Item className="border-0 justify-center">
                                <a className="text-white font-sans text-base" onClick={()=> {dispatch(destroyUser()); navigate('/discovery/overview', {replace: true});}}>
                                    {L.sidebarLogOut}
                                </a>
                            </List.Item>
                        </List>
                    </>
                : ''}
        </>
    )
}
export default Sidebar;