import React, {useState, useEffect} from "react";
import {Select} from "antd";
import axiosData from "../../helpers/axiosData";
import {useDispatch, useSelector} from "react-redux";

const { Option } = Select;

const SelectSearch = ({changeAccount, selectedItem}) => {
    
    let IsAdmin = useSelector ((state) => state.menu.IsAdmin);
    let L = useSelector (state => state.settings.L);

    const [UserAdresses, setUserAdresses] = useState([])
    const [trigger, setTrigger] = useState(1)
    const [defaultValue_, setDefaultValue] = useState('----')
    const [listItems, setlistItems] = useState([])

    const dispatch = useDispatch()

    const getUserAdresses = () => {

        axiosData
         .get(`/api/_web3/user/get-user-addresses/`)
         .then((res) => { 
            if (res.data.users  &&  res.data.users.length > 0) {
                setUserAdresses(res.data.users);
                makeOptions(res.data.users);
                setTrigger(trigger+1);
                dispatch({type: 'setIsAdmin', payload: true});
            } 
         })
        .catch((error) => {
            console.log('get error: ', error)
        });
    }

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const makeOptions = (UserAdresses) => {
        let list = [];
        let default_ = '----';

        if (UserAdresses && UserAdresses.length) {
                
            list.push(<Option value="0" key="0" selected={true}> ---- </Option>)
            UserAdresses.map((key) => { 

                if (selectedItem && selectedItem == key.id) {
                    default_ = key.account;
                }

                 list.push(<Option value={key.id} key={key.id}>{key.username} - {key.name} - {key.account} </Option>)
            })
            
            setDefaultValue(default_)
            setlistItems(list)

        }

    }

    useEffect(() => {
        getUserAdresses();
    }, [])

    return (
        
        <>
        { IsAdmin && UserAdresses.length &&  listItems.length ?
            <>
                <Select name="accounts" key="1"
                    showSearch
                    placeholder={L.selectOneAccount}
                    optionFilterProp="children"
                    className="custom-select"
                    onChange={changeAccount}
                    onSearch={onSearch}
                    defaultValue={defaultValue_}
                >
                    {listItems}
                </Select>
            </>
            : ''
        }
        </> 
    )
}

export default SelectSearch;