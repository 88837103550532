import axios from "axios";
import {API, basePath, TEST_ENV} from ".";
import axiosData from "../helpers/axiosData";

export const get = (url, callback) => {

    const xhr = new XMLHttpRequest()
    //open a get request with the remote server URL
    xhr.open("GET", url)
    //send the Http request
    xhr.send()

    //triggered when the response is completed
    xhr.onload = function() {
        if (xhr.status === 200) {
            //parse JSON data
            let data = xhr.responseText
            callback(data)
        } else if (xhr.status === 404) {
            // console.log("No records found")
        }
    }
}

export const redirectTo = (url) => {
        
    // let root_ = basePath + url; 
    // root_ = root_.replace((basePath+basePath), basePath);
    //  console.log( 'url', url, ', basePath', basePath, ', root_', root_, 'window.location.href', window.location.pathname)

    if ( url !== window.location.pathname ) {
        window.location.href = url;
    }
}

export const getItems = (key, is_array) => {

    let item = localStorage.getItem(key);

    try{ item =  JSON.parse(item); } catch(err) { /*console.log('err parse', err);*/ item = []; }
    // console.log('item === ', item != null ,'&&', item != 'null' ,'&&', item != '', '&&', (typeof item.length != 'undefined'), typeof item == 'object' )

    item = item != null && item != 'null' && item != '' && (typeof item == 'object' || typeof item.length != 'undefined') ? item : (is_array?[]:{});
    // console.log('item => ', item)

    return item;
}


export const addPlotToLocalstorage = (plots) => {

    let item = localStorage.getItem('myCollection');

    try { item =  JSON.parse(item); } catch(err) { /*console.log('err parse', err);*/ item = []; }
    
    item = item != null && item != 'null' && item != '' && (typeof item == 'object' || typeof item.length != 'undefined') ? item : {};
    // console.log('item => ', item)

    if (plots && plots.length) {
        for (const k in plots) {
            if (Object.hasOwnProperty.call(plots, k)) {
                const li = plots[k];
                item[parseInt(li.tokenID)] = {

                    buyer_acoount : li.to ? li.to : '...',
                    id : li.id,
                    is_buy : 0,
                    is_my : 1,
                    real_coord : li.real_coord,
                    transform : li.transform,
                    x : li.x,
                    y : li.y
                }
            }
        }
    }

    localStorage.setItem("myCollection", JSON.stringify(item)); 
    // console.log('itemsss', item)
}



/*-----------------------*/
export const getRectSelecteds = (return_array) => {

    let items = getItems('selectedrects', return_array)

    if(return_array) {

        let items_ = [];

        for (let i in items) {
            items_.push(i)
        }

        return items_;
    }

    return items;
}

export const updateUserAccount = (account) => {
    
    alert('You don`t connect wallet. For continue connect wallet');
    /* axiosData
    .post(`/api/_web3/user/settings/`, {account:account})
    .then((res) => {
       console.log('response', res);
    })
   .catch((error) => {
   console.log('put error: ', error)
       // setErrors(error.response.data.errors);
       // toast.error(error.response.data.message);
   })
   .finally(); */
}

export const getAppSettings = (callback=()=>{}) => {

    axiosData
    .get(`/api/_web3/user/app-settings/`)
    .then((res) => {
        callback (res.data.data);
    })
   .catch((error) => {
        console.log('error: ', error)
        callback ({});
   })
}


/*---------------------------*/
export const saveBuyedNFT = (account, items='') => {

    if (items !== '') {

        get(API+'?account='+account+'&items='+items, function(data) {
            // console.log('data', data)
        });
    }

    

    axiosData.get(`/api/_web3/land-buy/${account}`)
    .then((res) => {
    //    console.log('response land-buy ', res);
    })
   .catch((error) => {
        console.log('put error: ', error)
       // setErrors(error.response.data.errors);
       // toast.error(error.response.data.message);
   })
   .finally((data) => {console.log('final data', data)});
    
 
    // https://app.metafluence.com/api/v1/public/api/_web3/land-buy/0x74322856849113bC99943b7adA7B3ed4f4518415
}





export const log_ = (data) => {
    if (TEST_ENV == 0) {
        get(API+'log.php?account='+getCookie('account')+'&data='+JSON.stringify(data), function(result) {
            let dat = JSON.parse(result);
        });
    }
}


export const getCookie = (cookie_) => {
    let Cookies = document.cookie;
    if(Cookies != '' && Cookies.indexOf(cookie_ + '=') > -1) {
        let SpCookie = Cookies.split(cookie_ + '=');
        let Cookie = SpCookie[1].split(';')[0];
        return Cookie;
    }

    return '';
}

export const setCookie = (cookie_, val_, expire=0) => {
    let date_1 = new Date(); 
    date_1.setDate(date_1.getDate()+(expire ? -365 : 365));
    date_1.getFullYear()

    document.cookie = cookie_ + "=" + val_ + "; expires="+date_1.toUTCString()+"; path=/"; //Thu, 18 Dec 2023 12:00:00 UTC

    return true;
}



export const C = (w,percent) => {

    let transform = document.getElementById('panzom-panned').style.transform;

    transform = transform.replace('matrix(', '').replace(')', '').replace(' ', '').split(',')

    let dif = 1.3-parseFloat(transform[0]);

    let resultd =  w + (dif*w)

    percent = typeof percent != 'undefined' ? percent : -.21;
    // console.log('percent', percent)
    return resultd+(resultd*percent);

}

export const ConvertDate = (date, utc_to_local=1) => {
    
    let result = '';

    try {
        date = utc_to_local ? date + ' UTC' : date;
        let d = new Date(date);

        result = d.toLocaleString('en-US', { month: 'short' })
        
        let day = parseInt(d.getDate());
        let year =  d.getFullYear();
        let hours = parseInt(d.getHours());
        let minutes = parseInt(d.getMinutes());

        day = day > 9 ? day : '0'+day;
        hours = hours > 9 ? hours : '0'+hours;
        minutes = minutes > 9 ? minutes : '0'+minutes;

        result += ' ' + day + ', ' + year + ' - ' + hours + ':' + minutes

        return result;
    }
    catch (error) { /* console.log('fetchUser error', error) */
        return '';
    }
}


export const FullConvertDate = (date, utc_to_local=0) => {
    
    let result = '';

    try {
        date = utc_to_local ? date + ' UTC' : date;
 
        let d = new Date(date);
 
        let month, day, year, hours, minutes;
        
        if (utc_to_local) {

            month = parseInt(d.getMonth())+1;
            day = parseInt(d.getDate());
            year =  d.getFullYear();
            hours = parseInt(d.getHours());
            minutes = parseInt(d.getMinutes());
        } else {
            month = parseInt(d.getUTCMonth())+1;
            day = parseInt(d.getUTCDate());
            year =  d.getUTCFullYear();
            hours = parseInt(d.getUTCHours());
            minutes = parseInt(d.getUTCMinutes());
        } 

        month = month > 9 ? month : '0'+month;
        day = day > 9 ? day : '0'+day;
        hours = hours > 9 ? hours : '0'+hours;
        minutes = minutes > 9 ? minutes : '0'+minutes;

        result += year + '-' + month + '-' + day + ' ' + hours + ':' + minutes

        return result;
    }
    catch (error) {  
        return '';
    }
}



export const logUrl = (user_id) => {
    
    let url = window.location.href
    
    if (url != '') {

        url = encodeURI(url)

        axiosData.post(`/api/_web3/front-log/`, 
            {   
                user_id: user_id, 
                url: url
    
            }).then((res) => {
    
           console.log('log response', res);
        })
       .catch((error) => {
            console.log('404 error: ', error)
       })
       .finally(); 

    }
}




export const approvePullingWithApi = (wallet, callback=()=>{}) => {
    
    if (wallet != '') {

        axiosData.get('/api/_web3/user/approve-pulling/' + wallet)
        .then((res) => {
            callback(true)
        })
       .catch((error) => {
            callback(false)
       })
       .finally(); 

    }

}
