import React, {useEffect, useState} from "react";
import {Button, Col, Layout, Modal, Row, Table, Tooltip} from "antd";
import SettingsSidebar from "./SettingsSidebar";
import {PATH, PullContractAddress} from "../../../config";
import axiosData from "../../../helpers/axiosData";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import {fetchUser, login, selectUser} from "../../../redux/features/userSlice";

import { ToastContainer, toast } from "react-toastify";

import { ROUTES } from "../../../config/constants";
import { ethers } from 'ethers';

import JsxParser from 'react-jsx-parser';
import InstallMetamsk from "../staking/components/Alerts/InstallMetamask";
import ConnectMetamask from "../staking/components/Alerts/ConnectMetamask";
import ChangeNetwork from "../staking/components/Alerts/ChangeNetwork";
import { approve, getBalance, pullMETO } from "../../../redux/actions/SettingsAction";
import { approvePullingWithApi } from "../../../config/CoreMethods";



const {Content} = Layout;

const Wallet = () => {

    let L = useSelector   (state => state.settings.L);
    let GetUser = useSelector(selectUser);
    
    const dispatch = useDispatch();
    const [UserData, setUserData] = useState([]);
    const [SummaryData, setSummaryData] = useState({soto:[0,0], meto:[0,0],busd:[0,0]});
    const [Last, setLast] = useState({});
    const [FromTokenValue, setFromValue] = useState(0);
    const [toTokenValue, setToValue] = useState(0);

    const [TokenConvertLimit, setTokenConvertLimit] = useState(0);
    const [TokenConvertPercent, setTokenConvertPercent] = useState(0);
    const [fromToken, setFromToken] = useState('soto');
    
    const [MetoRate, setMetoRate] = useState(0);

    const [SotoValue, setSotoValue] = useState(0);
    
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalImportMeto, setModalImportMeto] = useState(false);

    const [SendToWallet, setSendToWallet] = useState('');
    const [TransferValue, setTransferValue] = useState(0);
    const [MaxTransferValue, setMaxTransferValue] = useState(0);
    const [PullMetoAmount, setPullMetoAmount] = useState(0);
    const [Balance, setBalance] = useState(0);

    let FrontNetwork = useSelector ((state) => state.metahut.FrontNetwork)
    let FrontLogin = useSelector ((state) => state.metahut.FrontLogin)
    let Account = useSelector ((state) => state.metahut.Account)

    // const [showMetamask, setShowMetamask] = useState(false);


    const getUserData = () => {
        axiosData
            .get('/api/_web3/user/wallet/statictics')
            .then((res) => {
                if ( res) {
                    let data = res.data.list;
                    setUserData(data);
                    setSummaryData(res.data.summary)
                    setLast(res.data.last)
                }
            })
            .catch((error) => {
                console.log('status error: ', error)
            })
    }
    

    const changeFromToken = () => {
        let from_token = FromTokenValue;
        let to_token = toTokenValue;
        
        setFromToken(token => {

            setFromValue(to_token)
            setToValue(from_token)
            
            return token == 'soto' ? 'meto' : 'soto'
        })
    }


    const SwapTokens = () => {
        if (GetUser && GetUser.rank >= 5) {
            
            if (SotoValue <= GetUser.soto_limit) {
                if (SotoValue > 0) {

                    axiosData.post('/api/_web3/user/exchange-tokens', {
                        from_token : fromToken,
                        to_token : (fromToken=='soto'?'meto':'soto'),
                        from_value : FromTokenValue 
                    }).then((res) => {
                        if ( res) {
                            let data = res.data;
                            
                            setIsModalVisible(false)

                            if (data.success) {
                                toast.success("Success");
                                getUserData()
                            } else {
                                toast.error(data.message);
                            }

                        }
                    })
                     .catch((error) => {
                        console.log('status error: ', error)
                    })
        
                } else {
                    toast.error(L.insertTokenAmount);
                } 
            } else {
                toast.error(L.youDailyLimitNotEnough);
            }
        }
    }

    
    const TransferTokensToWallet = () => {
        if (GetUser && GetUser.rank >= 5) {
            
            if (TransferValue > 0) {

                axiosData.post('/api/_web3/user/transfer-tokens-to-wallet', {
                    wallet : SendToWallet,
                    amount : TransferValue,
                }).then((res) => {

                    console.log('result', res.data)

                    if ( res) {
                        let data = res.data;
                        
                        setIsModalVisible(false)

                        if (data.success) {
                            toast.success("Success");
                            getUserData()
                        } else {
                            toast.error(data.message);
                        }

                    }
                })
                    .catch((error) => {
                    console.log('status error: ', error)
                })
    
            } else {
                setIsModalVisible(false)
                toast.error(L.insertTokenAmount);
            } 
            
        }
    }


    const changeInput = (direction, value) => {

        value = parseInt(value.replace(',',''))
        
        // console.log('direction', direction, ', value', value, ', fromToken', fromToken)

        if (direction == 'from') {
            

            if (fromToken == 'soto') { 
                if (TokenConvertLimit >= value) {                    
                    setFromValue(value)
                    setSotoValue(value) 
                    setToValue( Math.round(100*value/MetoRate)/100 )
                    return
                }

            } else {
                if ( value * MetoRate <= TokenConvertLimit ) {                    
                    setFromValue(value)
                    setSotoValue(Math.round(100 * value * MetoRate)/100)
                    setToValue(Math.round(100 * value * MetoRate)/100)
                    return
                }
            }

        } else { 
            
            
            if (fromToken == 'soto') { 

                if (value * MetoRate <= TokenConvertLimit) {
                    setToValue(value)
                    setSotoValue(Math.round(100 * value * MetoRate)/100)
                    setFromValue(Math.round(100 * value * MetoRate)/100)
                    return
                }

            } else {
                if (value <= TokenConvertLimit) {
                
                    setToValue(value)
                    setSotoValue(Math.round( value) )
                    setFromValue(Math.round( 100 * value/MetoRate)/100 )
                    return 
                }
            }    
        }


        toast.error('You exceed limit')


    }


    useEffect(() => {

        getUserData()
        
        if (GetUser && GetUser.exchange_daily_limit_soto) {
            setTokenConvertLimit(GetUser.soto_limit)
            setTokenConvertPercent(GetUser.exchange_transaction_fee)
            setMetoRate(GetUser.meto_rate);

            let fee = GetUser.exchange_transaction_fee/100;
            let soto = GetUser.soto_balance < (GetUser.soto_limit+GetUser.soto_limit*fee) ? GetUser.soto_balance - GetUser.soto_balance*fee : GetUser.soto_limit;
            
            setFromValue(soto)
            setSotoValue(soto)
            setToValue( Math.round(100*soto/GetUser.meto_rate)/100 )

            setSendToWallet(GetUser.account)
            setTransferValue (parseInt(GetUser.meto_balance))
            setMaxTransferValue (parseInt(GetUser.meto_balance))
            
        }

        if (Account != '') {
            getBalance(Account).then(balance=>{
                // console.log('Balance', balance, Math.round(parseInt(balance)/10**18));
                setBalance(Math.round(parseInt(balance)/10**18))
            })
        }

    }, [GetUser, Account])


    const pageSize = 20;

    const dataReferral = (dataList) => {

        let List = [];

        if (dataList && dataList.length) {
            dataList.forEach(li => {
                
                List.push({
                    key: li.id,
                    username: (
                        li.direction=='in' ? 
                            <div className="flex items-center gap-x-3">
                                <img src={PATH+'mint.svg'} width="21px" />
                                <span className="color-mint">{L.mint}</span>
                            </div>
                        :
                            <div className="flex items-center gap-x-3">
                                <img src={PATH+'burn.svg'} width="21px" />
                                <span className="color-burn">{L.burn}</span>
                            </div>
                         ),
                    date: li.date,
                    source : li.source, 
                    type: li.type,
                    rewards: (
                        <div className="flex items-center gap-x-2">
                            <img src={PATH+li.currency_short.toLowerCase()+'_icon.svg'} alt="" style={{height: "20px"}} />
                            <span className="font-light text-base">{li.value} {li.currency_short}</span>
                        </div>
                    ),
                },)
            });
        }

        return List;
    }


    const columns = [
        {
            title: '',
            width: 200,
            dataIndex: 'username',
            key: 'username',
            fixed: 'left', 
        },
        {
            title: L.date,
            width: 150,
            dataIndex: 'date',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            key: 'date',
        },
        {
            title: L.source,
            dataIndex: 'source',
            key: 'source',
            width: 150,
        },
        {
            title: L.type,
            dataIndex: 'type',
            key: 'type',
            width: 170,
        },
        {
            title: L.rewards,
            dataIndex: 'rewards',
            key: 'rewards',
            width: 180,
        },
    ];

    const handleOk = () => { setIsModalVisible(false); } 
    const handleCancel = () => { setIsModalVisible(false); } 

    const ExecImportMeto = () => {
        try {
            if (Balance >= 0 && Balance >= PullMetoAmount) {
                
                approve(PullContractAddress, PullMetoAmount+'000000000000000000')
                .then(data => {

                    if ( typeof data != 'undefined' ) {
                        setTimeout(()=>{

                            pullMETO(PullMetoAmount+'000000000000000000').then(pull => {
                                
                                approvePullingWithApi(Account, result=>{
        
                                    getUserData();
                                    toast.success('Succesfully imported');
                                    setModalImportMeto(false)
                                })
                                // console.log('Pull then', pull)
        
                            }).catch(error => {
                                toast.error('something went wrong, please, try again') 
                            })

                        }, 7000)
                    } else {
                        dispatch({type: 'SetLandBuyingLoading', payload: 4})
                    }

                }).catch(error => {
                    toast.error('Something went wrong') 
                })
            } else {
                toast.error('Amount axceed balance')
            }
        } catch (err) {
            console.log('Error catched', err)
        }
    }



    return <>

        <ToastContainer />

        <Modal 
            footer={false} 
            onCancel={() => setModalImportMeto(false)} 
            closeIcon={<i className="icon-close"></i>} 
            centered 
            visible={modalImportMeto} 
            className="modal emodal addmodal"
        >
            <div className="emodal-header">
                <h2 className="emodal-ttl">Import from wallet</h2>
            </div>
            <div className="line-x"></div>
            
            {!window.ethereum ? <InstallMetamsk /> : !Account ? <ConnectMetamask /> : !FrontNetwork ? <ChangeNetwork/> : 
            GetUser && GetUser.account.toLowerCase() == Account.toLowerCase() ? 
            <>
                
                <input onChange={(e) => setPullMetoAmount( parseInt(e.target.value) )} className="bg-indigo-700 pl-4 pr-10 w-80 border-0 mb-4 input rounded-md text-white w-100" type="text" autoComplete="off" name="fromVal" style={{fontSize:'12px'}}
                />
                <Button className="submit-btn" onClick={ExecImportMeto}>Add</Button>
            
            </> : <>
                <div style={{color:"#fff"}}>
                    <p> For import meto, please select below wallet in metamask  </p>
                    <i> {GetUser && GetUser.account} </i>
                </div>
            </>
            }


        </Modal>
    
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
        <Layout className="h-full settings-layout">
            <SettingsSidebar />

            
            <Modal footer={false} closable={false} visible={isModalVisible} onOk={handleOk} 
                onCancel={handleCancel} className="modal modal-logo" 
                style={{background: `#1c1a42 url(${PATH+'logo-icon-gray.svg'}) no-repeat center center / 140%`}}
            >
                <div className="text-center">
                    {/* <h3 className="font-normal text-lg text-white mb-9">{L.youWantToBuyTicket && parse(L.youWantToBuyTicket.replace("_TICKET_NAME_", '<strong>“'+data.name+'”</strong>') ) }</h3> */}
                    <div className="flex items-center justify-center gap-x-3 mb-3">
                    </div>
                     
                    <p className="font-normal text-sm text-white">{L.SwapTokenAreYouSure}</p>

                </div>
                    <div className="flex items-center justify-center gap-x-4 mt-12">
                        <Button onClick={() => handleCancel()} className="min-w-24 border-0 bg-gray-200 text-indigo-900 hover:bg-gray-300 rounded-2xl font-large">{L.ticketModalBalanceMoreCancel}</Button>
                        <Button onClick={() => { return isModalVisible==2 ? TransferTokensToWallet() : SwapTokens() } } className="min-w-24 border-0 bg-pink-300 text-white hover:bg-pink-400 rounded-2xl font-large">
                            {isModalVisible==2 ? L.TransferYesIdo : L.SwapYesIdo}</Button>
                    </div>
            </Modal>


            


            <Content className="pt-8 pl-10 pr-4 settings-content">
                <h3 className="text-xl text-white font-bold mb-12 settings-title">{L.sidebarWallet}</h3>

                <h3 className="text-gray-300 text-lg font-light">{L.myAccountBalance}</h3>
                <Row gutter={24} className="mb-16">
                    <Col xs={24} sm={24} md={12} lg={8} className="mt-10">
                        <div className="relative rounded-xl px-4 py-8 flex justify-center items-center overflow-hidden" style={{minHeight: '165px', background: '#1c1a42'}}>
                            <div className="w-100 h-full bg-indigo-900 absolute rounded-xl z-1" style={{opacity: "50%"}}></div>
                            <img src={PATH+'soto_icon.svg'} alt="" style={{height: "100%", filter: "grayscale(1)", opacity: ".2", scale: "1.4"}} className="absolute" />
                            <span className="absolute top-0 left-0 mt-4 ml-4 z-2" style={{color: '#45EAFF'}}>SOTO</span>
                            <div className="flex gap-x-3 z-2">
                                <img src={PATH+'soto_icon.svg'} alt="" style={{height: "48px"}} />
                                <div className="text-right">
                                    <span className="text-3xl font-light">
                                        <NumberFormat
                                            value={SummaryData.soto[0]}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={(value) =><>{value}</>}
                                        />
                                    </span>
                                    {Last && Last.soto ? 
                                        <p className="mb-0 text-xs" style={{color: Last.soto.direction=='in'?'#00FF38':'#EF5261'}}>{Last.soto.direction=='in'?'+ ':'- '}
                                            <NumberFormat
                                                value={Last.soto.value}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value) =><>{value}</>}
                                            /> SOTO</p>
                                    :''}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} className="mt-10">
                        <div className="relative rounded-xl px-4 py-8 flex justify-center items-center overflow-hidden" style={{minHeight: '165px', background: '#1c1a42'}}>
                            <div className="w-100 h-full bg-indigo-900 absolute rounded-xl opacity-50"></div>
                            <img src={PATH+'meto_icon.svg'} alt="" style={{height: "100%", filter: "grayscale(1)", opacity: ".15", scale: "1.4"}} className="absolute" />
                            <span className="absolute top-0 left-0 mt-4 ml-4" style={{color: '#CF4EF7'}}>METO</span>
                            <div className="flex gap-x-3 z-1">
                                <img src={PATH+'meto_icon.svg'} alt="" style={{height: "48px"}} />
                                <div className="text-right">
                                    <span className="text-3xl font-light">
                                        <NumberFormat
                                            value={SummaryData.meto[0]}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={(value) =><>{value}</>}
                                        />
                                    </span>
                                    {Last && Last.meto ? 
                                        <p className="mb-0 text-xs" style={{color: Last.meto.direction=='in'?'#00FF38':'#EF5261'}}>{Last.meto.direction=='in'?'+ ':'- '}
                                            <NumberFormat
                                                value={Last.meto.value}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value) =><>{value}</>}
                                            /> METO</p>
                                    :''}
                                </div>
                            </div>
                        </div>
                        <div className="text-right mt-2">
                            <a href="https://promote.mexc.com/a/meto" target="_blank" className="text-white font-bold text-sm">Get <img src={PATH+'meto.svg'} alt="" style={{height: "16px"}} /> METO</a>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} className="mt-10">
                        <div className="relative rounded-xl px-4 py-8 flex justify-center items-center overflow-hidden" style={{minHeight: '165px', background: '#1c1a42'}}>
                            <div className="w-100 h-full bg-indigo-900 absolute rounded-xl opacity-50"></div>
                            <img src={PATH+'bnb.svg'} alt="" style={{height: "100%", filter: "grayscale(1)", opacity: ".15", scale: "1.4"}} className="absolute" />
                            <span className="absolute top-0 left-0 mt-4 ml-4" style={{color: '#EFB80A'}}>BUSD</span>
                            <div className="flex gap-x-3 z-1">
                                <img src={PATH+'bnb.svg'} alt="" style={{height: "48px"}} />
                                <div className="text-right">
                                    <span className="text-3xl font-light">
                                        <NumberFormat
                                            value={SummaryData.busd[0]}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={(value) =><>{value}</>}
                                        />
                                    </span>
                                    {Last && Last.busd ? 
                                        <p className="mb-0 text-xs" style={{color: Last.busd.direction=='in'?'#00FF38':'#EF5261'}}>{Last.busd.direction=='in'?'+ ':'- '}
                                            <NumberFormat
                                                value={Last.busd.value}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value) =><>{value}</>}
                                            /> BUSD</p>
                                    :''}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row gutter={24} className="mb-16 pb-25">
    
                    {/* Swap Card */}
                    <Col xs={24} sm={24} md={12} className="mt-10">
                        <div className="relative rounded-xl px-8 py-6 bg-indigo-900 overflow-clip">
                            <div className="w-100 left-0 h-full bg-indigo-900 absolute rounded-xl z-1" style={{opacity: "50%"}}></div>
                            <img src={PATH+'swap.svg'} alt="" style={{opacity: ".5"}} className="absolute left-0 w-100 h-full" />
                            
                            <div className="relative z-1">
                                <div className="flex items-center justify-between gap-x-4 mb-12">
                                    <span className="uppercase text-white text-sm">{L.swap}</span>
                                    <div className="flex items-center gap-x-2">
                                        <span className="text-cyan-500 uppercase">{L.rank} {GetUser && GetUser.rank}</span>
                                        <Tooltip placement="topRight" title="For exchange tokens you must have 5 or higher Rank" overlayClassName="tooltip-tr">
                                            <i className="icon-info"></i>
                                        </Tooltip>
                                    </div>
                                </div>
                                    
                                <div className="flex items-center gap-x-6">
                                    <div>
                                        <label htmlFor="fromVal" className="text-white text-xs mb-2 inline-block">
                                            {L.from}
                                        </label>
                                        <div className="flex items-center gap-x-5 mb-8">
                                            <div className="addon-after addon-swap">
                                                <NumberFormat
                                                    value={FromTokenValue}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    renderText={(value) =><input
                                                        className="bg-indigo-700 pl-4 pr-10 w-100 border-0 input rounded-md text-white"
                                                        type="text" 
                                                        autoComplete="off"
                                                        value={value}
                                                        max={2000}
                                                        name="fromVal"
                                                        onChange={(e) => changeInput('from', e.target.value)}
                                                    />}
                                                />
                                                <span className="text-cyan-500">{L.max}</span>
                                            </div>
                                            <div className="flex items-center gap-x-2">
                                                {fromToken=='soto'?
                                                <>
                                                    <img src={PATH+'soto_icon.svg'} alt="" style={{height: "24px"}} />
                                                    <span className="text-white text-sm uppercase">Soto</span>
                                                </> :
                                                <>
                                                    <img src={PATH+'meto_icon.svg'} alt="" style={{height: "24px"}} />
                                                    <span className="text-white text-sm uppercase">Meto</span>            
                                                </>
                                                }
                                            </div>
                                        </div>
                                        
                                        <label htmlFor="toVal" className="text-white text-xs mb-2 inline-block">
                                            {L.to}
                                        </label>
                                        <div className="flex items-center gap-x-5 mb-10">
                                            <NumberFormat
                                                value={toTokenValue}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value) =><input
                                                    className="bg-indigo-700 w-100 pl-4 pr-3 border-0 input rounded-md text-white"
                                                    type="text" 
                                                    autoComplete="off"
                                                    value={value}
                                                    name="toVal"
                                                    // onChange={(e) => setToValue(e.target.value)}
                                                    onChange={(e) => changeInput('to', e.target.value)}

                                                />}
                                            />
                                            <div className="flex items-center gap-x-2">
                                                {fromToken=='soto'?
                                                <>
                                                    <img src={PATH+'meto_icon.svg'} alt="" style={{height: "24px"}} />
                                                    <span className="text-white text-sm uppercase">Meto</span>                                                
                                                </>:
                                                <>
                                                    <img src={PATH+'soto_icon.svg'} alt="" style={{height: "24px"}} />
                                                    <span className="text-white text-sm uppercase">Soto</span>
                                                </> 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <Button className="bg-gray-300 hover:bg-bg-gray-200 swap-btn" onClick={changeFromToken}>
                                        <img src={PATH+'swap.svg'} alt="" style={{height: "14px"}} />
                                    </Button>
                                </div>
                                
                                <p className="text-xs font-light">
                                        {/* Your daily limit is <span className="font-bold">500,000 SOTO</span>. During transactions, a <span className="font-bold">3%</span> commission will be applied. */}
                                        
                                    {TokenConvertLimit ? 
                                        <NumberFormat 
                                            value={TokenConvertLimit} 
                                            displayType={'text'} 
                                            thousandSeparator={true} 
                                            renderText={ (value) =>  L.userExchangeInfo && <JsxParser jsx={L.userExchangeInfo.replace("_LIMIT_", '<span className="font-bold">'+value+' SOTO</span>').replace("_PERCENT_", '<span className="font-bold">'+TokenConvertPercent+'%</span>') } />  }  
                                            /> 
                                        : L.youDontHaveDailyLimit
                                        }

                                </p>
                                <div className="text-center">    
                                    <Button disabled={GetUser && GetUser.rank>=5 && FromTokenValue>1?false:true} onClick={()=> setIsModalVisible(true)} className="border-0 mt-5 mb-4 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-bold uppercase avatar_btn">{L.swap}</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    

                    {/* Transfer Card */}
                    <Col xs={24} sm={24} md={12} className="mt-10">
                        <div className="relative rounded-xl px-8 py-6 bg-indigo-900 h-full">
                            
                            <div className="h-full flex flex-col">
                                <div className="flex items-center justify-between gap-x-4">
                                    <span className="uppercase text-white text-sm">{L.transfer}</span>
                                    <div className="flex items-center gap-x-2">
                                        <span className="text-cyan-500 uppercase">{L.rank} {GetUser && GetUser.rank}</span>
                                        <Tooltip placement="topRight" title="Info text" overlayClassName="tooltip-tr">
                                            <i className="icon-info"></i>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="h-full flex flex-col justify-center">
                                    <div className="flex items-center justify-center gap-x-5 mb-8">
                                            <input
                                                    className="bg-indigo-700 pl-4 pr-10 w-80 border-0 input rounded-md text-white w-100"
                                                    type="text" 
                                                    autoComplete="off"
                                                    value={SendToWallet}
                                                    name="fromVal"
                                                    style={{fontSize:'12px'}}
                                                    // onChange={(e) => setFromValue(e.target.value)}
                                                />
                                        </div>


                                    <div className="flex items-center justify-center gap-x-5 mb-8">
                                        <div className="addon-after addon-swap w-100">

                                            <NumberFormat
                                                value={TransferValue}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value) =><input
                                                    className="bg-indigo-700 pl-4 pr-10 w-100 border-0 input rounded-md text-white"
                                                    type="text" 
                                                    autoComplete="off"
                                                    value={value}
                                                    max={MaxTransferValue}
                                                    name="fromVal"
                                                    onChange={(e) => {return  parseInt(e.target.value) <= MaxTransferValue ? setTransferValue(e.target.value) : false }}
                                                />}

                                                
                                            />
                                            <span className="text-cyan-500">{L.max}</span>
                                        </div>
                                        <div className="flex items-center gap-x-2">
                                            <img src={PATH+'meto_icon.svg'} alt="" style={{height: "24px"}} />
                                            <span className="text-white text-sm uppercase">Meto</span>
                                        </div>
                                    </div>
                                    <div className="text-center">     
                                        <Button disabled={GetUser && GetUser.rank>=5 && MaxTransferValue > 1?false:true} onClick={()=>setIsModalVisible(2)} className="border-0 mt-5 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-bold uppercase avatar_btn">
                                            {L.transferToMetamask}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    

                    {/* Import Card */}
                    <Col xs={24} sm={24} md={12} className="mt-10">
                        <div className="relative rounded-xl px-8 py-6 bg-indigo-900 h-full">
                            
                            <div className="h-full flex flex-col">
                                <div className="flex items-center justify-between gap-x-4 mb-8">
                                    <span className="uppercase text-white text-sm">Import</span>
                                </div>

                                <div className="h-full flex flex-col justify-center">

                                    <div className="text-center mb-8">  
                                        <Button onClick={()=>{
                                                
                                                setModalImportMeto(true);
                                            
                                            }} className="border-0 bg-cyan-500 text-indigo-900 hover:bg-cyan-400 rounded-3xl font-bold uppercase avatar_btn">
                                            Import from wallet 
                                        </Button>
                                    </div>
                                    <div className="flex items-center justify-center gap-x-5 mb-8">
                                            <input
                                                    className="bg-indigo-700 pl-4 pr-10 w-80 border-0 input rounded-md text-white w-100"
                                                    type="text" 
                                                    autoComplete="off"
                                                    value={SendToWallet}
                                                    name="fromVal"
                                                    style={{fontSize:'12px'}}
                                                    // onChange={(e) => setFromValue(e.target.value)}
                                                />
                                        </div>

                                </div>
                            </div>
                        </div>
                    </Col> 
                </Row>

                <h3 className="text-gray-300 text-lg font-light">{L.earnings}</h3>
                <p className="font-light mb-0">{L.infNetworkDesc}</p>
                 <Table
                    className="referral-table mt-10"
                    columns={columns}
                    pagination={UserData.length > pageSize && {pageSize}}
                    dataSource={UserData && UserData.length ? dataReferral(UserData) : dataReferral([]) }
                    scroll={{
                        x: 800,
                    }}
                /> 
            </Content>
        </Layout>
    </div>
    </>
}

export default Wallet;