import React, {useEffect, useState} from "react";
import {Col, Layout, Row, Radio, notification} from "antd";
import {PATH} from "../../config";
import {useDispatch, useSelector} from "react-redux";
import GetLandLoading from "../loaders/GetLandLoading";
import MetahutPurchase from "./MetahutPurchase";
import MetahutLeasing from "./MetahutLeasing";
import MetahutRent from "./MetahutRent";
import GetMetahutCarousel from "./GetMetahutCarousel";
import { approveMetahut, getMapSettings, switchNetwork } from "../../redux/actions/metahutActions";
import { login } from "../../redux/features/userSlice";
import { updateUserAccount } from "../../config/CoreMethods";
import GetMetahutLoadings from "../loaders/GetMetahutLoadings";

const { Content } = Layout;

const GetMetahut = () => {

    const dispatch = useDispatch()
    
    let L = useSelector(state => state.settings.L);
    let IsWhiteList = useSelector(state => state.land.IsWhiteList)
    let MetoConvertRate = useSelector ((state) => state.metahut.MapSettings.meto_price);
    let BalanceBusd = useSelector ((state) => state.metahut.BalanceBusd);
    let BalanceMeto = useSelector ((state) => state.metahut.BalanceMeto);
    let MetahutBuyLoading = useSelector ((state) => state.metahut.MetahutBuyLoading);
    
    let MetahutPriceMeto = IsWhiteList ? 3500 : 3900;
    let MetahutPriceBusd = IsWhiteList ? 3700 : 4150;

    let State = useSelector(state => state)
    let FrontNetwork = useSelector ((state) => state.metahut.FrontNetwork);
    let FrontLogin = useSelector ((state) => state.metahut.FrontLogin);
    let BackLogin = useSelector ((state) => state.metahut.BackLogin);
    let paymentTitle;

    let PriceMetoDiscount = 0.10;

    const [paymentType, setPaymentType] = useState('');
    const [metoSpend, setMetoSpend] = useState(0);
    const [TotalMetoWillSpend, setTotalMetoWillSpend] = useState(0);
    const [Token, setToken] = useState({name:'', price:0, priceUsd:0, totalPrice:0, totalPriceUsd:0, discount:0, nftCount:0});    
    const [metahutCount, setMetahutCount] = useState(1);

    const [valueTab, setValueTab] = useState('Purchase');
    const tokenTypes = [
        {name: 'METO', icon: 'meto_icon.svg'},
        {name: 'BUSD', icon: 'bnb.svg'},
        {name: 'USD', icon: 'binance_usd.svg'}
    ]

    const onChangeTab = (e) => {
        setValueTab(e.target.value);
        setPaymentType('');
    };

    const PaymentTypeChange = (e) => {
        setPaymentType(e.target.value);
        updateTokens(e.target.value, metahutCount)
    };

    const updateMetahutCount = (value) => {
        setMetahutCount(value);
        updateTokens(paymentType, value)
    }

    const updateTokens = (paymentType, metahutCount) => {
        let token = {};

        if (paymentType == 1) {
            token = { 
                name: tokenTypes[paymentType-1].name, 
                icon: tokenTypes[paymentType-1].icon, 
                price: (MetoConvertRate ? Math.round(MetahutPriceMeto/MetoConvertRate) : '-'), 
                priceUsd: MetahutPriceMeto, 
                totalPrice: (MetoConvertRate ? Math.round(metahutCount * MetahutPriceMeto/MetoConvertRate) : '-' ),
                totalPriceUsd: metahutCount * MetahutPriceMeto,
                discount : (MetahutPriceBusd * metahutCount * PriceMetoDiscount),
                nftCount : metahutCount,
            }
        }

        else if (paymentType == 2) {
            token = { 
                name: tokenTypes[paymentType-1].name, 
                icon: tokenTypes[paymentType-1].icon, 
                price: (MetahutPriceBusd), 
                priceUsd: MetahutPriceBusd, 
                totalPrice: ( metahutCount * MetahutPriceBusd ),
                totalPriceUsd: metahutCount * MetahutPriceBusd,
                discount : 0,
                nftCount : metahutCount,
            }
        }

        setToken(token);
        console.log('paymentType', paymentType, 'metahutCount', metahutCount, 'Token', token, 'MetahutPriceBusd', MetahutPriceBusd, 'PriceMetoDiscount', PriceMetoDiscount)
    }


    /** --------------------- */
    const calculateData = (is_meto, BalanceBusd, BalanceMeto, convert_price) => {
        let totalPrice=-1, balanceSwiched=-1;

        BalanceBusd = parseFloat(BalanceBusd)
        BalanceMeto = parseFloat(BalanceMeto)

        if (is_meto) {
            totalPrice = metahutCount * Math.round(MetahutPriceMeto/parseFloat(convert_price));
            balanceSwiched = BalanceMeto
            paymentTitle = 'METO'
            notification.destroy()
        } else {

            totalPrice = metahutCount*MetahutPriceBusd; // 105 MetahutPriceBusd
            balanceSwiched = BalanceBusd
            paymentTitle = 'BUSD'
            notification.destroy()
        }

        if (balanceSwiched &&  balanceSwiched <= totalPrice) {
            openNotificationWithIcon('error')
            window.scrollTo(0, 0);

            return false;
        }

        return totalPrice;
    }

    /**----------- */
    const DoBuying = async () => {

        let is_meto = paymentType
        let totalPrice=-1;        

        if (FrontLogin && BackLogin) { 
            
            if ( FrontNetwork ) { 

                if (State.user.user.account == '' || State.user.user.account == null) {
                    return updateUserAccount(State.metahut.Account)
                }
        
                totalPrice = calculateData(is_meto, BalanceBusd, BalanceMeto, MetoConvertRate);
                if (totalPrice !== false) {
                    dispatch( approveMetahut(is_meto, metahutCount, totalPrice) )
                }

            } else {
                dispatch(switchNetwork(state => { 
                    console.log('state', state);
                }))
            }

        } else if (window.ethereum) {
            dispatch(login())
            
        } else {
            openNotificationWithIcon('error')
        } 
    }    


    const openNotificationWithIcon = type => {
        if (typeof paymentTitle != 'undefined') {            

            notification[type]({
                message: 'Error',
                description: L.youDontHaveEnoughToken,
                maxCount: 1,
            });
        } else {
            notification['error']({
                message: 'Error',
                description: L.installMetamask,
                maxCount: 1,
            });
        }
    };


    
    useEffect(() => { 

        dispatch( getMapSettings() );  

        if ( MetoConvertRate  &&  metahutCount) {
            setTotalMetoWillSpend(Math.round(MetahutPriceMeto/parseFloat(MetoConvertRate)) * metahutCount);
            setMetoSpend(Math.round(MetahutPriceMeto/parseFloat(MetoConvertRate)));
        }
        
        updateTokens(paymentType, metahutCount)

    }, [TotalMetoWillSpend, IsWhiteList, BalanceBusd, BalanceMeto, MetoConvertRate, metahutCount])

    return (
        <div className="container mx-auto pt-162 min-h-calc-head h-full">
            <Layout className="bg-transparent h-full">
                <Content className="pt-8 pb-8 pl-10 pr-10 font-mont">
                    <Row gutter={84} className="mt-10">
                        <Col xs={24} sm={24} md={16} lg={15}>
                            <div 
                                className="flex items-center justify-center metahut-intro" 
                                style={{backgroundImage: `url(${PATH + 'metahut-intro.png'})`}}
                            >
                                <h1 className="mb-0 font-bold text-white gland-intro_ttl">
                                    {L.getMetahutTitle}
                                </h1>
                            </div>
                            <div className="gland-info">
                                <Row gutter={44}>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <div className="mb-20">
                                            <h2 className="font-medium font-mont mb-5 text-base uppercase text-white gland-info_ttl">{L.getMetahutLeftColTitle1}</h2>
                                            <p className="font-light font-mont gland-info_text">
                                                {L.getMetahutLeftColText1}
                                            </p>
                                        </div>
                                        <div>
                                            <h2 className="font-medium font-mont mb-5 text-base uppercase text-white gland-info_ttl">
                                                {L.getMetahutLeftColTitle2}
                                            </h2>
                                            <ul className="font-mont font-light gland-info_list">
                                                <li>{L.getMetahutLeftColList1}</li>
                                                <li>{L.getMetahutLeftColList2}</li>
                                                <li>{L.getMetahutLeftColList3}</li>
                                                <li>{L.getMetahutLeftColList4}</li>
                                                <li>{L.getMetahutLeftColList5}</li>
                                                <li>{L.getMetahutLeftColList6}</li>
                                                <li>{L.getMetahutLeftColList7}</li>
                                                <li>{L.getMetahutLeftColList8}</li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <GetMetahutCarousel />
                                        <h2 className="font-medium font-mont mb-5 text-base uppercase text-white gland-info_ttl">{L.getMetahutRightColTitle}</h2>
                                        <ul className="font-mont font-light gland-info_list">
                                            <li>{L.getMetahutRightColList1}</li>
                                            <li>{L.getMetahutRightColList2}</li>
                                            <li>{L.getMetahutRightColList3}</li>
                                            <li>{L.getMetahutRightColList4}</li>
                                            <li>{L.getMetahutRightColList5}</li>
                                            <li>{L.getMetahutRightColList6}</li>
                                            <li>{L.getMetahutRightColList7}</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={9}>
                            <Radio.Group
                                onChange={onChangeTab}
                                value={valueTab}
                                optionType="button"
                                buttonStyle="solid"
                                className="metahut-tab"
                            >
                                <Radio value='Purchase'>{L.getMetahutPurchase}</Radio>
                                <Radio value='Leasing' disabled>{L.getMetahutLeasing}</Radio>
                                <Radio value='Rent' disabled>{L.getMetahutRent}</Radio>
                            </Radio.Group>
                            {valueTab == 'Purchase' &&
                                <div className="flex flex-col gap-y-1">
                                    <div className="gland-pay metahut-pay">
                                        <h4 className="gland-pay_ttl">{L.payment}</h4>
                                        <Radio.Group onChange={PaymentTypeChange} value={paymentType} className="flex flex-col gland-pay_check">
                                            {tokenTypes && tokenTypes.map((item, i) => {
                                                return <Radio value={i+1} key={i+1} className={`gland-pay-token ${i == 2 ? 'opacity-50' : ''}`} disabled={i == 2 ? true : false}>
                                                        <div className="flex items-center gap-x-1 font-normal text-white gland-pay_label">
                                                            {L.payWith} <img src={PATH + item.icon} alt="" style={{height: "16px", marginLeft: "4px"}} /> {item.name}
                                                        </div>
                                                        <span className="font-bold uppercase gland-pay-type">{i != 2 ? 'crypto' : 'fiat'}</span>
                                                    </Radio>

                                            })}
                                        </Radio.Group>
                                    </div>
                                    {paymentType &&
                                    <div className="relative gland-amount metahut-amount">
                                        {MetahutBuyLoading ? <GetMetahutLoadings /> : ""}
                                        <MetahutPurchase
                                        paymentType={paymentType}
                                        Token={Token}
                                        updateMetahutCount={updateMetahutCount}
                                        DoBuying={DoBuying} 
                                        L={L}                                           
                                        />
                                    </div>
                                    }
                                </div>
                            }
                            {valueTab == 'Leasing' &&
                                <div className="flex flex-col gap-y-1">
                                    <div className="gland-pay">
                                        <h4 className="gland-pay_ttl">{L.payment}</h4>
                                        <Radio.Group onChange={PaymentTypeChange} value={paymentType} className="flex flex-col gland-pay_check">
                                            {tokenTypes && tokenTypes.map((item, i) => {
                                                return (
                                                    <Radio value={i+1} key={i+1} className="gland-pay-token">
                                                        <div className="flex items-center gap-x-1 font-normal text-white gland-pay_label">
                                                            {L.payWith} <img src={PATH + item.icon} alt="" style={{height: "16px", marginLeft: "4px"}} /> {item.name}
                                                        </div>
                                                        <span className="font-bold uppercase gland-pay-type">{i != 2 ? 'crypto' : 'fiat'}</span>
                                                        {i == 0 ? 
                                                        <span className="font-bold gland-pay-discount">
                                                            {PriceMetoDiscount*100}% {L.discountOff}
                                                        </span> : ''}
                                                    </Radio>
                                                )
                                            })}
                                        </Radio.Group>
                                    </div>
                                    {paymentType &&
                                    <div className="relative gland-amount">
                                        {MetahutBuyLoading ? <GetLandLoading /> : ""}
                                        <MetahutLeasing
                                            paymentType={paymentType}
                                            updateMetahutCount={updateMetahutCount}
                                            Token={Token}
                                            L={L}
                                        />
                                    </div>
                                    }
                                </div>
                            }
                            {valueTab == 'Rent' &&
                            <div className="flex flex-col gap-y-1">
                                <div className="gland-pay">
                                    <h4 className="gland-pay_ttl">{L.payment}</h4>
                                    <Radio.Group onChange={PaymentTypeChange} value={paymentType} className="flex flex-col gland-pay_check">
                                        {tokenTypes && tokenTypes.map((item, i) => {
                                            return (
                                                <Radio value={i+1} key={i+1} className="gland-pay-token">
                                                    <div className="flex items-center gap-x-1 font-normal text-white gland-pay_label">
                                                        {L.payWith} <img src={PATH + item.icon} alt="" style={{height: "16px", marginLeft: "4px"}} /> {item.name}
                                                    </div>
                                                    <span className="font-bold uppercase gland-pay-type">{i != 2 ? 'crypto' : 'fiat'}</span>
                                                    {i == 0 ? 
                                                    <span className="font-bold gland-pay-discount">
                                                        {PriceMetoDiscount*100}% {L.discountOff}
                                                    </span> : ''}
                                                </Radio>
                                            )
                                        })}
                                    </Radio.Group>
                                </div>
                                {paymentType &&
                                <div className="relative gland-amount">
                                    {MetahutBuyLoading ? <GetLandLoading /> : ""}
                                    <MetahutRent
                                        paymentType={paymentType}
                                        updateMetahutCount={updateMetahutCount}
                                        Token={Token}
                                        L={L}
                                    />
                                </div>
                                }
                            </div>
                            }
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    )
}

export default GetMetahut;