import React from "react";
import {PATH} from "../../../config";
import {Col} from "antd";
import { useSelector } from "react-redux";

const BenefitsItem = () => {
    let L = useSelector  (state => state.settings.L);

    const data = [
        {
            benefitId: 0,
            benefitBg: 'ticket1.png',
            benefitName: L.benefitName1,
            benefitInfo: L.benefitInfo1,
        },
        {
            benefitId: 1,
            benefitBg: 'sell-nft.png',
            benefitName: L.benefitName2,
            benefitInfo: L.benefitInfo2,
        },
        {
            benefitId: 2,
            benefitBg: 'gov1.png',
            benefitName: L.benefitName3,
            benefitInfo: L.benefitInfo3,
        },
        {
            benefitId: 3,
            benefitBg: 'prop3.png',
            benefitName: L.benefitName4,
            benefitInfo: L.benefitInfo4,
        },
        {
            benefitId: 4,
            benefitBg: 'colab.png',
            benefitName: L.benefitName5,
            benefitInfo: L.benefitInfo5,
        },
        {
            benefitId: 5,
            benefitBg: 'iconn2.png',
            benefitName: L.benefitName6,
            benefitInfo: L.benefitInfo6,
        },
        {
            benefitId: 6,
            benefitBg: 'sell1.png',
            benefitName: L.benefitName7,
            benefitInfo: L.benefitInfo7,
        },
        {
            benefitId: 7,
            benefitBg: 'iconn1.png',
            benefitName: L.benefitName8,
            benefitInfo: L.benefitInfo8,
        },
    ]

    return (
        data.map((item) => {
            return (
                <Col xs={24} sm={12} md={8} lg={6} className="gutter-row py-8" key={item.benefitId}>
                    <div className="bg-indigo-900 px-8 py-8 rounded-xl text-center">
                        <img src={PATH + item.benefitBg} alt="" style={{width: "70px", height: "70px"}} className="mb-6" />
                        <h4 className="text-xl text-white">{item.benefitName}</h4>
                        <p className="opacity-50" style={{lineHeight: "24px", fontSize: "16px"}}>{item.benefitInfo}</p>
                    </div>
                </Col>
            )
        })
    )
}

export default BenefitsItem;